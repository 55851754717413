import React from 'react'

import Image from 'next/image'

import Event from 'Utilities/Event'

import Typography from 'Atoms/Typography'

import styles from './ArticlePreview.module.scss'

const ArticlePreview = ({
  imageUrl,
  title,
  summary,
  articleUrl,
  shouldLimitDescriptionToOneLine = false,
  gaEvent,
}) => {
  const descriptionClassName = shouldLimitDescriptionToOneLine
    ? styles.articlePreview__singleLine
    : styles.articlePreview__multiLine

  return (
    <a
      href={articleUrl}
      data-testid={'articlepreview'}
      onClick={() => Event(gaEvent)()}
    >
      <Image className={styles.articleImage} src={imageUrl} width={300} />
      <div className={styles.articlePreviewTitle}>
        <Typography size={1} tone={'contrast'}>
          {title}
        </Typography>
      </div>

      <div className={descriptionClassName}>
        <Typography size={0.75}>{summary}</Typography>
      </div>
    </a>
  )
}

export default ArticlePreview
