import React, { useContext } from 'react'

import { useThemeStore } from 'Stores/ThemeStore'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'

import FadeInFadeOut from 'Components/Transitions/FadeInFadeOut/FadeInFadeOut'

import styles from './Mask.module.scss'
import styled from 'styled-components'

const propTypes = {
  visible: PropTypes.bool,
}

const Mask = ({ visible, ...props }) => {
  const inverseBackgroundColor = useThemeStore(
    (x) => x.inverse_background_color,
  )

  const { hue, saturation, alpha, lightness } = inverseBackgroundColor
  const background_color = `hsla(${hue}, ${saturation}%, ${lightness}%, ${0.5})`

  return (
    <FadeInFadeOut isOpen={visible}>
      <StyledMask
        className={ClassNames(styles.mask)}
        background_color={background_color}
        {...props}
      />
    </FadeInFadeOut>
  )
}

//if you want the hover color to work properly with an inverted button you will need to do some work inside the color finder. (probably pass invert into colorFinder)
const StyledMask = styled.div`
  background-color: ${(props) => props.background_color};
  ${(props) => (props.visible ? 'backdrop-filter: blur(2px)' : '')};
  ${(props) => (props.visible ? '-webkit-backdrop-filter: blur(2px)' : '')};
`

Mask.propTypes = propTypes

export default Mask
