import useCjAffiliateSaveClickParameter from './useCjAffiliateSaveClickParameter'
import useTikTokSaveClickParameter from './useTikTokSaveClickParameter'

function ClickParameters() {
  useCjAffiliateSaveClickParameter()
  useTikTokSaveClickParameter()

  return false
}

export default ClickParameters
