import React, { useContext, useEffect, useState } from 'react'

import Image from 'next/image'

import GetAdditonalFacetInfo from 'Utilities/AdditionalFacets/AdditionalFacets'
import ColorFinder from 'Utilities/ColorFinder'
import LayerColorFinder from 'Utilities/LayerColorFinder'

import { AddToCartExperienceContext } from 'Contexts/AddToCartExperienceContext/AddToCartExperienceContext'
import { useSkuBasePromoData } from 'Contexts/UserSpecificProductDataStore/UserSpecificProductDataStore'

import useFeatureFlag from 'Hooks/useFeatureFlag/useFeatureFlag'
import useRecentHistory from 'Hooks/useRecentHistory.js'

import Button from 'Molecules/Button'

import Panel from 'Atoms/Panel'
import Rating from 'Atoms/Rating'
import Skeleton from 'Atoms/Skeleton'
import Svg from 'Atoms/Svg'
import TextLink from 'Atoms/TextLink'
import Typography from 'Atoms/Typography'

import {
  useSkuBaseFitmentData,
  useSkuBasesOrVariantsShippingData,
} from '../../Contexts/UserSpecificProductDataStore/UserSpecificProductDataStore'
import { useUserStore } from '../../Stores/UserStore/UserStore'
import { useVehicleStore } from '../../Stores/VehicleStore'
import { createClickedSegmentEvent } from '../../Utilities/Instrumentation/Impressions/impressionSegmentEvents'
import { getSuperMarketAffinity } from '../../Utilities/Instrumentation/SegmentUtilities/segmentFunctions'
import { PromotionRibbon } from '../PromotionRibbon/PromotionRibbon'
import styles from './ProductComparisonTable.module.scss'

const ProductComparisonTable = ({ recBlock, dataTestId, treatment }) => {
  const recommendations = recBlock.recommendations
  const style = {
    '--borderColor': LayerColorFinder(2),
    '--backgroundColor': LayerColorFinder(1),
    '--actionColor': ColorFinder('action').color.replace('1)', '0.2)'),
  }

  return (
    <Panel className={styles.scroller} layer={0}>
      <table style={style} cellSpacing={0} className={styles.compareTable}>
        <tbody>
          <ProductComparisonRibbonRow recommendations={recommendations} />
          <ProductComparisonImageAndTitleRow
            recommendations={recommendations}
            miso_id={recBlock.misoId}
            treatment={treatment}
            dataTestId={dataTestId}
          />
          <ProductComparisonRatingRow recommendations={recommendations} />
          <ProductComparisonPriceRow recommendations={recommendations} />
          <ProductComparisonBrandRow recommendations={recommendations} />
          <ProductComparisonFitmentRow
            recommendations={recommendations}
            expectFitmentInfo={recBlock.checkFitment}
          />
          <ProductComparisonAvailabilityRow recommendations={recommendations} />
          <ProductComparisonShippingRow
            recommendations={recommendations}
            expectShippingInfo={recBlock.getShippingEstimate}
          />
          <ProductComparisonPickupRow recommendations={recommendations} />
          <ProductComparisonKeyValues recommendations={recommendations} />
          <ProductComparisonATCRow
            recommendations={recommendations}
            treatment={treatment}
            dataTestId={dataTestId}
          />
        </tbody>
      </table>
    </Panel>
  )
}

const ProductComparisonRibbonRow = ({ recommendations }) => {
  const thisRow = [<th key={`comparison_product_ribbon_start`}></th>]
  for (let i = 1, ilen = recommendations.length; i < ilen; i++) {
    const ribbonText = recommendations[i].ribbonText

    const promoData = useSkuBasePromoData(recommendations[i]?.skuBaseNumber)

    thisRow.push(
      <td key={`comparison_product_ribbon_${i}`}>
        <PromotionRibbon
          textToOveride={i == 1 ? 'Current Item' : null}
          displayText={ribbonText}
          promotionPreview={promoData}
          fontSize={0.75}
          className={styles.ribbon}
        />
      </td>,
    )
  }
  return <tr>{thisRow}</tr>
}

const ProductComparisonFitmentRow = ({
  recommendations,
  expectFitmentInfo,
}) => {
  const selectedCustomerProject = useVehicleStore(
    (x) => x.context.selectedCustomerProject,
  )
  const customerProjects = useVehicleStore((x) => x.context.customerProjects)

  const recsFitmentData = recommendations.map((x) =>
    useSkuBaseFitmentData(x.skuBaseNumber),
  )
  const hasSomeFitmentData = recsFitmentData.some((x) => x)

  const productComparisonTableMightFit = useFeatureFlag(
    'Product_Comparison_Table_MightFit',
  )

  const shouldRenderSkeletons =
    expectFitmentInfo &&
    !hasSomeFitmentData &&
    !recommendations.every(
      (x) => x.excludeFromThisPartFitsMyVehicle || x.comparisonLabels?.length,
    )
  if (
    (!expectFitmentInfo && !recommendations.some((x) => x.fitment)) ||
    !(
      selectedCustomerProject?.vehicleBaseId ||
      selectedCustomerProject?.raceTypeId ||
      selectedCustomerProject?.engineManufacturerId
    )
  ) {
    return false
  }

  if (
    recommendations
      .slice(1)
      .every(
        (x) =>
          x.fitment === 'none' ||
          x.comparisonLabels?.length ||
          x.excludeFromThisPartFitsMyVehicle === true,
      )
  ) {
    return false
  }

  const vehiclePlaceholder = selectedCustomerProject?.hydrated
    ? selectedCustomerProject
    : customerProjects?.[0]

  let placeholder = ''

  if (vehiclePlaceholder?.raceDisplay) {
    placeholder = vehiclePlaceholder.raceDisplay
  } else {
    placeholder = `${vehiclePlaceholder?.yearId ?? vehiclePlaceholder?.year} ${
      vehiclePlaceholder?.makeDisplay ?? vehiclePlaceholder?.make
    } ${vehiclePlaceholder?.modelDisplay ?? vehiclePlaceholder?.model}`
  }

  if (placeholder.includes('null') || placeholder.includes('undefined')) {
    placeholder = ''
  }

  const firstRow = [
    <Typography
      as="th"
      key={`cell_fitment`}
      className={styles.hideMobile}
      size={0.875}
      font={'bold'}
    >
      Fitment{placeholder != '' ? `: ${placeholder}` : ''}
    </Typography>,
  ]
  firstRow.push(
    <Typography
      as="th"
      key={`cell_fitment_header`}
      className={styles.hideDesktop}
      size={0.875}
      font={'bold'}
    >
      Fitment
    </Typography>,
  )

  for (let i = 1, ilen = recommendations.length; i < ilen; i++) {
    firstRow.push(
      <ProductComparisonFitmentColumn
        key={`cell_fitment_${i}`}
        skuBaseNumber={recommendations[i]?.skuBaseNumber}
        shouldRenderSkeletons={shouldRenderSkeletons}
        productComparisonTableMightFit={productComparisonTableMightFit}
      />,
    )
  }

  return (
    <>
      <tr className={styles.dataRow}>{firstRow}</tr>
    </>
  )
}

const ProductComparisonFitmentColumn = ({
  skuBaseNumber,
  shouldRenderSkeletons,
  productComparisonTableMightFit,
}) => {
  const selectedCustomerProject = useVehicleStore(
    (x) => x.context.selectedCustomerProject,
  )
  const fitmentData = useSkuBaseFitmentData(skuBaseNumber)

  if (shouldRenderSkeletons) {
    return (
      <td key={`comparison_fitment_skeletons_${skuBaseNumber}`}>
        <Skeleton height={16} fullWidth />
      </td>
    )
  }

  if (fitmentData == 'none') {
    return <td key={`empty_header_cell_fitment_${skuBaseNumber}`}></td>
  }

  const additionalFacetInfo = productComparisonTableMightFit
    ? GetAdditonalFacetInfo(selectedCustomerProject, fitmentData, skuBaseNumber)
    : null

  return (
    <td key={`comparison_fitment_${skuBaseNumber}`}>
      {fitmentData?.isDirectFit || fitmentData?.isDrivelineFit ? (
        <div className={styles.fitment}>
          <Svg icon={'check'} intent={'positive'} size={0.875} />
          <Typography size={0.875}>Guaranteed To Fit</Typography>
        </div>
      ) : fitmentData?.isUniversalFit ? (
        <div className={styles.universalFitment}>
          <div className={styles.fitmentSvg}>
            <Svg icon={'check'} intent={'action'} size={0.875} />
            <Typography size={0.875}>Universal Fit</Typography>
          </div>
          <Typography size={0.75} tone={'subtle'}>
            Modification may be required
          </Typography>
        </div>
      ) : !fitmentData?.fits && additionalFacetInfo?.ctaText ? (
        <div className={styles.fitment}>
          <Svg icon={'warning'} intent={'warning'} size={0.875} />
          <TextLink
            className={styles.vehicle_fitment_link}
            size={0.875}
            chevron={'right'}
            onClick={additionalFacetInfo?.ctaTextLinkOnClick}
          >
            {additionalFacetInfo?.ctaText}
          </TextLink>
        </div>
      ) : !fitmentData?.fits ? (
        <div className={styles.fitment}>
          <Svg icon={'unavailable'} intent={'negative'} size={0.875} />
          <Typography size={0.875}>May Not Fit</Typography>
        </div>
      ) : null}
    </td>
  )
}

const ProductComparisonShippingRow = ({
  recommendations,
  expectShippingInfo,
}) => {
  const zipCode = useUserStore((x) => x.context.zipCode)

  const recsShippingData = recommendations.map((x) =>
    useSkuBasesOrVariantsShippingData(x.skuBaseNumber),
  )
  const hasSomeShippingData = recsShippingData.some((x) => x)

  const shouldRenderSkeletons = expectShippingInfo && !hasSomeShippingData
  if (!zipCode || (!expectShippingInfo && !hasSomeShippingData)) {
    return false
  }

  const firstRow = [
    <Typography
      as="th"
      key={`comparison_shipping_header_0`}
      size={0.875}
      font={'bold'}
    >
      Shipping
    </Typography>,
  ]
  for (let i = 1, ilen = recsShippingData.length; i < ilen; i++) {
    if (shouldRenderSkeletons) {
      firstRow.push(
        <td key={`comparison_shipping_skeletons_${i}`}>
          <Skeleton height={16} fullWidth />
          <Skeleton height={16} fullWidth />
        </td>,
      )
      continue
    }
    if (recsShippingData[i] == 'none') {
      firstRow.push(<th key={`empty_header_cell_shipping_${i}`}></th>)
      continue
    }

    firstRow.push(
      <td key={`comparison_shipping_${i}`}>
        <Typography size={0.875}>
          {recsShippingData[i]?.shippingDetailsString}
        </Typography>
        {recsShippingData[i]?.label && (
          <Typography size={0.875} tone={'subtle'} inline>
            {recsShippingData[i]?.label + ' '}
          </Typography>
        )}
        {recsShippingData[i]?.dateString && (
          <Typography size={0.875} font={'bold'} inline>
            {recsShippingData[i]?.dateString}
          </Typography>
        )}
      </td>,
    )
  }
  return (
    <>
      <tr className={styles.dataRow}>{firstRow}</tr>
    </>
  )
}

const ProductComparisonPickupRow = ({ recommendations }) => {
  if (!recommendations.some((x) => x.shippingEstimate?.suggestedPickup)) {
    return false
  }
  const firstRow = [
    <Typography
      as="th"
      key="empty_header_cell_pickup"
      size={0.875}
      font={'bold'}
    >
      In-Store Pickup
    </Typography>,
  ]
  for (let i = 1, ilen = recommendations.length; i < ilen; i++) {
    firstRow.push(
      <Typography
        as={'td'}
        size={0.875}
        tone={'subtle'}
        key={`comparison_pickup_${i}`}
      >
        {recommendations[i].shippingEstimate?.suggestedPickup}
      </Typography>,
    )
  }
  return (
    <>
      <tr className={styles.dataRow}>{firstRow}</tr>
    </>
  )
}

const ProductComparisonAvailabilityRow = ({ recommendations }) => {
  if (!recommendations.some((x) => x.availability)) {
    return false
  }

  const firstRow = [
    <Typography
      as="th"
      key="empty_header_cell_availability"
      size={0.875}
      font={'bold'}
    >
      Availability
    </Typography>,
  ]
  for (let i = 1, ilen = recommendations.length; i < ilen; i++) {
    firstRow.push(
      <Typography
        as={'td'}
        size={0.875}
        tone={'subtle'}
        key={`comparison_pickup_${i}`}
      >
        {recommendations[i].availability}
      </Typography>,
    )
  }
  return (
    <>
      <tr className={styles.dataRow}>{firstRow}</tr>
    </>
  )
}

const ProductComparisonKeyValues = ({ recommendations }) => {
  const rows = []

  for (
    let i = 0, ilen = recommendations[0].comparisonLabels.length;
    i < ilen;
    i++
  ) {
    let thisRow = []
    thisRow.push(
      <Typography
        as="th"
        key={`comparison_label_${i}`}
        size={0.875}
        font={'bold'}
      >
        {recommendations[0].comparisonLabels[i]}
      </Typography>,
    )

    for (let j = 1, jlen = recommendations.length; j < jlen; j++) {
      thisRow.push(
        <td key={`comparison_value_rec_${j}_value_${i}`}>
          {recommendations[j].comparisonValues[i] === 'Yes' ? (
            <Svg icon={'check'} intent={'positive'} size={0.875} />
          ) : recommendations[j].comparisonValues[i] === 'No' ? (
            <Svg icon={'unavailable'} intent={'negative'} size={0.875} />
          ) : recommendations[j].comparisonValues[i] === 'N/A' ? null : (
            <Typography
              key={`comparison_value_rec_${j}_value_${i}`}
              size={0.875}
            >
              {recommendations[j].comparisonValues[i]}
            </Typography>
          )}
        </td>,
      )
    }
    thisRow = (
      <tr key={`last_row_comparison_cell_${i}`} className={styles.dataRow}>
        {thisRow}
      </tr>
    )
    rows.push(thisRow)
  }

  return rows
}

const ProductComparisonATCRow = ({
  recommendations,
  dataTestId,
  treatment,
}) => {
  if (!recommendations.some((x) => x.availability)) {
    return false
  }

  const thisRow = [<th key="empty_header_cell_atc"></th>]
  for (let i = 1, ilen = recommendations.length; i < ilen; i++) {
    if (recommendations[i].availability != 'Not Available') {
      thisRow.push(
        <td key={`comparison_product_atc_${i}`}>
          <ProductComparisonATC
            recommendation={recommendations[i]}
            dataTestId={dataTestId}
            treatment={treatment}
          />
        </td>,
      )
    } else {
      thisRow.push(<td></td>)
    }
  }
  return <tr>{thisRow}</tr>
}

const ProductComparisonATC = ({ recommendation, dataTestId, treatment }) => {
  const [waiting, setWaiting] = useState()
  const {
    handleAddToCartPRP,
    isVariantDetailsDrawerOpen,
    isKitDetailsDrawerOpen,
    isAtcDrawerOpen,
  } = useContext(AddToCartExperienceContext)

  useEffect(() => {
    if (isVariantDetailsDrawerOpen || isKitDetailsDrawerOpen || isAtcDrawerOpen)
      setWaiting(false)
  }, [isVariantDetailsDrawerOpen, isKitDetailsDrawerOpen, isAtcDrawerOpen])

  return (
    <Button
      intent={'positive'}
      isWaiting={waiting}
      fill
      invert
      segmentEvent={createClickedSegmentEvent('CompareToolAddToCart')}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        setWaiting(true)
        useRecentHistory.SetMostRecentProductList(
          'rec',
          `${dataTestId}_${treatment}`,
        )
        handleAddToCartPRP(
          recommendation.productPageId,
          recommendation.skuBaseNumber,
          recommendation.productPageUrl,
        )
      }}
      size={'small'}
    >
      <Typography className={styles.addToCart} size={0.875} font={'bold'}>
        Add to Cart
      </Typography>
    </Button>
  )
}

const ProductComparisonBrandRow = ({ recommendations }) => {
  const thisRow = [
    <Typography
      as="th"
      key={'empty_header_cell_image'}
      size={0.875}
      font={'bold'}
    >
      Brand
    </Typography>,
  ]
  for (let i = 1, ilen = recommendations.length; i < ilen; i++) {
    thisRow.push(
      <Typography as="td" size={0.875} key={`comparison_brand_${i}`}>
        {recommendations[i].brand}
      </Typography>,
    )
  }
  return <tr className={styles.dataRow}>{thisRow}</tr>
}

const ProductComparisonPriceRow = ({ recommendations }) => {
  const thisRow = [<th key="empty_header_cell_price"></th>]
  for (let i = 1, ilen = recommendations.length; i < ilen; i++) {
    thisRow.push(
      <Typography
        as="td"
        size={0.875}
        font={'bold'}
        key={`comparison_pricing_${i}`}
      >
        {recommendations[i].pricing?.priceString}
        {recommendations[i].unit && recommendations[i].pricing?.priceString && (
          <Typography
            className={styles.units}
            size={0.75}
            tone={'subtle'}
            inline
          >
            {` /${recommendations[i].unit}`}
          </Typography>
        )}
        {recommendations[i].pricing?.strikeThroughPrice && (
          <Typography
            size={0.75}
            tone={'subtle'}
            strikethrough
            dataTesting={`strikethrough_price_${recommendations[i].skuBaseNumber}`}
          >
            {recommendations[i].pricing.strikeThroughPrice}
          </Typography>
        )}
      </Typography>,
    )
  }
  return <tr className={styles.productInfoRow}>{thisRow}</tr>
}

const ProductComparisonRatingRow = ({ recommendations }) => {
  const thisRow = [<th key="empty_header_cell_rating"></th>]
  for (let i = 1, ilen = recommendations.length; i < ilen; i++) {
    thisRow.push(
      <td key={`comparison_rating_${i}`}>
        {recommendations[i].reviewCount > 0 && (
          <>
            <Rating ratingValue={recommendations[i].rating} size={1} />
            <Typography
              size={0.75}
              tone={'subtle'}
              inline
              className={styles.review_count}
            >
              {` (${recommendations[i].reviewCount || 0})`}
            </Typography>
          </>
        )}
      </td>,
    )
  }
  return <tr className={styles.productInfoRow}>{thisRow}</tr>
}

const ProductComparisonImageAndTitleRow = ({
  recommendations,
  dataTestId,
  treatment,
  miso_id,
}) => {
  const thisRow = [<th key="empty_header_cell_image"></th>]
  for (let i = 1, ilen = recommendations.length; i < ilen; i++) {
    let price = null
    try {
      price = parseFloat(
        (recommendations[i].pricing?.priceString || 0).replace('$', ''),
      )
    } catch (e) {
      console.error(e)
    }
    thisRow.push(
      <td key={`comparison_image_and_title_${i}`}>
        <TextLink
          href={recommendations[i].productPageUrl}
          onClick={() => {
            useRecentHistory.SetMostRecentProductList(
              'rec',
              `${dataTestId}_${treatment}`,
            )

            if (miso_id) {
              useRecentHistory.SetRecentMisoId(
                miso_id,
                recommendations[i].skuBaseNumber,
              )
            }
          }}
          segmentEvent={{
            event: 'Product Clicked',
            properties: {
              list_id: dataTestId,
              product_id: recommendations[i].skuBaseNumber,
              sku: recommendations[i].skuBaseNumber,
              category: recommendations[i].segmentCategorization,
              name: recommendations[i].displayTitle,
              brand: recommendations[i].brand,
              //variant: null,
              price: price,
              position: i,
              url: recommendations[i].productPageUrl,
              image_url: recommendations[i].imageLink,
              availability: recommendations[i].availabilityEnum,
              rating: recommendations[i].reviewCount
                ? recommendations[i].rating
                : 0,
              reviews: recommendations[i].reviewCount,
              audience_affinity: getSuperMarketAffinity(recommendations[i]),
              miso: {
                miso_id: miso_id,
              },
            },
          }}
        >
          <div className={styles.imageWrapper}>
            <Image
              src={`https:${
                recommendations[i].imageLink
                  ? recommendations[i].imageLink
                  : '//content.speedwaymotors.com/OtherImages/missingimage2.jpg'
              }`}
              alt={recommendations[i].displayTitle}
              width={124}
              height={124}
            />
          </div>
          <Typography
            size={0.875}
            tone={'subtle'}
            href={recommendations[i].productPageUrl}
          >
            {`#${recommendations[i].skuBaseNumber}`}
          </Typography>
          <Typography
            size={1}
            tone={'contrast'}
            font={'bold'}
            href={recommendations[i].productPageUrl}
            data-testid={`product_card_title_${recommendations[i].displayTitle}`}
          >
            {recommendations[i].displayTitle}
          </Typography>
        </TextLink>
      </td>,
    )
  }
  return <tr className={styles.productImageRow}>{thisRow}</tr>
}

export default ProductComparisonTable
