import React from 'react'

import AppName from 'AppName'

import Button from 'Molecules/Button'

const FeedbackButton = ({
  name,
  isInternal,
  children,
  onClick,
  isPositive,
  MFESpecificData,
  actionText,
  searchProvider,
  isClicked,
  misoId,
}) => {
  let text = ''
  if (typeof window !== 'undefined') {
    text = `${window.location.pathname}${window.location.search}`
  }

  const qualifier = isPositive ? 'Positive' : 'Negative'

  const segmentEvent = {
    event: 'Feedback Clicked',
    properties: {
      emotion: qualifier.toLowerCase(),
      element: name,
    },
  }

  if (misoId) {
    segmentEvent.properties.miso = { miso_id: misoId }
  }

  if (searchProvider) segmentEvent.properties.search_provider = searchProvider

  return (
    <Button
      brand={isClicked ? 'primary' : null}
      intent={'subdued'}
      size={'small'}
      segmentEvent={segmentEvent}
      onClick={() => {
        if (!isClicked) {
          onClick(isPositive)
        }
      }}
      className={`lr-${name}Feedback-${qualifier}`}
      data-testid={`${name}Feedback-${qualifier}`}
      aria-label={`${qualifier} ${name} Feedback`}
    >
      {children}
    </Button>
  )
}

export default FeedbackButton
