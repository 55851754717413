import { useEffect, useState } from 'react'

import CookieReader, {
  GetBrowserUserValues,
} from 'Utilities/CookieUtilties/CookieReader'

const useGlobalPrivacyControl = () => {
  const [isGpcEnabled, setIsGpcEnabled] = useState(true)

  useEffect(() => {
    const { isGpcEnabled: isGpcEnabledValue } = GetBrowserUserValues()

    setIsGpcEnabled(isGpcEnabledValue)
  }, [])

  return { isGpcEnabled }
}

export default useGlobalPrivacyControl
