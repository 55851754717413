import React from 'react'

import List from 'Molecules/List'

import Divider from 'Atoms/Divider'
import Typography from 'Atoms/Typography'

import ClearSelection from '../ClearSelection'
import styles from './ExistingProjectHeading.module.scss'

const ExistingProjectHeading = ({ type, total, haveClearSelection }) => {
  let headingText = null
  if (type === 'CTA') {
    headingText = 'Add a Vehicle'
  } else {
    // s Adds an s to pluralize headingText if needed
    const s = total > 1 ? 's' : ''
    // Format string create the headingText with type of project, plurality, and amount
    headingText = `${type}${s} (${total})`
  }

  return (
    <>
      <List itemLayout="horizontal" className={styles.garageHeaderFlex}>
        <Typography
          className={styles.garageHeading}
          tone={'normal'}
          size={1.25}
          font={'heading'}
        >
          {headingText}
        </Typography>
        {haveClearSelection && <ClearSelection />}
      </List>
      <Divider className={styles.garageDivider} tone={'superfluous'} />
    </>
  )
}

export default ExistingProjectHeading
