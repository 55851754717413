import { useContext } from 'react'

import { TrackingContext } from '../../Contexts/TrackingContext/TrackingContext'

const useNavigationState = () => {
  const { toggleNavigatingState, isNavigating } = useContext(TrackingContext)

  const tryDisableGtmAutoLinkTrackingForInternalNavigation = (href) => {
    const isNavigatingOnDomain =
      href && href.startsWith('/') && !href.startsWith('/api')
    if (
      isNavigatingOnDomain &&
      window?.google_tag_manager?.autoEventsSettings?.lcl
    ) {
      // this is a long story... (and a work around/hack)
      // GTM isn't explicitly implmeneted on our page, however the "new" gtag automatically pulls in GTM and is built upon it
      // GTM automatically instruments all link clicks to send an event
      // the production of these events (not the sending) is VERY costly to INP (50ms on 6x CPU slowdown) (40% of the click time)
      // I can see no logical reason to send these events because we produce page views ourselves
      // google documentation also suggests that they are for OUTBOUND traffic
      // google seems to suggest that they should be for outbound links only
      // there's an option to disable them in GTM by domain, but it doesn't stop the production of the events (only sending)
      // setting this setting right before the click is done works.
      window.google_tag_manager.autoEventsSettings.lcl = {}
    }
  }

  return {
    isNavigating,
    showNavigation: () => toggleNavigatingState(true),
    hideNavigation: () => toggleNavigatingState(false),
    tryDisableGtmAutoLinkTrackingForInternalNavigation,
  }
}

export default useNavigationState
