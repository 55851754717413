import React, { useRef, useState } from 'react'

import { useUserStore } from 'Stores/UserStore/UserStore'
import ErrorBoundary, {
  WSODErrorBoundary,
} from 'UtilityComponents/ErrorBoundary'
import { del, get, set } from 'idb-keyval'
// we shim an implementation of "next/dynamic" in DS9 in webpack
// we HAVE to use magic comments https://loadable-components.com/docs/babel-plugin/#magic-comments
import dynamic from 'next/dynamic'
import { onCLS, onINP } from 'web-vitals/attribution'

import CookieReader from 'Utilities/CookieUtilties/CookieReader'
import Event from 'Utilities/Event'
import Retry from 'Utilities/Retry'

import LayoutProvider from 'Contexts/LayoutProvider'
import { NavigationContext } from 'Contexts/Navigation/NavigationContext'

import useRecentHistory from 'Hooks/useRecentHistory'
import useWindow from 'Hooks/useWindow'

import DeferredLoadingWrapper from 'Components/Transitions/DeferredLoadingWrapper/DeferredLoadingWrapper'

import NavigatingProgressBar from '../Atoms/NavigatingProgressBar/NavigatingProgressBar'
import { withContexts } from '../HigherOrderComponents/withContext/withContext'
import useCLSLogger from '../Hooks/CoreWebVitals/CLSLogger'
import useINPLogger from '../Hooks/CoreWebVitals/INPLogger'
import useAcquisitionType from '../Hooks/useAcquisition'
import FeedbackTab from '../Organisms/AppFeedbackDialog/AppFeedbackDialog'
import IosInstallPrompt from '../Organisms/IosInstallPrompt/IosInstallPrompt'
import PushNoteUserPermissionPrompt from '../Organisms/PushNoteUserPermissionPrompt/PushNoteUserPermissionPrompt'
import { useThemeStore } from '../Stores/ThemeStore'
import EmailSignUpModal from './EmailSignUpModal'
import ClickParameters from './Head/Scripts/ClickParameters/ClickParameters'
import GoogleAds from './Head/Scripts/GoogleAds'
import GoogleAnalytics4 from './Head/Scripts/GoogleAnalytics4'
import IsClutchLoaded from './Head/Scripts/IsClutchLoaded'
import Miso from './Head/Scripts/Miso/Miso'
import NoibuScript from './Head/Scripts/Noibu'
import Segment from './Head/Scripts/Segment'
import Tracking from './Head/Scripts/Tracking'
import Header from './Header'
import Loader from './Loader/Loader'
import SsrScripts from './Next/SsrScripts'
import SeoClarityScripts from './Next/seoClarityScripts'
import OfflineStatus from './OfflineStatus'
import VariantDetailsDrawer from './drawers/VariantDetailsDrawer'
import AddToCartDrawer from './drawers/addToCartDrawer'
import KitDetailsDrawer from './drawers/kitDetailsDrawer'
import ListDrawer from './drawers/listDrawer'
import SelectIndexOptionsDrawer from './drawers/selectIndexOptionsDrawer/selectIndexOptionsDrawer'

const Layout = ({
  state,
  children,
  reserveBannerSpace,
  freeShippingThreshold,
  crispEnabled = false,
  seoClarityEnabled = true,
  dontAutoSendClarityInitEvent = false,
  cwvEventsEnabled = true,
  showPwaBackButton,
}) => {
  const renderCounter = useRef(0)
  renderCounter.current = renderCounter.current + 1
  console.debug(`Layout Renders: ${renderCounter.current}`)

  const [isCondensedFooter, setIsCondensedFooter] = useState(false)

  const stateObject = JSON.parse(state)
  try {
    if (!String.prototype.replaceAll) {
      String.prototype.replaceAll = function (str, newStr) {
        if (
          Object.prototype.toString.call(str).toLowerCase() ===
          '[object regexp]'
        ) {
          return this.replace(str, newStr)
        }
        return this.replace(new RegExp(str, 'g'), newStr)
      }
    }
  } catch (err) {
    console.error(`Error setting up replaceall polyfill`, err)
  }

  useWindow((window) => {
    //this puts indexdb functions on the windows for tng to use
    window.indexGet = get
    window.indexSet = set
    window.indexDelete = del
    if (window.location.href.includes('.com/buy')) setIsCondensedFooter(true)
    else if (window.location.href.includes('/emailsignup'))
      setIsCondensedFooter(true)

    window.chatOpenCallback = function () {
      Event({
        category: 'Chat',
        action: 'Clicked',
        label: 'Chat',
      })

      try {
        document.getElementById('pdpSkeleton').style.display = 'none'
      } catch (err) {
        console.error(err)
      }
    }

    function perfObserver(list, observer) {
      try {
        window.smi_web_vitals = window.smi_web_vitals || {}

        list.getEntries().forEach((entry) => {
          if (entry?.entryType == 'paint') {
            window.smi_web_vitals.FCP = entry.startTime
          } else if (entry?.entryType == 'largest-contentful-paint') {
            window.smi_web_vitals.LCP = entry.startTime
          } else if (entry?.entryType == 'layout-shift') {
            let shiftValueToAdd = 0
            if (!entry.hadRecentInput) shiftValueToAdd = entry.value
            window.smi_web_vitals.CLS =
              shiftValueToAdd + (window.smi_web_vitals.CLS || 0)
            if (window.smi_web_vitals.CLS > 0.1 && shiftValueToAdd > 0) {
              //log so we can have visibility in logrocket
              // console.log(`CLS threshold exceeded`)
              // console.log('CLS', window.smi_web_vitals.CLS)
            }
          }
        })
      } catch (err) {
        console.error(err)
      }
    }

    try {
      const observer = new PerformanceObserver(perfObserver)
      observer.observe({
        entryTypes: ['paint', 'largest-contentful-paint', 'layout-shift'],
      })
    } catch (err) {
      console.error(err)
    }
  })

  const inpLogger = useINPLogger()
  const inpConsoleLogger = useINPLogger(true)
  const clsLogger = useCLSLogger()

  useWindow((window) => {
    //hard coding this off for now
    if (cwvEventsEnabled) {
      onINP(inpLogger)
      if (false) {
        onCLS(clsLogger)
      }
    }

    const inpCookie = CookieReader('LogINP')
    if (inpCookie) {
      onINP(inpConsoleLogger)
    }
  })

  return (
    <>
      <LayoutProvider
        headerRedesignEnabled={stateObject.headerRedesignEnabled}
        stateObject={stateObject}
        services={stateObject.services}
        freeShippingThreshold={freeShippingThreshold}
      >
        <AppBody
          stateObject={stateObject}
          children={children}
          isCondensedFooter={isCondensedFooter}
          reserveBannerSpace={reserveBannerSpace}
          crispEnabled={crispEnabled}
          seoClarityEnabled={seoClarityEnabled}
          dontAutoSendClarityInitEvent={dontAutoSendClarityInitEvent}
          showPwaBackButton={showPwaBackButton}
        />
      </LayoutProvider>
      {/* Make sure this is always last */}
      <IsClutchLoaded />
    </>
  )
}

function LayoutLoader() {
  const isLoading = useThemeStore((x) => x.isLoading)
  return <Loader isLoading={isLoading} />
}

const AppBodyGuts = ({
  stateObject,
  children,
  isCondensedFooter,
  reserveBannerSpace,
  crispEnabled,
  seoClarityEnabled,
  dontAutoSendClarityInitEvent,
  setPdpSkeleton,
  showPwaBackButton,
}) => {
  const isSignedUpForEmails = useUserStore((x) => x.context.isSignedUpForEmails)
  const setUserAcquisition = useUserStore((x) => x.setUserAcquisition)

  var html = stateObject.microFrontEndHtml
  const currentTime = new Date()

  useWindow((window) => {
    useAcquisitionType((acquisition) => {
      useRecentHistory.addOrUpdateAttribution(
        acquisition ? { acquisition } : {},
      )
      setUserAcquisition(acquisition)
    })

    window.addEventListener('pageshow', () => {
      setPdpSkeleton(false)
    })
    setPdpSkeleton(false)
  })

  return (
    <>
      <SsrScripts />
      <LayoutLoader />
      <NavigatingProgressBar />
      <FeedbackTab />
      {stateObject.headerRedesignEnabled && (
        <>
          <div style={{ zIndex: '1', minHeight: '75vh' }}>
            <div style={{ position: 'relative' }}>
              <Header
                currentTime={currentTime}
                services={stateObject.services}
                user={stateObject.userGuid}
                reserveBannerSpace={reserveBannerSpace}
                showPwaBackButton={showPwaBackButton}
              />
              {children}
            </div>
            {/* https://github.com/facebook/react/issues/10923#issuecomment-338715787 */}
            <div
              dangerouslySetInnerHTML={{ __html: html }}
              suppressHydrationWarning
            />
          </div>
          <KitDetailsDrawer />
          <AddToCartDrawer />
          <SelectIndexOptionsDrawer />
          <ListDrawer />
          <VariantDetailsDrawer />
        </>
      )}
      {/* we only have headerRedesignEnabled = false on checkout, it doesnt use new header */}
      {!stateObject.headerRedesignEnabled ? (
        <div style={{ zIndex: '-1' }}>
          {children}
          {/* https://github.com/facebook/react/issues/10923#issuecomment-338715787 */}
          <div
            dangerouslySetInnerHTML={{ __html: html }}
            suppressHydrationWarning
          />
        </div>
      ) : (
        <div style={{ zIndex: '-1' }} />
      )}
      <GoogleAnalytics4 />
      <GoogleAds />
      <ClickParameters /> {/*  tiktok, cj ... things we track ourselves */}
      <Segment />
      <Miso />
      <Tracking />
      <NoibuScript />
      <ErrorBoundary>
        <DeferredLoadingWrapper
          loadPadding={'500px'}
          onLoad={() => {
            return dynamic(
              /* #__LOADABLE__ */ () => Retry(() => import('./Footer')),
            )
          }}
          condensed={isCondensedFooter}
          currentTime={currentTime}
          isSignedUpForEmails={isSignedUpForEmails}
          services={stateObject.services}
        />

        <EmailSignUpModal />
        <IosInstallPrompt />
        <PushNoteUserPermissionPrompt />
      </ErrorBoundary>
      {seoClarityEnabled ? (
        <SeoClarityScripts
          dontAutoSendClarityInitEvent={dontAutoSendClarityInitEvent}
        />
      ) : (
        false
      )}
      <OfflineStatus />
    </>
  )
}

const AppBody = withContexts(
  AppBodyGuts,
  [NavigationContext],
  (navigationContext) => ({
    setPdpSkeleton: navigationContext.setPdpSkeleton,
  }),
)

const LayoutWithErrorHandling = (props) => {
  return (
    <WSODErrorBoundary>
      <Layout {...props} />
    </WSODErrorBoundary>
  )
}

export default LayoutWithErrorHandling
