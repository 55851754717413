import React, { useEffect, useState } from 'react'

import { useVehicleStore } from 'Stores/VehicleStore'
import { get, set } from 'idb-keyval'

import Event from 'Utilities/Event'
import { createClickedSegmentEvent } from 'Utilities/Instrumentation/Impressions/impressionSegmentEvents'

import Carousel from 'Organisms/Carousel'

import Button from 'Molecules/Button'

import Panel from 'Atoms/Panel'
import Typography from 'Atoms/Typography'

import { buildNewSearchUrl } from '../SaytDropdown/SaytUtilities/setNewSearchUrl'
import styles from './RecentSearches.module.scss'

const recentSearchesKey = 'RecentSearches'

const RecentSearches = () => {
  const [recentSearches, setRecentSearches] = useState([])

  useEffect(() => {
    async function getRecentSearches() {
      try {
        const recentSearchesFromIndexDB = await get(recentSearchesKey)
        setRecentSearches(recentSearchesFromIndexDB)
      } catch (err) {
        console.error(err)
      }
    }
    getRecentSearches()
  }, [])

  const selectedCustomerProject = useVehicleStore(
    (x) => x.context.selectedCustomerProject,
  )

  return recentSearches && recentSearches.length > 0 ? (
    <Panel
      layer={0}
      className={styles.recentSearchesPanel}
      data-testid={'recentSearchesTestId'}
    >
      <div className={styles.hideMobile}>
        <div className={styles.headingText}>
          <Typography size={1.25} font={'bold'}>
            Recent Searches
          </Typography>
          <div
            onClick={() => {
              clearRecentSearches(setRecentSearches)
              Event({
                category: 'Header',
                action: 'Clear Search History Clicked',
              })()
            }}
          >
            <Typography
              intent={'action'}
              size={0.875}
              font={'heading'}
              className={styles.clearSearchHistory}
              dataTesting={'ClearRecentSearches'}
            >
              Clear History
            </Typography>
          </div>
        </div>

        <hr />
      </div>
      <Carousel>
        {recentSearches.map((recentSearch, index) => {
          return (
            <Carousel.Item key={`recentSearch-carousel-item-${index}`}>
              <Button
                key={`recentSearch-${index}`}
                className={styles.recentSearchButton}
                icon={'magnifyingglass'}
                intent={'subdued'}
                size={'small'}
                text={recentSearch}
                href={buildNewSearchUrl(
                  recentSearch,
                  selectedCustomerProject.customerProjectSearchUrl,
                )}
                segmentEvent={createClickedSegmentEvent('RecentSearchTerm')}
                testingName={'RecentSearch#' + index}
                onClick={() => updateRecentSearches(recentSearch)}
              />
            </Carousel.Item>
          )
        })}
      </Carousel>
    </Panel>
  ) : (
    false
  )
}

const clearRecentSearches = (setRecentSearches) => {
  set(recentSearchesKey, [])
  setRecentSearches([])
}

const updateRecentSearches = (searchTerm) => {
  if (!searchTerm.trim()) {
    return
  }
  get(recentSearchesKey)
    .then((recentSearches) => {
      if (recentSearches == null || !recentSearches.length) {
        var searchTerms = [searchTerm]
        set(recentSearchesKey, searchTerms)
      } else {
        var existingSearchTerms = recentSearches
        if (existingSearchTerms.includes(searchTerm)) {
          existingSearchTerms.splice(existingSearchTerms.indexOf(searchTerm), 1)
          existingSearchTerms.unshift(searchTerm)
          set(recentSearchesKey, existingSearchTerms)
        } else {
          existingSearchTerms.unshift(searchTerm)
          set(
            recentSearchesKey,
            existingSearchTerms.length > 5
              ? existingSearchTerms.slice(0, 5)
              : existingSearchTerms,
          )
        }
      }
    })
    .catch((err) => {
      console.error(err)
    })
}

export { RecentSearches, updateRecentSearches, clearRecentSearches }
