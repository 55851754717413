import React, { Fragment, useEffect, useState } from 'react'

import LayerColorFinder from 'Utilities/LayerColorFinder'

import useSegment from 'Hooks/useSegment'

import Typography from 'Atoms/Typography'

import styles from './Checkbox.module.scss'
import styled from 'styled-components'

const CheckboxGroup = ({
  options,
  brand,
  intent,
  id,
  size,
  font,
  isVertical = false,
  checkboxSize = 1,
  noTransition = false,
}) => {
  const activeColor = LayerColorFinder(undefined, undefined, brand, intent)
  const disabledColor = LayerColorFinder(2)

  const checkboxes = options.map((option, index) => {
    return (
      <Checkbox
        option={option}
        brand={brand}
        intent={intent}
        activeColor={activeColor}
        disabledColor={disabledColor}
        checkboxSize={checkboxSize}
        id={id}
        dataTestId={option.dataTestId}
        index={index}
        onChange={option.onChange}
        size={size}
        font={font}
        key={`${option.label}_${index}`}
        ariaLabel={option.ariaLabel}
        noTransition={noTransition}
        isVertical={isVertical}
      />
    )
  })

  return checkboxes
}

const Checkbox = ({
  option,
  activeColor,
  disabledColor,
  id,
  dataTestId,
  index,
  onChange,
  font,
  size = 1,
  checkboxSize,
  ariaLabel,
  noTransition = false,
  isVertical,
}) => {
  const [active, setActive] = useState(option.checked)
  const { sendSegmentTrackEvent } = useSegment()

  useEffect(() => {
    if (option.checked !== active) {
      if (onChange) {
        setActive(option.checked)
        onChange(option.checked)
      }
    }
  }, [option.checked])

  const handleOnClick = () => {
    if (onChange) {
      onChange(!active)
    }
    setActive(!active)

    if (option.segmentEvent) {
      sendSegmentTrackEvent(option.segmentEvent)
    }

    if (option.href && typeof window !== 'undefined') {
      window.location.href = option.href
    }
  }

  const accentColor = active && !option.disabled ? activeColor : disabledColor

  let label = (
    <Typography
      as={'label'}
      htmlFor={`${id}-${index}`}
      size={size}
      font={font}
      className={styles.label}
    >
      {option.label}
    </Typography>
  )

  if (option.render) label = option.render(option, id, index)

  const CheckboxStyleInput = () => (
    <>
      <StyledInput
        className={noTransition ? styles.noTransition : styles.checkbox}
        type="checkbox"
        id={`${id}-${index}`}
        data-testid={dataTestId}
        name={`${id}`}
        height={5}
        width={5}
        value={option.value}
        checked={active}
        disabled={option.disabled}
        accentColor={accentColor}
        checkboxSize={checkboxSize}
        onClick={handleOnClick}
        aria-label={ariaLabel}
        onChange={() => {}}
      />
      {label}
      <br />
    </>
  )

  return (
    <Fragment>
      {isVertical ? (
        <div className={styles.verticalCheckbox}>
          <CheckboxStyleInput />
        </div>
      ) : (
        <CheckboxStyleInput />
      )}
    </Fragment>
  )
}

const StyledInput = styled.input`
  accent-color: ${(props) => props.accentColor};
  width: ${(props) => props.checkboxSize}rem;
  min-width: ${(props) => props.checkboxSize}rem;
  height: ${(props) => props.checkboxSize}rem;
  cursor: pointer;
`

export default CheckboxGroup
