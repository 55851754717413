const memoryCaches = {}

const useMemoryCache = (cacheName, func, keys) => {
  if (!(cacheName in memoryCaches)) {
    memoryCaches[cacheName] = {} // create an empty "cache directory"
  }
  const cache = memoryCaches[cacheName]

  const key = JSON.stringify(keys)
  if (!(key in cache)) {
    cache[key] = func()
  }
  return cache[key]
}

export default useMemoryCache
