const BASE_SEARCH_URL = '/Search?query='

function buildNewSearchUrl(searchTerm, searchUrl) {
  if (!searchTerm) return false
  searchTerm = searchTerm.replaceAll(`'`, '')
  searchTerm = encodeURIComponent(searchTerm)
  let updatedSearchUrl = ''
  if (!searchUrl) {
    updatedSearchUrl = `${BASE_SEARCH_URL}${searchTerm}`
  } else {
    const splitUrl = searchUrl.split('facet=')
    updatedSearchUrl = `${BASE_SEARCH_URL}${searchTerm}&facet=${decodeURIComponent(decodeURIComponent(splitUrl[1]))}`
  }

  return updatedSearchUrl
}

export { buildNewSearchUrl }
