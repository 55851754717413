import React from 'react'

import reactDeepEqual from 'fast-deep-equal/es6/react'

export const deepEqual = reactDeepEqual

export const deepMemo = (Component) =>
  React.memo(Component, (prevProps, nextProps) =>
    deepEqual(prevProps, nextProps),
  )

export const withMemo = (Component, getProps, options) => {
  const MemoizedComponent = options?.deepComparison
    ? deepMemo(Component)
    : React.memo(Component)

  return (props) => {
    const mappedProps = getProps ? getProps() : {}

    if (options?.disableMemo) {
      return <Component {...props} {...mappedProps} />
    }
    return <MemoizedComponent {...props} {...mappedProps} />
  }
}
