import React, { useEffect } from 'react'

import ClassNames from 'classnames'
import PropTypes from 'prop-types'

import Brands from 'Constants/Brand'
import Intents from 'Constants/Intent'
import Sizes from 'Constants/Size'

import InlineSpinner from '../InlineSpinner/InlineSpinner'
import styles from './CoveringSpinner.module.scss'

const propTypes = {
  isLoading: PropTypes.bool,
  brand: PropTypes.oneOf(Brands),
  intent: PropTypes.oneOf(Intents),
  size: PropTypes.oneOf(Sizes),
}

const CoveringSpinner = ({
  isLoading = true,
  brand,
  size = 1,
  intent,
  opacityWhenLoading = 0.5,
  className,
  children,
  fillWidth,
}) => {
  const contentClassName = className
    ? `${styles.content} ${className}`
    : styles.content

  useEffect(() => {
    window.addEventListener('pageshow', () => {
      isLoading = false
    })
  }, [])

  return (
    <>
      <div
        className={ClassNames(
          styles.wrapper,
          fillWidth ? styles.fillWidth : undefined,
        )}
      >
        <div
          className={contentClassName}
          style={{ opacity: isLoading ? opacityWhenLoading : 1 }}
        >
          {children}
        </div>

        <div
          className={styles.blocker}
          style={{ display: isLoading ? 'block' : 'none' }}
        ></div>
        <div className={styles.floater}>
          <InlineSpinner
            isLoading={isLoading}
            brand={brand}
            size={size}
            intent={intent}
          />
        </div>
      </div>
    </>
  )
}

CoveringSpinner.propTypes = propTypes

export default CoveringSpinner
