import React from 'react'

import AppName from 'AppName'
import ErrorBoundary from 'UtilityComponents/ErrorBoundary'
import classNames from 'classnames'

import Expander from 'Organisms/Expander/Expander'

import RecommendationCard from 'Molecules/RecommendationCard'
import RecommendationsSkeleton from 'Molecules/RecommendationsSkeleton'

import Divider from 'Atoms/Divider'
import Typography from 'Atoms/Typography'

import styles from './RecommendationsGrid.module.scss'

const RecommendationsGrid = ({
  recBlock,
  dataTestId,
  isExpandable,
  blockIndex,
  isLoading,
  numberOfSkeletons,
  numberOfSkeletonSections,
  skeletonType,
  useDivider,
  useSubtitle,
  useItemCount,
  horizontalCard,
  treatment,
}) => {
  return (
    <ErrorBoundary>
      {recBlock?.isExpandable || isExpandable ? (
        <Expander
          count={recBlock.recommendations?.length}
          closedHeight={'300px'}
          buttonProps={{ invert: true }}
          gradient={false}
          data-testid={`${dataTestId}_grid_${blockIndex}_expander`}
        >
          <RecommendationsGridBase
            recBlock={recBlock}
            isLoading={isLoading}
            numberOfSkeletons={numberOfSkeletons}
            numberOfSkeletonSections={numberOfSkeletonSections}
            skeletonType={skeletonType}
            dataTestId={dataTestId}
            blockIndex={blockIndex}
            useDivider={useDivider}
            useSubtitle={useSubtitle}
            useItemCount={useItemCount}
            horizontalCard={horizontalCard}
            treatment={treatment}
          />
        </Expander>
      ) : (
        <RecommendationsGridBase
          recBlock={recBlock}
          isLoading={isLoading}
          numberOfSkeletons={numberOfSkeletons}
          numberOfSkeletonSections={numberOfSkeletonSections}
          skeletonType={skeletonType}
          dataTestId={dataTestId}
          blockIndex={blockIndex}
          useDivider={useDivider}
          useSubtitle={useSubtitle}
          useItemCount={useItemCount}
          horizontalCard={horizontalCard}
          treatment={treatment}
        />
      )}
    </ErrorBoundary>
  )
}

const RecommendationsGridBase = ({
  recBlock,
  dataTestId,
  blockIndex,
  isLoading,
  numberOfSkeletons,
  numberOfSkeletonSections,
  skeletonType,
  useDivider,
  useSubtitle,
  useItemCount,
  horizontalCard,
  treatment,
}) => {
  const contentType = recBlock.recommendations?.[0]?.__typename?.toLowerCase()

  function getColumnStyles(contentType, horizontalCard) {
    if (contentType == 'landingpage') {
      return classNames(
        styles.rec_wrapper_base,
        styles.landing_page_rec_wrapper,
      )
    } else if (horizontalCard) {
      return classNames(styles.rec_wrapper_base, styles.horizontal_rec_wrapper)
    } else {
      return classNames(styles.rec_wrapper_base, styles.rec_wrapper)
    }
  }

  return (
    <div data-testid={`${dataTestId}_grid_recs_${blockIndex}`}>
      {isLoading ? (
        <RecommendationsSkeleton
          dataTestId={dataTestId}
          displayType={'Grid'}
          numberOfSkeletons={numberOfSkeletons}
          numberOfSkeletonSections={numberOfSkeletonSections}
          skeletonType={skeletonType}
          blockIndex={blockIndex}
          useDivider={useDivider}
          useSubtitle={useSubtitle}
          useItemCount={useItemCount}
          horizontalCard={horizontalCard}
        />
      ) : (
        <>
          <Typography
            as={'h2'}
            size={1.25}
            font={'bold'}
            className={styles.title}
          >
            {recBlock.title}
          </Typography>
          {useSubtitle ? (
            <Typography size={0.875} font={'regular'} className={styles.title}>
              {' '}
              {recBlock.subtitle}{' '}
            </Typography>
          ) : (
            false
          )}
          {useItemCount ? (
            <Typography size={0.875} font={'regular'} className={styles.title}>
              {recBlock.recommendations?.length != 1
                ? `${recBlock.recommendations?.length} items`
                : '1 item'}
            </Typography>
          ) : (
            false
          )}
          {useDivider ? (
            <Divider className={styles.divider} />
          ) : (
            <div className={styles.noDivider} />
          )}
          <div className={getColumnStyles(contentType, horizontalCard)}>
            {recBlock.recommendations?.map((rec, index) => {
              return (
                <div
                  key={index}
                  className={styles.rec_base}
                  data-testid={`${dataTestId}_grid_${blockIndex}_rec_${index}`}
                >
                  <RecommendationCard
                    recBlock={recBlock}
                    rec={rec}
                    index={index}
                    dataTestId={dataTestId}
                    horizontal={horizontalCard}
                    treatment={treatment}
                  />
                </div>
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}

export default RecommendationsGrid
