'use client'

import React from 'react'

import ClassNames from 'classnames'
import PropTypes from 'prop-types'

import LayerColorFinder from 'Utilities/LayerColorFinder'

import Brands from 'Constants/Brand'
import Intents from 'Constants/Intent'

import PanelContext from 'Contexts/PanelContext'

import Panel from 'Atoms/Panel'
import TextLink from 'Atoms/TextLink'

import styles from './Card.module.scss'

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  brand: PropTypes.oneOf(Brands),
  intent: PropTypes.oneOf(Intents),
  layer: PropTypes.number,
  invert: PropTypes.bool,
  fill: PropTypes.bool,
  fillHeight: PropTypes.bool,
  fillPanelHeight: PropTypes.bool,
  noPadding: PropTypes.bool,
  noMargin: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
  targetBlank: PropTypes.bool,
  nofollow: PropTypes.bool,
  segmentEvent: PropTypes.object,
  dataTestId: PropTypes.string,
  image: PropTypes.string,
  imageOpacity: PropTypes.number,
  imageLoading: PropTypes.bool,
}

const Card = React.forwardRef(
  (
    {
      children,
      invert,
      image,
      imageLoading,
      brand,
      intent,
      layer,
      fill,
      fillHeight,
      fillPanelHeight,
      noPadding,
      noMargin,
      href,
      onClick,
      targetBlank,
      hover,
      nofollow,
      dataTestId,
      segmentEvent,
      classNames,
      className,
      backgroundColorOverride,
      onPointerDown,
      imageOpacity,
      isAccent = false,
      ...props
    },
    ref,
  ) => {
    if (hover) {
      console.warn(
        "The prop 'hover' is no longer supported in the 'Card' component.",
      )
    }

    const panelContextValue = {
      brand: undefined,
      intent: undefined,
      inverseTheme: false,
      layer: layer || -1,
    }

    const classes = ClassNames(
      classNames,
      className,
      styles.card,
      isAccent && styles.accent,
      fill && styles.cardFill,
      fillHeight && styles.cardFillHeight,
      noMargin && styles.cardNoMargin,
      (href || onClick) && styles.cardHover,
    )

    const backgroundColor =
      backgroundColorOverride ??
      LayerColorFinder(
        panelContextValue.layer > 0
          ? panelContextValue.layer - 1
          : panelContextValue.layer,
        panelContextValue.inverseTheme,
        brand,
        intent,
      )

    const backgroundColorStyle = isAccent
      ? { '--accentColor': backgroundColor }
      : { backgroundColor: backgroundColor }
    const panelItent = isAccent ? null : intent
    const panelBrand = isAccent ? null : brand

    if (href || onClick) {
      return (
        <PanelContext.Provider value={panelContextValue}>
          <TextLink
            className={classes}
            ref={ref}
            {...(href ? { href: href } : {})}
            {...(onClick ? { onClick: onClick } : {})}
            targetBlank={targetBlank}
            nofollow={nofollow}
            data-testid={dataTestId}
            segmentEvent={segmentEvent}
            style={backgroundColorStyle}
            onPointerDown={onPointerDown}
          >
            <Panel
              inverseTheme={invert}
              brand={panelBrand}
              intent={panelItent}
              layer={layer}
              {...props}
              fillHeight={fillHeight || fillPanelHeight}
              image={image}
              imageLoading={imageLoading}
              transparent={backgroundColorOverride}
              borderRadius={true}
              imageOpacity={imageOpacity}
            >
              <div
                className={ClassNames(
                  noPadding ? undefined : styles.card_internal,
                  styles.cardContentWrapper,
                )}
              >
                {children}
              </div>
            </Panel>
          </TextLink>
        </PanelContext.Provider>
      )
    }

    return (
      <PanelContext.Provider value={panelContextValue}>
        <div className={classes} ref={ref} style={backgroundColorStyle}>
          <Panel
            inverseTheme={invert}
            brand={panelBrand}
            intent={panelItent}
            {...props}
            fillHeight={fillHeight || fillPanelHeight}
            data-testid={dataTestId}
            layer={layer}
            image={image}
            imageLoading={imageLoading}
            transparent={backgroundColorOverride}
            borderRadius={true}
            imageOpacity={imageOpacity}
          >
            <div
              className={ClassNames(
                noPadding ? undefined : styles.card_internal,
                styles.cardContentWrapper,
              )}
            >
              {children}
            </div>
          </Panel>
        </div>
      </PanelContext.Provider>
    )
  },
)

Card.propTypes = propTypes

export default Card
