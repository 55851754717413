import React, { Fragment, useContext, useEffect } from 'react'

import Drawer from 'Molecules/Drawer'
import List from 'Molecules/List'
import ListItemIcon from 'Molecules/List/ListItemIcon'
import ListItemLink from 'Molecules/List/ListItemLink'
import ListItemText from 'Molecules/List/ListItemText'

import Panel from 'Atoms/Panel'
import Svg from 'Atoms/Svg'

import styles from './HeaderDrawer.module.scss'
import HeaderDrawerManager from './HeaderDrawerManager'

const HeaderDrawer = ({
  isDrawerOpen,
  closeFunction,
  services,
  isDarkModeEnabled,
}) => {
  try {
    return (
      <Fragment>
        <Drawer
          portalId={'headerDrawer'}
          className={styles.mainDrawerNav}
          location={'left'}
          width={350}
          open={isDrawerOpen}
          inverseTheme
          closeFunction={() => {
            closeFunction()
          }}
          renderWhenClosed
          ariaLabel={'Header Drawer'}
        >
          <div className={styles.drawerNavHeader}>
            <Svg
              icon={'SpeedwayMotors_Logo'}
              brand={'secondary'}
              size={8}
              tone={'normal'}
              href={'/'}
              title={'Home Page Link'}
              aria-label={'Home Page Link'}
            />
          </div>

          <Panel className={styles.drawerNavBanner}>
            <List component={'div'} itemLayout={'horizontal'}>
              <ListItemLink
                className={styles.listItem}
                href={'/street?from=navmenu'}
                hover
              >
                <ListItemIcon
                  icon={'street'}
                  size={1.5}
                  className={styles.listItemIcon}
                />
                <ListItemText
                  primary={'Shop By'}
                  secondary={'Street Parts'}
                  primaryTypographyProps={{
                    className: styles.listItemText,
                  }}
                  secondaryTypographyProps={{
                    className: styles.listItemText,
                  }}
                />
              </ListItemLink>
              <ListItemLink
                className={styles.listItem}
                href={'/race?from=navmenu'}
                hover
              >
                <ListItemIcon
                  icon={'race'}
                  size={1.5}
                  className={styles.listItemIcon}
                />
                <ListItemText
                  primary={'Shop By'}
                  secondary={'Race Parts'}
                  primaryTypographyProps={{
                    className: styles.listItemText,
                  }}
                  secondaryTypographyProps={{
                    className: styles.listItemText,
                  }}
                />
              </ListItemLink>
            </List>
          </Panel>
          <div className={styles.drawerNavContent}>
            <HeaderDrawerManager
              services={services}
              url={services?.traits?.url}
              isDarkModeEnabled={isDarkModeEnabled}
              closeFunction={closeFunction}
            />
          </div>
        </Drawer>
      </Fragment>
    )
  } catch (e) {
    console.error(e)
    return false
  }
}

export default HeaderDrawer
