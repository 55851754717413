import { useEffect } from 'react'

import { getRecommendationSections } from 'Stores/RecommendationStore/Fetches/recommendations'
import { useUserStore } from 'Stores/UserStore/UserStore'
import { useVehicleStore } from 'Stores/VehicleStore'

import { useUserSpecificProductDataStore } from '../../../Contexts/UserSpecificProductDataStore/UserSpecificProductDataStore'
import { useRecommendationStore } from '../RecommendationStore'

const RecommendationLoader = ({ set }) => {
  //REC STORE
  const inView = useRecommendationStore((x) => x.inView)
  const sections = useRecommendationStore((x) => x.sections)
  const isPageLoading = useRecommendationStore((x) => x.isPageLoading)
  const skuBaseNumbers = useRecommendationStore((x) => x.skuBaseNumbers)
  const skuVariantNumbers = useRecommendationStore((x) => x.skuVariantNumbers)
  const articleIds = useRecommendationStore((x) => x.articleIds)
  const keywords = useRecommendationStore((x) => x.keywords)
  const skusToNotDisplay = useRecommendationStore((x) => x.skusToNotDisplay)

  //USER STORE
  const isBot = useUserStore((x) => x.context.isBot)
  const userVinGet = useUserStore((x) => x.context.userVin)
  const getGuid = useUserStore((x) => x.context.getGuid)
  const userStateHydrated = useUserStore((x) => x.context.hydrated)

  //VEHICLE STORE
  const vehicleStoreHydrated = useVehicleStore((x) => x.context.hydrated)
  const selectedCustomerProject = useVehicleStore(
    (x) => x.context.selectedCustomerProject,
  )
  const customerProjects = useVehicleStore((x) => x.context.customerProjects)

  //USER SPECIFIC PRODUCT DATA
  const addTrackedSkuBases = useUserSpecificProductDataStore(
    (x) => x.addTrackedSkuBases,
  )

  useEffect(() => {
    try {
      const isReady =
        userStateHydrated &&
        vehicleStoreHydrated &&
        selectedCustomerProject?.hydrated

      if (isReady && inView && !isBot()) {
        const userVin = userVinGet()
        const webUserGuid = getGuid()
        // WIFR-32634 I would like to update this variables naming convention when given the chance
        const ymmData = getYmmData(selectedCustomerProject, customerProjects)

        if (!isPageLoading) {
          getRecommendationSections(
            sections,
            skuBaseNumbers,
            skuVariantNumbers,
            articleIds,
            userVin,
            webUserGuid,
            ymmData,
            keywords,
          )
            .then((data) => {
              if (data && skusToNotDisplay) {
                data.recommendationSections[0].recommendationGroups.forEach(
                  (group) => {
                    group.recommendations = group.recommendations.filter(
                      (recommendation) =>
                        !(
                          skusToNotDisplay.includes(recommendation.skuBase) ||
                          skusToNotDisplay.includes(recommendation.skuVariant)
                        ),
                    )
                  },
                )
              }
              beginLoadingVehicleAndShippingDataForRecommendations(data)

              set((state) => {
                return {
                  ...state,
                  recommendationData: data,
                }
              })
            })
            .then(() => {
              set((state) => {
                return {
                  ...state,
                  isLoading: false,
                }
              })
            })
        }
      }
    } catch (e) {
      console.error(e)
    }
  }, [
    inView,
    isPageLoading,
    userStateHydrated,
    vehicleStoreHydrated,
    selectedCustomerProject?.hydrated,
  ])

  function beginLoadingVehicleAndShippingDataForRecommendations(
    recommendationData,
  ) {
    const skuBasesToAdd = []
    for (const recommendationSection of recommendationData.recommendationSections) {
      for (const recommendationGroup of recommendationSection.recommendationGroups) {
        if (
          recommendationGroup.getShippingEstimate ||
          recommendationGroup.checkFitment
        ) {
          for (const recommendation of recommendationGroup.recommendations) {
            if (recommendation.skuBase || recommendation.skuBaseNumber) {
              const skuBase =
                recommendation.skuBase ?? recommendation.skuBaseNumber
              skuBasesToAdd.push(skuBase)
            }
          }
        }
      }
    }
    if (skuBasesToAdd.length) {
      addTrackedSkuBases(skuBasesToAdd)
    }
  }
}

export function getYmmData(selectedCustomerProject, customerProjects) {
  if (selectedCustomerProject?.projectId) {
    const customerProject = selectedCustomerProject
    return {
      isSelected: true,
      year: customerProject.yearId,
      make: customerProject.makeId,
      makeDisplay: customerProject.makeDisplay,
      model: customerProject.modelId,
      modelDisplay: customerProject.modelDisplay,
      raceTypeId: customerProject.raceTypeId,
      raceDisplay: customerProject.raceDisplay,
      vehicleBaseId: customerProject.vehicleBaseId,
      engineData: {
        engineManufacturerId: customerProject.engineManufacturerId,
        engineManufacturerName: customerProject.engineManufacturerName,
        engineMakeId: customerProject.engineMakeId,
        engineMakeName: customerProject.engineMakeName,
        engineSeriesId: customerProject.engineSeriesId,
        engineSeriesName: customerProject.engineSeriesName,
        engineConfigurationId: customerProject.engineConfigurationId,
        engineConfigurationName: customerProject.engineConfigurationName,
        engineDisplacementId: customerProject.engineDisplacementId,
        engineDisplacementName: customerProject.engineDisplacementName,
        engineVersionId: customerProject.engineVersionId,
        engineVersionName: customerProject.engineVersionName,
        engineQualifierId: customerProject.engineQualifierId,
        engineQualifierName: customerProject.engineQualifierName,
        engineCodeId: customerProject.engineCodeId,
        engineCodeName: customerProject.engineCodeName,
        engineCamshaftTypeId: customerProject.EngineCamshaftTypeId,
        engineCamshaftTypeName: customerProject.EngineCamshaftTypeName,
        engineFuelSystemId: customerProject.EngineFuelSystemId,
        engineFuelSystemName: customerProject.EngineFuelSystemName,
        engineDisplayName: customerProject.engineDisplayName,
      },
    }
  }
  if (customerProjects?.length > 0) {
    const customerProject = customerProjects[0]
    return {
      isSelected: false,
      year: customerProject.year,
      make: customerProject.makeId,
      makeDisplay: customerProject.make,
      model: customerProject.modelId,
      modelDisplay: customerProject.model,
      raceTypeId: customerProject.raceTypeId,
      raceDisplay: customerProject.raceName,
      vehicleBaseId: customerProject.vehicleBaseId,
      engineData: {
        engineManufacturerId: customerProject.engineManufacturerId,
        engineManufacturerName: customerProject.engineManufacturerName,
        engineMakeId: customerProject.engineMakeId,
        engineMakeName: customerProject.engineMakeName,
        engineSeriesId: customerProject.engineSeriesId,
        engineSeriesName: customerProject.engineSeriesName,
        engineConfigurationId: customerProject.engineConfigurationId,
        engineConfigurationName: customerProject.engineConfigurationName,
        engineDisplacementId: customerProject.engineDisplacementId,
        engineDisplacementName: customerProject.engineDisplacementName,
        engineVersionId: customerProject.engineVersionId,
        engineVersionName: customerProject.engineVersionName,
        engineQualifierId: customerProject.engineQualifierId,
        engineQualifierName: customerProject.engineQualifierName,
        engineCodeId: customerProject.engineCodeId,
        engineCodeName: customerProject.engineCodeName,
        engineCamshaftTypeId: customerProject.EngineCamshaftTypeId,
        engineCamshaftTypeName: customerProject.EngineCamshaftTypeName,
        engineFuelSystemId: customerProject.EngineFuelSystemId,
        engineFuelSystemName: customerProject.EngineFuelSystemName,
        engineDisplayName: customerProject.engineDisplayName,
      },
    }
  }

  return null
}

export default RecommendationLoader
