import React from 'react'

import ErrorBoundary from 'UtilityComponents/ErrorBoundary'
import classNames from 'classnames'

import Carousel from 'Organisms/Carousel'

import Card from 'Atoms/Card'
import Divider from 'Atoms/Divider'
import Gutters from 'Atoms/Gutters'
import Skeleton from 'Atoms/Skeleton'
import Spacing from 'Atoms/Spacing/Spacing'

import gridStyles from '../../Containers/RecommendationsGrid/RecommendationsGrid.module.scss'
import styles from './RecommendationsSkeleton.module.scss'

const RecommendationsSkeleton = ({
  dataTestId,
  displayType,
  cardDisplayType,
  isCard,
  numberOfSkeletons,
  numberOfSkeletonSections,
  skeletonType,
  blockIndex,
  useDivider,
  useSubtitle,
  useItemCount,
  horizontalCard,
  leftAlignCarousel,
  cardProps,
  spacingSize,
}) => {
  return [...Array(numberOfSkeletonSections)].map((_, index) => (
    <ErrorBoundary key={index}>
      {isCard ? (
        <Card fill className={styles.card} {...cardProps}>
          <RecommendationsSkeletonBase
            dataTestId={dataTestId}
            displayType={displayType}
            cardDisplayType={cardDisplayType}
            numberOfSkeletons={numberOfSkeletons}
            numberOfSkeletonSections={numberOfSkeletonSections}
            skeletonType={skeletonType}
            blockIndex={blockIndex}
            useDivider={useDivider}
            useSubtitle={useSubtitle}
            useItemCount={useItemCount}
            horizontalCard={horizontalCard}
            leftAlignCarousel={leftAlignCarousel}
          />
        </Card>
      ) : (
        <Spacing key={`rec_skel_${index}`} spacingSize={spacingSize}>
          <RecommendationsSkeletonBase
            dataTestId={dataTestId}
            displayType={displayType}
            cardDisplayType={cardDisplayType}
            numberOfSkeletons={numberOfSkeletons}
            numberOfSkeletonSections={numberOfSkeletonSections}
            skeletonType={skeletonType}
            blockIndex={blockIndex}
            useDivider={useDivider}
            useSubtitle={useSubtitle}
            useItemCount={useItemCount}
            horizontalCard={horizontalCard}
            leftAlignCarousel={leftAlignCarousel}
          />
        </Spacing>
      )}
    </ErrorBoundary>
  ))
}

const RecommendationsSkeletonBase = ({
  dataTestId,
  displayType,
  cardDisplayType,
  numberOfSkeletons,
  skeletonType,
  blockIndex,
  useDivider,
  useSubtitle,
  useItemCount,
  horizontalCard,
  leftAlignCarousel,
}) => {
  skeletonType = skeletonType?.toLowerCase()
  displayType = displayType?.toLowerCase()

  function getSkeletonHeaderHeight(useSubtitle, useItemCount) {
    if (useSubtitle) {
      if (useItemCount) {
        return 80
      } else {
        return 60
      }
    } else {
      return 30
    }
  }

  function determineSkeletonItemSize(skeletonType, index, horizontalCard) {
    if (skeletonType == 'landingpage') {
      return <Skeleton width={288} height={56} key={'skel2-' + index} />
    } else if (skeletonType == 'landingpageimage') {
      return <Skeleton width={192} height={113} key={'skel2-' + index} />
    } else if (skeletonType == 'article') {
      if (horizontalCard) {
        return <Skeleton width={384} height={92} key={'skel2-' + index} />
      } else {
        return <Skeleton width={192} height={222} key={'skel2-' + index} />
      }
    } else if (skeletonType == 'comparison') {
      return <Skeleton width={192} height={500} key={'skel2-' + index} />
    } else {
      if (horizontalCard || displayType == 'vertical') {
        return <Skeleton width={384} height={107} key={'skel2-' + index} />
      } else {
        return <Skeleton width={192} height={233} key={'skel2-' + index} />
      }
    }
  }

  function getColumnStyles(skeletonType, horizontalCard) {
    if (skeletonType == 'landingpage') {
      return classNames(
        gridStyles.rec_wrapper_base,
        gridStyles.landing_page_rec_wrapper,
      )
    } else if (horizontalCard) {
      return classNames(
        gridStyles.rec_wrapper_base,
        gridStyles.horizontal_rec_wrapper,
      )
    } else {
      return classNames(gridStyles.rec_wrapper_base, gridStyles.rec_wrapper)
    }
  }

  if (displayType == 'grid') {
    return (
      <div data-testid={`${dataTestId}_grid_recs_${blockIndex}`}>
        <Skeleton
          width={300}
          height={getSkeletonHeaderHeight(useSubtitle, useItemCount)}
          key={'skel1-' + blockIndex}
        />
        {useDivider ? (
          <Divider className={styles.divider} />
        ) : (
          <div className={styles.noDivider} />
        )}
        <Gutters>
          <div className={getColumnStyles(skeletonType, horizontalCard)}>
            {[...Array(numberOfSkeletons)].map((_, index) => (
              <div className={gridStyles.rec_base} key={index}>
                {determineSkeletonItemSize(skeletonType, index, horizontalCard)}
              </div>
            ))}
          </div>
        </Gutters>
      </div>
    )
  } else if (displayType == 'vertical') {
    return (
      <div data-testid={`${dataTestId}_vertical_recs_${blockIndex}`}>
        <Skeleton
          width={300}
          height={getSkeletonHeaderHeight(useSubtitle, useItemCount)}
          key={'skel1-' + blockIndex}
        />
        {useDivider ? (
          <Divider className={styles.divider} />
        ) : (
          <div className={styles.noDivider} />
        )}
        <div className={styles.recWrapperVertical}>
          {[...Array(numberOfSkeletons)].map((_, index) => (
            <div key={index} className={styles.skeletonItemVertical}>
              <Skeleton fullWidth height={100} key={'skel2-' + index} />
            </div>
          ))}
        </div>
      </div>
    )
  } else if (displayType == 'card') {
    cardDisplayType = cardDisplayType?.toLowerCase()

    if (cardDisplayType == 'single') {
      return (
        <Card>
          <div data-testid={`${dataTestId}_card_recs_${blockIndex}`}>
            <Skeleton
              width={300}
              height={getSkeletonHeaderHeight(useSubtitle, useItemCount)}
              key={'skel1-' + blockIndex}
            />
            {useDivider ? (
              <Divider className={styles.divider} />
            ) : (
              <div className={styles.noDivider} />
            )}
            <div
              className={styles.single_rec_wrapper}
              data-testid={`${dataTestId}_card_recs_${blockIndex}`}
            >
              {determineSkeletonItemSize(skeletonType, 0, horizontalCard)}
            </div>
          </div>
        </Card>
      )
    } else if (cardDisplayType == 'double') {
      return (
        <Card>
          <div data-testid={`${dataTestId}_card_recs_${blockIndex}`}>
            <Skeleton
              width={300}
              height={getSkeletonHeaderHeight(useSubtitle, useItemCount)}
              key={'skel1-' + blockIndex}
            />
            {useDivider ? (
              <Divider className={styles.divider} />
            ) : (
              <div className={styles.noDivider} />
            )}
            <div
              className={
                skeletonType == 'landingpage' || horizontalCard
                  ? styles.double_landing_page_rec_wrapper
                  : styles.grid_rec_wrapper
              }
            >
              {[...Array(2)].map((_, index) => {
                return (
                  <div
                    key={index}
                    className={styles.double_rec_base}
                    data-testid={`${dataTestId}_card_${blockIndex}_rec_${index}`}
                  >
                    {determineSkeletonItemSize(
                      skeletonType,
                      index,
                      horizontalCard,
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </Card>
      )
    } else {
      return (
        <Card>
          <div data-testid={`${dataTestId}_card_recs_${blockIndex}`}>
            <Skeleton
              width={300}
              height={getSkeletonHeaderHeight(useSubtitle, useItemCount)}
              key={'skel1-' + blockIndex}
            />
            {useDivider ? (
              <Divider className={styles.divider} />
            ) : (
              <div className={styles.noDivider} />
            )}
            <div className={styles.grid_rec_wrapper}>
              {[...Array(4)].map((_, index) => {
                return (
                  <div
                    data-testid={`${dataTestId}_card_${blockIndex}_rec_${index}`}
                    key={index}
                  >
                    {determineSkeletonItemSize(
                      skeletonType,
                      index,
                      horizontalCard,
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </Card>
      )
    }
  } else {
    return (
      <div data-testid={`${dataTestId}_carousel_recs_${blockIndex}`}>
        <Skeleton
          width={300}
          height={getSkeletonHeaderHeight(useSubtitle, useItemCount)}
          key={'skel1-' + blockIndex}
        />
        {useDivider ? (
          <Divider className={styles.divider} />
        ) : (
          <div className={styles.noDivider} />
        )}
        <Carousel leftAlignCarousel={leftAlignCarousel}>
          {[...Array(numberOfSkeletons)].map((_, index) => (
            <ErrorBoundary key={`${dataTestId}-${index}`}>
              <Carousel.Item className={styles.skeletonItemCarousel}>
                {determineSkeletonItemSize(skeletonType, index, horizontalCard)}
              </Carousel.Item>
            </ErrorBoundary>
          ))}
        </Carousel>
      </div>
    )
  }
}

export default RecommendationsSkeleton
