import React from 'react'

import ClassNames from 'classnames'
import PropTypes from 'prop-types'

import LayerColorFinder from 'Utilities/LayerColorFinder'

import Brands from 'Constants/Brand'
import Intents from 'Constants/Intent'
import { Svgs } from 'Constants/Svg'

import PanelContext from 'Contexts/PanelContext'

import Svg from 'Atoms/Svg'
import Typography from 'Atoms/Typography'

import styles from './Ribbon.module.scss'
import styled from 'styled-components'

const propTypes = {
  text: PropTypes.string,
  icon: PropTypes.oneOf(Svgs),
  brand: PropTypes.oneOf(Brands),
  intent: PropTypes.oneOf(Intents),
  inverseTheme: PropTypes.bool,
}

const Ribbon = ({
  text,
  icon,
  brand,
  intent,
  inverseTheme,
  dataTestId,
  fontSize,
  dimensional = false,
  bold = true,
  uppercase = true,
  ...props
}) => {
  if (!text && !icon) {
    return false
  }

  const backgroundColor = LayerColorFinder(3, inverseTheme, brand, intent)
  const darkenedBackgroundColor = darkenHsla(backgroundColor)

  const panelContextValue = {
    layer: 3,
    brand: brand,
    intent: intent,
    inverseTheme: inverseTheme,
  }

  const classes = ClassNames(
    styles.ribbon,
    props.className,
    `bg-color-${brand ?? intent}`,
    dimensional ? styles.dimensional : '',
    uppercase ? styles.uppercase : null,
  )

  if (icon) {
    return (
      <StyledRibbon
        className={classes}
        backgroundColor={backgroundColor}
        darkenedBackgroundColor={darkenedBackgroundColor}
        data-testid={dataTestId}
      >
        <PanelContext.Provider value={panelContextValue}>
          <Typography className={styles.ribbon_text} font={'bold'} size={0.875}>
            <Svg className={styles.ribbon_svg} icon={icon} size={0.875} />
            {text}
          </Typography>
        </PanelContext.Provider>
      </StyledRibbon>
    )
  }

  return (
    <StyledRibbon
      className={classes}
      backgroundColor={backgroundColor}
      darkenedBackgroundColor={darkenedBackgroundColor}
      data-testid={dataTestId}
    >
      <PanelContext.Provider value={panelContextValue}>
        <Typography
          className={styles.ribbon_text}
          font={bold ? 'bold' : 'regular'}
          size={fontSize ?? 0.875}
        >
          {text}
        </Typography>
      </PanelContext.Provider>
    </StyledRibbon>
  )
}

const StyledRibbon = styled.div`
  --c: ${(props) => props.backgroundColor};
  --d: ${(props) => props.darkenedBackgroundColor};
  --f: -0.35rem;
  --b: 8px;

  background-color: var(--c);
  &:before {
    background-color: var(--c);
  }
`

const darkenHsla = (hslaString) => {
  const [h, s, l, a] = hslaString.match(/hsla\((.*)\)/)[1].split(/,\s+/)
  const darkenedColor = `hsla(${h}, ${parseFloat(s)}%, ${Math.round(parseFloat(l) * 0.5)}%, ${a})`
  return darkenedColor
}

export default Ribbon
