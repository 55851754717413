import React, { createContext, useEffect, useState } from 'react'

import useAlert from './useAlert'

const initialState = {
  notifications: [],
}

const NotificationContext = createContext(initialState)

const { Provider } = NotificationContext

const NotificationProvider = ({ children }) => {
  const [context, setContext] = useState(initialState)

  const PushNotification = (
    message,
    intent = null,
    timeToLive = 3000,
    content = null,
    svgProps = null,
    invert = false,
    imageUrl = null,
  ) => {
    try {
      if (!message && !content) {
        throw 'I pitty the foo that doesn include a message or content on a notification'
      }
      const newNote = {
        message,
        svgProps,
        intent,
        content: content,
        id: Math.random(),
        dismissing: false,
        invert,
        imageUrl,
      }

      if (context.notifications.some((x) => x.message === newNote.message)) {
        return
      }

      setContext((state) => {
        state.notifications.push(newNote)
        state.notifications = state.notifications.slice(-2) //take last two elements
        if (timeToLive > 0) {
          RemoveNotification(newNote.id, timeToLive)
        }
        return {
          ...state,
        }
      })
      return newNote.id
    } catch (error) {
      console.error('Failed to push notification', error)
    }
  }

  const RemoveNotification = (notifyId, timeToLive) => {
    setTimeout(function () {
      DismissNotification(notifyId)
    }, timeToLive)
  }

  const DismissNotificationsBy = (condition) => {
    if (context.notifications.filter((n) => condition(n)).length > 0) {
      const EXITANIMATIONTIMING = 200

      setContext((state) => {
        state.notifications.forEach((n, i) => {
          if (condition(n)) {
            n.dismissing = true
          }
        })
        return {
          ...state,
        }
      })

      setTimeout(function () {
        setContext((state) => {
          state.notifications = state.notifications.filter((n) => !condition(n))
          return {
            ...state,
          }
        })
      }, EXITANIMATIONTIMING)
    }
  }

  const DismissNotificationByMessage = (message) => {
    DismissNotificationsBy((m) => m.message == message)
  }

  const DismissNotification = (notifyId) => {
    DismissNotificationsBy((n) => n.id == notifyId)
  }

  const DismissAllNotifications = () => {
    DismissNotificationsBy(() => true)
  }

  // pull in the functions from useAlert hook for re-export
  const {
    SetPromptAlertInputValue,
    ClearAlert,
    ShowAlert,
    ShowConfirmAlert,
    ShowPromptAlert,
    alertState,
    onConfirmClick,
  } = useAlert()

  useEffect(() => {
    window.Clutch = window.Clutch || {}
    window.Clutch.Notification = {
      ClearAlert,
      ShowAlert,
      ShowConfirmAlert,
      ShowPromptAlert,
      PushNotification,
      DismissNotification,
      DismissAllNotifications,
      DismissNotificationByMessage,
    }
  }, [])

  return (
    <Provider
      value={{
        context,
        alertState,
        onConfirmClick,
        PushNotification,
        DismissNotification,
        DismissAllNotifications,
        DismissNotificationByMessage,
        ShowAlert,
        ShowConfirmAlert,
        ShowPromptAlert,
        ClearAlert,
        SetPromptAlertInputValue,
      }}
    >
      {children}
    </Provider>
  )
}
export { NotificationProvider, NotificationContext }
