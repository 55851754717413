import React, { useState } from 'react'
import { useInView } from 'react-intersection-observer'

import useSegment from '../../Hooks/useSegment/useSegment'
import { createViewedSegmentEvent } from '../../Utilities/Instrumentation/Impressions/impressionSegmentEvents'

const Impression = ({ segmentEvent, functions = [], sectionName }) => {
  const [eventsSent, setEventsSent] = useState(false)
  const { sendCustomSegmentTrackEvent } = useSegment()

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
    rootMargin: '0px 0px',
  })

  if (!functions.length && !segmentEvent && !sectionName) {
    console.error(
      'Impression component did not have a segment event, section name, or other functions provided',
    )
    return false
  }

  if (typeof window === 'undefined' || eventsSent) {
    return false
  }

  if (inView && !eventsSent) {
    for (let i = 0, ilen = functions.length; i < ilen; i++) {
      functions[i]()
    }
    if (segmentEvent) {
      sendCustomSegmentTrackEvent(segmentEvent)
    }
    if (sectionName) {
      sendCustomSegmentTrackEvent(createViewedSegmentEvent(sectionName))
    }
    setEventsSent(true)
  }

  return <span ref={ref}></span>
}

export default Impression
