import React, { Fragment, useContext } from 'react'

import ClassNames from 'classnames'
import PropTypes from 'prop-types'

import LayerColorFinder from 'Utilities/LayerColorFinder'

import PanelContext from 'Contexts/PanelContext'

import Typography from 'Atoms/Typography'

import ColorFinder from '../../../Utilities/ColorFinder'
import styles from './Select.module.scss'
import styled from 'styled-components'

const propTypes = {
  size: PropTypes.oneOf([0.6, 0.75, 0.875, 1, 1.25, 1.5, 2]),
}

const Select = ({
  options = [],
  id,
  label,
  isDisabled,
  size,
  value,
  placeholder,
  className,
  font,
  brand,
  borderTone,
  borderIntent,
  borderBrand,
  inline,
  displayLabel = true,
  testingName,
  ...props
}) => {
  if (!borderTone && !borderIntent && !borderBrand) borderTone = 'contrast'
  const panelContext = useContext(PanelContext)
  let layer = panelContext?.layer
  layer = layer || 3

  const { hoverColor } = ColorFinder(null, null, null, isDisabled)
  const color = LayerColorFinder(layer, false, null, null)
  const backgroundColor = LayerColorFinder(0, false, null, null)
  const { color: brandColor } = ColorFinder(null, null, null)

  const selectOptions = options.map((option, index) => {
    return (
      <option key={index} value={option.value} disabled={option.disabled}>
        {option.label}
      </option>
    )
  })

  let placeholderSelect = ''

  if (placeholder) {
    placeholderSelect = (
      <option value="" disabled>
        {placeholder}
      </option>
    )
  }

  var formChildren = (
    <>
      {displayLabel && (
        <label htmlFor={id} id={`label_${label}`}>
          <Typography size={size} tone={'contrast'} className={styles.label}>
            {label}
          </Typography>
        </label>
      )}
      <StyledSelect
        name={id}
        {...(id ? { id: id } : {})}
        className={ClassNames(
          className,
          styles.select,
          styles[font || 'regular'],
        )}
        disabled={isDisabled}
        value={value ?? ''}
        brand={brandColor}
        backgroundColor={backgroundColor}
        borderColor={color}
        borderHoverColor={hoverColor}
        aria-label={label}
        aria-labelledby={displayLabel ? `label_${label}` : null}
        data-testid={testingName}
        {...props}
      >
        {placeholderSelect}
        {selectOptions}
      </StyledSelect>
    </>
  )

  return !inline ? (
    <>{formChildren}</>
  ) : (
    <div className={styles.inline}>{formChildren}</div>
  )
}

const StyledSelect = styled.select`
  background-color: ${(props) => props.backgroundColor};
  border-color: ${(props) => props.borderColor};
  &:hover {
    border-color: ${(props) => props.borderHoverColor};
    cursor: pointer;
  }
  color: ${(props) => props.brand};
`

Select.propTypes = propTypes

export default Select
