import React, { useContext, useEffect } from 'react'
import { createPortal } from 'react-dom'

import classNames from 'classnames'

import { NavigationContext } from 'Contexts/Navigation/NavigationContext'

import Divider from 'Atoms/Divider'
import Panel from 'Atoms/Panel'

import useMountTransition from '../../Hooks/useMountTransition'
import usePortal from '../../Hooks/usePortal'
import styles from './Drawer.module.scss'
import DrawerHeader from './DrawerHeader/DrawerHeader'
import DrawerImage from './DrawerImage/DrawerImage'

const getDrawerLocationStyLes = (styles, location) => {
  if (location === 'left')
    return {
      locationClassName: styles.drawerLeft,
      openClassName: styles.drawerLeftRightOpen,
    }
  else if (location === 'right')
    return {
      locationClassName: styles.drawerRight,
      openClassName: styles.drawerLeftRightOpen,
    }
  else if (location === 'top')
    return {
      locationClassName: styles.drawerTop,
      openClassName: styles.drawerTopBottomOpen,
    }
  else if (location === 'bottom')
    return {
      locationClassName: styles.drawerBottom,
      openClassName: styles.drawerTopBottomOpen,
    }
  else
    return {
      locationClassName: styles.drawerLeft,
      openClassName: styles.drawerLeftRightOpen,
    }
}

const Drawer = ({
  isOpen,
  onClose,
  children,
  className,
  location,
  title,
  portalId,
  zIndex = 200,
  footer,
  removeWhenClosed = true,
  testId,
  image,
  images,
  svgProps,
  showBackButton,
  onBackButtonPressed,
  basePanelLayer = 1,
  isStacked,
}) => {
  const isTransitioning = useMountTransition(isOpen, 300)
  const target = usePortal(portalId, zIndex)
  const { dispatch: navigationDispatch } = useContext(NavigationContext)

  useEffect(() => {
    return () => {
      const bodyElement = document.querySelector('body')
      bodyElement.style.overflow = ''
    }
  }, [])

  useEffect(() => {
    const bodyElement = document.querySelector('body')
    if (isOpen) {
      bodyElement.style.overflow = 'hidden'
      document.body.style.marginRight = '0.875rem'
    } else {
      bodyElement.style.overflow = ''
      document.body.style.marginRight = ''
    }

    navigationDispatch({
      type: 'setIsAnyDrawerOpen',
      payload: {
        isAnyDrawerOpen: isOpen ? true : false,
      },
    })
  }, [isOpen, navigationDispatch])

  useEffect(() => {
    const onKeyPress = (e) => {
      if (e.key === 'Escape') {
        onClose()
      }
    }

    if (isOpen) {
      window.addEventListener('keyup', onKeyPress)
    }

    return () => {
      window.removeEventListener('keyup', onKeyPress)
    }
  }, [isOpen, onClose])

  if ((!isTransitioning && removeWhenClosed && !isOpen) || !target) return null

  if (image == null && images?.length === 1) {
    image = images[0]
  }

  const { locationClassName, openClassName } = getDrawerLocationStyLes(
    styles,
    location,
  )

  let FooterComponent = null
  if (footer)
    FooterComponent = (
      <Panel className={styles.footerContainer} layer={0}>
        <Divider className={styles.footer_divider} />
        {footer}
      </Panel>
    )

  return createPortal(
    <div
      aria-hidden={isOpen ? 'false' : 'true'}
      className={classNames(styles.drawerContainer, className)}
    >
      <div
        className={classNames(styles.drawer, isStacked && styles.stacked, {
          [locationClassName]: true,
          [openClassName]: isOpen && isTransitioning,
        })}
        role="dialog"
      >
        <Panel className={styles.drawerPanel} layer={basePanelLayer}>
          <DrawerHeader
            title={title}
            onClose={onClose}
            testId={testId}
            svgProps={svgProps}
            showBackButton={showBackButton}
            onBackButtonPressed={onBackButtonPressed}
          />
          {image ? (
            <DrawerImage src={image} alt={''} />
          ) : images?.length > 0 ? (
            <div className={styles.drawerImages}>
              {' '}
              {images?.map((x, i) => (
                <DrawerImage
                  key={'drawer_' + testId + '_image_' + i}
                  excludeDivider
                  src={x}
                  alt={''}
                />
              ))}{' '}
            </div>
          ) : (
            false
          )}
          <div className={styles.drawerContentContainer}>{children}</div>
        </Panel>
        {FooterComponent}
      </div>
      <div
        className={classNames(styles.backdrop, isStacked && styles.stacked, {
          [styles.drawerOpenBackdrop]: isOpen,
        })}
        onClick={onClose}
      />
    </div>,
    target,
  )
}

export default Drawer
