'use client'

import React from 'react'

import List from 'Molecules/List'

import Skeleton from 'Atoms/Skeleton'

const SkeletonContent = ({ rows, skeletonProps }) => {
  const skeletonRows = []
  for (let i = 0; i < rows; i++) {
    skeletonRows.push(<Skeleton {...skeletonProps} key={'skeleton' + i} />)
  }

  return <List>{skeletonRows}</List>
}

export default SkeletonContent
