import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'

import { get, set } from 'idb-keyval'
// we shim an implementation of "next/dynamic" in DS9 in webpack
// we HAVE to use magic comments https://loadable-components.com/docs/babel-plugin/#magic-comments
import dynamic from 'next/dynamic'

import { NavigationContext } from 'Contexts/Navigation/NavigationContext'

import useWindow from 'Hooks/useWindow'

import { NotificationContext } from '../../Contexts/NotificationContext/NotificationContext'
import { isInApp } from '../../Hooks/useAppInstall/AppUtilities'
import useSegmentLeadCaptureEvent from '../../Hooks/useSegment/segmentSpecificHooks/useSendLeadCaptureEvent'
import { useUserStore } from '../../Stores/UserStore/UserStore'

// we shim an implementation of "next/dynamic" in DS9 in webpack
// we HAVE to use magic comments https://loadable-components.com/docs/babel-plugin/#magic-comments
const EmailSignUpModalContent = dynamic(
  /* #__LOADABLE__ */ () => import('./EmailSignUpModalContent'),
)

const hasDisplayedModalKey = 'EmailSignupModal_HasDisplayedPreviously'
const lastPageViewedKey = 'EmailSignupModal_LastPageViewed'
const numberOfPagesViewedKey = 'EmailSignupModal_NumberOfPagesViewed'

const DISALLOWEDURLS = [
  `/buy`,
  `/emailsignup`,
  `/account/login`,
  `/account/register`,
]

const EmailSignUpModal = () => {
  const { sendEmailEvent, sendSmsEvent } = useSegmentLeadCaptureEvent()

  const { state: navigationState, dispatch: navigationDispatch } =
    useContext(NavigationContext)
  const [isOpen, setIsOpen] = useState(false)
  const modal = useRef(null)

  const { DismissAllNotifications } = useContext(NotificationContext)
  const isRegistered = useUserStore((x) => x.context.isRegistered)

  useEffect(() => {
    const { email } = navigationState
    setIsOpen(email?.isEmailSignupModalOpen ?? false)
    if (email?.isEmailSignupModalOpen) {
      DismissAllNotifications()
      set(hasDisplayedModalKey, true)
      sendEmailEvent('Impression', 'gobal signup modal')
      sendSmsEvent('Impression', 'gobal signup modal')
    }
  }, [navigationState])

  const handleNavigationChange = (value) =>
    navigationDispatch({
      type: 'setEmailState',
      payload: {
        emailState: {
          isEmailSignupModalOpen: value,
        },
      },
    })

  useEffect(() => {
    const shouldShowOnThisUrl = !DISALLOWEDURLS.some((x) =>
      window.location.pathname.toLowerCase().includes(x.toLowerCase()),
    )
    const isUserLoggedIn = isRegistered
    get(hasDisplayedModalKey).then((hasDisplayedModal) => {
      if (
        shouldShowOnThisUrl &&
        !hasDisplayedModal &&
        !isUserLoggedIn &&
        !isInApp()
      ) {
        const currentPage = document.location.pathname
        const isCurrentPageShoppingCart = new RegExp(
          '^/shoppingcart/?$',
          'i',
        ).test(currentPage)
        const isCurrentPageLayoutPage = new RegExp('^/ds9layout/?$', 'i').test(
          currentPage,
        )
        get(lastPageViewedKey).then((lastPageViewed) => {
          get(numberOfPagesViewedKey).then((numberOfPagesViewedValue) => {
            let numberOfPagesViewed = parseInt(numberOfPagesViewedValue) || 0
            if (lastPageViewed != currentPage) {
              set(lastPageViewedKey, window.location.href)
              set(
                numberOfPagesViewedKey,
                numberOfPagesViewed ? ++numberOfPagesViewed : 1,
              )
            }

            let modalTimeout = 25005
            if (isCurrentPageShoppingCart) {
              modalTimeout = 0
            } else if (isCurrentPageLayoutPage) {
              modalTimeout = 30000
            } else if (numberOfPagesViewed >= 2) {
              modalTimeout = 2001
            }

            setTimeout(function () {
              handleNavigationChange(true)
            }, modalTimeout)
          })
        })
      }
    })
  }, [])

  return (
    <Fragment>
      {isOpen ? (
        <EmailSignUpModalContent
          sendEmailEvent={sendEmailEvent}
          sendSmsEvent={sendSmsEvent}
          ref={modal}
        />
      ) : (
        false
      )}
    </Fragment>
  )
}
export default EmailSignUpModal
