import useSaveClickParameterEffect from './Hooks/useSaveClickParameterEffect'

export default function useTikTokClickParameter() {
  useSaveClickParameterEffect({
    cacheKey: 'tiktok_cache',
    expirationDays: 30,
    storageMethod: 'localstorage',
    urlParameters: {
      ttclid: {
        required: true,
        storageKey: 'ttclid',
      },
      lead_id: {
        required: false, // lead ID isn't required, but we store it and forward it via segment
        storageKey: 'lead_id',
      },
    },
  })
}
