import React, { useContext } from 'react'

import ClassNames from 'classnames'
import Image from 'next/image'
import PropTypes from 'prop-types'

import Brands from 'Constants/Brand'
import Intents from 'Constants/Intent'
import Sizes from 'Constants/Size'
import { Svgs } from 'Constants/Svg'

import { NotificationContext } from 'Contexts/NotificationContext/NotificationContext'

import Panel from 'Atoms/Panel'
import Svg from 'Atoms/Svg'
import Typography from 'Atoms/Typography'

import { AlertSwitch } from './AlertSwitch'
import styles from './Notification.module.scss'

export const gaOptOut = { optOut: true }

const NotificationContainer = () => {
  const { context, DismissNotification } = useContext(NotificationContext)
  return (
    <>
      <AlertSwitch />
      <div className={styles.container}>
        {context.notifications.map((notification) => {
          return (
            <Notification
              message={notification.message}
              svgProps={notification.svgProps}
              isDismissing={notification.dismissing}
              intent={notification.intent}
              dismiss={() => DismissNotification(notification.id)}
              invert={notification.invert}
              key={notification.id}
              imageUrl={notification.imageUrl}
            >
              {notification.content}
            </Notification>
          )
        })}
      </div>
    </>
  )
}

const propTypes = {
  message: PropTypes.any,
  brand: PropTypes.oneOf(Brands),
  intent: PropTypes.oneOf(Intents),
  svgProps: PropTypes.shape({
    icon: PropTypes.oneOf(Svgs),
    intent: PropTypes.oneOf(Intents),
    size: PropTypes.oneOf(Sizes),
  }),
  dismiss: PropTypes.func,
  isDismissing: PropTypes.bool,
  invert: PropTypes.bool,
}

const Notification = ({
  message,
  intent,
  svgProps,
  dismiss,
  isDismissing,
  imageUrl,
  children,
  invert,
}) => {
  let classes = ClassNames(styles.notification)
  if (isDismissing) {
    classes += ' ' + styles.dismissing
  }

  let layer = 0
  if (invert) {
    layer = 3
  }

  return (
    <Panel
      className={classes}
      intent={intent}
      layer={layer}
      inverseTheme={invert}
      role="alert"
    >
      <div className={styles.wrapper}>
        {imageUrl ? (
          <Image
            src={!imageUrl.includes('https') ? `https:${imageUrl}` : imageUrl}
            height={64}
            width={64}
          />
        ) : null}
        <div className={styles.innerWrapper}>
          <div className={styles.message}>
            {svgProps ? (
              <Svg
                size={svgProps.size || 1}
                icon={svgProps.icon}
                intent={svgProps.intent}
                className={styles.notificationIcon}
              ></Svg>
            ) : null}
            {message ? <Typography inline>{message}</Typography> : null}
          </div>
          {children}
        </div>
      </div>
      {dismiss ? (
        <div onClick={dismiss} className={styles.dismiss}>
          <Svg icon={'close'} size={0.75} />
        </div>
      ) : (
        false
      )}
    </Panel>
  )
}

Notification.propTypes = propTypes

export { NotificationContainer, Notification }
