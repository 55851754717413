import React, {
  createContext,
  startTransition,
  useCallback,
  useEffect,
  useReducer,
} from 'react'

import useWindow from 'Hooks/useWindow'

import drawerStates from '../../Organisms/CustomerProjectDrawer/Constants/drawerStates'

const initialState = {
  global: {
    isAnyDrawerOpen: false,
  },
  account: {
    isAccountDropdownOpen: false,
  },
  email: {
    isEmailSignupModalOpen: false,
  },
  loyalty: {
    isLoyaltySignupModalOpen: false,
  },
  nonBeta: {
    isLoyaltyNonBetaModalOpen: false,
  },
  garage: {
    isCustomerProjectDrawerOpen: false,
    customerProjectDrawerView: false, //this should use Organisms/CustomerProjectDrawer/Constants/drawerStates values
    isAdditionalFacetDrawerOpen: false,
    skuBaseOverride: null,
  },
  controlBar: {
    isMenuActive: false,
    isShopActive: false,
    isGarageActive: false,
    isAccountActive: false,
    isCartActive: false,
  },
  drawer: {
    isDrawerOpen: false,
    navigation: [{ component: 'PrimaryNavigation', menu: 'mainMenu' }],
    prerenderedNavigation: [
      { component: 'SecondaryNavigation', menu: 'shopParts' },
    ],
  },
  loadingStates: {
    pdpSkeleton: {
      isVisible: false,
      product: {},
    },
  },
  pageType: null,
}

const NavigationReducer = (state, action) => {
  const calculatePageType = action?.payload?.pageType
    ? action?.payload?.pageType
    : state.pageType
  let isSaytModalOpen = false

  switch (action.type) {
    case 'setPageType':
      const { pageType } = action.payload
      return { ...state, pageType: calculatePageType }
    case 'setPdpSkeleton':
      const { isVisible, product } = action.payload
      return {
        ...state,
        loadingStates: {
          ...state.loadingStates,
          pdpSkeleton: { isVisible, product },
        },
      }
    case 'setAccountState':
      const { accountState } = action.payload
      return {
        ...initialState,
        account: accountState,
        pageType: calculatePageType,
      }
    case 'setEmailState':
      const { emailState } = action.payload

      try {
        isSaytModalOpen = !!document.getElementById('sayt_container')
      } catch (e) {
        console.error(e)
        isSaytModalOpen = false
      }

      // dont open if drawer is open, return prev state
      if (
        state?.drawer?.isDrawerOpen ||
        isSaytModalOpen ||
        state?.global?.isAnyDrawerOpen
      ) {
        return {
          ...state,
          email: { ...initialState.email },
          pageType: calculatePageType,
        }
      }

      return {
        ...initialState,
        email: { ...initialState.email, ...emailState },
        pageType: calculatePageType,
      }
    case 'setIosInstallPromptState':
      const { iosInstallpromptState } = action.payload
      return {
        ...initialState,
        iosInstallpromptState,
        pageType: calculatePageType,
      }
    case 'setPushNotePermissionPromptState':
      const { pushNotePermissionPromptState } = action.payload
      return {
        ...initialState,
        pushNotePermissionPromptState,
        pageType: calculatePageType,
      }
    case 'setLoyaltyState':
      const { loyaltyState } = action.payload
      try {
        isSaytModalOpen = !!document.getElementById('sayt_container')
      } catch (e) {
        console.error(e)
        isSaytModalOpen = false
      }

      // dont open if drawer is open, return prev state
      if (state?.drawer?.isDrawerOpen || isSaytModalOpen) {
        return {
          ...state,
          loyalty: { ...initialState.loyalty },
          pageType: calculatePageType,
        }
      }

      return {
        ...initialState,
        loyalty: { ...initialState.loyalty, ...loyaltyState },
        pageType: calculatePageType,
      }
    case 'setNonBetaState':
      const { nonBetaState } = action.payload
      // dont open if drawer is open, return prev state
      if (state?.drawer?.isDrawerOpen) {
        return {
          ...state,
          nonBeta: { ...initialState.nonBeta },
          pageType: calculatePageType,
        }
      }

      return {
        ...initialState,
        nonBeta: { ...initialState.nonBeta, ...nonBetaState },
        pageType: calculatePageType,
      }

    case 'setGarageState': {
      const { garageState } = action.payload

      return {
        ...initialState,
        garage: { ...initialState.garage, ...garageState },
        pageType: calculatePageType,
      }
    }
    case 'setCustomerProjectDrawerState': {
      const { garageState } = action.payload
      const { isCustomerProjectDrawerOpen } = garageState

      if (isCustomerProjectDrawerOpen) {
        return {
          ...initialState,
          garage: { ...initialState.garage, ...garageState },
          controlBar: {
            ...initialState.controlBar,
            isGarageActive: true,
          },
          pageType: calculatePageType,
        }
      }

      return {
        ...initialState,
        garage: { ...initialState.garage, ...garageState },
        pageType: calculatePageType,
      }
    }
    case 'setAdditionalFacetDrawerState': {
      const { garageState } = action.payload
      const { isAdditionalFacetDrawerOpen } = garageState

      if (isAdditionalFacetDrawerOpen) {
        return {
          ...initialState,
          garage: { ...initialState.garage, ...garageState },
          controlBar: {
            ...initialState.controlBar,
            isGarageActive: true,
          },
          pageType: calculatePageType,
        }
      }

      return {
        ...initialState,
        garage: { ...initialState.garage, ...garageState },
        pageType: calculatePageType,
      }
    }
    case 'setDrawerState':
      const { drawerAction, drawerState } = action.payload
      if (drawerAction) {
        return DrawerReducer(state, action)
      }
      return {
        ...state,
        drawer: { ...initialState.drawer, ...drawerState },
        pageType: calculatePageType,
      }
    case 'setIsAnyDrawerOpen':
      const { isAnyDrawerOpen } = action.payload

      return {
        ...state,
        global: { ...state.global, isAnyDrawerOpen },
        pageType: calculatePageType,
      }
    case 'setControlBarState':
      const { controlBarState } = action.payload
      return {
        ...state,
        controlBar: { ...initialState.controlBar, ...controlBarState },
        pageType: calculatePageType,
      }
    default:
      return state
  }
}

const DrawerReducer = (state, action) => {
  const { drawerAction, drawerState } = action.payload
  switch (drawerAction.type) {
    case 'resetNavigation':
      return {
        ...initialState,
        drawer: { ...initialState.drawer, ...drawerState },
        controlBar: { ...initialState.controlBar, isMenuActive: true },
      }
    case 'gotoShopNavigation': {
      const navigation = [...initialState.drawer.navigation]
      navigation.push({
        component: 'SecondaryNavigation',
        menu: 'shopParts',
      })
      return {
        ...initialState,
        drawer: { ...initialState.drawer, ...drawerState, navigation },
        controlBar: { ...initialState.controlBar, isShopActive: true },
      }
    }
    case 'addNavigation': {
      const navigation = [...state.drawer.navigation]
      const addedNavigation = drawerAction.payload
      navigation.push(addedNavigation)
      return {
        ...initialState,
        drawer: { ...initialState.drawer, ...drawerState, navigation },
      }
    }
    case 'dropNavigation': {
      const navigation = [...state.drawer.navigation]
      navigation.pop()
      return {
        ...initialState,
        drawer: { ...initialState.drawer, ...drawerState, navigation },
      }
    }
    default:
      return state
  }
}
const NavigationContext = createContext(initialState)

const NavigationProvider = ({ children, customState }) => {
  const [state, dispatchInner] = useReducer(NavigationReducer, initialState)

  const dispatch = useCallback(
    (action) =>
      startTransition(() => {
        dispatchInner(action)
      }),
    [dispatchInner],
  )

  const setPdpSkeleton = useCallback((isVisible, product) => {
    try {
      //document.getElementById('pdpSkeleton').style.display = isVisible ? 'block' : 'none'
    } catch (err) {
      console.error(err)
    }

    dispatch({
      type: 'setPdpSkeleton',
      payload: {
        isVisible,
        product,
      },
    })
  }, [])

  const setPageType = (type) => {
    dispatch({
      type: 'setPageType',
      payload: {
        pageType: type ?? null,
      },
    })
  }

  useEffect(() => {
    //reset pagetype on load until mfe sets it
    setPageType(null)
  }, [])

  const urlDrawerParams = [
    {
      param: 'showymm=true',
      view: drawerStates.addYmm,
    },
    {
      param: 'showracecars=true',
      view: drawerStates.addRaceType,
    },
    {
      param: 'showengines=true',
      view: drawerStates.addEngine,
    },
    {
      param: 'showvehicles=true',
      view: undefined, // just opens the drawer
    },
  ]

  useWindow((window) => {
    const match = urlDrawerParams.find((param) =>
      window.location.href.includes(param.param),
    )
    if (match) {
      dispatch({
        type: 'setCustomerProjectDrawerState',
        payload: {
          garageState: {
            isCustomerProjectDrawerOpen: true,
            customerProjectDrawerView: match.view,
          },
        },
      })
    }
  })

  return (
    <NavigationContext.Provider
      value={{
        state: { ...state, ...customState },
        dispatch,
        setPageType,
        setPdpSkeleton,
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
}

export { NavigationProvider, NavigationContext }
