export const imageUrlsByCategory = {
  street: {
    'Air and Fuel Delivery':
      'https://content.speedwaymotors.com/ArticleSectionImages/329930_ArticleSection_XXXXL_457ee6d5-f06c-40bd-97df-bb7ffb5f8703.jpg',
    'Apparel, Gifts and Literature':
      'https://content.speedwaymotors.com/ArticleSectionImages/327599_ArticleSection_XXXXL_40c090fb-97ea-4ab9-977c-be5871d3a50c.jpg',
    'Audio and Electronics':
      'https://content.speedwaymotors.com/HomePageImages/TopCategories-Audio-320.jpg',
    Brakes:
      'https://content.speedwaymotors.com/ArticleSectionImages/272680_ArticleSection_XXXXL_a99004b0-dc90-4589-83ea-cb2e770d9b0f.jfif',
    'Chassis and Suspension':
      'https://content.speedwaymotors.com/ArticleSectionImages/342237_ArticleSection_XXXXL_10a9cafe-02ec-4edc-9a15-1bc0e46acd04.jpg',
    'Cooling and Heating':
      'https://content.speedwaymotors.com/ArticleSectionImages/311180_ArticleSection_XXXXL_170c5b08-432c-41e5-9ddc-7fde8a6efb53.png',
    'Driveline and Axles':
      'https://content.speedwaymotors.com/ArticleSectionImages/75064_ArticleSection_XXXXL_d5879c99-828a-45de-95ad-7aa264766a80.jpg',
    'Electrical, Lighting and Wiring':
      'https://content.speedwaymotors.com/ProductImages/wiringHarness.png',
    Engine:
      'https://content.speedwaymotors.com/ArticleSectionImages/275477_ArticleSection_XXXXL_f5cbcca6-4783-4585-9971-131238b2a8a4.jpg',
    'Exterior, Accessories and Trim':
      'https://content.speedwaymotors.com/ArticleSectionImages/329453_ArticleSection_XXXXL_4b376f18-fac5-42f3-a96d-c51e5595f986.jfif',
    'Fasteners and Hardware':
      'https://content.speedwaymotors.com/ArticleSectionImages/109657_ArticleSection_XXXXL_6b65ec83-d649-401a-a32d-07f68a3cf2fd.JPG',
    'Fittings and Hose':
      'https://content.speedwaymotors.com/ArticleSectionImages/105885_ArticleSection_XXXXL_fb319b27-cfce-4bd1-ac86-5ab61de787b7.jpg',
    'Gaskets and Seals':
      'https://content.speedwaymotors.com/ArticleSectionImages/5548_ArticleSection_XL_fe24a078-2baa-49d2-9b91-be085bfeef47.jpg',
    'Gauges and Instrumentation':
      'https://content.speedwaymotors.com/ArticleSectionImages/188015_ArticleSection_XXXXL_41b9232b-7e91-4bb0-bb66-28c7eff78107.jpg',
    'Headers and Exhaust':
      'https://content.speedwaymotors.com/ArticleSectionImages/249855_ArticleSection_XXXXL_75c9850c-6e3e-4dfc-b820-196368bea8a8.JPG',
    'Ignition, Charging and Starting':
      'https://content.speedwaymotors.com/ArticleSectionImages/262302_ArticleSection_M_218bc3bc-2d6d-40bb-9771-223cfb01a0b4.jpg',
    'Interior, Accessories and Trim':
      'https://content.speedwaymotors.com/ArticleSectionImages/329534_ArticleSection_XXXXL_5409cb39-9126-4b2a-b581-640596eb27d5.jfif',
    'Kit Cars':
      'https://content.speedwaymotors.com/MarketCategoryImages/TopCategories-KitCar-320.jpg',
    'Lubricants, Liquids and Adhesives':
      'https://content.speedwaymotors.com/ArticleSectionImages/249895_ArticleSection_XXXXL_b291d45b-b873-48eb-8a86-e1e58c3c365f.jpeg',
    'Paint and Detailing':
      'https://content.speedwaymotors.com/ArticleSectionImages/352509_ArticleSection_XXXXL_f5327fed-127b-4282-8f94-0377930df654.jpg',
    'Pedal Car':
      'https://content.speedwaymotors.com/ArticleSectionImages/171548_ArticleSection_XXXXL_0229f4c7-7439-4e81-817e-60362f2dade0.jpg',
    'Safety Equipment':
      'https://content.speedwaymotors.com/ArticleSectionImages/284581_ArticleSection_XXXXL_2415ad3b-058c-4cf3-b949-ccdb34605f45.jpg',
    Steering:
      'https://content.speedwaymotors.com/ArticleSectionImages/352477_ArticleSection_XXXXL_7854d61d-2dbf-46a4-9ad1-2df28957fb70.jpg',
    'Tools and Equipment':
      'https://content.speedwaymotors.com/ArticleSectionImages/274486_ArticleSection_XXXXL_eaae97a7-58d5-4fc5-af24-f646fa905c8b.jfif',
    'Trailer, Towing and Cargo':
      'https://content.speedwaymotors.com/HomePageImages/trailer-towing-cat-image.jpg',
    Transmission:
      'https://content.speedwaymotors.com/ArticleSectionImages/287505_ArticleSection_XXXXL_58373af8-1947-4155-bceb-520a104ebe8e.png',
    'Wheel and Tire':
      'https://content.speedwaymotors.com/ArticleSectionImages/170435_ArticleSection_XXXXL_9d5951c1-1fc4-455b-af17-37b5f0a1ad6c.jpg',
    'Car Detailing Supplies':
      'https://content.speedwaymotors.com/HomePageImages/CarCare-Category.jpg',
  },
  race: {
    'Air and Fuel Delivery':
      'https://content.speedwaymotors.com/HomePageImages/race-categories-Air-Fuel-Delivery.jpg',
    'Driveline and Axles':
      'https://content.speedwaymotors.com/HomePageImages/race-categories-Driveline-Axles.jpg',
    Engine:
      'https://content.speedwaymotors.com/HomePageImages/race-categories-Engine.jpg',
    'Chassis and Suspension':
      'https://content.speedwaymotors.com/HomePageImages/race-categories-Chassis-Suspension.jpg',
    'Safety Equipment':
      'https://content.speedwaymotors.com/HomePageImages/race-categories-Safety-Equipment.jpg',
    'Trailer, Towing and Cargo':
      'https://content.speedwaymotors.com/HomePageImages/trailer-towing-cat-image.jpg',
    'Exterior, Accessories and Trim':
      'https://content.speedwaymotors.com/HomePageImages/race-categories-Racing-Exterior.jpg',
    'Tools and Equipment':
      'https://content.speedwaymotors.com/HomePageImages/race-categories-Tools-Equipment.jpg',
  },
}
const getImageUrl = (version, categoryName) => {
  return imageUrlsByCategory[version][categoryName]
}
export default getImageUrl
