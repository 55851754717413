import { useContext } from 'react'

import { NavigationContext } from 'Contexts/Navigation/NavigationContext'

import drawerStates from 'Organisms/CustomerProjectDrawer/Constants/drawerStates'

const GetAdditonalFacetInfo = (
  selectedVehicle,
  fitmentData,
  skuBaseOverride = null,
) => {
  if (!selectedVehicle) return

  const promptUserFor = fitmentData?.promptUserFor
  if (!promptUserFor) return

  let vehicleDisplayText

  if (selectedVehicle.vehicleBaseId)
    vehicleDisplayText = `${selectedVehicle.year} ${selectedVehicle.model}`
  else if (selectedVehicle.raceTypeId)
    vehicleDisplayText = selectedVehicle.raceName
  else if (selectedVehicle.engineDisplayName)
    vehicleDisplayText = selectedVehicle.engineDisplayName

  let title
  let ctaText
  let ctaTextLinkOnClick

  if (promptUserFor === 'Vehicle') {
    title = 'Verify the Vehicle for your '
    ctaText = 'Add Vehicle'
    ctaTextLinkOnClick = goToCPDView(drawerStates.updateProjectAddYMM)
  } else if (promptUserFor === 'RaceType') {
    title = 'Verify the Race Type for your '
    ctaText = 'Add Race Type'
    ctaTextLinkOnClick = goToCPDView(drawerStates.updateProjectAddRaceType)
  } else if (promptUserFor === 'Engine') {
    title = 'Verify the Engine for your '
    ctaText = 'Add Engine'
    ctaTextLinkOnClick = goToCPDView(drawerStates.updateProjectAddEngine)
  } else if (promptUserFor === 'AdditionalFacets') {
    title =
      'This part may fit your vehicle and engine, complete options to verify fitment.'
    ctaText = 'Complete Vehicle'
    ctaTextLinkOnClick = showAdditionalFacetDrawer(skuBaseOverride)
    // This keeps old functionality. Unsure if this intended? Mike question
    vehicleDisplayText = ''
  }

  return {
    title,
    vehicleDisplayText,
    ctaText,
    ctaTextLinkOnClick,
  }
}

const goToCPDView = (customerProjectDrawerView) => {
  const { dispatch: navigationDispatch } = useContext(NavigationContext)
  return () =>
    navigationDispatch({
      type: 'setCustomerProjectDrawerState',
      payload: {
        garageState: {
          isCustomerProjectDrawerOpen: true,
          customerProjectDrawerView: customerProjectDrawerView,
        },
      },
    })
}

const showAdditionalFacetDrawer = (skuBaseOverride) => {
  const { dispatch: navigationDispatch } = useContext(NavigationContext)
  return () =>
    navigationDispatch({
      type: 'setAdditionalFacetDrawerState',
      payload: {
        garageState: {
          isAdditionalFacetDrawerOpen: true,
          skuBaseOverride: skuBaseOverride,
        },
      },
    })
}

export default GetAdditonalFacetInfo
