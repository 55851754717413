import RecommendationsFragment from '../Graphql/recommendations.fragment'

export async function getRecommendationSections(
  sections,
  skuBaseNumbers,
  skuVariantNumbers,
  articleIds,
  userVin,
  webUserGuid,
  ymmData,
  keywords,
) {
  const query =
    `
query GetRecommendationSections($sections: [String]
  $skuBaseNumbers: [String]
  $skuVariantNumbers: [String]
  $articleIds: [String]
  $ymmData: YmmDataInput
  $keywords: String
  $userVin: String
  $webUserGuid: String){
  recommendationSections(sections: $sections
    skuBaseNumbers:$skuBaseNumbers
    skuVariantNumbers:$skuVariantNumbers
    articleIds:$articleIds
    ymmData:$ymmData
    keywords:$keywords
    userVin:$userVin
    webUserGuid:$webUserGuid){
    ` +
    RecommendationsFragment +
    `
  }
}
`
  const variables = {
    keywords,
    skuBaseNumbers: skuBaseNumbers?.filter((x) => x), //filter out any empties
    skuVariantNumbers: skuVariantNumbers?.filter((x) => x), //filter out any empties
    articleIds: articleIds?.filter((x) => x), //filter out any empties
    userVin,
    webUserGuid,
    sections,
    ymmData,
  }

  const body = JSON.stringify({
    query,
    variables,
  })

  const url = '/graphql/recommendations'

  const res = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  }).catch((error) => {
    console.error('failed to load recommendations')
    console.error('error: ', error)
  })
  const data = await res.json()

  return data.data
}
