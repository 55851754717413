import React, { Fragment } from 'react'

import ErrorPage from '../../Organisms/ErrorPage'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, errorStatusCode: null }
    this.showUI = this.props.showUI ?? false
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorStatusCode: error.statusCode ?? null }
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo)

    // an optional parameter to do something onError (like a hard refresh)
    if (this.props.onError) {
      this.props.onError(error, errorInfo)
    }
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      if (this.showUI) {
        return <ErrorPage statusCode={this.state.errorStatusCode ?? 500} />
      }
      if (this.props.showFallbackUI) {
        return this.props.fallbackUI
      }
      return false
    }
    return this.props.children
  }
}

export const WSODErrorBoundary = ({ children }) => {
  // allows one hard refresh if we have a white screen style of error
  const handleError = (err, errorInfo) => {
    console.error(`WSOD Error Boundary caught: ${err?.message}`, err, errorInfo)
    console.error("It's likely a WSOD occurred")
    const parameter = 'CsrErrorRefresh'
    const url = new URL(window.location.href)
    if (!url.searchParams.get(parameter)) {
      console.error('Refreshing the page due to WSOD')
      url.searchParams.append(parameter, '1')
      window.location.replace(url)
    } else {
      console.error(
        parameter +
          ' param was detected from WSOD - not refreshing a second time',
      )
    }
  }

  return (
    <ErrorBoundary showUI={true} onError={handleError}>
      {children}
    </ErrorBoundary>
  )
}

export default ErrorBoundary
