import { StorageAccessor } from 'Utilities/LocalStorageUtility'

export const fetchTraitNavItems = (type, url) => {
  switch (type) {
    case 'Brand':
      return getBrandItems(url)
    case 'Category':
      return getCategoryItems(url)
    case 'Make':
      return getMakeItems(url)
    case 'Engine':
      return getEngineItems(url)
    case 'NewEngine':
      return getNewEngineItems(url)
    case 'Transmission':
      return getTransmissionItems(url)
    case 'Rearaxle':
      return getRearAxleItems(url)
    case 'Platform':
      return getPlatforms(url)
    default:
      return Promise.resolve([])
  }
}

function getPlatforms(url) {
  const query = `query AllTopLevelPlatforms{
        links: allTopLevelPlatforms{
                href: url
                primary: fullName
                subItems: childPlatforms{
                    href: url
                    primary: partialName
                }
            }
        }`

  return requestTraitData(query, url)
}

function getBrandItems(url) {
  const query = `query {
        links : brandLinks{
            href : url
            primary : displayName,
            brandImageUrl
        }
    }`

  return requestTraitData(query, url)
}

function getCategoryItems(url) {
  const query = `query {
        links : categoryLinks{
            href : url
            primary : displayName
        }
    }`

  return requestTraitData(query, url)
}

function getMakeItems(url) {
  const query = `query {
        links :  allMakes {
            href : url
            primary : make
        }
    }`

  return requestTraitData(query, url)
}

function getEngineItems(url) {
  const query = `query {
        links : engineLinks{
            href : url
            primary : displayName
        }
    }`

  return requestTraitData(query, url)
}

function getNewEngineItems(url) {
  const query = `query {
        links: engineLinksForSiteMap {
            href: url
            primary: displayName
        } 
    }`

  return requestTraitData(query, url)
}

function getTransmissionItems(url) {
  const query = `query {
        links : transmissionLinks{
            href : url
            primary : displayName
        }
    }`
  return requestTraitData(query, url)
}

function getRearAxleItems(url) {
  const query = `query {
        links : rearAxleLinks{
            href : url
            primary : displayName
        }
    }`

  return requestTraitData(query, url)
}

function requestTraitData(query, url) {
  const fromLocalStorage = StorageAccessor.localStorage.getObj(query)
  if (fromLocalStorage) {
    return fromLocalStorage
  }

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: query,
    }),
    credentials: 'include',
  })
    .then((response) => {
      return response.json()
    })
    .then((response) => {
      StorageAccessor.localStorage.setObj(query, response.data.links, 720)
      return response.data.links
    })
    .catch((error) => {
      console.error('Failed Trait Nav Request', error)
      return []
    })
}
