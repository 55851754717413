import React, { useContext, useEffect, useState } from 'react'

import { createViewedSegmentEvent } from 'Utilities/Instrumentation/Impressions/impressionSegmentEvents'

import { NavigationContext } from 'Contexts/Navigation/NavigationContext'

import useSegment from 'Hooks/useSegment'

import Drawer from 'Molecules/NewDrawer/Drawer'

import AddEngineView from './Components/AddEngineView/AddEngineView'
import AddRaceTypeView from './Components/AddRaceTypeView/AddRaceTypeView'
import AddYmmView from './Components/AddYmmView'
import ExistingProjectView from './Components/ExistingProjectView'
import UpdateProjectView from './Components/UpdateProjectView/UpdateProjectView'
import drawerStates from './Constants/drawerStates'
import styles from './CustomerProjectDrawer.module.scss'

const CustomerProjectDrawer = ({ isOpen, onClose }) => {
  const [drawerState, setDrawerState] = useState(drawerStates.existingProjects)
  const [isOpenInner, setIsOpenInner] = useState(false)

  const { state: navigationState } = useContext(NavigationContext)
  const { garage } = navigationState
  const { customerProjectDrawerView } = garage

  const { sendSegmentTrackEvent } = useSegment()

  useEffect(() => {
    if (drawerState === drawerStates.existingProjects) setIsOpenInner(false)
    else setIsOpenInner(true)
  }, [drawerState])

  useEffect(() => {
    if (
      customerProjectDrawerView &&
      getDrawerStateFromStore(customerProjectDrawerView) != null
    ) {
      setDrawerState(customerProjectDrawerView)
    }
  }, [customerProjectDrawerView])

  useEffect(() => {
    if (isOpen) {
      const viewEvent = createViewedSegmentEvent('Customer Project Drawer')
      sendSegmentTrackEvent(viewEvent)
    }
  }, [isOpen])

  let DrawerContents
  let drawerTitle
  let showBackButton = false

  if (drawerState === drawerStates.existingProjects) {
    DrawerContents = undefined
    drawerTitle = 'Your Garage'
  } else if (drawerState === drawerStates.addYmm) {
    DrawerContents = <AddYmmView />
    drawerTitle = 'Add a Vehicle'
    showBackButton = true
  } else if (drawerState === drawerStates.addRaceType) {
    DrawerContents = <AddRaceTypeView />
    drawerTitle = 'Add a Race Car'
    showBackButton = true
  } else if (drawerState === drawerStates.addEngine) {
    DrawerContents = <AddEngineView />
    drawerTitle = 'Add an Engine'
    showBackButton = true
  } else if (drawerState === drawerStates.updateProjectAddEngine) {
    DrawerContents = <UpdateProjectView promptFor={'ENGINE'} />
    drawerTitle = 'Complete Your Vehicle'
    showBackButton = true
  } else if (drawerState === drawerStates.updateProjectAddYMM) {
    DrawerContents = <UpdateProjectView promptFor={'YMM'} />
    drawerTitle = 'Complete Your Vehicle'
    showBackButton = true
  } else if (drawerState === drawerStates.updateProjectAddRaceType) {
    DrawerContents = <UpdateProjectView promptFor={'RACETYPE'} />
    drawerTitle = 'Complete Your Vehicle'
    showBackButton = true
  }

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => {
        setDrawerState(drawerStates.existingProjects)
        onClose()
      }}
      location={'right'}
      title={'Your Garage'}
      portalId={'customerProjectDrawer'}
      testId={'customerProjectDrawer'}
      isStacked={isOpenInner}
    >
      <div className={styles.container}>
        <ExistingProjectView setDrawerState={setDrawerState} />
      </div>
      <Drawer
        isOpen={isOpenInner}
        onClose={() => {
          setDrawerState(drawerStates.existingProjects)
          onClose()
        }}
        setDrawerState={undefined}
        location={'right'}
        title={drawerTitle}
        portalId={'customerDrawerInner'}
        showBackButton={showBackButton}
        onBackButtonPressed={() => {
          setDrawerState(drawerStates.existingProjects)
          setIsOpenInner(false)
        }}
        testId={'customerDrawerInner'}
      >
        <div className={styles.container}>{DrawerContents}</div>
      </Drawer>
    </Drawer>
  )
}

function getDrawerStateFromStore(value) {
  return Object.keys(drawerStates).find((key) => drawerStates[key] === value)
}

export default CustomerProjectDrawer
