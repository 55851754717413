const getEngineIds = (
  hasEngineSelected,
  existingState,
  selectedStockEngine,
) => {
  const engineIds = {}
  const engineIdSource =
    hasEngineSelected && existingState
      ? existingState
      : selectedStockEngine?.engineIds
  if (!engineIdSource) return null

  if (engineIdSource.engineManufacturerId)
    engineIds.engineManufacturerId = engineIdSource.engineManufacturerId
  if (engineIdSource.engineMakeId)
    engineIds.engineMakeId = engineIdSource.engineMakeId
  if (engineIdSource.engineSeriesId)
    engineIds.engineSeriesId = engineIdSource.engineSeriesId
  if (engineIdSource.engineConfigurationId)
    engineIds.engineConfigurationId = engineIdSource.engineConfigurationId
  if (engineIdSource.engineQualifierId)
    engineIds.engineQualifierId = engineIdSource.engineQualifierId
  if (engineIdSource.engineVersionId)
    engineIds.engineVersionId = engineIdSource.engineVersionId
  if (engineIdSource.engineDisplacementId)
    engineIds.engineDisplacementId = engineIdSource.engineDisplacementId
  if (engineIdSource.engineCodeId)
    engineIds.engineCodeId = engineIdSource.engineCodeId
  if (engineIdSource.engineCamShaftTypeId)
    engineIds.engineCamShaftTypeId = engineIdSource.engineCamShaftTypeId
  if (engineIdSource.engineFuelSystemId)
    engineIds.engineFuelSystemId = engineIdSource.engineFuelSystemId

  return engineIds
}

export default getEngineIds
