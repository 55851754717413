import React, { useContext } from 'react'

import { NotificationContext } from 'Contexts/NotificationContext/NotificationContext'

import ConfirmationDialog, {
  AlertDialog,
  PromptDialog,
} from 'Molecules/ConfirmationDialog/ConfirmationDialog'

import Typography from 'Atoms/Typography'

export const AlertSwitch = () => {
  // alertType: '', // alert, confirm, prompt
  // isOpen: false,
  // message: null,
  // successCallback: () => {},
  // cancelCallback: () => {},
  // promptInputConfig: {
  //     inputValue: null,
  //     inputLabel: null,
  //     inputValidationPredicates: [],
  //     failedValidationMessages: [], // the results of inputValidationPredicates if validation fails
  // },
  const { alertState, ClearAlert, onConfirmClick, SetPromptAlertInputValue } =
    useContext(NotificationContext)

  if (!alertState) {
    return false
  } else if (alertState.alertType == 'confirm') {
    return (
      <ConfirmationDialog
        isOpen={alertState.isOpen}
        closeSelfFunc={ClearAlert}
        onConfirmClick={onConfirmClick}
        buttonText={'Confirm'}
        parentManagedVisibility={true}
      >
        <Typography data-testid={'modalContent'}>
          {alertState.message}
        </Typography>
      </ConfirmationDialog>
    )
  } else if (alertState.alertType == 'alert') {
    return (
      <AlertDialog
        isOpen={alertState.isOpen}
        closeSelfFunc={ClearAlert}
        onConfirmClick={onConfirmClick}
        parentManagedVisibility={true}
      >
        <Typography data-testid={'modalContent'}>
          {alertState.message}
        </Typography>
      </AlertDialog>
    )
  } else if (alertState.alertType == 'prompt') {
    return (
      <PromptDialog
        isOpen={alertState.isOpen}
        closeSelfFunc={ClearAlert}
        onConfirmClick={onConfirmClick}
        parentManagedVisibility={true}
        buttonText={'Confirm'}
        label={alertState.promptInputConfig?.inputLabel}
        setValue={SetPromptAlertInputValue}
        value={alertState.promptInputConfig?.inputValue}
        validationErrorMessages={
          alertState.promptInputConfig?.failedValidationMessages
        }
      >
        <Typography data-testid={'modalContent'}>
          {alertState.message}
        </Typography>
      </PromptDialog>
    )
  } else {
    return false
  }
}
