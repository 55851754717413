import React from 'react'

import Button from 'Molecules/Button'

import Svg from 'Atoms/Svg'
import Typography from 'Atoms/Typography/Typography'

import useFeatureFlag from '../../../Hooks/useFeatureFlag/useFeatureFlag'
import { createClickedSegmentEvent } from '../../../Utilities/Instrumentation/Impressions/impressionSegmentEvents'
import styles from './HeaderDrawer.module.scss'

const CallNow = () => {
  const contactUsFormFeatureFlag = useFeatureFlag('Contact_Us_Form')
  return (
    <div className={styles.drawerNavFooter}>
      <div className={styles.drawerCallButton}>
        <Button
          href={'tel:18553139176'}
          brand={'secondary'}
          intent={'positive'}
          invert
          fill
          size={'large'}
          segmentEvent={createClickedSegmentEvent('HeaderCallUs')}
          text={'Call Now'}
        />
      </div>
      {contactUsFormFeatureFlag && (
        <Button
          href={'/info/helpcenter#contactform'}
          brand={'secondary'}
          invert
          segmentEvent={createClickedSegmentEvent('ContactUsFooter')}
          size={'small'}
          inline
          icon={'wheel'}
          text={`Contact Us`}
        />
      )}
    </div>
  )
}

export default CallNow
