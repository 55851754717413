import React, { forwardRef, useCallback, useEffect, useState } from 'react'

import Modal from 'Components/Modal/ModalWithPortal'

import Button from 'Molecules/Button'

import Divider from 'Atoms/Divider'
import Form from 'Atoms/Form'
import Panel from 'Atoms/Panel'
import Typography from 'Atoms/Typography'

import styles from './ListCreationModal.module.scss'

const ListCreationModal = ({ onCreateList }, ref) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [nameInput, setNameInput] = useState('')

  const createNewListEvent = {
    event: 'Clicked',
    properties: {
      element: 'List Creation Modal: Submit Create New List',
    },
  }

  const onChange = useCallback(() => {
    setNameInput(document.getElementById('new_list_name')?.value)
  })

  const onSubmitFunction = useCallback((e) => {
    e.preventDefault()
    const newListName = document.getElementById('new_list_name')?.value

    if (!newListName) {
      setErrorMessage('Please provide a list name.')
    } else if (newListName.length > 40) {
      setErrorMessage('List name must be less than 40 characters.')
    } else {
      onCreateList(newListName)
      setNameInput('')
      setErrorMessage('')
      ref.current.close()
    }
  })

  const onClose = () => {
    setNameInput('')
    setErrorMessage('')
  }

  const content = (
    <Panel layer={0} className={styles.listCreationModal}>
      <Typography
        tone={'normal'}
        font={'bold'}
        size={1.25}
        className={styles.listCreationModalContentTop}
      >
        Create New List
      </Typography>
      <Divider />
      <Typography tone={'normal'} className={styles.listCreationModalContent}>
        Create a new list and get your project going!
      </Typography>
      <Form id={'list_creation_form'} onSubmit={onSubmitFunction}>
        <Form.Textbox
          autoFocus
          id={'new_list_name'}
          fill
          brand={'primary'}
          placeholder={'Name your new list'}
          data-testid={'newListInput'}
          onChange={onChange}
        />
        <br />
        <Typography>{nameInput?.length || 0}/40 Characters</Typography>
        <Typography intent={'negative'} size={1}>
          {errorMessage}
        </Typography>
        <Button
          className={styles.button}
          size={'small'}
          text={'Create New List'}
          intent={'action'}
          fill
          label={'Create New List Button'}
          segmentEvent={createNewListEvent}
          onClick={onSubmitFunction}
          testingName={'createNewListButton'}
        />
      </Form>
    </Panel>
  )

  return (
    <Modal
      id={'createListModal'}
      ref={ref}
      outsideClick
      className={styles.listCreationModalContainer}
      customOnClose={onClose}
    >
      {content}
    </Modal>
  )
}

export default forwardRef(ListCreationModal)
