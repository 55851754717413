'use client'

import React, { useRef } from 'react'

import ClassNames from 'classnames'
import PropTypes from 'prop-types'

import ColorFinder from 'Utilities/ColorFinder'
import LayerColorFinder from 'Utilities/LayerColorFinder'

import Brands from 'Constants/Brand'

import PanelContext from 'Contexts/PanelContext'

import InlineSpinner from 'Atoms/InlineSpinner'
import Svg from 'Atoms/Svg'
import Typography from 'Atoms/Typography'

import useNavigationState from '../../Hooks/useAppInstall/useNavigationState'
import useSegment from '../../Hooks/useSegment'
import styles from './Button.module.scss'
import styled from 'styled-components'

const propTypes = {
  className: PropTypes.string,
  intent: PropTypes.oneOf([
    'positive',
    'negative',
    'action',
    'warning',
    'subdued',
    undefined,
  ]),
  brand: PropTypes.oneOf(Brands),
  size: PropTypes.oneOf(['large', 'medium', 'small', undefined]),
  type: PropTypes.oneOf(['submit', 'input', 'button', 'div', undefined]),
  href: PropTypes.string,
  onClick: PropTypes.func,
  invert: PropTypes.bool,
  inline: PropTypes.bool,
  fill: PropTypes.bool,
  allowWrap: PropTypes.bool,
  ariaLabel: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
}

const Button = ({
  className,
  intent,
  brand,
  size,
  type = 'button',
  href,
  onClick,
  invert,
  inline,
  fill,
  allowWrap = true,
  ariaLabel,
  children,
  testingName,
  text,
  icon,
  isWaiting,
  disabled,
  segmentEvent,
  ...props
}) => {
  const ref = useRef()
  const { sendSegmentTrackEvent } = useSegment()
  const { showNavigation, tryDisableGtmAutoLinkTrackingForInternalNavigation } =
    useNavigationState()

  intent = intent && intent.toLowerCase()
  brand = brand && brand.toLowerCase()
  size = (size && size.toLowerCase()) || 'large'

  let { color, hoverColor, panelColor } = ColorFinder(
    intent,
    undefined,
    brand,
    disabled,
  )
  const subduedColor = LayerColorFinder(null, null, null, null) // this is a hook... so ... don't conditionally run it

  const onClickWrapper = (e, onClick, segmentEvent, disabled) => {
    if (disabled) {
      return
    }
    if (onClick) {
      onClick(e)
    }

    const isNavigatingFromBrowserInsteadOfJs = href && !e?.defaultPrevented
    const isSpaLikeInteraction = !isNavigatingFromBrowserInsteadOfJs
    if (isNavigatingFromBrowserInsteadOfJs) {
      tryDisableGtmAutoLinkTrackingForInternalNavigation(href)
    }

    if (href) {
      showNavigation()
    }

    if (segmentEvent.optOut) {
      return
    }

    const deferSegmentEvent = isSpaLikeInteraction
    sendSegmentTrackEvent(segmentEvent, deferSegmentEvent)
  }

  if (!intent && !brand) {
    console.error(
      "Button not rendered: Neither 'intent' or 'brand' was specified",
    )
    return false
  }
  if (!segmentEvent?.event && !segmentEvent?.optOut) {
    console.error('Button not rendered: No Segment Event')
    return false
  }
  if (!disabled && type !== 'submit' && type !== 'div' && !(onClick || href)) {
    console.info('Button does not have onClick or href, but is rendered', ref)
    console.error(
      'Button does not have onClick or href, but is rendered',
      ref?.current?.className,
      ref?.current?.id,
    )
  }

  const component =
    type === 'div'
      ? 'div'
      : href
        ? 'a'
        : type === 'submit'
          ? 'button'
          : 'button'

  const panelContextValue = {
    brand: brand,
    intent: intent,
    invert: invert,
  }
  const globalClassNames = ClassNames(
    className,
    invert ? `btn btn-invert` : 'btn',
    `bg-color-${brand ?? intent}`,
  )
  const buttonNoChildrenClasses = ClassNames(
    className,
    styles.button,
    size === 'large' && styles.buttonLarge,
    size === 'small' && styles.buttonSmall,
    size === 'medium' && styles.buttonMedium,
    !allowWrap && styles.noWrap,
    isWaiting && styles.buttonWaiting,
    fill && styles.buttonFill,
    disabled && styles.buttonDisabled,
    globalClassNames,
  )

  if (intent === 'subdued' && !brand && !disabled) {
    //same as optionButton styling
    color = subduedColor
  }

  return (
    <PanelContext.Provider value={panelContextValue}>
      <StyledButton
        as={component}
        className={buttonNoChildrenClasses}
        type={type == 'submit' || type == 'button' ? type : null}
        href={href}
        onClick={(e) => onClickWrapper(e, onClick, segmentEvent, disabled)}
        aria-label={ariaLabel}
        color={color}
        hoverColor={hoverColor}
        invert={invert}
        panelColor={panelColor}
        data-testid={testingName}
        disabled={disabled || isWaiting}
        isWaiting={isWaiting}
        intent={intent}
        ref={ref}
        {...props}
      >
        {isWaiting ? (
          <>
            <div className={styles.spinnerWrapper}>
              <InlineSpinner intent={intent} brand={panelContextValue.brand} />
            </div>
          </>
        ) : null}
        <ButtonInnerContent
          text={text}
          icon={icon}
          forwardedChildren={children}
          size={size}
          disabled={disabled}
        />
      </StyledButton>
    </PanelContext.Provider>
  )
}

const sizeLookup = {
  small: 1,
  medium: 1.25,
  large: 1.5,
}

const ButtonInnerContent = ({
  forwardedChildren,
  icon,
  text,
  size,
  disabled,
}) => {
  /*
          IMPORTANT
          If you use children just assume that none of the attributes are working according to the tested state and you must test all functionality
      */

  if (forwardedChildren) {
    return forwardedChildren
  } else {
    return (
      <>
        {icon ? (
          <div
            style={{
              height: sizeLookup[size],
              display: 'flex',
              alignItems: 'center',
              marginRight: text ? (size === 'small' ? '0.5rem' : '1rem') : '0',
            }}
          >
            <Svg
              icon={icon}
              size={sizeLookup[size]}
              tone={'normal'}
              disabled={disabled}
            />
          </div>
        ) : (
          false
        )}
        {text ? (
          <Typography
            tone={'contrast'}
            font={'bold'}
            size={sizeLookup[size]}
            inline
            disabled={disabled}
          >
            {text}
          </Typography>
        ) : (
          false
        )}
      </>
    )
  }
}

const StyledButton = styled.button`
  background-color: ${(props) =>
    props.invert ? props.panelColor : props.color};
  border: ${(props) =>
    props.invert
      ? '2px solid ' + props.color
      : props.intent === 'subdued'
        ? '1px solid ' + LayerColorFinder(3)
        : '2px solid transparent'};

  ${(props) =>
    !props.disabled && !props.isWaiting
      ? `&:hover {
            background-color: ${
              props.invert ? props.color : props.hoverColor
            };    
            ${
              props.invert
                ? `p{
                    color: ${props.panelColor};
                }
                svg{
                    fill: ${props.panelColor};
                }`
                : ''
            }        
        }`
      : ''}
`

Button.propTypes = propTypes

export default Button
