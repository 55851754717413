import React, { useContext, useEffect, useState } from 'react'

import ErrorBoundary from 'UtilityComponents/ErrorBoundary'

import { StorageAccessor } from 'Utilities/LocalStorageUtility'

import useCart from 'Hooks/useCart/useCart'
import { useLazyFeatureFlag } from 'Hooks/useFeatureFlag/useFeatureFlag'
import useWindow from 'Hooks/useWindow'

import Popover from 'Components/Popover'

import ListItemIcon from 'Molecules/List/ListItemIcon'

import { NavbarItemLink } from '../NavbarItem/NavbarItem'

const Cart = ({ classNames, url }) => {
  const { cart, updateCart } = useCart()

  useWindow((window) => {
    if (window.location.href.includes('Confirmation')) {
      updateCart()
    }

    if (window.SMI) {
      window.SMI.updateDs9CartContext = (itemCount) => {
        updateCart()
      }
    }
  })

  return <CommonCartGuts classNames={classNames} itemCount={cart?.itemCount} />
}

const CommonCartGuts = ({ classNames, itemCount }) => {
  return (
    <NavbarItemLink
      className={classNames}
      title={'Shopping Cart Link'}
      href={'/shoppingCart'}
      border={false}
      hover
      data-testid={'shoppingCart'}
    >
      <ListItemIcon
        icon={'cart'}
        tone={'subtle'}
        size={1.75}
        badge
        badgeProps={{
          brand: 'secondary',
          number: itemCount ?? 0,
          size: 'small',
        }}
      />
    </NavbarItemLink>
  )
}

export default Cart
