import { useSyncExternalStore } from 'react'

import { useGlobalStateStore } from '../../Stores/GlobalStateStore/GlobalStateStore'

// this is split because you can set local state without rerendering the setting component through this
export const useGlobalStateSetter = (key) => {
  const setValue = useGlobalStateStore((x) => x.setValue)
  const setGlobalState = (newState) => {
    setValue(key, newState)
  }

  return { setGlobalState }
}

const useGlobalState = (key, initialState) => {
  let globalState = useGlobalStateStore((x) => x[key])
  const { setGlobalState } = useGlobalStateSetter(key)

  if (!globalState && !!initialState) {
    globalState = initialState
  }

  return { globalState, setGlobalState }
}

export default useGlobalState
