import React, { useContext, useEffect, useState } from 'react'

import { useVehicleStore } from 'Stores/VehicleStore'
import ClassNames from 'classnames'

import { NavigationContext } from 'Contexts/Navigation/NavigationContext'

import headerStyles from '../Header.module.scss'
import { NavbarItem } from '../NavbarItem/NavbarItem'

const GarageSection = ({}) => {
  try {
    const customerProjects = useVehicleStore((x) => x.context.customerProjects)
    const getSelectedCustomerProjectTitle = useVehicleStore(
      (x) => x.getSelectedCustomerProjectTitle,
    )
    const executeGarageReload = useVehicleStore((x) => x.executeGarageReload)
    const selectedCustomerProject = useVehicleStore(
      (x) => x.context.selectedCustomerProject,
    )
    const { dispatch: navigationDispatch } = useContext(NavigationContext)
    const [customRef, setCustomParentTriggerRef] = useState(null)

    useEffect(() => {
      if (typeof window !== 'undefined') {
        if (window.SMI) {
          window.SMI.showDs9YMMDropdown = function (
            market = 'street',
            reloadCurrentLocation = false,
          ) {
            const totalCustomerProjects = customerProjects
              ? customerProjects.length
              : 0

            if (totalCustomerProjects === 0) {
              navigationDispatch({
                type: 'setCustomerProjectDrawerState',
                payload: { garageState: { isCustomerProjectDrawerOpen: true } },
              })
              executeGarageReload(reloadCurrentLocation)
              return
            }

            if (totalCustomerProjects > 0 && customRef) {
              customRef.click()
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
              })
              navigationDispatch({
                type: 'setCustomerProjectDrawerState',
                payload: { garageState: { isCustomerProjectDrawerOpen: true } },
              })
              executeGarageReload(reloadCurrentLocation)
            }
          }
        }
      }
    }, [typeof window, customRef, customerProjects])

    const totalCustomerProjects = customerProjects ? customerProjects.length : 0
    const displayText =
      getSelectedCustomerProjectTitle() ??
      (totalCustomerProjects > 0 ? 'Select A Vehicle' : 'Add a Vehicle')

    return (
      <NavbarItem
        key={selectedCustomerProject}
        icon={'garage'}
        badge
        badgeProps={{
          intent: 'action',
          number: totalCustomerProjects,
          size: 'small',
          removeNumber: true,
        }}
        primaryText={'My Garage'}
        secondaryTextOptions={{
          className: ClassNames(
            headerStyles.headerGarageTextWrapper,
            headerStyles.headerOverflowText,
          ),
        }}
        secondaryText={displayText}
        hover
        data-testid={'Garage'}
        onClick={() =>
          navigationDispatch({
            type: 'setCustomerProjectDrawerState',
            payload: { garageState: { isCustomerProjectDrawerOpen: true } },
          })
        }
      />
    )
  } catch (e) {
    console.error(e)
    return false
  }
}

export default GarageSection
