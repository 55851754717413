import { useState } from 'react'

import { useUserStore } from 'Stores/UserStore/UserStore'
import { useVehicleStore } from 'Stores/VehicleStore'

import Retry from 'Utilities/Retry'

import useLockedAsyncEffect from 'Hooks/useLockedAsyncEffect'

import { useUserSpecificProductDataStore } from '../UserSpecificProductDataStore'

export default function LazyLoadFitmentData({ disable }) {
  const [
    previousFitmentDataFromFitmentFacet,
    setPreviousFitmentDetailsFromFitmentFacet,
  ] = useState({ firstRender: true }) //must != null for first fetch
  const [previousTrackedSkuBases, setPreviousTrackedSkuBases] = useState([])

  const isBot = useUserStore((x) => x.context.isBot)

  const trackedSkuBases = useUserSpecificProductDataStore(
    (x) => x.trackedSkuBases,
  )
  const fitmentDataBySkuBase = useUserSpecificProductDataStore(
    (x) => x.fitmentDataBySkuBase,
  )
  const addFitmentDataForSkuBase = useUserSpecificProductDataStore(
    (x) => x.addFitmentDataForSkuBase,
  )
  const fitmentDetails = useUserSpecificProductDataStore(
    (x) => x.fitmentDetailsFromSelectedFacets,
  )
  const selectedCustomerProject = useVehicleStore(
    (x) => x.context?.selectedCustomerProject,
  )

  async function loadProductFitmentData() {
    if (disable) {
      return
    }

    const hasSelectedCustomerProjectFromFacets =
      fitmentDetails?.vehicleBaseId ||
      fitmentDetails?.raceTypeId ||
      fitmentDetails?.engineManufacturerId
    const hasSelectedCustomerProject =
      selectedCustomerProject?.vehicleBaseId ||
      selectedCustomerProject?.raceTypeId ||
      selectedCustomerProject?.engineDisplayName

    if (!hasSelectedCustomerProjectFromFacets && !hasSelectedCustomerProject) {
      for (const skuBase of Object.keys(fitmentDataBySkuBase)) {
        addFitmentDataForSkuBase(skuBase, null)
      }
      setPreviousFitmentDetailsFromFitmentFacet({ firstRender: true })
      setPreviousTrackedSkuBases([])
      return
    }

    //use the vehicle fitment from facets first for PRPs, fallback to customer project
    let fitmentToUse

    if (hasSelectedCustomerProjectFromFacets) fitmentToUse = fitmentDetails
    else fitmentToUse = selectedCustomerProject

    let skuBasesToRequest = []

    // If the selected fitment has changed, request fitment data for all tracked skuBases
    if (fitmentToUse != previousFitmentDataFromFitmentFacet) {
      skuBasesToRequest = trackedSkuBases
    } else if (
      trackedSkuBases.length > 0 &&
      trackedSkuBases.length > previousTrackedSkuBases.length
    ) {
      skuBasesToRequest = trackedSkuBases.filter(
        (skuBase) => !fitmentDataBySkuBase[skuBase],
      )
    }

    if (skuBasesToRequest.length) {
      const fitmentModule = await Retry(
        () => import('Utilities/checkFitment/checkFitment'),
      )
      const CheckFitmentForSkuBases = fitmentModule.default
      const fitmentData = await CheckFitmentForSkuBases(
        skuBasesToRequest,
        fitmentToUse,
      )
      fitmentData?.forEach((fitment) => {
        addFitmentDataForSkuBase(
          fitment.key,
          fitment?.value?.fitment?.[0]?.value,
        )
      })
      setPreviousFitmentDetailsFromFitmentFacet(fitmentToUse)
      setPreviousTrackedSkuBases(trackedSkuBases)
    }
  }

  useLockedAsyncEffect(async () => {
    if (isBot() == false) {
      await loadProductFitmentData()
    }
  }, [
    disable,
    trackedSkuBases,
    fitmentDetails?.vehicleBaseId,
    fitmentDetails?.raceTypeId,
    fitmentDetails?.engineDisplayName,
    fitmentDetails?.engineManufacturerId,
    fitmentDetails?.engineMakeId,
    fitmentDetails?.engineSeriesId,
    fitmentDetails?.engineConfigurationId,
    fitmentDetails?.engineVersionId,
    fitmentDetails?.engineQualifierId,
    fitmentDetails?.engineDisplacementId,
    fitmentDetails?.engineCodeId,
    fitmentDetails?.camshaftTypeId,
    fitmentDetails?.fuelSystemId,
    selectedCustomerProject?.vehicleBaseId,
    selectedCustomerProject?.raceTypeId,
    selectedCustomerProject?.engineDisplayName,
    selectedCustomerProject?.engineManufacturerId,
    selectedCustomerProject?.engineMakeId,
    selectedCustomerProject?.engineSeriesId,
    selectedCustomerProject?.engineConfigurationId,
    selectedCustomerProject?.engineVersionId,
    selectedCustomerProject?.engineQualifierId,
    selectedCustomerProject?.engineDisplacementId,
    selectedCustomerProject?.engineCodeId,
    selectedCustomerProject?.camshaftTypeId,
    selectedCustomerProject?.fuelSystemId,
  ])

  return false
}
