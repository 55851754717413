import React from 'react'

import Script from 'next/script'
import { func } from 'prop-types'

const GoogleAnalytics4Queue = () => {
  return (
    <Script id="GoogleAnalytics4Queue" strategy="beforeInteractive">
      {`
        window.dataLayer = window.dataLayer || [];
        window.gtag = function() {
          dataLayer.push(arguments);
        }
    `}
    </Script>
  )
}

const FacebookQueue = () => {
  return (
    <Script id="FacebookQueue" strategy="beforeInteractive">
      {`
      try {
            !function (f, b, e, v, n, t, s) {
                if (f.fbq) return;
                n = f.fbq = function () {
                    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                };
                
                if (!f._fbq) f._fbq = n;
                n.push = n;
                n.loaded = !0;
                n.version = '2.0';
                n.queue = [];
                
            }(window, document, 'script', '//connect.facebook.net/en_US/fbevents.js');
            
            fbq('init', '1742530245979963');

        } catch (err) {
            console.log(err);
        }
      `}
    </Script>
  )
}

const SegmentQueue = () => {
  // we preinitialize the analytics object for segment in a somewhat clever way so that NO events can be dropped
  // IE: all the events will always be queued.
  // note that we removed the "invoked" flag here (which causes a similar script to be reran in Segment.js)
  // However, that second run is non destructive and inexpensive to execute

  return (
    <Script id="SegmentQueue" strategy="beforeInteractive">
      {`
        var analytics = (window.analytics = window.analytics || []);
        if(!analytics.initialize){
          analytics.methods = [
            "trackSubmit",
            "trackClick",
            "trackLink",
            "trackForm",
            "pageview",
            "identify",
            "reset",
            "group",
            "track",
            "ready",
            "alias",
            "debug",
            "page",
            "once",
            "off",
            "on",
            "addSourceMiddleware",
            "addIntegrationMiddleware",
            "setAnonymousId",
            "addDestinationMiddleware",
          ];
          analytics.factory = function (e) {
            return function () {
              var t = Array.prototype.slice.call(arguments);
              t.unshift(e);
              analytics.push(t);
              return analytics;
            };
          };
          for (var e = 0; e < analytics.methods.length; e++) {
            var key = analytics.methods[e];
            analytics[key] = analytics.factory(key);
          }
        }
      `}
    </Script>
  )
}

const SsrScripts = () => {
  return (
    <>
      <GoogleAnalytics4Queue />
      <FacebookQueue />
      <SegmentQueue />
    </>
  )
}

export default SsrScripts
