import React from 'react'

import PropTypes from 'prop-types'

import ColorFinder from 'Utilities/ColorFinder'

import Brands from 'Constants/Brand'
import Intents from 'Constants/Intent'
import Sizes from 'Constants/Size'

import styles from './InlineSpinner.module.scss'
import styled from 'styled-components'

const propTypes = {
  isLoading: PropTypes.bool,
  brand: PropTypes.oneOf(Brands),
  intent: PropTypes.oneOf(Intents),
  size: PropTypes.oneOf(Sizes),
}

const InlineSpinner = ({ isLoading = true, brand, size, intent }) => {
  size = size ?? 1

  if (typeof size === 'string') {
    const sizeMapping = { small: 1, large: 1.5 }
    size = sizeMapping[size]
  }
  const { color } = ColorFinder(intent, null, brand, false)
  return (
    <>
      {isLoading ? (
        <div className={styles.softIn} data-testid={'spinner'}>
          <StyledSpinner className={styles.spinner} size={size} color={color}>
            Loading...
          </StyledSpinner>
        </div>
      ) : null}
    </>
  )
}
const StyledSpinner = styled.div`
  color: ${(props) => props.color};
  width: ${(props) => props.size}rem;
  height: ${(props) => props.size}rem;
`

InlineSpinner.propTypes = propTypes

export default InlineSpinner
