import defer from 'Utilities/defer'

const useCLSLogger = () => {
  const ClsLogger = ({ name, value, id, attribution }) => {
    // console.log(
    //   `Going to send CLS info name=${JSON.stringify(
    //     name
    //   )}, value=${value}, id=${id}, attribution=${JSON.stringify(attribution)}`
    // );
    // Destructure the attribution object:
    const {
      largestShiftTarget,
      largestShiftTime,
      largestShiftValue,
      largestShiftEntry,
      largestShiftSource,
      loadState,
    } = attribution

    const eventParams = {
      /**
       * A selector identifying the first element (in document order) that
       * shifted when the single largest layout shift contributing to the page's
       * CLS score occurred.
       */
      metric_cls_largestShiftTarget: largestShiftTarget,
      /**
       * The time when the single largest layout shift contributing to the page's
       * CLS score occurred.
       */
      metric_cls_largestShiftTime: largestShiftTime,
      /**
       * The layout shift score of the single largest layout shift contributing to
       * the page's CLS score.
       */
      metric_cls_largestShiftValue: largestShiftValue,
      /**
       * The `LayoutShiftEntry` representing the single largest layout shift
       * contributing to the page's CLS score. (Useful when you need more than just
       * `largestShiftTarget`, `largestShiftTime`, and `largestShiftValue`).
       */
      metric_cls_largestShiftEntry: JSON.stringify(largestShiftEntry),
      /**
       * The first element source (in document order) among the `sources` list
       * of the `largestShiftEntry` object. (Also useful when you need more than
       * just `largestShiftTarget`, `largestShiftTime`, and `largestShiftValue`).
       */
      metric_cls_largestShiftSource: JSON.stringify(largestShiftSource),
      /**
       * The loading state of the document at the time when the largest layout
       * shift contribution to the page's CLS score occurred (see `LoadState`
       * for details).
       */
      metric_cls_loadState: loadState,
    }

    if (typeof window?.gtag !== 'undefined') {
      // Send to Google Analytics
      defer(() => {
        gtag('event', name, eventParams)
      })
    }
  }

  return ClsLogger
}

export default useCLSLogger
