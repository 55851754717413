//do not update these string until this file is uploaded to S3
const SvgResourceURL = '/Svgs/s3-apr-02-25.svg'
const ordersFileName = '/Svgs/s3-orders-feb-3-2025.svg'

const svgFileNames = [
  {
    fileName: SvgResourceURL,
    svgsCovered: [
      'rewards',
      '360-degree',
      'Expert-tech',
      'inStock-ship',
      'filter-sort',
      'add-to-list',
      'wheel',
      'facebook',
      'twitter',
      'x',
      'pinterest',
      'instagram',
      'youtube',
      'play',
      'phone',
      'phone_unavailable',
      'star',
      'star-half',
      'fast_mail',
      'magnifyingglass',
      'menu',
      'garage',
      'parts',
      'toolbox',
      'made-usa',
      'SpeedwayMotors_Logo',
      'street',
      'race',
      'trash',
      'backarrow',
      'right-arrow',
      'catalog',
      'close',
      'chevron',
      'chevron-up',
      'chevron-down',
      'cloud_upload',
      'buildlist',
      'cart',
      'user',
      'deals',
      'info',
      'minus',
      'plus',
      'share',
      'ios_share',
      'standard_delivery',
      'truck_shipment',
      'unavailable',
      'universal_fit',
      'warning',
      'box',
      'check',
      'external_link',
      'fast_and_free',
      'fast_shipping',
      'factory_shipped',
      'made_to_order',
      'in_store_pickup',
      'add',
      'backordered',
      'kit',
      'Wrench',
      'dollar',
      'help',
      'add_to_cart',
      'CARB',
      'prop65',
      'timer',
      'lock',
      'copy',
      'edit',
      'SpeedwayMotors_Wheel_Full_Color',
      'SpeedwayMotors_Logo_Full_Color',
      'creditcard',
      'moreInformation',
      'chevron-left',
      'list-view',
      'grid-view',
      'thumb_down',
      'thumb_up',
      'wheel_hollow',
      'video',
      'tiktok',
      'heart',
      'engine',
      'pistons',
      'heart-selected',
      'rearaxle',
      'transmission',
      'icon_cloud_download',
      'visa',
      'amex',
      'discover',
      'master-card',
      'amazon',
      'apple-pay',
      'google-pay',
      'paypal',
      'car',
      'kebab',
      'eye',
      'eye-slash',
      'xmark-large',
      'v8-engine',
      'savedCheckmark',
      'child-arrow',
      'flag-checkered',
    ],
  },
  {
    fileName: ordersFileName,
    svgsCovered: [
      'xpo',
      'dayton',
      'tforce',
      'rl',
      'ups',
      'fedex',
      'usps',
      'speedee',
      'ontrac',
      'dhl',
      'trackorder',
      'amazon-shipping',
    ],
  },
]

const svgDict = {}
const Svgs = []

for (const file of svgFileNames) {
  const svgsCovered = file.svgsCovered
  Svgs.push(...svgsCovered)

  for (const svg of svgsCovered) {
    svgDict[svg] = file.fileName
  }
}

//exported so we can preload our primary svgs
export { SvgResourceURL, Svgs }

export default svgDict
