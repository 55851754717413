import React, { useContext } from 'react'

import ClassNames from 'classnames'

import Event from 'Utilities/Event'
import LayerColorFinder from 'Utilities/LayerColorFinder'

import DrawerContext from 'Contexts/DrawerContext'
import PanelContext from 'Contexts/PanelContext'

import useSegment from 'Hooks/useSegment'

import useNavigationState from '../../../Hooks/useAppInstall/useNavigationState'
import styles from './ListItem.module.scss'
import styled from 'styled-components'

const ListItem = React.forwardRef(
  (
    {
      children,
      className,
      component,
      href,
      onClick,
      onHover,
      border,
      hover,
      title,
      dataTesting,
      ariaLabel,
      event,
      colorStyles,
      segmentEvent,
      as,
      ...props
    },
    ref,
  ) => {
    const { showNavigation } = useNavigationState()
    const drawerContext = useContext(DrawerContext)
    // Grab the panel context if it exists for hover otherwise default to current layer
    const panelContext = useContext(PanelContext)
    const inverseTheme =
      panelContext.layer !== undefined ? panelContext.inverseTheme : false
    const hoverLayer =
      panelContext.layer !== undefined ? panelContext.layer + 1 : 0
    const listItemBase = LayerColorFinder(
      panelContext.layer,
      inverseTheme,
      panelContext.brand,
    )
    const listItemHover = LayerColorFinder(hoverLayer, inverseTheme)

    const { sendSegmentTrackEvent } = useSegment()

    // opt in features for interactive pieces
    border = typeof border !== 'undefined' ? border : false
    hover = typeof hover !== 'undefined' ? hover : false

    // defaults for safety

    component = component ?? (href ? 'a' : 'li')
    href = href ? href : null

    if (as) component = as

    const classnames = ClassNames(
      className,
      styles.listItem,
      drawerContext.opened && styles.drawerItemAnimation,
      drawerContext.location === 'left' && styles.drawerItemAnimationLeft,
      drawerContext.location === 'right' && styles.drawerItemAnimationRight,
      hover && styles.listItemHover,
    )

    const onClickWrapper = (e, event, segmentEvent, onClick) => {
      if (href) {
        showNavigation()
      }
      if (event && !segmentEvent) {
        const linkEvent = {
          category: event.category,
          action: `${event.action} (Link)`,
          label: event.label,
        }

        Event(linkEvent)()
      }

      if (segmentEvent) {
        sendSegmentTrackEvent(segmentEvent)
      }

      if (onClick) {
        onClick(e)
      }
    }

    var style = {
      '--base': listItemBase,
      '--hoverColor': hover
        ? colorStyles?.spinnerColor
          ? colorStyles?.spinnerColor
          : listItemHover
        : 'none',
      '--cursor':
        component === 'button' || component === 'a' || onClick
          ? 'pointer'
          : 'default',
      '--border': border ? '1px solid' : 'none',
      '--borderColor': border ? listItemHover : 'none',
      '--spinnerColor': colorStyles?.spinnerColor
        ? colorStyles?.spinnerColor
        : 'none',
    }

    return (
      <StyledListItem
        as={component}
        className={classnames}
        onClick={(e) => {
          onClickWrapper(e, event, segmentEvent, onClick)
        }}
        href={href}
        style={style}
        title={title}
        data-testid={dataTesting}
        ref={ref}
        aria-label={ariaLabel}
        {...props}
      >
        {children}
      </StyledListItem>
    )
  },
)

const StyledListItem = styled.div``

export default ListItem
