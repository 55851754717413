import NextLayout from './NextLayout'

// THIS FILE SHOULD BE CRAZY SMALL, IF YOU NEED TO UPDATE SOMETHING CONSIDER "NEXTLAYOUT"
// THIS FILE SHOULD BE CRAZY SMALL, IF YOU NEED TO UPDATE SOMETHING CONSIDER "NEXTLAYOUT"
// THIS FILE SHOULD BE CRAZY SMALL, IF YOU NEED TO UPDATE SOMETHING CONSIDER "NEXTLAYOUT"
// THIS FILE SHOULD BE CRAZY SMALL, IF YOU NEED TO UPDATE SOMETHING CONSIDER "NEXTLAYOUT"
// THIS FILE SHOULD BE CRAZY SMALL, IF YOU NEED TO UPDATE SOMETHING CONSIDER "NEXTLAYOUT"
// THIS FILE SHOULD BE CRAZY SMALL, IF YOU NEED TO UPDATE SOMETHING CONSIDER "NEXTLAYOUT"
// THIS FILE SHOULD BE CRAZY SMALL, IF YOU NEED TO UPDATE SOMETHING CONSIDER "NEXTLAYOUT"
// THIS FILE SHOULD BE CRAZY SMALL, IF YOU NEED TO UPDATE SOMETHING CONSIDER "NEXTLAYOUT"
// THIS FILE SHOULD BE CRAZY SMALL, IF YOU NEED TO UPDATE SOMETHING CONSIDER "NEXTLAYOUT"
// THIS FILE SHOULD BE CRAZY SMALL, IF YOU NEED TO UPDATE SOMETHING CONSIDER "NEXTLAYOUT"
// THIS FILE SHOULD BE CRAZY SMALL, IF YOU NEED TO UPDATE SOMETHING CONSIDER "NEXTLAYOUT"

function MyApp({ Component, pageProps }) {
  return (
    <NextLayout>
      <Component {...pageProps} />
    </NextLayout>
  )
}

function AppWithCustomLayout({ Component, pageProps }) {
  const App = Component.CustomLayout || MyApp
  return <App Component={Component} pageProps={pageProps} />
}

export default AppWithCustomLayout
