const Intents = [
  'positive',
  'negative',
  'action',
  'warning',
  'subdued',
  'facade',
  'lingerie',
]
export default Intents
