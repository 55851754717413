import React, { useContext, useEffect, useState } from 'react'

import LogRocket from 'logrocket'
import Script from 'next/script'

import CookieReader from 'Utilities/CookieUtilties/CookieReader'

import { TrackingContext } from 'Contexts/TrackingContext/TrackingContext'

import { isInApp } from 'Hooks/useAppInstall/AppUtilities'
import useGlobalPrivacyControl from 'Hooks/useGlobalPrivacyControl'
import useNetwork from 'Hooks/useNetwork'

import { useUserStore } from '../../../Stores/UserStore/UserStore'
import {
  GetUserGuid,
  GetUserVin,
  IsUserBot,
} from '../../../Utilities/CookieUtilties/UserCookieUtilities'
import {
  addSessionProperties,
  incrementSessionStoragePageViewCount,
  initializeSession,
  setIsEngagedSessionFired,
  trackEngagedSession,
} from '../../../Utilities/EngagedSessionStorageUtility/EngagedSessionStorageUtility'
import { StorageAccessor } from '../../../Utilities/LocalStorageUtility'

export default function Segment() {
  const { segmentReady, setSegmentReady } = useContext(TrackingContext)
  const [segmentApiKey, setSegmentApiKey] = useState()
  const { isGpcEnabled } = useGlobalPrivacyControl()

  const segmentDoneHandler = (e) => {
    if (typeof analytics === 'undefined') console.error('Segment is not ready')
    setSegmentReady()
  }

  const hydrated = useUserStore((x) => x.context.hydrated)
  const isEmployee = useUserStore((x) => x.context.isEmployee)
  const isInternational = useUserStore((x) => x.context.isInternational)
  const zipCode = useUserStore((x) => x.context.zipCode)
  const emailAddress = useUserStore((x) => x.context.emailAddress) || ''

  initializeSession()

  useEffect(() => {
    if (segmentReady) {
      const segmentTrack = window.analytics.track.bind(window.analytics)
      window.analytics.track = (...args) => {
        try {
          LogRocket.track(...args)
        } catch (e) {
          console.error(e)
        }
        try {
          segmentTrack(...args)
        } catch (e) {
          console.error(e)
        }
      }
    }
  }, [segmentReady])

  useEffect(() => {
    window.addEventListener('segmentLoaded', segmentDoneHandler)

    return () => {
      window.removeEventListener('segmentLoaded', segmentDoneHandler)
    }
  }, [])

  //const [networkData, setNetworkData] = useState({})

  useNetwork(
    (data) => {
      //the buy route is client side rendered so we don't want to fire like this.
      //segment middleware in tng react is firing its own page views

      if (window?.pageViewOverrideEnabled) {
        // for when we want to override the page view event
        return
      }

      if (segmentReady) {
        if (!window.location.href.includes('/buy')) {
          let properties = {
            speed: `${data.speed}`,
            network: `${data.network}`,
            cpu: `${data.cpu}`,
            memory: `${data.memory}`,
            dataSaver: `${data.dataSaver}`,
          }

          properties = addSessionProperties(properties)

          incrementSessionStoragePageViewCount()
          trackEngagedSession()

          analytics.page(properties)

          setIsEngagedSessionFired()
        }
      }
    },
    [segmentReady],
  )

  useEffect(() => {
    const host = window.location.hostname.toLowerCase()

    switch (host) {
      case 'www.speedwaymotors.com':
        setSegmentApiKey('Kqqz7XsVdnN81nBHti25sIeLESudJDEh')
        break
      case 'staging.speedway2.com':
      case 'qa.speedway2.com':
      default:
        setSegmentApiKey('6g5OCMzAvN6UebUc9rEXJSSt0f010JFB')
        break
    }
  }, [])

  const [renderSegmentScript, setRenderSegmentScript] = useState(false)
  const campaignRegex = /utm_campaign=campaign_(\d+)/ // regex for matching Iterable url campaign ids

  function initializeMiddleware() {
    window.__SMI_SEGMENT_MIDDLEWARE__ = window.__SMI_SEGMENT_MIDDLEWARE__ || {}
    var MIDDLEWARE = __SMI_SEGMENT_MIDDLEWARE__

    MIDDLEWARE.DESTINATION_FACEBOOK = function ({ payload, next }) {
      // destination middleware clones the event so we can mutate it safely for the destination
      // https://github.com/segmentio/analytics-next/blob/bf8685737466cb1193a54f99871ec7348b8616d8/packages/browser/src/plugins/middleware/index.ts#L32

      const properties = payload?.obj?.properties || {}
      if (properties.variant) {
        properties.product_id = properties.variant // unfortunately the segment fb pixel integration prioritizes product_id and doesn't ever send variant (where we store our most specific SKU #) https://github.com/segmentio/analytics.js-integrations/blob/d08bdacaf1cdfc0a142aff74e4b69c4f205508b1/integrations/facebook-pixel/lib/index.js#L410
      }
      const products = properties.products || []
      for (const product of products) {
        if (product.variant) {
          product.product_id = product.variant // unfortunately the segment fb pixel integration prioritizes product_id and doesn't ever send variant (where we store our most specific SKU #)https://github.com/segmentio/analytics.js-integrations/blob/d08bdacaf1cdfc0a142aff74e4b69c4f205508b1/integrations/facebook-pixel/lib/index.js#L410
        }
      }

      next(payload)
    }
  }

  useEffect(() => {
    // we need to wait until the user context has been updated with data (hydrated)
    // before allowing the script to render

    const shouldRender =
      !isGpcEnabled && hydrated && !IsUserBot() && isEmployee && !isEmployee()
    console.info(
      'Segment shouldRenderLogObject (all should be true to render)',
      {
        '!isGpcEnabled': !isGpcEnabled,
        hydrated: hydrated,
        '!IsUserBot()': !IsUserBot(),
        '!isEmployee()': isEmployee
          ? !isEmployee()
          : 'isEmployeeCouldNotBeInvokedDueToNull',
      },
    )
    if (shouldRender) {
      setRenderSegmentScript(true)
      initializeMiddleware()
      if (window) {
        const currentUrl = window.location.href
        if (currentUrl.includes('utm_source=Iterable')) {
          // set the expiration to 14 days in the future
          var datePlus14Days = new Date(Date.now())
          datePlus14Days.setDate(datePlus14Days.getDate() + 14)
          const datePlus14DaysString = datePlus14Days.toUTCString()

          // read in already set cookie (if any)
          const templateId = CookieReader('iterableTemplateId')
          const messageId = CookieReader('iterableMessageId')
          let campaignId = CookieReader('iterableEmailCampaignId')

          // check for a (new) campaign Id on the url, and use that if present
          const urlCampaignMatch = currentUrl.match(campaignRegex)
          const urlCampaignId =
            urlCampaignMatch == null ? '' : urlCampaignMatch[1]
          if (urlCampaignId != '') {
            campaignId = currentUrl.match(campaignRegex)[1]
          }

          const host = document.location.host.replace('www.', '')
          document.cookie = `iterableTemplateId=${templateId}; path=/; domain=${host}; expires=${datePlus14DaysString};`
          document.cookie = `iterableMessageId=${messageId}; path=/; domain=${host}; expires=${datePlus14DaysString};`
          document.cookie = `iterableEmailCampaignId=${campaignId}; path=/; domain=${host}; expires=${datePlus14DaysString};`
        }
      }
    }
  }, [hydrated, isGpcEnabled])

  const canRender = renderSegmentScript && !!segmentApiKey
  console.log('Segment can render object', {
    renderSegmentScript: renderSegmentScript,
    segmentApiKey: segmentApiKey,
    isGpcEnabled: isGpcEnabled,
  })

  if (!canRender) {
    return false
  }

  const isApp = isInApp()
  const tikTokJson = tryReadCookieToEmbedInSegment('tiktok_cache')
  const cjeValue = tryReadCookieToEmbedInSegment('cje')
  const facebookClickIdentifier = tryReadCookieToEmbedInSegment('_fbc') // carries the fbclid
  const facebookPixelIdentifier = tryReadCookieToEmbedInSegment('_fbp') // their client ID

  const { region, division } = getRegionAndDevision(isInternational, zipCode)

  const segmentCdnHost = 'evs.seg.speedwaymotors.com'
  const segmentVersion = 'v1'
  const segmentScriptUrl = `https://${segmentCdnHost}/analytics.js/${segmentVersion}/${segmentApiKey}/analytics.min.js` // latest
  const segmentApiHostWithVersion = `api.seg.speedwaymotors.com/${segmentVersion}`

  const segmentScript = `   
    if('${segmentApiKey}' === '')
    {
        console.error('No Segment API key was loaded')
    } 
    else{        
        var isBot = false
        if (navigator && navigator.userAgent) {
            var userAgent = navigator.userAgent.toLowerCase()
            var botList = ["GoogleBot", "facebot", "DuckDuckBot", "Baiduspider", "YandexBot", "Bingbot", "Slurp", "AboutUsBot", "Adsbot", "AlphaBot", "ArchiveBot", "AspiegelBot", "AwarioSmartBot", "BLEXBot", "BingPreview", "BingPreview 1.0", "Cliqzbot", "Cocolyzebot", "Discordbot", "DomCopBot", "DomainStatsBot", "DuckDuckGo", "Exabot", "FullStoryBot", "IndeedBot", "KenshooBot", "KomodiaBot", "LinkedInBot", "LinkisBot", "LivelapBot", "MAZBot", "Mail.RU_Bot", "MauiBot", "MojeekBot", "NetpeakCheckerBot", "OnalyticaBot", "PREMSGO", "PaperLiBot", "PubMatic", "Quora-Bot", "RedirectBot", "ResearchBot", "SEMrushBot", "ScraperBot", "SemanticScholarBot", "SeobilityBot", "SerendeputyBot", "SeznamBot", "SurdotlyBot", "TelegramBot", "TurnitinBot", "TweetmemeBot", "Twitterbot", "WordChampBot", "Yandex", "YelpBot", "ZoomBot", "ZoominfoBot", "adsbot-google", "ahrefs.com", "aiHitBot", "apis-google", "applebot", "archive.org_bot", "bingpreview", "bitlybot", "bot@linkfluence", "caliperbot", "chimebot", "cincrawdata", "coccocbot", "deepcrawl", "dotbot", "duckduckbot", "facebookexternalhit/1.1", "google-read", "istellabot", "linguee.com", "mediapartners-google", "mj12bot", "msnbot", "niocBot", "petalbot", "pinterest.com/bot", "rogerbot", "seewithkids.com", "semrush.com", "seotoolset", "serpstatbot", "seznambot", "slack.com", "smtbot", "superpagesbot", "xforce-security", "yahoo ad monitoring", "yahoo link preview", "yahoocachesystem"]
            for (var i = 0; i < botList.length; i++) {
                var value = userAgent.indexOf(botList[i].toLowerCase())
                if (value >= 0) {
                    isBot = true
                }
            }
        }
        !function () {
                var analytics = window.analytics = window.analytics || []; if (!analytics.initialize) if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice."); else {
                    analytics.invoked = !0; analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on", "addSourceMiddleware", "addIntegrationMiddleware", "setAnonymousId", "addDestinationMiddleware"]; analytics.factory = function (e) { return function () { var t = Array.prototype.slice.call(arguments); t.unshift(e); analytics.push(t); return analytics } }; for (var e = 0; e < analytics.methods.length; e++) { var key = analytics.methods[e]; analytics[key] = analytics.factory(key) } analytics.load = function (key, e) {
                        var t = document.createElement("script"); t.type = "text/javascript"; t.async = !0; t.src = "${segmentScriptUrl}"; var n = document.getElementsByTagName("script")[0];
                        // we need this bot check to help us reduce calls to segment. If we update the segment script make sure we add this!!!!!!
                        if (!isBot) {
                            n.parentNode.insertBefore(t, n);
                        }
                        analytics._loadOptions = e
                    }; 
                    analytics._writeKey = '${segmentApiKey}'; analytics.SNIPPET_VERSION = "4.13.2";
                    const segmentOrderSourceMiddleware = function(middlewareOptions) {
                        const payload = middlewareOptions.payload;
                        const next = middlewareOptions.next;

                        if(payload.obj.properties){
                            // these will get them to the DW, etc...
                            // the other obj level mapping just gets it to segment
                            payload.obj.properties.userVin = '${GetUserVin()}'
                            payload.obj.properties.isPWA = '${isApp}'
                        }

                        payload.obj.context = payload.obj.context || {}
                        payload.obj.context.traits = payload.obj.context.traits || {}

                        if('${emailAddress}'){
                          payload.obj.context.traits.email = '${emailAddress}'
                        }

                        payload.obj.anonymousId = '${GetUserGuid()}'
                        payload.obj.userVin = '${GetUserVin()}'
                        payload.obj.isPWA = '${isApp}'
                        try {
                          payload.obj.properties.cjevent = ${cjeValue};
                          payload.obj.tiktok = ${tikTokJson};
                          payload.obj.properties.tiktok = ${tikTokJson};
                          payload.obj.properties.fbc = ${facebookClickIdentifier};
                          payload.obj.properties.fbp = ${facebookPixelIdentifier};
                        } catch(err) {
                          console.log("Social media data did not save");
                        }
                        ${region ? `payload.obj.region = '${region}'` : null}
                        ${
                          division
                            ? `payload.obj.division = '${division}'`
                            : null
                        }

                        if (payload.obj.type == 'page' || payload.obj.type == 'track') {
                          payload.obj.ga4SessionId = window.ga4SessionData?.sessionId;
                          payload.obj.ga4SessionNumber = window.ga4SessionData?.sessionNumber;
                          payload.obj.ga4ClientId = window.ga4SessionData?.clientId;
                          if(payload.obj.properties){
                            payload.obj.properties.ga4SessionId = window.ga4SessionData?.sessionId;
                          }
                        }

                        if (payload.obj.type == 'page') {
                            let cookieArr = document.cookie.split(';')
                            let parsedCookie = cookieArr.filter((cookie) => cookie.includes('SessionGuid'))[0]
                            if (parsedCookie) {
                                const sessionGuid = parsedCookie.split('=')[1];
                                payload.obj.sessionGuid = sessionGuid
                                if(payload.obj.properties){
                                  payload.obj.properties.sessionGuid = sessionGuid
                                }
                                return next(payload)
                            }

                        }

                        if (payload.obj.event !== 'Order Completed') {
                            return next(payload)
                        }
                        payload.obj.messageId = 'order-completed-' + payload.obj.properties.order_id;
                        payload.obj.orderId = payload.obj.properties.order_id;
                        next(payload);
                    }
                    analytics.reset();
                    analytics.setAnonymousId('${GetUserGuid()}');
                    analytics.addSourceMiddleware(segmentOrderSourceMiddleware);
                    analytics.addDestinationMiddleware('Facebook Pixel', window.__SMI_SEGMENT_MIDDLEWARE__.DESTINATION_FACEBOOK);
                    analytics.load('${segmentApiKey}', {
                        flushAt: 1,
                        integrations: {
                          "Segment.io": {
                            apiHost: "${segmentApiHostWithVersion}"
                          }
                        }
                      });
                }
            }();   
        window.dispatchEvent(new CustomEvent('segmentLoaded', null))          
    }  
`
  return (
    <Script
      id="segment-init"
      data-testid="segment-init"
      strategy="afterInteractive"
    >
      {segmentScript}
    </Script>
  )
}

function getRegionAndDevision(isInternational, zipCode) {
  let region
  let division

  if (isInternational) {
    region = 'international'
  } else if (zipCode) {
    region = zipCode.substring(0, 1)

    if (region === '0' || region === '1') {
      if (region === '0') {
        division = 'US New England'
      }
      if (region === '1') {
        division = 'US Mid-Atlantic'
      }
      region = 'US Northeast'
    }

    if (region === '2' || region === '3' || region === '7') {
      if (region === '2') {
        division = 'US South Atlantic'
      }
      if (region === '3') {
        division = 'US East South Central'
      }
      if (region === '7') {
        division = 'US West South Central'
      }
      region = 'US South'
    }

    if (region === '4' || region === '5' || region === '6') {
      if (region === '4') {
        division = 'US East North Central'
      }
      if (region === '5' || region === '6') {
        division = 'US West North Central'
      }
      region = 'US Midwest'
    }

    if (region === '8' || region === '9') {
      if (region === '8') {
        division = 'US Mountain'
      }
      if (region === '9') {
        division = 'US Pacific'
      }
      region = 'US West'
    }
  }
  return { region, division }
}

function tryReadCookieToEmbedInSegment(cookieName) {
  try {
    return JSON.stringify(CookieReader(cookieName))
  } catch (e) {
    console.error('failed to serialize cookie for segment ' + cookieName)
    return JSON.stringify('')
  }
}
