import React, { useContext } from 'react'

import ArticleCard from 'Containers/ArticleCard'

import { NavigationContext } from 'Contexts/Navigation/NavigationContext'

import useContent from 'Hooks/useContent/useContent'

import Button from 'Molecules/Button'
import List from 'Molecules/List'
import ListItem from 'Molecules/List/ListItem'
import ListItemIcon from 'Molecules/List/ListItemIcon'
import ListItemLink from 'Molecules/List/ListItemLink'
import ListItemText from 'Molecules/List/ListItemText'

import Panel from 'Atoms/Panel'
import Typography from 'Atoms/Typography'

import { createClickedSegmentEvent } from '../../../../../Utilities/Instrumentation/Impressions/impressionSegmentEvents'
import styles from '../../HeaderDrawer.module.scss'
import ReturnItem from '../Common/ReturnItem'

const FeaturedArticleNavigation = ({
  menu,
  services,
  closeFunction,
  isToolbox,
}) => {
  const { dispatch: navigationDispatch } = useContext(NavigationContext)
  const { featuredArticle } = useContent(true)
  const { navigation, primary, secondary } = menu

  if (!featuredArticle) return null

  return (
    <>
      <ReturnItem
        primary={primary}
        secondary={secondary}
        closeFunction={closeFunction}
      />{' '}
      <Panel className={styles.subNavigation}>
        <div className={styles.featuredArticleTitle}>
          <Typography size={1.25} tone={'contrast'}>
            Featured Article
          </Typography>
        </div>
        <ArticleCard article={featuredArticle} border={false} useButton />
        <List component={'div'} className={styles.subNavigationList}>
          {navigation.map((item) => {
            const RootComponent = item.href ? ListItemLink : ListItem

            return (
              <RootComponent
                key={item.id}
                className={styles.listItem}
                onClick={
                  item.href
                    ? null
                    : () => {
                        navigationDispatch(item.onClick)
                      }
                }
                event={item.event}
                href={item.href}
                hover
                data-testid={item.secondary}
              >
                <ListItemText
                  primary={!item.simplified ? item.primary : item.secondary}
                  primaryTypographyProps={{
                    size: 1,
                    tone: 'contrast',
                  }}
                  className={styles.listItemText}
                />
                <ListItemIcon
                  icon={item.icon ? item.icon : 'chevron'}
                  className={styles.listItemIcon}
                  size={1}
                />
              </RootComponent>
            )
          })}
        </List>
      </Panel>
      <div className={styles.seeAllToolboxArticlesContainer}>
        <Button
          brand={'secondary'}
          segmentEvent={createClickedSegmentEvent('SeeAllArticles')}
          size={'medium'}
          text={'All Articles'}
          href={'/the-toolbox'}
          data-testid={`header_drawer_see_all_toolbox_articles`}
        >
          <Typography size={1} font={'bold'}>
            See All Toolbox Articles
          </Typography>
        </Button>
      </div>
    </>
  )
}

export default FeaturedArticleNavigation
