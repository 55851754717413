import engineFitmentFacetTypes from 'Constants/EngineFitmentFacetTypes'

const getFitmentDetailsFromSelectedFacets = (
  selectedFacets,
  engineDisplayName,
) => {
  const selectedFitmentFacet = selectedFacets?.find((x) => !!x.fitmentDetails)
  const engineFitmentFacets = selectedFacets?.filter((x) =>
    engineFitmentFacetTypes.includes(x.displayText),
  )
  const fitmentDetails = {
    ...selectedFitmentFacet?.fitmentDetails,
    displayValue:
      selectedFitmentFacet?.searchResponseFacetOptions?.[0]?.displayValue,
    engineFitmentDisplayValue: '',
  }

  engineFitmentFacets?.forEach((x) => {
    for (const key in x.engineFitmentDetails) {
      if (!!x.engineFitmentDetails[key]) {
        fitmentDetails[key] = x.engineFitmentDetails[key]
      }
    }
    if (
      x.searchResponseFacetOptions?.[0]?.displayValue &&
      !x.searchResponseFacetOptions?.[0]?.displayValue?.includes('NO')
    )
      fitmentDetails.engineFitmentDisplayValue =
        fitmentDetails.engineFitmentDisplayValue.length > 1
          ? fitmentDetails.engineFitmentDisplayValue +
            ' ' +
            x.searchResponseFacetOptions?.[0]?.displayValue
          : x.searchResponseFacetOptions?.[0]?.displayValue
  })

  if (fitmentDetails.engineFitmentDisplayValue === '')
    fitmentDetails.engineFitmentDisplayValue = null

  if (engineDisplayName && engineDisplayName.length) {
    fitmentDetails.engineFitmentDisplayValue = engineDisplayName
  }

  return fitmentDetails
}

export default getFitmentDetailsFromSelectedFacets
