const getToolBoxMainMenu = () => {
  const toolboxMainMenu = {
    primary: 'Toolbox',
    secondary: 'Articles',
    showCallNow: false,
    navigation: [
      {
        id: 'Recommended',
        primary: 'Toolbox',
        secondary: 'Recommended Articles',
        simplified: true,
        onClick: {
          type: 'setDrawerState',
          payload: {
            drawerState: {
              isDrawerOpen: true,
            },
            drawerAction: {
              type: 'addNavigation',
              payload: {
                component: 'RecommendedArticleNavigation',
                menu: 'toolboxRecommendedArticles',
              },
            },
          },
        },
        event: {
          category: 'Header',
          action: 'Header Drawer Toolbox Recommended Articles Opened',
        },
      },
      {
        id: 'LatestArticles',
        primary: 'Toolbox',
        secondary: 'Latest Articles',
        simplified: true,
        href: '/the-toolbox',
        event: {
          category: 'Header',
          action: 'Header Drawer Latest Articles Clicked',
        },
      },
      {
        id: 'Tech',
        primary: 'Toolbox',
        secondary: 'Tech',
        simplified: true,
        onClick: {
          type: 'setDrawerState',
          payload: {
            drawerState: {
              isDrawerOpen: true,
            },
            drawerAction: {
              type: 'addNavigation',
              payload: {
                component: 'ToolboxMenuItemNavigation',
                menu: 'toolboxTech',
              },
            },
          },
        },
        event: {
          category: 'Header',
          action: 'Header Drawer Toolbox Tech Opened',
        },
      },
      {
        id: 'Builds',
        primary: 'Toolbox',
        secondary: 'Builds',
        simplified: true,
        onClick: {
          type: 'setDrawerState',
          payload: {
            drawerState: {
              isDrawerOpen: true,
            },
            drawerAction: {
              type: 'addNavigation',
              payload: {
                component: 'ToolboxMenuItemNavigation',
                menu: 'toolboxBuilds',
              },
            },
          },
        },
        event: {
          category: 'Header',
          action: 'Header Drawer Toolbox Builds Opened',
        },
      },
      {
        id: 'Stories',
        primary: 'Toolbox',
        secondary: 'Stories',
        simplified: true,
        onClick: {
          type: 'setDrawerState',
          payload: {
            drawerState: {
              isDrawerOpen: true,
            },
            drawerAction: {
              type: 'addNavigation',
              payload: {
                component: 'ToolboxMenuItemNavigation',
                menu: 'toolboxStories',
              },
            },
          },
        },
        event: {
          category: 'Header',
          action: 'Header Drawer Toolbox Stories Opened',
        },
      },
      {
        id: 'HorsepowerRecipes',
        primary: 'Toolbox',
        secondary: 'Horsepower Recipes',
        simplified: true,
        href: '/the-toolbox/engine-recipes~512-169',
        event: {
          category: 'Header',
          action: 'Header Drawer Toolbox Horsepower Recipes Opened',
        },
      },
      {
        id: 'LS Swaps',
        primary: 'Toolbox',
        secondary: 'LS Swaps',
        simplified: true,
        href: '/the-toolbox/ls-swap~512-171',
        event: {
          category: 'Header',
          action: 'Header Drawer Toolbox LS Swaps Opened',
        },
      },
    ],
  }

  return toolboxMainMenu
}

const getRecommendedArticlesMenu = () => {
  return {
    primary: 'Toolbox',
    secondary: 'Recommended',
    showCallNow: false,
    navigation: [
      {
        id: 'See All Toolbox Articles',
        primary: 'Toolbox',
        secondary: 'See All Toolbox Articles',
        simplified: true,
        href: '/the-toolbox',
        event: {
          category: 'Header',
          action:
            'Header Drawer Recommended Articles See All Toolbox Articles Clicked',
        },
      },
    ],
  }
}

const getVideosMenu = () => {
  return {
    primary: 'Toolbox',
    secondary: 'Videos',
    showCallNow: false,
  }
}

const getPodcastMenu = () => {
  return {
    primary: 'Toolbox',
    secondary: 'Podcasts',
    showCallNow: false,
  }
}
const getTechMenu = () => {
  return {
    primary: 'Toolbox',
    secondary: 'Tech',
    showCallNow: false,
  }
}

const getBuildMenu = () => {
  return {
    primary: 'Toolbox',
    secondary: 'Builds',
    showCallNow: false,
  }
}

const getNewsMenu = () => {
  return {
    primary: 'Toolbox',
    secondary: 'News',
    showCallNow: false,
  }
}

const getStoriesMenu = () => {
  return {
    primary: 'Toolbox',
    secondary: 'Stories',
    showCallNow: false,
  }
}

export {
  getToolBoxMainMenu,
  getVideosMenu,
  getPodcastMenu,
  getRecommendedArticlesMenu,
  getTechMenu,
  getBuildMenu,
  getNewsMenu,
  getStoriesMenu,
}
