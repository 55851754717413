import React from 'react'

import ClassNames from 'classnames'

import Panel from 'Atoms/Panel'

import styles from './ControlBar.module.scss'

const ControlBar = ({
  children,
  setReferenceElement,
  hide,
  darkControlBarEnabled,
  ...props
}) => {
  const classNames = ClassNames(
    styles.controlBar,
    !hide ? styles.fixControlBar : null,
  )
  const panelClassNames = ClassNames(styles.controlBarPanel)

  return (
    <div className={classNames} ref={setReferenceElement} {...props}>
      <Panel
        className={panelClassNames}
        layer={0}
        inverseTheme={darkControlBarEnabled}
      >
        {children}
      </Panel>
    </div>
  )
}

export default ControlBar
