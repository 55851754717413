import React, { useContext, useEffect } from 'react'

import ClassNames from 'classnames'

import Event from 'Utilities/Event'

import { ListContext } from 'Contexts/ListContext/ListContext'

import { GetUserGuid } from '../../../../Utilities/CookieUtilties/UserCookieUtilities'
import headerStyles from '../../Header.module.scss'
import { NavbarItemLink } from '../../NavbarItem/NavbarItem'

const ListsNavItem = () => {
  const { listContext, getUserListsCount } = useContext(ListContext)

  useEffect(() => {
    if (GetUserGuid()) {
      getUserListsCount()
    }
  }, [GetUserGuid()])

  return (
    <NavbarItemLink
      className={ClassNames(
        headerStyles.headerItem,
        headerStyles.mobileHide,
        headerStyles.tabletHide,
        headerStyles.mobileLimitedHide,
        headerStyles.headerBuildlist,
      )}
      title={'My List Link'}
      href={'/buildlist'}
      primaryText={'My'}
      secondaryText={'List'}
      icon={'buildlist'}
      hover
      badgeProps={{
        intent: 'action',
        number: listContext.listCount,
        size: 'small',
        removeNumber: true,
      }}
      badge
      onClick={() => {
        Event({
          category: 'Header',
          action: 'My List Clicked',
        })
      }}
      data-testid={'buildlist'}
    />
  )
}

export default ListsNavItem
