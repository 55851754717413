import AppName from 'AppName'
import PropTypes from 'prop-types'

import EventCategories from 'Constants/EventCategories'

const propTypes = {
  event: PropTypes.shape({
    //event is required on the button
    optOut: PropTypes.bool,
    //top level faceting in GA/Segment. Adding a new category is okay to do,
    //just make sure that people reading the tracked events know what the new cateogry means
    category: PropTypes.oneOf(EventCategories),
    action: PropTypes.string,
    label: PropTypes.string,
  }),
  optOutofSegment: PropTypes.bool,
}
function Event(event, isNonInteraction = false, optOutofSegment = false) {
  const category = event.category != null ? event.category : AppName

  if (!event.action) console.warn('Provide action for event', { event: event })
  const eventWrapper = () => {
    //don't send feature flag events to segment
    if (
      typeof analytics !== 'undefined' &&
      category != 'FrontEndFeatureFlag' &&
      !!!optOutofSegment
    ) {
      analytics.track({
        event: `${event.action}`,
        properties: {
          category: `${category}`,
          action: `${event.action}`,
          label: `${event.label}`,
          nonInteraction: isNonInteraction,
        },
      })
    } else if (typeof analytics == 'undefined') {
      console.info('analytics not defined')
    }
  }

  return eventWrapper
}

Event.propTypes = propTypes

export default Event
