import React, { useEffect, useState } from 'react'

import Gutters from '../../Atoms/Gutters/Gutters'
import Typography from '../../Atoms/Typography'
import { isInApp } from '../../Hooks/useAppInstall/AppUtilities'
import useFeatureFlag from '../../Hooks/useFeatureFlag/useFeatureFlag'
import Button from '../../Molecules/Button/Button'
import Drawer from '../../Molecules/Drawer/Drawer'
import Feedback from '../../Molecules/Feedback/Feedback'
import { createClickedSegmentEvent } from '../../Utilities/Instrumentation/Impressions/impressionSegmentEvents'
import { StorageAccessor } from '../../Utilities/LocalStorageUtility/LocalStorageUtility'
import styles from './AppFeedbackDialog.module.scss'

const PWA = 'App'
const GENERAL = 'Website'
const LOCALSTORAGEKEY = 'shown_feedback_tab'
const SESSIONSTORAGEKEY = 'should_show_feedback_tab'
const ONEMONTHMINUTES = 43800
const PERCENTCHANCEOFSHOWINGPERSESSION = 0.5

const shouldBeShown = () => {
  try {
    if (typeof window == 'undefined') {
      return false
    }
    const hasBeenShown = StorageAccessor.localStorage.getObj(LOCALSTORAGEKEY)
    if (hasBeenShown) {
      return false
    }
    let shouldShow = StorageAccessor.sessionStorage.getObj(SESSIONSTORAGEKEY)
    if (typeof shouldShow !== `boolean`) {
      shouldShow = Math.random() <= PERCENTCHANCEOFSHOWINGPERSESSION
      StorageAccessor.sessionStorage.setObj(SESSIONSTORAGEKEY, shouldShow)
    }
    return shouldShow
  } catch (err) {
    console.error(`failure to determine if feedback tab should be shown`, err)
    return false
  }
}

const FeedbackTab = () => {
  const [openDrawer, setOpenDrawer] = useState()
  const [shouldShow, setShouldShow] = useState(false)
  const featureFlag = useFeatureFlag(`Frontend_Feedback_General_Tab`)

  useEffect(() => {
    setShouldShow(shouldBeShown())
  }, [])

  if (!shouldShow || !featureFlag) {
    return false
  }
  const inApp = isInApp()

  const closeDrawer = () => {
    setOpenDrawer(false)
    setShouldShow(false)
    StorageAccessor.localStorage.setObj(LOCALSTORAGEKEY, true, ONEMONTHMINUTES)
  }

  const feedBackType = inApp ? PWA : GENERAL
  if (!openDrawer) {
    return (
      <Button
        brand={'secondary'}
        size={'small'}
        data-testid={`${feedBackType}-button-tab`}
        className={styles.floatingButton}
        segmentEvent={createClickedSegmentEvent(`${feedBackType} Feedback`)}
        onClick={() => {
          setOpenDrawer(true)
        }}
      >
        <Typography size={0.75} tone={'contrast'}>
          {feedBackType} Feedback
        </Typography>
      </Button>
    )
  }

  return (
    <Drawer
      width={500}
      renderWhenClosed={false}
      location={'right'}
      open={openDrawer}
      closeFunction={closeDrawer}
    >
      <Feedback
        name={feedBackType}
        callback={closeDrawer}
        feedbackPrompt={`How can we improve our ${feedBackType}?`}
        prompt={`How do you like the ${feedBackType}?`}
      />
    </Drawer>
  )
}

export default FeedbackTab
