import React, { useContext, useEffect, useState } from 'react'

import { RecommendationProvider } from 'Stores/RecommendationStore/RecommendationStore'

import LandingPageLinkRack from 'Organisms/LandingPageLinkRack'
import RecommendationSection from 'Organisms/RecommendationSection'

import Button from 'Molecules/Button'

import Gutters from 'Atoms/Gutters'
import Panel from 'Atoms/Panel'
import Typography from 'Atoms/Typography'

import { AddToCartExperienceContext } from '../../Contexts/AddToCartExperienceContext/AddToCartExperienceContext'
import {
  createClickedSegmentEvent,
  createViewedSegmentEvent,
} from '../../Utilities/Instrumentation/Impressions/impressionSegmentEvents'
import styles from './ErrorPage.module.scss'
import { CategoryLinks, DataSets } from './ErrorPageDataSets'
import ServerErrorPage from './ServerErrorPage'

const ErrorPage = ({ statusCode, hasRecommendations = true }) => {
  const addToCartExperienceContext = useContext(AddToCartExperienceContext)
  const [dataSet, setDataSet] = useState([])

  useEffect(() => {
    setDataSet(DataSets[getRandomInt(DataSets.length)])
  }, [])

  if (statusCode != 404) {
    return <ServerErrorPage />
  }

  return (
    <Panel layer={0}>
      <Gutters className={styles.wrapper}>
        <Typography
          as="h2"
          brand={'primary'}
          size={2}
        >{`Error ${statusCode}`}</Typography>
        <img className={styles.image} src={dataSet.imageSource} />
        <Typography as="h3" size={1.5}>
          {dataSet.h3}
        </Typography>
        <br />
        <Typography as="h4" size={1}>
          {dataSet.h4}
        </Typography>
        {dataSet.addText ? (
          <Typography as="h4" size={1}>
            {dataSet.addText}
          </Typography>
        ) : (
          false
        )}
        <div className={styles.links}>
          <Button
            brand={'primary'}
            href={'/'}
            size={'small'}
            segmentEvent={createClickedSegmentEvent('ErrorPageHomeLink')}
          >
            <Typography>Continue Shopping</Typography>
          </Button>
          <Button
            brand={'primary'}
            href={'/deals'}
            size={'small'}
            segmentEvent={createClickedSegmentEvent('ErrorPageDealsLink')}
          >
            <Typography>Shop Deals</Typography>
          </Button>
          <Button
            brand={'primary'}
            href={'/the-toolbox'}
            size={'small'}
            segmentEvent={createClickedSegmentEvent('ErrorPageToolboxLink')}
          >
            <Typography>Read Articles</Typography>
          </Button>
        </div>
        {hasRecommendations ? (
          <div className={styles.recWrapper}>
            <RecommendationProvider
              sections={['404']}
              skuBaseNumbers={(addToCartExperienceContext.items ?? []).map(
                (product) => product.skuBaseNumber,
              )}
            >
              <RecommendationSection
                section={'404'}
                numberOfSkeletons={8}
                numberOfSkeletonSections={2}
              />
            </RecommendationProvider>
          </div>
        ) : null}
        <Typography as={'h2'} font={'bold'} size={1.25}>
          Shop Top Categories
        </Typography>
        <LandingPageLinkRack
          links={CategoryLinks}
          showMoreLink={{ text: 'Shop All Categories', url: '/shop/all' }}
        />
      </Gutters>
    </Panel>
  )
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max)
}

export default ErrorPage
