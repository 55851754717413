import React from 'react'

const SeoClarityScripts = ({ dontAutoSendClarityInitEvent = false }) => {
  return (
    <>
      <script
        src={'https://js.clrt.ai/11405.js'}
        async
        onLoad={() => {
          try {
            if (
              typeof window !== 'undefined' &&
              !dontAutoSendClarityInitEvent
            ) {
              window.dispatchEvent(
                new CustomEvent('clarityautomate.initialized'),
              )
            }
          } catch (err) {
            console.error('onLoad for seoClarity failed', err)
          }
        }}
      />
    </>
  )
}
export default SeoClarityScripts
