import React, { Fragment } from 'react'

import LayerColorFinder from 'Utilities/LayerColorFinder'

import Card from 'Atoms/Card'
import Divider from 'Atoms/Divider'
import Typography from 'Atoms/Typography'

import styles from './Radio.module.scss'
import styled from 'styled-components'

const Radio = ({
  values,
  isCard,
  inlineRadioLabel,
  radioSize = 0.75,
  labelFont,
  id,
  dataTestId,
  brand = 'primary',
  intent,
  selectedValue,
  fontSize = 1,
  ...props
}) => {
  const borderColor =
    intent || brand
      ? LayerColorFinder(undefined, undefined, brand, intent)
      : undefined

  const radioOptions = values.map((value, index) => (
    <Fragment key={index}>
      {isCard ? (
        <>
          <div className={styles.radioInputItem}>
            <div className={styles.radioInputBody}>
              <StyledRadioInput
                radioSize={radioSize}
                accentColor={borderColor}
                type="radio"
                id={`${id}-${index}`}
                name={`${value?.name}`}
                className={styles.radioItem}
                value={value?.value ?? ''}
                checked={selectedValue == value?.value}
                disabled={value.disabled}
                data-testid={value.dataTestId}
                {...props}
              />
              <Typography
                as={'label'}
                htmlFor={`${id}-${index}`}
                size={fontSize}
                className={styles.radioLabelText + ' ' + styles.padded}
                font={labelFont}
              >
                {value.label}
              </Typography>
            </div>
            {value.subLabel ? (
              <Typography
                as={'label'}
                htmlFor={`${id}-${index}`}
                size={1}
                className={styles.radioLabelSubText}
                style={{
                  paddingLeft: radioSize + 0.75 + 'rem',
                  paddingRight: radioSize + 0.75 + 'rem',
                }}
              >
                {value.subLabel}
              </Typography>
            ) : (
              false
            )}
          </div>
          {index + 1 != values.length ? (
            <Divider className={styles.divider} />
          ) : (
            false
          )}
        </>
      ) : (
        <div className={styles.radioInputBody}>
          <StyledRadioInput
            radioSize={radioSize}
            accentColor={borderColor}
            type="radio"
            id={`${id}-${index}`}
            name={`${id}`}
            value={value?.value ?? ''}
            checked={selectedValue == value?.value}
            disabled={value.disabled}
            data-testid={`radio_option_${value?.value}`}
            className={styles.radioItem}
            {...props}
          />
          <Typography
            as={'label'}
            htmlFor={`${id}-${index}`}
            size={fontSize}
            className={styles.radioLabelText}
          >
            {value.label}
          </Typography>
        </div>
      )}
    </Fragment>
  ))

  return (
    <Fragment>
      {isCard ? (
        <Card fill noPadding>
          {radioOptions}
        </Card>
      ) : (
        radioOptions
      )}
    </Fragment>
  )
}

const StyledRadioInput = styled('input')`
  height: ${(props) => props.radioSize + 'rem'};
  width: ${(props) => props.radioSize + 'rem'};
  accent-color: ${(props) => props.accentColor};
`

export default Radio
