import React, { Fragment, useEffect, useState } from 'react'

import useFacebook from 'Hooks/useFacebook'

import Form from 'Atoms/Form'

import CoveringSpinner from '../../../Atoms/CoveringSpinner/CoveringSpinner'

const IndexOptionSelect = ({
  indexOptionValues,
  selectIndexOption,
  label,
  productPageId,
  selectedIndexOptions,
  isIndexOptionErrorState,
}) => {
  const [waiting, setWaiting] = useState(false)

  // gets the currently selected value to prepopulate the select
  // shape: 42 (int)
  const selectedValue =
    selectedIndexOptions && selectedIndexOptions?.length > 0
      ? indexOptionValues?.some((value) =>
          selectedIndexOptions?.includes(value?.id),
        )
        ? indexOptionValues?.filter((value) =>
            selectedIndexOptions?.includes(value?.id),
          )[0].id
        : ''
      : ''

  const [prev, setPrev] = useState(null)

  useEffect(() => {
    if (selectedValue != null && selectedValue != prev) {
      setWaiting(false)
      setPrev(selectedValue)
    }
  }, [selectedValue, prev])

  if (!indexOptionValues) return false

  const sendFacebookEvent = useFacebook()

  const options = indexOptionValues
    .sort((a, b) => {
      return (
        (parseInt(a.ordinality) || a.displayName) -
        (parseInt(b.ordinality) || b.displayName)
      )
    })
    .map((value) => {
      return {
        value: value.id,
        label: value.displayName,
        disabled: value.isDisabled,
      }
    })

  const handleOnChange = (e) => {
    e.preventDefault()
    setWaiting(true)
    selectIndexOption(
      productPageId,
      null,
      selectedIndexOptions,
      e.target.value,
      sendFacebookEvent,
    )
  }

  return (
    <Fragment>
      <CoveringSpinner isLoading={waiting} fill>
        <Form.Select
          id={`indexoption_${label.toLowerCase()}`}
          options={[]
            .concat({ value: '', label: label, disabled: true })
            .concat(options)}
          label={''}
          size={1.5}
          value={selectedValue}
          borderIntent={
            !isIndexOptionErrorState ||
            (selectedValue != null && selectedValue !== '')
              ? false
              : 'negative'
          }
          onChange={(e) => handleOnChange(e)}
        />
      </CoveringSpinner>
    </Fragment>
  )
}

export default IndexOptionSelect
