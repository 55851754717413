import React, { useState } from 'react'

import Svg from 'Atoms/Svg'

import { useUserStore } from '../../Stores/UserStore/UserStore'
import styles from './Feedback.module.scss'
import FeedbackButton from './FeedbackButton'
import FeedbackTextArea from './FeedbackTextArea'

const Feedback = ({
  name,
  MFESpecificData = {},
  prompt = 'Did you find what you are looking for?',
  feedbackPrompt = `How could we do better?`,
  callback,
  searchProvider,
  size = 0.875,
  hideCopy,
  hideTextFeedback,
  misoId,
}) => {
  const [hasBeenClicked, setHasBeenClicked] = useState(false)

  const [isPositive, setIsPositive] = useState(false)

  const isCustomerInternal = useUserStore((x) => x.context.isCustomerInternal)

  if (!misoId && (name == 'Search' || name == 'Shop')) {
    console.error('Miso id required for feedback component')
  }
  if (!name) {
    console.error('Name is required for feedback component')
    return null
  }

  const onClick = (isPositiveSetFromTheButton) => {
    setIsPositive(isPositiveSetFromTheButton)
    setHasBeenClicked(true)

    //intentional for logrocket
    console.log(
      `${isPositiveSetFromTheButton ? 'Positive' : 'Negative'} feedback clicked for ${name}`,
    )

    if (callback && hideTextFeedback) {
      callback(isPositiveSetFromTheButton)
    }
  }

  const actionText = isCustomerInternal ? '-Internal' : '-External'

  if (hasBeenClicked && !hideTextFeedback) {
    return (
      <FeedbackTextArea
        callback={callback}
        feedbackPrompt={feedbackPrompt}
        actionText={actionText}
        name={name}
        isInternal={isCustomerInternal}
        isPositive={isPositive}
        searchProvider={searchProvider}
      />
    )
  }

  return (
    <>
      <div className={styles.container}>
        {!hideCopy ? (
          <>
            <p className={'p'}>{name} Feedback:</p>
            <p className={'p p-bold'}>{prompt}</p>
          </>
        ) : (
          false
        )}

        <div className={styles.buttonContainer}>
          <FeedbackButton
            actionText={actionText}
            name={name}
            MFESpecificData={MFESpecificData}
            isInternal={isCustomerInternal}
            isPositive={true}
            onClick={onClick}
            searchProvider={searchProvider}
            isClicked={hasBeenClicked && isPositive}
            misoId={misoId}
          >
            <Svg icon={'thumb_up'} className={styles.svg} size={size} />
          </FeedbackButton>

          <FeedbackButton
            actionText={actionText}
            name={name}
            MFESpecificData={MFESpecificData}
            isInternal={isCustomerInternal}
            isPositive={false}
            onClick={onClick}
            searchProvider={searchProvider}
            isClicked={hasBeenClicked && !isPositive}
            misoId={misoId}
          >
            <Svg icon={'thumb_down'} className={styles.svg} size={size} />
          </FeedbackButton>
        </div>
      </div>
    </>
  )
}

export default Feedback
