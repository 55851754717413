import React, { useContext } from 'react'

import { isInApp } from '../../Hooks/useAppInstall/AppUtilities'
import useNavigationState from '../../Hooks/useAppInstall/useNavigationState'
import styles from './NavigatingProgressBar.module.scss'

const NavigatingProgressBar = () => {
  const { isNavigating } = useNavigationState()
  if (!isNavigating || !isInApp()) {
    return false
  }

  return (
    <div className={styles.bar}>
      <div className={styles.barValue}></div>
    </div>
  )
}

export default NavigatingProgressBar
