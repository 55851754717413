import React from 'react'

import {
  CorporateContactJsonLd,
  SiteLinksSearchBoxJsonLd,
  SocialProfileJsonLd,
} from 'next-seo'

const JsonLD = ({}) => {
  return (
    <>
      <CorporateContactJsonLd
        keyOverride={'orginization'}
        url={'https://www.speedwaymotors.com'}
        logo={
          'https://content.speedwaymotors.com/OtherImages/Speedway_Motors_Logo_300.png'
        }
        contactPoint={[
          {
            telephone: '+1-800-979-0122',
            contactType: 'Customer Service',
            contactOption: 'TollFree',
            areaServed: ['US'],
            availableLanguage: ['English'],
          },
          {
            telephone: '+1-855-313-9180',
            contactType: 'Customer Service',
            areaServed: ['CA'],
            availableLanguage: ['English'],
          },
          {
            telephone: '+1-402-323-3200',
            contactType: 'Customer Service',
            availableLanguage: ['English'],
          },
        ]}
      />

      <SiteLinksSearchBoxJsonLd
        url={'https://www.speedwaymotors.com'}
        potentialActions={[
          {
            target: 'https://www.speedwaymotors.com/Search?query',
            queryInput: 'search_term_string',
          },
        ]}
      />

      <SocialProfileJsonLd
        type={'Organization'}
        name={'Speedway Motors'}
        url={'https://www.speedwaymotors.com'}
        sameAs={[
          'https://www.facebook.com/speedwaymotors',
          'https://twitter.com/speedwaymotors',
          'https://www.pinterest.com/speedwaymotors/',
          'https://www.instagram.com/speedwaymotorsinc/',
          'https://www.youtube.com/user/SpeedwayMotorsInc',
        ]}
      />
    </>
  )
}

export default JsonLD
