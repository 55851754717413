import { useContext } from 'react'

import { NotificationContext } from '../../Contexts/NotificationContext/NotificationContext'
import {
  IdentifyLogRocket,
  InitLogRocket,
} from '../../Contexts/UserContext/InitLogRocket'
import { LogoutUser } from '../../Contexts/UserContext/LogoutUser'
import { UpdateUserProfile } from '../../Contexts/UserContext/UpdateUserProfile'
import CookieReader from '../../Utilities/CookieUtilties/CookieReader'
import SetCookie from '../../Utilities/CookieUtilties/SetCookie'
import {
  GetUserGuid,
  GetUserVin,
  IsUserBot,
} from '../../Utilities/CookieUtilties/UserCookieUtilities'
import { StorageAccessor } from '../../Utilities/LocalStorageUtility'
import { saveLocation } from '../../Utilities/Location/Location'
import createZustandContext from '../../Utilities/Zustand/createZustandContext'
import { useThemeStore } from '../ThemeStore'
import UserStoreWiring from './UserStoreWiring'

const UserContext = createZustandContext(
  ({ initialProps, set, get, props, hardCodedPropsForTesting }) => {
    const defaultState = {
      context: {
        hydrated: false,
        emailAddress: null,
        zipCode: null,
        firstName: null,
        lastName: null,
        userGuid: null,
        userName: null,
        userVin: GetUserVin,
        getGuid: GetUserGuid,
        isBot: IsUserBot,
        isRegistered: null,
        isInternational: null,
        sopCustomerId: null,
        isSignedUpForEmails: null,
        isCustomerInternal: null,
        isLoyaltyBetaUser: true,
        isLoyaltyRegisteredUser: false,
        logRocketInitialized: false,
        logRocketIdentified: false,
        isSpecialPriceEligible: false,
        stateObject: props.stateObject,
        updateUserGuid: props.updateUserGuid,
        isEmployee: function () {
          return (
            CookieReader('ExcludeIpFromAnalytics') ||
            CookieReader('ExcludeEmailFromAnalytics')
          )
        },
      },
    }

    const initialState = hardCodedPropsForTesting || defaultState

    return {
      ...initialState,
      ...initialProps,

      updateUserProfile: async () => {
        const updatedProfile = await UpdateUserProfile()
        set((state) => ({
          ...state,
          context: {
            ...state.context,
            ...updatedProfile,
            hydrated: true,
          },
        }))
      },

      logoutUser: async () => {
        const response = await LogoutUser(get()._dependencies.setIsLoading)
        if (response != null) {
          set((state) => ({
            ...state,
            context: {
              ...initialState.context,
              redirectUrl: response.redirectUrl,
              hydrated: true,
            },
          }))
        } else {
          set((state) => ({
            ...state,
            context: {
              ...initialState.context,
              hydrated: true,
            },
          }))
        }
      },

      updateUserZipCode: (zipCode) => {
        saveLocation(zipCode, null)
        set((state) => ({
          ...state,
          context: {
            ...state.context,
            zipCode,
            hydrated: true,
          },
        }))
      },

      updateUserIsInternational: (isInternational) => {
        saveLocation(null, isInternational)
        set((state) => ({
          ...state,
          context: {
            ...state.context,
            isInternational,
            hydrated: true,
          },
        }))
      },

      setUserAcquisition: (acquisitionType) => {
        set((state) => ({
          ...state,
          context: {
            ...state.context,
            acquisitionType,
            acquisitionTypeHydrated: true,
          },
        }))
      },

      setLogRocketIdentified: () => {
        set((state) => ({
          ...state,
          context: {
            ...state.context,
            logRocketIdentified: true,
          },
        }))
      },

      setLogRocketInitialized: (logRocketInitialized) => {
        set((state) => ({
          ...state,
          context: {
            ...state.context,
            logRocketInitialized,
          },
        }))
      },

      setPromoCodeFromUrl: () => {
        if (typeof window !== 'undefined') {
          const searchParams = new URLSearchParams(window.location.search)
          const promoCodeFromUrl = searchParams.get('smi_pc')

          if (
            promoCodeFromUrl &&
            promoCodeFromUrl.trim() !== '' &&
            !document.cookie.includes(promoCodeFromUrl)
          ) {
            SetCookie('PromoCode', promoCodeFromUrl, 1)
            get()._dependencies.PushNotification(
              `${promoCodeFromUrl} has been applied!`,
            )
          }
        }
      },

      showFreeShippingToast: (
        enableShippingToast,
        PushNotification,
        freeShippingThreshold,
      ) => {
        if (enableShippingToast) {
          const cookieVal = 'freeShippingAdvertised'
          if (
            !document.cookie.includes(cookieVal) &&
            PushNotification !== undefined &&
            freeShippingThreshold
          ) {
            SetCookie('FreeShippingToast', cookieVal, 1)
            PushNotification(
              `Free Shipping over $${freeShippingThreshold}`,
              'positive',
              10000,
              false,
              { icon: 'fast_shipping', intent: 'positive', size: 1.25 },
            )
          }
        }
      },

      initializeLogRocket: async (
        isGpcEnabled,
        lrEnabled,
        logRocketAppId,
        releaseVersion,
      ) => {
        if (
          !isGpcEnabled &&
          get().context.hydrated &&
          !get().context.isBot() &&
          lrEnabled &&
          !get().context.logRocketInitialized
        ) {
          set((state) => ({
            ...state,
            context: {
              ...state.context,
              logRocketInitialized: null,
            },
          }))
          await InitLogRocket(logRocketAppId, releaseVersion)
          set((state) => ({
            ...state,
            context: {
              ...state.context,
              logRocketInitialized: true,
            },
          }))
        }

        if (
          get().context.logRocketInitialized &&
          !get().context.logRocketIdentified &&
          get().context.hydrated
        ) {
          await IdentifyLogRocket(
            get().context.getGuid(),
            get().context.emailAddress,
            get().context.userVin(),
            get().context.sopCustomerId,
          )
          set((state) => ({
            ...state,
            context: {
              ...state.context,
              logRocketIdentified: true,
            },
          }))
        }
      },

      setUserAudiences: (audiences) => {
        if (audiences) {
          SetCookie('segment_audiences', JSON.stringify(audiences), 365)
        }
      },

      setGoogleAudiences: (googleAudiences) => {
        const gtagCacheKey = 'sentGtagSegmentAudiences'
        try {
          for (const ga of googleAudiences ?? []) {
            const sentEventsValues =
              StorageAccessor.sessionStorage.getObj(gtagCacheKey) ?? []
            if (!sentEventsValues.includes(ga)) {
              gtag('event', 'segment_google_audiences', {
                google_audience_name: `${ga}`,
              })
              sentEventsValues.push(ga)
              StorageAccessor.sessionStorage.setObj(
                gtagCacheKey,
                sentEventsValues,
              )
            }
          }
        } catch (e) {
          console.error('unable to send google gtag event')
        }
      },
    }
  },
  {
    name: 'UserStore',
    missingSelectorBehavior: 'quiet for now',
    disableOld: false,
  },
)

UserContext.useSynchronizedState = () => {
  const { PushNotification } = useContext(NotificationContext)
  const setIsLoading = useThemeStore((x) => x.setIsLoading)

  return {
    _dependencies: {
      PushNotification,
      setIsLoading,
    },
  }
}

UserContext.Wiring = UserStoreWiring

export const useUserStore = UserContext.useStore
export const UserProvider = UserContext.Provider
