import React, { useContext, useEffect, useRef, useState } from 'react'

import ClassNames from 'classnames'

import LayerColorFinder from 'Utilities/LayerColorFinder'

import PanelContext from 'Contexts/PanelContext'

import { Default } from 'Molecules/Button/Variants/OptionButtons'

import TextLink from 'Atoms/TextLink'

import styles from './Expander.module.scss'

const Expander = ({
  children,
  closedHeight,
  classNames,
  testid,
  count,
  expanderCallback,
  showOnMobileOnly,
  isExpanded,
  minifiedButtons,
}) => {
  if (!children) {
    return false
  }

  const itemsWrapper = useRef(null)

  const [expanded, setExpanded] = useState(isExpanded ? isExpanded : false)
  const [showLessButtonClicked, setShowLessButtonClicked] = useState(false)
  const panelContext = useContext(PanelContext)

  const [isRendered, setIsRendered] = useState(false)
  useEffect(() => {
    setIsRendered(true)
  }, [])

  useEffect(() => {
    setExpanded(isExpanded)
  }, [isExpanded])

  useEffect(() => {
    setExpanded(false)
  }, [count])

  const panelColor = LayerColorFinder(
    panelContext.layer,
    panelContext.inverseTheme,
  )

  const hoverColor = LayerColorFinder(2)

  const outerDivStyle = {
    '--h': expanded ? '999999px' : closedHeight,
    '--c': panelColor,
    '--b': hoverColor,
  }

  const contentClasses = ClassNames(styles.contentDiv, classNames)

  const handleShowMoreClick = () => {
    if (expanderCallback) expanderCallback(true)
    setExpanded(true)
  }

  const handleShowLessClick = () => {
    if (expanderCallback) expanderCallback(false)
    if (!showLessButtonClicked) setShowLessButtonClicked(true)
    setExpanded(false)
  }

  const seeMoreButton = (
    <div className={styles.buttonWrapper}>
      <div className={styles.gradient} onClick={handleShowMoreClick} />
      <div
        className={ClassNames(
          styles.buttonAbsolute,
          styles.buttonDiv,
          'seeAllExpanderButton',
        )}
        onClick={handleShowMoreClick}
        data-testid={`${testid}_seeAllExpanderButton`}
      >
        {minifiedButtons ? (
          <TextLink as={'span'} onClick={handleShowMoreClick} testid={testid}>
            See Less
          </TextLink>
        ) : (
          <Default
            value={{
              displayName: count > 0 ? `See All (${count})` : 'See All',
            }}
            onClick={handleShowMoreClick}
            testid={testid}
          />
        )}
      </div>
    </div>
  )

  const seeLessButton = (
    <div className={styles.buttonWrapper}>
      <div onClick={handleShowLessClick} />
      <div
        className={ClassNames(styles.buttonDiv, 'seeAllExpanderButton')}
        onClick={handleShowLessClick}
        data-testid={`${testid}_seeAllExpanderButton`}
      >
        {minifiedButtons ? (
          <TextLink as={'span'} onClick={handleShowMoreClick} testid={testid}>
            See Less
          </TextLink>
        ) : (
          <Default
            value={{ displayName: 'Show Less' }}
            onClick={handleShowLessClick}
            testid={testid}
          />
        )}
      </div>
    </div>
  )
  const clientHeight = itemsWrapper?.current?.clientHeight
  const scrollHeight = itemsWrapper?.current?.scrollHeight
  const shouldRenderShowMoreButton =
    !expanded && (clientHeight < scrollHeight || showLessButtonClicked)

  return (
    <>
      <div
        className={
          showOnMobileOnly
            ? ClassNames(styles.outerDiv, styles.mobileOnly)
            : styles.outerDiv
        }
        data-testid={`${testid}`}
      >
        <div
          className={contentClasses}
          style={outerDivStyle}
          ref={itemsWrapper}
        >
          {children}
          <div>{shouldRenderShowMoreButton && seeMoreButton}</div>
        </div>
        {expanded ? seeLessButton : false}
      </div>
    </>
  )
}

export default Expander
