import AppName from 'AppName'
import { resourceUsage } from 'process'

import { StorageAccessor } from 'Utilities/LocalStorageUtility/LocalStorageUtility'

const HISTORYTORETAIN = 30
const GetCacheKey = (pageType) => {
  return `${pageType}_RecentHistory`
}
const GetMetaImageUrl = () => {
  const ogImage = document.querySelector("meta[property='og:image']")
  return ogImage?.getAttribute('content') ?? null
}

const AddToRecentHistory = (pageDetails = {}) => {
  try {
    if (typeof window === 'undefined') {
      return
    }
    const defaultDetails = {
      pageType: AppName,
      url: `${window.location.pathname}${window.location.search}`,
      title: document.title.split('|')[0].trim(),
      date: new Date(),
    }
    pageDetails = { ...defaultDetails, ...pageDetails }
    pageDetails.imageUrl = pageDetails.imageUrl ?? GetMetaImageUrl()
    const localStorageKeyForPageType = GetCacheKey(pageDetails.pageType)
    let existing =
      StorageAccessor.localStorage.getObj(localStorageKeyForPageType) ?? []
    existing.unshift(pageDetails)
    existing = existing.slice(0, HISTORYTORETAIN)
    StorageAccessor.localStorage.setObj(
      GetCacheKey(pageDetails.pageType),
      existing,
      43800,
    )
  } catch (err) {
    console.error('Error adding to recent history', err)
  }
}

const GetRecentHistory = (pageType, deDupByPropertyName = 'url') => {
  try {
    if (typeof window === 'undefined') {
      return
    }
    const localStorageKeyForPageType = GetCacheKey(pageType)
    const found =
      StorageAccessor.localStorage.getObj(localStorageKeyForPageType) ?? []
    if (
      deDupByPropertyName &&
      found.length &&
      found.some((x) => x[deDupByPropertyName])
    ) {
      const results = []
      const uniquePropValues = [
        ...new Set(found.map((x) => x[deDupByPropertyName])),
      ]
      for (let i = 0, ilen = uniquePropValues.length; i < ilen; i++) {
        results.push(
          found.find((x) => x[deDupByPropertyName] == uniquePropValues[i]),
        )
      }
      return results
    }
    return found
  } catch (err) {
    console.error('Error getting recent history', err)
    return []
  }
}
const not_set = 'not_set'
const PRODUCTLISTTYPES = {
  search: 'Searched For',
  shop: 'Shopped',
  rec: 'Recommended',
}
const RECENTPRODUCTLISTKEY = 'RecentProductList'
const PERSISTED_ATTRIBUTION = ['acquisition']

const SetMostRecentProductList = (type, name, attribution) => {
  try {
    const prefix = PRODUCTLISTTYPES[type]
    if (!prefix) {
      throw `unrecognized type: ${type}`
    }

    const existing =
      StorageAccessor.sessionStorage.getObj(RECENTPRODUCTLISTKEY) ?? {}
    if (existing?.attribution && attribution) {
      PERSISTED_ATTRIBUTION.forEach((key) => {
        attribution[key] = existing.attribution[key]
      })
    }

    StorageAccessor.sessionStorage.setObj(RECENTPRODUCTLISTKEY, {
      prefix,
      name,
      attribution,
    })
  } catch (err) {
    console.error('Error saving most recent product list', err)
  }
}

const addOrUpdateAttribution = (attribution) => {
  try {
    let recentList = StorageAccessor.sessionStorage.getObj(RECENTPRODUCTLISTKEY)

    recentList = recentList || {}
    recentList.attribution = {
      ...(recentList?.attribution || {}),
      ...attribution,
    }
    StorageAccessor.sessionStorage.setObj(RECENTPRODUCTLISTKEY, recentList)
  } catch (err) {
    console.error('Error adding or updating attribution', err)
  }
}

const MISOIDKEY = 'MisoId'
const SetRecentMisoId = (misoId, skuBase) => {
  try {
    const data = {
      misoId,
      skuBase,
    }

    StorageAccessor.sessionStorage.setObj(MISOIDKEY, JSON.stringify(data), 1)
  } catch {
    console.error('Error setting the miso id in session storage.', err)
  }
}

const GetAndDeleteMisoId = () => {
  try {
    const misoIdData = StorageAccessor.sessionStorage.getObj(MISOIDKEY)

    const data = JSON.parse(misoIdData)

    if (data && (!data.misoId || !data.skuBase)) {
      throw 'Invalid miso id data.'
    }

    StorageAccessor.sessionStorage.removeItem(MISOIDKEY)

    return data
  } catch (err) {
    console.error('Error getting miso id from session storage.', err)
  }
}

const GetMostRecentProductListName = () => {
  try {
    const recentList =
      StorageAccessor.sessionStorage.getObj(RECENTPRODUCTLISTKEY)
    if (recentList) {
      if (recentList.prefix && recentList.name) {
        return `${recentList.prefix}: ${recentList.name}`
      }

      if (typeof recentList === 'string') {
        return recentList
      }
    }
    return not_set
  } catch (err) {
    console.error('Error getting recent product list name', err)
    return not_set
  }
}

const GetMostRecentProductListObject = (listName) => {
  try {
    const recentList =
      listName ?? StorageAccessor.sessionStorage.getObj(RECENTPRODUCTLISTKEY)
    if (recentList && recentList !== not_set) {
      if (recentList.prefix && recentList.name) {
        return {
          list_id: recentList.prefix,
          list_name: recentList.name,
          attribution: recentList.attribution,
        }
      }

      if (recentList.attribution) {
        return {
          list_id: not_set,
          list_name: not_set,
          attribution: recentList.attribution,
        }
      }

      const splitString = recentList.split(':')
      return {
        list_id: splitString[0], //Searched For
        list_name: splitString[1], //search term
      }
    }

    return { list_id: not_set, list_name: not_set }
  } catch (err) {
    console.error('Error getting recent product list name', err)
    return {}
  }
}

export default {
  AddToRecentHistory,
  GetRecentHistory,
  GetMostRecentProductListName,
  SetMostRecentProductList,
  GetMostRecentProductListObject,
  SetRecentMisoId,
  GetAndDeleteMisoId,
  addOrUpdateAttribution,
}
