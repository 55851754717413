const safeDecodeURIComponent = (encodedStr) => {
  let decodedStr = encodedStr
  let previousStr

  if (!encodedStr) return encodedStr

  do {
    previousStr = decodedStr
    try {
      decodedStr = decodeURIComponent(decodedStr)
    } catch (e) {
      console.error(e)
      return previousStr
    }
  } while (decodedStr !== previousStr)

  return decodedStr
}

export default safeDecodeURIComponent
