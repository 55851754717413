import { useContext } from 'react'

import { useThemeStore } from 'Stores/ThemeStore'

import LayerColorFinder from 'Utilities/LayerColorFinder'

import PanelContext from 'Contexts/PanelContext'

import useMemoryCache from 'Hooks/useMemoryCache'

/*
    IMPORTANT
    DO NOT. Repeat for emphasis ... DO NOT. Remove parathenses around the object destructuring code for hue, saturation, lightness, and alpha or remove the semicolons on those lines
    or the lines before them. It will not work, color will not calculate properly, the website might not render at all and you will make yourself a sad panda
*/

const ColorFinder = (intent, tone, brand, disabled = false) => {
  const inverse_content_color = useThemeStore((x) => x.inverse_content_color)
  const inverse_background_modifier = useThemeStore(
    (x) => x.inverse_background_modifier,
  )
  const background_modifier = useThemeStore((x) => x.background_modifier)
  const content_color = useThemeStore((x) => x.content_color)
  const name = useThemeStore((x) => x.name)

  const themeBrand = useThemeStore((x) => x.brand)
  const themeIntent = useThemeStore((x) => x.intent)

  const panelContext = useContext(PanelContext)
  const panelColor = LayerColorFinder(
    panelContext.layer,
    panelContext.inverseTheme,
  )

  const color = useMemoryCache(
    'ColorFinder',
    () => {
      let hue, saturation, lightness, alpha

      if (panelContext && (panelContext.brand || panelContext.intent)) {
        if (panelContext.brand) {
          if (disabled) {
            ;({ hue, saturation, lightness, alpha } = panelContext.invert
              ? brand['disabled'].color
              : inverse_content_color)
          } else {
            ;({ hue, saturation, lightness, alpha } = panelContext.invert
              ? themeBrand[panelContext.brand].color
              : themeBrand[panelContext.brand].content_color)
          }

          const hover_color = disabled
            ? themeBrand['disabled'].color
            : themeBrand[panelContext.brand].content_color

          return {
            color: `hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`,
            hoverColor: `hsla(${hover_color.hue}, ${hover_color.saturation}%, ${hover_color.lightness}%, ${hover_color.alpha})`,
            panelColor: `${panelColor}`,
          }
        } else if (panelContext.intent) {
          if (disabled) {
            ;({ hue, saturation, lightness, alpha } = panelContext.invert
              ? themeIntent['disabled'].color
              : inverse_content_color)
          } else {
            ;({ hue, saturation, lightness, alpha } = panelContext.invert
              ? themeIntent[panelContext.intent].color
              : themeIntent[panelContext.intent].content_color)
          }
          const hover_color = disabled
            ? themeIntent['disabled'].color
            : themeIntent[panelContext.intent].content_color

          return {
            color: `hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`,
            hoverColor: `hsla(${hover_color.hue}, ${hover_color.saturation}%, ${hover_color.lightness}%, ${hover_color.alpha})`,
            panelColor: `${panelColor}`,
          }
        }
      }

      if (brand) {
        // If brand is an undefined value, it will set the value to what is specified below.
        const UNDEFINED_BRAND_VALUE = 0
        ;({ hue, saturation, lightness, alpha } = disabled
          ? themeBrand['disabled'].color
          : themeBrand[brand].color)

        const shadeOrTint = panelContext.inverseTheme
          ? shadeOrTintModifierValues[inverse_background_modifier]
          : shadeOrTintModifierValues[background_modifier]
        const shadeOrTintMultiplier =
          brand == 'secondarypalette_black' ? -4 : shadeOrTint ? shadeOrTint : 0

        const hoveredLightness = calculateModifier(
          lightness,
          intentModifierValues[0],
          UNDEFINED_BRAND_VALUE,
          shadeOrTintMultiplier,
        )

        return {
          color: `hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`,
          hoverColor: `hsla(${hue}, ${saturation}%, ${hoveredLightness}%, ${alpha})`,
          panelColor: `${panelColor}`,
        }
      } else if (intent) {
        // If intent or tone are an undefined value, it will set the value to what is specified below.
        const UNDEFINED_INTENT_VALUE = 0

        ;({ hue, saturation, lightness, alpha } = disabled
          ? themeIntent['disabled'].color
          : themeIntent[intent].color)

        const shadeOrTint = panelContext.inverseTheme
          ? shadeOrTintModifierValues[inverse_background_modifier]
          : shadeOrTintModifierValues[background_modifier]
        const shadeOrTintMultiplier = shadeOrTint ? shadeOrTint : 0

        const modifiedLightness = calculateModifier(
          lightness,
          intentModifierValues[panelContext.layer],
          UNDEFINED_INTENT_VALUE,
          shadeOrTintMultiplier,
        )
        const hoveredLightness = calculateModifier(
          modifiedLightness,
          intentModifierValues[panelContext.layer],
          UNDEFINED_INTENT_VALUE,
          shadeOrTintMultiplier,
        )
        return {
          color: `hsla(${hue}, ${saturation}%, ${modifiedLightness}%, ${alpha})`,
          hoverColor: `hsla(${hue}, ${saturation}%, ${hoveredLightness}%, ${alpha})`,
          panelColor: `${panelColor}`,
        }
      } else {
        const UNDEFINED_TONE_VALUE = 0.2

        ;({ hue, saturation, lightness, alpha } = panelContext.inverseTheme
          ? inverse_content_color
          : content_color)

        const modifiedAlpha = calculateModifier(
          alpha,
          toneModifierValues[tone],
          UNDEFINED_TONE_VALUE,
        )

        let toneHover = disabled ? tone : 'contrast'
        if (!disabled) {
          if (tone === 'subtle') {
            toneHover = 'normal'
          } else if (tone === 'superfluous') {
            toneHover = 'subtle'
          }
        }
        const hoveredAlpha = calculateModifier(
          alpha,
          toneModifierValues[toneHover],
          UNDEFINED_TONE_VALUE,
          disabled,
        )
        return {
          color: `hsla(${hue}, ${saturation}%, ${lightness}%, ${modifiedAlpha})`,
          hoverColor: `hsla(${hue}, ${saturation}%, ${lightness}%, ${hoveredAlpha})`,
          panelColor: `${panelColor}`,
        }
      }
    },
    [name, panelContext, panelColor, intent, tone, brand, disabled],
  )

  return color
}

const calculateModifier = (
  initial,
  value,
  undefinedValue,
  shadeOrTint = 1,
  disabled = false,
) => {
  // Usually used to disable hover effects
  if (disabled) {
    return parseFloat(initial)
  }
  const modifierValue = value !== undefined ? value : undefinedValue
  return parseFloat(initial) - modifierValue * shadeOrTint
}

// Gets an intent modifier based on the layer number of the panel context
const intentModifierValues = {
  0: 5,
  1: 10,
  2: 15,
  3: 17,
}

// Gets a tone modifier based on the tone passed in
const toneModifierValues = {
  contrast: 0,
  normal: 0.2,
  subtle: 0.34,
  superfluous: 0.6,
}

// Multiplies the intent modifier based on wether we want to add or subtract the intent modifier to the intent
const shadeOrTintModifierValues = {
  shade: 1,
  tint: -1,
}

export default ColorFinder
