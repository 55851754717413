import React, { useContext } from 'react'

import Image from 'next/image'

import { getSuperMarketAffinity } from 'Utilities/Instrumentation/SegmentUtilities/segmentFunctions'

import { NavigationContext } from 'Contexts/Navigation/NavigationContext'
import { useSkuBasePromoData } from 'Contexts/UserSpecificProductDataStore/UserSpecificProductDataStore'

import Rating from 'Atoms/Rating'
import Ribbon from 'Atoms/Ribbon'
import TextLink from 'Atoms/TextLink'
import Typography from 'Atoms/Typography'

import useFeatureFlag from '../../Hooks/useFeatureFlag/useFeatureFlag'
import useRecentHistory from '../../Hooks/useRecentHistory'
import { PromotionRibbon } from '../../Organisms/PromotionRibbon/PromotionRibbon'
import styles from './ProductCard.module.scss'

function isKitRecommendation(skuVariant) {
  return (
    skuVariant &&
    skuVariant.kit &&
    (skuVariant.kit.skuBaseComponents || skuVariant.kit.skuVariantComponents)
  )
}

const ProductCard = ({
  productPageUrl,
  imageUrl,
  displayWriteAReview,
  displayTitle,
  rating,
  reviewCount,
  salePriceString,
  strikeThroughPriceString,
  percentOffString,
  horizontal,
  skuBaseNumber,
  skuVariant,
  segmentEvent,
  dataTestId,
  treatment,
  brand,
  category,
  skuBaseDetails,
  onClick,
  ribbonText,
  ...props
}) => {
  const usePdpSkeleton = useFeatureFlag('PDP_Skeleton')
  const navigationContext = useContext(NavigationContext)
  const hasActiveRebate =
    skuBaseDetails?.hasActiveRebate || skuVariant?.hasActiveRebate
  const promoData = useSkuBasePromoData(skuBaseNumber)

  if (ribbonText == null)
    if (hasActiveRebate) ribbonText = 'Rebate'
    else ribbonText = percentOffString

  return (
    <TextLink
      className={horizontal ? styles.horizontal_image : styles.vertical_image}
      href={productPageUrl}
      onClick={() => {
        useRecentHistory.SetMostRecentProductList(
          'rec',
          `${dataTestId}_${treatment}`,
        )
        if (usePdpSkeleton) {
          const pdpProduct = {
            displayTitle: displayTitle,
            brand: brand,
            sku: skuBaseNumber,
            //image: imageUrl?.includes("http") ? imageUrl : `https:${imageUrl}`
          }
          navigationContext.setPdpSkeleton(true, pdpProduct)
        }
        if (typeof onClick == 'function') {
          onClick()
        }
      }}
      segmentEvent={
        segmentEvent ?? {
          event: 'Product Clicked',
          properties: {
            list_id: dataTestId,
            product_id: skuBaseNumber,
            sku: skuBaseNumber,
            category: category,
            name: displayTitle,
            brand: brand,
            variant: skuVariant?.skuVariantNumber ?? null,
            price: parseFloat(
              salePriceString.replace('$', '').replaceAll(',', ''),
            ),
            url: productPageUrl,
            image_url: imageUrl,
            availability: skuBaseDetails?.availability,
            rating: skuBaseDetails?.reviewCount ? skuBaseDetails?.rating : 0,
            reviews: skuBaseDetails?.reviewCount,
            audience_affinity: getSuperMarketAffinity(skuBaseDetails),
          },
        }
      }
    >
      <div
        className={
          horizontal
            ? styles.horizontal_product_card
            : styles.vertical_product_card
        }
        data-testid={`${dataTestId}_product_card_${
          skuBaseNumber ? skuBaseNumber : skuVariant
        }`}
      >
        {isKitRecommendation(skuVariant) ? (
          <Ribbon
            text={`${
              (skuVariant.kit.skuBaseComponents &&
              skuVariant.kit.skuBaseComponents.length
                ? skuVariant.kit.skuBaseComponents.length
                : 0) +
              (skuVariant.kit.skuVariantComponents &&
              skuVariant.kit.skuVariantComponents.length
                ? skuVariant.kit.skuVariantComponents.length
                : 0)
            } ITEM KIT`}
            dataTestId={`${dataTestId}_kit_ribbon_${skuBaseNumber}`}
          />
        ) : (
          <PromotionRibbon
            displayText={ribbonText}
            promotionPreview={promoData}
            className={styles.productRibbon}
            dataTestId={`${dataTestId}_discount_ribbon_${
              skuBaseNumber ? skuBaseNumber : skuVariant
            }`}
          />
        )}
        <div className={styles.image}>
          <Image
            src={`${!imageUrl?.includes('https:') ? 'https:' : ''}${
              imageUrl
                ? imageUrl
                : '//content.speedwaymotors.com/OtherImages/missingimage2.jpg'
            }`}
            width={horizontal ? 100 : 124}
            height={horizontal ? 100 : 124}
            alt={displayTitle}
          />
        </div>
        <div className={horizontal ? styles.horizontalContent : null}>
          <Typography className={styles.title}>{displayTitle}</Typography>
          {rating !== 'undefined' && reviewCount !== 0 ? (
            <span
              className={
                horizontal ? styles.horizontal_rating : styles.vertical_rating
              }
            >
              <Rating ratingValue={rating} size={1} />
              {reviewCount ? (
                <Typography size={0.875} tone={'subtle'} inline>
                  {`(${reviewCount})`}
                </Typography>
              ) : undefined}
            </span>
          ) : undefined}
          <div className={horizontal ? styles.horizontalPricing : null}>
            <Typography size={1} font={'bold'}>
              {salePriceString}
            </Typography>
            {horizontal && strikeThroughPriceString ? (
              <Typography
                className={styles.strike_through_price}
                size={1}
                tone={'subtle'}
                strikethrough
              >
                {strikeThroughPriceString}
              </Typography>
            ) : undefined}
          </div>
          {displayWriteAReview && skuBaseNumber && (
            <TextLink
              brand={'secondarypalette_blue'}
              size={0.75}
              inline
              href={`/ReviewSubmission?pr_page_id=${skuBaseNumber}`}
            >
              Write a Review
            </TextLink>
          )}
        </div>
      </div>
    </TextLink>
  )
}

export { ProductCard as default, ProductCard, isKitRecommendation }
