import React, { useEffect, useState } from 'react'

import Event from 'Utilities/Event'

import useContent from 'Hooks/useContent/useContent'

import Popover from 'Components/Popover/Popover'

import { NavbarItem } from '../NavbarItem/NavbarItem'
import { ToolboxContent } from './ToolboxContent'

const ToolboxBlade = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { featuredArticle, builds, tech, stories, recommendedArticles } =
    useContent(isOpen)

  useEffect(() => {
    if (isOpen) {
      Event({
        category: 'Header',
        action: 'Toolbox Blade Opened',
      })()
    }
  }, [isOpen])

  return (
    <Popover
      content={
        <ToolboxContent
          recommendedArticles={recommendedArticles}
          tech={tech}
          builds={builds}
          stories={stories}
          featuredArticle={featuredArticle}
        />
      }
      isOpen={isOpen}
      onToggleMenu={setIsOpen}
      staticComponentWidth={1100} // width of our blade in pixels used to center
    >
      <NavbarItem
        className={className}
        title={'Toolbox Link'}
        primaryText={'Learn'}
        secondaryText={'Toolbox'}
        icon={'toolbox'}
        hover
        data-testid={'toolbox'}
      />
    </Popover>
  )
}

export default ToolboxBlade
