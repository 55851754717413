import React from 'react'

import { useUserSpecificProductDataStore } from '../UserSpecificProductDataStore'
import LazyLoadFitmentData from './LazyLoadFitmentData'
import LazyLoadProductActivityData from './LazyLoadProductActivityData'
import LazyLoadPromoData from './LazyLoadPromoData'
import LazyLoadShippingData from './LazyLoadShippingData'
import SynchronizeCartData from './SynchronizeCartData'
import SynchronizeListData from './SynchronizeListData'

export default function UserSpecificProductDataStoreWiring() {
  const areUpdatesAllowed = useUserSpecificProductDataStore(
    (x) => x.areUpdatesAllowed,
  )

  const disableUpdates = !areUpdatesAllowed

  return (
    <>
      <LazyLoadFitmentData disable={disableUpdates} />
      <LazyLoadShippingData disable={disableUpdates} />
      <LazyLoadProductActivityData disable={disableUpdates} />
      <LazyLoadPromoData disable={disableUpdates} />
      <SynchronizeCartData />
      <SynchronizeListData />
    </>
  )
}
