import createZustandContext from 'Utilities/Zustand/createZustandContext'

import RecommendationStoreWiring from './Wiring/RecommendationStoreWiring'

const recommendationContext = createZustandContext(
  ({ initialProps, set, get, props }) => {
    return {
      ...initialProps,
      ...props,
      inView: false,
      hasHydratedClientProductData: false,
      recommendationSectionsInView: {},
      recommendationData: {},
      recommendationImpressionsSent: false,
      isLoading: true,
      getRecommendationSection: (section) => {
        return get().recommendationData?.recommendationSections?.find(
          (x) => x.sectionType === section,
        )
      },
      setIsPageLoading: (isLoading) => {
        set((state) => {
          return {
            ...state,
            isPageLoading: isLoading,
          }
        })
      },
      setSections: (sections) => {
        set((state) => {
          return {
            ...state,
            sections: sections?.length ? [...sections] : [],
          }
        })
      },
      setSectionData: (sectionData) => {
        try {
          set((state) => {
            let index

            const existingSection =
              get().recommendationData?.recommendationSections?.find((x, i) => {
                if (x.sectionType === sectionData?.sectionType) {
                  index = i
                  return true
                }

                return false
              })

            if (existingSection) {
              state.recommendationData.recommendationSections =
                state.recommendationData.recommendationSections.filter(
                  (x) => x.sectionType !== sectionData.sectionType,
                )
              state.recommendationData.recommendationSections.splice(
                index,
                0,
                sectionData,
              )
            }

            return {
              ...state,
            }
          })
        } catch (err) {
          console.error(err)
        }
      },
      setRecommendationSectionInView: (section) => {
        try {
          set((state) => {
            return {
              ...state,
              inView: true,
              recommendationSectionsInView: {
                ...state.recommendationSectionsInView,
                [section]: true,
              },
            }
          })
        } catch (e) {
          console.error(e)
        }
      },
    }
  },
  {
    name: 'RecommendationStore',
    missingSelectorBehavior: 'quiet for now',
  },
)

export const useRecommendationStore = recommendationContext.useStore

export const useRecommendationSection = (section) => {
  return useRecommendationStore((x) =>
    x.recommendationData.recommendationSections?.find(
      (sectionData) => sectionData.sectionType === section,
    ),
  )
}

recommendationContext.Wiring = RecommendationStoreWiring

export const RecommendationProvider = recommendationContext.Provider
