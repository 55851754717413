import React, { useEffect, useState } from 'react'

import { useUserStore } from 'Stores/UserStore/UserStore'
import Script from 'next/script'

const MisoSearchImpression = ({ misoApiUrl }) => {
  const hydrated = useUserStore((x) => x.context.hydrated)
  const isBot = useUserStore((x) => x.context.isBot)
  const isCustomerInternal = useUserStore((x) => x.context.isCustomerInternal)
  const userVin = useUserStore((x) => x.context.userVin)
  const getGuid = useUserStore((x) => x.context.getGuid)

  const [renderMisoImpressionScript, setRenderMisoImpressionScript] =
    useState(false)

  useEffect(() => {
    // we need to wait until the user context has been updated with data (hydrated)
    // before allowing the script to render

    const shouldRender = hydrated && !isBot()

    if (shouldRender) {
      setRenderMisoImpressionScript(true)
    }
  }, [hydrated])

  if (!renderMisoImpressionScript) {
    return false
  }

  if (isCustomerInternal) {
    return false
  }

  const misoImpressionScript = `if(!window.fireSearchImpression){
            window.fireSearchImpression = (data) => {
                var payload =
                {
                    'data': [{
                        'type': 'impression',
                        'product_ids': data,
                        'user_id': '${getGuid()}',
                        'anonymous_id': '${userVin()}',
                        'timestamp': '${new Date().toISOString()}',
                    }]
                }
                var xmlhttp = new XMLHttpRequest();
                xmlhttp.open('POST', '${misoApiUrl}');
                xmlhttp.setRequestHeader('Content-Type', 'application/json');
                xmlhttp.send(JSON.stringify(payload));
            }
        }`

  return (
    <Script
      id={'miso-si'}
      data-testid={'miso-si'}
      strategy={'afterInteractive'}
      enableOnlyOnNextFrontEnd={true}
    >
      {misoImpressionScript}
    </Script>
  )
}

export default MisoSearchImpression
