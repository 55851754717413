import React, { useContext, useEffect, useState } from 'react'

import { createClickedSegmentEvent } from 'Utilities/Instrumentation/Impressions/impressionSegmentEvents'

import { AddToCartExperienceContext } from 'Contexts/AddToCartExperienceContext/AddToCartExperienceContext'
import { NotificationContext } from 'Contexts/NotificationContext/NotificationContext'

import IndexOptions from 'Components/IndexOptions/indexoptions.js'

import Button from 'Molecules/Button'
import RecommendationCard from 'Molecules/RecommendationCard'

import Divider from 'Atoms/Divider'
import CheckBoxGroup from 'Atoms/Form/Elements/Checkbox'
import Typography from 'Atoms/Typography'

import styles from './addToCartRecommendations.module.scss'
import styled from 'styled-components'

const AddToCartRecommendations = ({
  recBlock,
  dataTestId,
  treatment,
  blockIndex,
}) => {
  const { PushNotification } = useContext(NotificationContext)

  const addToCartExperienceContext = useContext(AddToCartExperienceContext)
  recBlock.recommendations.forEach((x) => (x.isSelected = true))

  const [recommendations, setRecommendations] = useState([
    ...recBlock.recommendations,
  ])
  const [selectedRecommendationsInner, setSelectedRecommendationsInner] =
    useState(recBlock.recommendations.map((x) => x.skuBase))
  const selectedRecommendations = recommendations.filter((rec) =>
    selectedRecommendationsInner.some((x) => rec.skuBase === x),
  )
  const [hasAddedToCart, setHasAddedToCart] = useState(false)
  const [waiting, setWaiting] = useState(false)
  const [originalItemCount, _] = useState(
    addToCartExperienceContext?.items?.length,
  )
  const [previousItemCount, setPreviousItemCount] = useState(
    addToCartExperienceContext?.items?.length,
  )

  const setSelectedRecommendations = (newSelectedRecommendations) => {
    setSelectedRecommendationsInner(
      newSelectedRecommendations.map((x) => x.skuBase),
    )
  }

  useEffect(() => {
    if (addToCartExperienceContext.isSelectIndexOptionsDrawerOpen) {
      setWaiting(false)
    }
  }, [addToCartExperienceContext.isSelectIndexOptionsDrawerOpen])

  useEffect(() => {
    if (addToCartExperienceContext?.items?.length > originalItemCount) {
      setWaiting(false)
      setHasAddedToCart(true)
      PushNotification(
        `${selectedRecommendations.length} Item${selectedRecommendations.length > 1 ? 's have' : ' has'} been added to your cart.`,
        null,
        5000,
        null,
        { icon: 'check', intent: 'positive' },
      )
      setSelectedRecommendations([])
    }
  }, [addToCartExperienceContext?.items?.length])

  const addAllRecommendationsToCart = () => {
    setWaiting(true)
    addToCartExperienceContext.handleAddRecommendationsToCart([
      ...selectedRecommendations,
    ])
    // setHasAddedToCart(true)
  }

  const handleOnCheckboxChange = (isChecked, rec) => {
    console.info('Interacted with ATC Recommendations')

    if (isChecked) {
      setSelectedRecommendations([...selectedRecommendations, rec])
    } else {
      setSelectedRecommendations(
        selectedRecommendations.filter(
          (x) =>
            x.publicSkuBaseDetails.skuBaseNumber !==
            rec.publicSkuBaseDetails.skuBaseNumber,
        ),
      )
    }
  }

  const calculateTotal = () => {
    if (!selectedRecommendations?.length) {
      return 0
    }

    const totalPrice = selectedRecommendations
      .map((x) => x.publicSkuBaseDetails.pricing.salePrice)
      .reduce((total, x) => x + total)
    return totalPrice
  }

  const Recommendation = ({
    rec,
    index,
    blockIndex,
    dataTestId,
    treatment,
  }) => {
    return (
      <div
        className={styles.rec}
        data-testid={`${dataTestId}_vertical_${blockIndex}_rec_${index}`}
        key={`vertical_rec_key_${index}`}
      >
        <RecommendationCard
          recBlock={recBlock}
          rec={rec}
          index={index}
          dataTestId={dataTestId}
          treatment={treatment}
          horizontal
        />
      </div>
    )
  }

  const totalPrice = calculateTotal()

  console.info('Showing ATC Recommendations')
  return (
    <>
      <div
        data-testid={`${dataTestId}_addToCartRecommendations`}
        className={styles.recWrapper}
      >
        <div className={styles.addToCartRecommendationsCheckboxes}>
          <CheckBoxGroup
            brand={'secondarypalette_purple'}
            noTransition={true}
            isVertical={true}
            options={recommendations?.map((rec, index) => {
              return {
                label: (
                  <Recommendation
                    rec={rec}
                    index={index}
                    blockIndex={blockIndex}
                    dataTestId={dataTestId}
                    treatment={treatment}
                  />
                ),
                checked: selectedRecommendations.some(
                  (x) =>
                    x.publicSkuBaseDetails.skuBaseNumber ===
                    rec.publicSkuBaseDetails.skuBaseNumber,
                ),
                segmentEvent: null,
                onChange: (x) => {
                  handleOnCheckboxChange(x, rec)
                },
              }
            })}
          />
        </div>
        <Divider />
        {hasAddedToCart ? (
          <Typography>{`Item${addToCartExperienceContext?.items?.length - originalItemCount > 1 ? 's have' : ' has'} been added to your cart`}</Typography>
        ) : (
          false
        )}
        <div className={styles.addRecommendationsToCartButtonContainer}>
          <Button
            onClick={() => addAllRecommendationsToCart()}
            isWaiting={waiting}
            segmentEvent={createClickedSegmentEvent(
              'AddToCartDrawerRecommendationsAddToCart',
            )}
            size={'small'}
            intent={'positive'}
            invert={totalPrice}
            disabled={!totalPrice}
            data-testid={`${dataTestId}_add_to_cart_button`}
            text={`Add ${selectedRecommendations.length} item${selectedRecommendations.length > 1 ? 's' : ''} To Cart | $${totalPrice.toFixed(2)}`}
            fill
          />
        </div>
      </div>
    </>
  )
}

export default AddToCartRecommendations
