import React, { useEffect, useState } from 'react'

import { useUserStore } from 'Stores/UserStore/UserStore'
import { useVehicleStore } from 'Stores/VehicleStore'
import Script from 'next/script'

const MisoPageView = ({ misoApiUrl }) => {
  const userVin = useUserStore((x) => x.context.userVin)
  const isBot = useUserStore((x) => x.context.isBot)
  const userStateHydrated = useUserStore((x) => x.context.hydrated)
  const getGuid = useUserStore((x) => x.context.getGuid)
  const isCustomerInternal = useUserStore((x) => x.context.isCustomerInternal)

  const hydrated = useVehicleStore((x) => x.context.hydrated)
  const selectedCustomerProject = useVehicleStore(
    (x) => x.context.selectedCustomerProject,
  )

  const [renderMisoPageViewScript, setRenderMisoPageViewScript] =
    useState(false)

  useEffect(() => {
    // we need to wait until the user context has been updated with data (hydrated)
    // before allowing the script to render

    const shouldRender = userStateHydrated && !isBot() && hydrated

    if (shouldRender) {
      setRenderMisoPageViewScript(true)
    }
  }, [userStateHydrated, hydrated])

  if (!renderMisoPageViewScript) {
    return false
  }

  if (!misoApiUrl) {
    return false
  }

  if (isCustomerInternal) {
    return false
  }

  const misoPageViewScript = `window.fireMisoPageView = function (data) {
            data.time = '${new Date().toISOString()}'
            const userVin = '${userVin()}'
            const webUserGuid = '${getGuid()}'

            var interactionData = {}
            if (webUserGuid && !userVin) {
                interactionData = { 'data': [{ 'user_id': webUserGuid, 'timestamp': data.time, 'context': {} }] }
            } else if (!webUserGuid && userVin) {
                interactionData = { 'data': [{ 'anonymous_id': userVin, 'timestamp': data.time, 'context': {} }] }
            } else if (webUserGuid && userVin) {
                interactionData = { 'data': [{ 'user_id': webUserGuid, 'anonymous_id': userVin, 'timestamp': data.time, 'context': {} }] }
            }
            if (${selectedCustomerProject && selectedCustomerProject.raceTypeId}) {
                interactionData.data[0].context =
                {
                    'custom_context':
                    {
                        'raceTypeId': '${selectedCustomerProject.raceTypeId}'
                    }
                }
            }
            else if (${
              selectedCustomerProject &&
              selectedCustomerProject.year &&
              selectedCustomerProject.makeId &&
              selectedCustomerProject.modelId
            }) {
                interactionData.data[0].context =
                {
                    'custom_context':
                    {
                        'yearId': '${selectedCustomerProject.year}',
                        'makeId': '${selectedCustomerProject.makeId}',
                        'modelId': '${selectedCustomerProject.modelId}'
                    }
                }
            }

            if (data.provider) {
                interactionData.data[0].context = {
                    ...interactionData.data[0].context
                }

                interactionData.data[0].context.custom_context = {
                    ...interactionData.data[0].context.custom_context,
                    provider: data.provider
                }
            }

            if (data.miso_categories) {
                interactionData.data[0].context = {
                    ...interactionData.data[0].context
                }

                interactionData.data[0].context.custom_context = {
                    ...interactionData.data[0].context.custom_context,
                    miso_categories: data.miso_categories
                }
            }

            try{
                if(interactionData.data[0].context){
                    interactionData.data[0].context.page = {
                        'url': '${window.location.href}',
                        'title': '${document.title}'
                    }
                }
            }catch(e){
                console.error(e)
            }


            switch (data.ptype) {
                case 'article':
                    //this is supposed to be 'product_*' cause miso
                    interactionData.data[0].type = 'product_detail_page_view';
                    interactionData.data[0].product_ids = [data.article_id];
                    interactionData.data[0].product_group_ids = []
                    onExitInterActionDataToSend.push(interactionData.data[0]);
                    return
                    break;
                case 'search':
                    interactionData.data[0].type = 'search';
                    interactionData.data[0].search = {
                        'keywords': data.search_term
                    }
                    break;
                case 'category':
                    interactionData.data[0].type = 'category_page_view';
                    interactionData.data[0].category = [data.cat];
                    break;
                default:
                    break;
            }
            pushMisoInteractionData(interactionData);
        }`

  return (
    <Script
      id={'miso-pv'}
      data-testid={'miso-pv'}
      strategy={'afterInteractive'}
      enableOnlyOnNextFrontEnd={true}
    >
      {misoPageViewScript}
    </Script>
  )
}

export default MisoPageView
