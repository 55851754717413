import React, { useEffect, useRef, useState } from 'react'

import Modal from 'Components/Modal/ModalWithPortal.js'

import Button from 'Molecules/Button'

import Form from 'Atoms/Form'

import { createClickedSegmentEvent } from '../../Utilities/Instrumentation/Impressions/impressionSegmentEvents'
import styles from './ConfirmationDialog.module.scss'

export const AlertDialog = ({
  isOpen,
  children,
  onConfirmClick,
  closeSelfFunc,
  buttonText = 'Ok',
  buttonProps = {
    intent: 'positive',
  },
  showCancelButton = false,
  parentManagedVisibility = false,
}) => {
  const modal = useRef(null)

  useEffect(() => {
    if (isOpen) modal.current.open()
    if (!isOpen) modal.current.close()
  }, [isOpen])

  async function onSecondClick() {
    setWaiting(true)
    await onConfirmClick()
    setWaiting(false)
    if (!parentManagedVisibility) {
      dismiss()
    }
  }

  function dismiss() {
    closeSelfFunc(false)
  }

  const [waiting, setWaiting] = useState(false)

  return (
    <>
      <Modal
        ref={modal}
        outsideClick={!waiting}
        closeButton={false}
        customOnClose={() => dismiss()}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault()
            onSecondClick()
          }}
          className={styles.modalWrap}
        >
          {children}
          <div className={styles.modalButtons}>
            {showCancelButton && (
              <Button
                data-testid={'cancelButton'}
                size={'small'}
                intent={'subdued'}
                text={'Cancel'}
                fill
                disabled={waiting}
                onClick={dismiss}
                type={'button'}
                segmentEvent={createClickedSegmentEvent(
                  'ConfirmationDialogDismiss',
                )}
              />
            )}
            <Button
              data-testid={'confirmButton'}
              size={buttonProps.size ?? 'small'}
              intent={buttonProps.intent ?? 'negative'}
              text={buttonProps.buttonText ?? buttonText}
              icon={buttonProps.icon ?? 'check'}
              allowWrap={false}
              fill
              isWaiting={waiting}
              type={'submit'}
              segmentEvent={createClickedSegmentEvent(
                'ConfirmationDialogConfirm',
              )}
            />
          </div>
        </form>
      </Modal>
    </>
  )
}

export const PromptDialog = ({
  isOpen,
  children,
  onConfirmClick,
  closeSelfFunc,
  value,
  setValue,
  label,
  inputType,
  buttonText = 'Confirm',
  buttonProps = {
    intent: 'positive',
  },
  validationErrorMessages,
  parentManagedVisibility = false,
}) => {
  return (
    <AlertDialog
      isOpen={isOpen}
      onConfirmClick={onConfirmClick}
      closeSelfFunc={closeSelfFunc}
      buttonText={buttonText}
      buttonProps={buttonProps}
      showCancelButton={true}
      parentManagedVisibility={parentManagedVisibility}
    >
      {children}
      <Form.Textbox
        fill
        label={label}
        // TODO add validation
        // intent={error1 ? 'negative' : null}
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
        }}
        type={inputType}
        validationErrorMessages={validationErrorMessages}
      />
    </AlertDialog>
  )
}

const ConfirmationDialog = ({
  isOpen,
  children,
  onConfirmClick,
  eventOnConfirmClick,
  closeSelfFunc,
  buttonText = 'Confirm',
  buttonProps = {},
  parentManagedVisibility = false,
}) => {
  return (
    <AlertDialog
      isOpen={isOpen}
      onConfirmClick={onConfirmClick}
      closeSelfFunc={closeSelfFunc}
      buttonText={buttonText}
      buttonProps={buttonProps}
      showCancelButton={true}
      parentManagedVisibility={parentManagedVisibility}
    >
      {children}
    </AlertDialog>
  )
}

export default ConfirmationDialog
