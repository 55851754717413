import React from 'react'

import useSaveClickParameterEffect from './Hooks/useSaveClickParameterEffect'

export default function useTikTokClickParameter() {
  // lead_id is included here because it is mapped in Segment
  useSaveClickParameterEffect({
    expirationDays: 365 + 31, // 13 months
    storageMethod: 'cookie',
    urlParameters: {
      cjevent: {
        required: true,
        storageKey: 'cje',
      },
    },
  })
}
