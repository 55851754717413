import useSegment from '../useSegment'

const useSegmentLeadCaptureEvent = () => {
  const { sendSegmentTrackEventWithName } = useSegment()

  const SendEventInternal = (leadType, actionType, location) => {
    try {
      const event = BuildLeadCaptureEvent(leadType, actionType, location)
      if (!event) {
        throw new Error(
          "Lead Capture event was falsy, probably didn't build it correctly!",
        )
      }
      sendSegmentTrackEventWithName(event.event, event.properties)
    } catch (err) {
      console.error(
        `Failed to send lead capture event ${leadType}, ${actionType}, ${location}`,
        err,
      )
    }
  }

  const sendEmailEvent = (actionType, location) => {
    SendEventInternal(LEADTYPES[0], actionType, location)
  }

  const sendSmsEvent = (actionType, location) => {
    SendEventInternal(LEADTYPES[1], actionType, location)
  }

  const sendSmsAndEmailEvent = (actionType, location) => {
    sendEmailEvent(actionType, location)
    sendSmsEvent(actionType, location)
  }

  const sendLoyaltyEvent = (actionType, location) => {
    SendEventInternal(LEADTYPES[2], actionType, location)
  }

  return {
    sendEmailEvent,
    sendSmsEvent,
    sendSmsAndEmailEvent,
    sendLoyaltyEvent,
  }
}

const LEADTYPES = ['Email', 'Sms', 'Loyalty']
const ACTIONTYPES = [
  'Impression',
  'Started',
  'Submitted',
  'Completed',
  'Cancelled',
]

const BuildLeadCaptureEvent = (leadType, actionType, location) => {
  try {
    if (!LEADTYPES.includes(leadType)) {
      throw new Error(`${leadType} is not a supported lead type`)
    }
    if (!ACTIONTYPES.includes(actionType)) {
      throw new Error(`${actionType} is not a supported action type`)
    }
    if (!location?.length) {
      throw new Error(`location is required`)
    }

    return {
      event: `Lead Capture`,
      properties: {
        lead_type: leadType,
        action_type: actionType,
        element: location,
      },
    }
  } catch (err) {
    console.error('Failed to build lead capture event', err)
    return null
  }
}

export default useSegmentLeadCaptureEvent
