import React, { Fragment, useContext, useState } from 'react'

import ClassNames from 'classnames'

import Event from 'Utilities/Event'
import LayerColorFinder from 'Utilities/LayerColorFinder'

import PanelContext from 'Contexts/PanelContext'

import useSegment from 'Hooks/useSegment/useSegment'

import ListItem from '../ListItem'
import baseStyles from '../ListItem/ListItem.module.scss'
import styles from './ListItemLink.module.scss'

const ListItemLink = ({
  title,
  href,
  children,
  className,
  onClick,
  onHover,
  border,
  hover,
  refElement,
  event,
  segmentEvent,
  dataTesting,
  ...props
}) => {
  const classnames = ClassNames(className, baseStyles.listItem, styles.link)
  const [isLoading, setIsLoading] = useState(false)
  const { sendSegmentTrackEvent } = useSegment()

  const panelContext = useContext(PanelContext)
  const inverseTheme =
    panelContext.layer !== undefined ? panelContext.inverseTheme : false
  const hoverLayer =
    panelContext.layer !== undefined ? panelContext.layer + 1 : 0

  const listItemHover = LayerColorFinder(hoverLayer, inverseTheme)
  const spinnerColor = LayerColorFinder(hoverLayer, !inverseTheme)

  return (
    <ListItem
      title={title}
      component={'a'}
      colorStyles={{
        '--hoverColor': listItemHover,
        '--spinnerColor': spinnerColor,
      }}
      href={href}
      className={classnames}
      onClick={(e) => {
        setIsLoading(true)
        onClickWrapper(
          e,
          event,
          onClick,
          href,
          segmentEvent,
          sendSegmentTrackEvent,
        )
      }}
      onHover={onHover}
      border={border}
      hover={hover}
      refElement={refElement}
      dataTesting={dataTesting}
      {...props}
    >
      {children}
    </ListItem>
  )
}

const onClickWrapper = (
  e,
  event,
  onClick,
  href,
  segmentEvent,
  sendSegmentTrackEvent,
) => {
  if (event) {
    const linkEvent = {
      category: event.category,
      action: `${event.action} (Link)`,
      label: event.label,
    }

    Event(linkEvent)()
  }

  if (segmentEvent) {
    sendSegmentTrackEvent(segmentEvent)
  }

  if (onClick) {
    onClick(e)
  }

  if (href) {
    window.location.href = href
  }
}
export default ListItemLink
