import React, { useState } from 'react'
import { useInView } from 'react-intersection-observer'

import useSegment from 'Hooks/useSegment'

import {
  createClickedSegmentEvent,
  createViewedSegmentEvent,
} from '../../Utilities/Instrumentation/Impressions/impressionSegmentEvents'

const Frame = ({
  children,
  sectionName,
  additionalViewedFuncs = [],
  additionalClickedFuncs = [],
  additionalProps,
  innerClassName,
  outerClassName,
  audience,
}) => {
  return (
    <ClickTrap
      sectionName={sectionName}
      additionalFuncs={additionalClickedFuncs}
      innerClassName={innerClassName}
      outerClassName={outerClassName}
      audience={audience}
      additionalProps={additionalProps}
    >
      <ViewedEvent
        sectionName={sectionName}
        additionalFuncs={additionalViewedFuncs}
        audience={audience}
        additionalProps={additionalProps}
      />
      {children}
    </ClickTrap>
  )
}
const ViewedEvent = ({
  sectionName,
  additionalFuncs = [],
  audience,
  additionalProps,
}) => {
  const [eventSent, setEventSent] = useState(false)
  const { sendCustomSegmentTrackEvent } = useSegment()
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
    rootMargin: '0px 0px',
  })

  if (!sectionName) {
    console.error('Impression component needs the section name specified!')
    return false
  }

  if (inView && !eventSent) {
    console.log(`Viewed: ${sectionName}`)
    sendCustomSegmentTrackEvent(
      createViewedSegmentEvent(sectionName, audience, additionalProps),
    )
    for (let i = 0, ilen = additionalFuncs.length; i < ilen; i++) {
      additionalFuncs[i]()
    }
    setEventSent(true)
  }

  return <span ref={ref}></span>
}

const ClickTrap = ({
  sectionName,
  children,
  additionalFuncs = [],
  innerClassName,
  outerClassName,
  audience,
  additionalProps,
}) => {
  const [eventSent, setEventSent] = useState(false)
  const { sendCustomSegmentTrackEvent } = useSegment()

  if (!sectionName) {
    console.error('Impression component needs the section name specified!')
    return false
  }

  const handleOnClick = () => {
    if (!eventSent) {
      console.log(`Clicked: ${sectionName}`)
      sendCustomSegmentTrackEvent(
        createClickedSegmentEvent(sectionName, audience, additionalProps),
      )
      for (let i = 0, ilen = additionalFuncs.length; i < ilen; i++) {
        additionalFuncs[i]()
      }
      setEventSent(true)
    }
  }

  return (
    <div
      style={{ pointerEvents: 'none' }}
      className={outerClassName}
      onClick={handleOnClick}
    >
      <div className={innerClassName} style={{ pointerEvents: 'auto' }}>
        {children}
      </div>
    </div>
  )
}

export default Frame

export { ViewedEvent, ClickTrap }
