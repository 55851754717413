import React from 'react'

import { createViewedSegmentEvent } from 'Utilities/Instrumentation/Impressions/impressionSegmentEvents'

import useSegment from 'Hooks/useSegment'

import EngineDropdown from './EngineDropdown/EngineDropdown'

const AddEngineView = () => {
  const { sendSegmentTrackEvent } = useSegment()

  const viewEvent = createViewedSegmentEvent('Add Engine View')
  sendSegmentTrackEvent(viewEvent)

  return (
    <EngineDropdown
      buttonText={'Add Engine'}
      buttonIcon={'v8-engine'}
      segmentEventText={'Add Engine'}
    />
  )
}

export default AddEngineView
