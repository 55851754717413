'use client'

import React, { useState } from 'react'

import { LoyaltyStoreProvider } from 'Stores/LoyaltyStore/LoyaltyStore'
import { NavigationProvider } from 'Stores/NavigationStore'
import { ThemeProvider } from 'Stores/ThemeStore'
import { UserProvider } from 'Stores/UserStore/UserStore'
import { VehicleProvider } from 'Stores/VehicleStore'
import Geolocation from 'UtilityComponents/GeoLocation'

import CookieReader from 'Utilities/CookieUtilties/CookieReader'
import setLocalHostCookies from 'Utilities/CookieUtilties/EnviromentCookieUtilities'
import SetCookie from 'Utilities/CookieUtilties/SetCookie'

import { AddToCartExperienceProvider } from 'Contexts/AddToCartExperienceContext/AddToCartExperienceContext'
import { ListStateProvider } from 'Contexts/ListContext/ListContext'
import { NotificationProvider } from 'Contexts/NotificationContext/NotificationContext'
import { ServicesProvider } from 'Contexts/ServicesContext/ServicesContext'
import { TrackingProvider } from 'Contexts/TrackingContext/TrackingContext'
import { UserSpecificProductDataProvider } from 'Contexts/UserSpecificProductDataStore/UserSpecificProductDataStore'

import useWindow from 'Hooks/useWindow'

import { NotificationContainer } from 'Atoms/Notification'

import { GlobalStateStoreProvider } from '../Stores/GlobalStateStore/GlobalStateStore'

const ExtraContextsThatNewHeaderNeed = ({
  children,
  headerRedesignEnabled,
  services,
  updateUserGuid,
  userGuid,
}) => {
  return headerRedesignEnabled ? (
    <ListStateProvider
      userGuid={userGuid}
      updateUserGuid={updateUserGuid}
      services={services}
    >
      <AddToCartExperienceProvider services={services}>
        <NotificationContainer />
        <UserSpecificProductDataProvider>
          {children}
        </UserSpecificProductDataProvider>
      </AddToCartExperienceProvider>
    </ListStateProvider>
  ) : (
    <>{children}</>
  )
}

// if you're considering doing this (don't) https://medium.com/nerd-for-tech/how-to-combine-context-providers-for-cleaner-react-code-9ed24f20225e
// read this instead ... https://medium.com/@webseanhickey/the-evolution-of-a-software-engineer-db854689243
// it's good to know you can/could.. and maybe when you would.
// complexity === hard to change/understand, hard to change/understand === technical debt
// also, why combine the contexts- they should be decoupled from one another
const LayoutProvider = ({
  children,
  headerRedesignEnabled,
  services,
  hardCodedPropsForTesting,
  stateObject,
  freeShippingThreshold,
}) => {
  // get rid of this someday once new header is always on
  const [userGuid, setUserGuid] = useState(null)

  useWindow(() => {
    const isLocalhost = Boolean(
      window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(
          /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
        ),
    )

    if (isLocalhost) setLocalHostCookies()

    setUserGuid(CookieReader('WebUserGuid'))
  })

  const updateUserGuid = (userGuid) => {
    if (!userGuid) {
      return
    }

    SetCookie('WebUserGuid', userGuid, 365)
    setUserGuid(userGuid)
  }

  return (
    // <SWRConfig value={{ provider: localStorageProvider }}>
    <GlobalStateStoreProvider>
      <ThemeProvider>
        <ServicesProvider services={services}>
          <NotificationProvider>
            <UserProvider
              userGuid={userGuid}
              updateUserGuid={updateUserGuid}
              stateObject={stateObject}
              services={services}
              freeShippingThreshold={freeShippingThreshold}
            >
              <LoyaltyStoreProvider>
                <Geolocation />
                <NavigationProvider>
                  <TrackingProvider>
                    <VehicleProvider
                      userGuid={userGuid}
                      updateUserGuid={updateUserGuid}
                      services={services}
                      hardCodedPropsForTesting={hardCodedPropsForTesting}
                      reloadCurrentLocation={
                        stateObject
                          ? stateObject.reloadPageOnVehicleSelection
                          : false
                      }
                      selectedFacetData={stateObject?.selectedFacetData}
                    >
                      <ExtraContextsThatNewHeaderNeed
                        headerRedesignEnabled={headerRedesignEnabled}
                        services={services}
                        hardCodedPropsForTesting={hardCodedPropsForTesting}
                        stateObject={stateObject}
                        userGuid={userGuid}
                        updateUserGuid={updateUserGuid}
                      >
                        {children}
                      </ExtraContextsThatNewHeaderNeed>
                    </VehicleProvider>
                  </TrackingProvider>
                </NavigationProvider>
              </LoyaltyStoreProvider>
            </UserProvider>
          </NotificationProvider>
        </ServicesProvider>
      </ThemeProvider>
    </GlobalStateStoreProvider>
    /* </SWRConfig> */
  )
}

export default LayoutProvider
