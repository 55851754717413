import React from 'react'

import ClassNames from 'classnames'
import Image from 'next/image'

import LayerColorFinder from 'Utilities/LayerColorFinder'

import TextLink from 'Atoms/TextLink'
import Typography from 'Atoms/Typography'

import styles from './LandingPageImageLink.module.scss'
import styled from 'styled-components'

const LandingPageImageLink = ({
  landingPageDetails,
  isBrandImage,
  isRaceTypeImage,
  classNames,
  border,
  setLoading,
  index,
  segmentEvent,
}) => {
  const backgroundColor = LayerColorFinder(0)
  const borderColor = LayerColorFinder(1)

  let width = 88
  let height = 88

  if (isBrandImage) {
    width = 149
    height = 55
  }

  if (isRaceTypeImage) {
    width = 384
    height = 132
  }

  return (
    <StyledOption
      className={ClassNames(styles.option, classNames)}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      border={border}
      data-testid={`landing_page_image_link_${landingPageDetails.id}`}
    >
      <TextLink
        href={landingPageDetails.facetOptionLink.url}
        className={styles.link}
        nofollow={landingPageDetails.facetOptionLink.nofollow}
        onClick={() => setLoading()}
        data-testid={`text_link_${landingPageDetails.id}`}
        segmentEvent={segmentEvent}
      >
        {landingPageDetails.thumbnailImageUrl ? (
          <div className={styles.optionImage}>
            <Image
              src={landingPageDetails.thumbnailImageUrl}
              width={width}
              height={height}
              priority={index < 6}
              data-testid={`image_link_${landingPageDetails.id}`}
            />
          </div>
        ) : null}
        <Typography
          size={0.875}
          tone={'contrast'}
          className={styles.option_name}
        >
          {landingPageDetails.displayValue}
        </Typography>
      </TextLink>
    </StyledOption>
  )
}

const StyledOption = styled.div`
  background-color: ${(props) => props.backgroundColor};
  border-color: ${(props) => props.borderColor};
  border: ${(props) => props.border ?? 'none'};
  &:hover {
    background-color: ${(props) => props.borderColor};
  }
`

export default LandingPageImageLink
