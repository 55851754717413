import Script from 'next/script'

import useFeatureFlag from '../../../Hooks/useFeatureFlag/useFeatureFlag'

const NoibuScript = () => {
  const featureFlag = useFeatureFlag('Frontend_Noibu')

  if (!featureFlag) {
    return null
  }
  return (
    <Script
      id="noibu-script"
      data-testid="noibu-script"
      src={`https://cdn.noibu.com/collect-core.js`}
      strategy="afterInteractive"
    />
  )
}

export default NoibuScript
