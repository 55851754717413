function SetCookie(cname, cvalue, exdays = 365) {
  if (typeof document === 'undefined') {
    return
  }
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  document.cookie = `${cname}=${cvalue}; expires=${d.toUTCString()}; path=/; secure`
}

export default SetCookie
