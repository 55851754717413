import GraphQLRequest from 'Utilities/GraphQLClient/GraphQLClient'
import { StorageAccessor } from 'Utilities/LocalStorageUtility/LocalStorageUtility'

export const getUserAudiences = async (customerUrl, webUserGuid = null) => {
  const cacheKey = `userAudiences__${webUserGuid || 'default'}`
  const cacheTTL = 360 // 6 hours

  const cachedData = StorageAccessor.localStorage.getObj(cacheKey)
  if (cachedData) {
    return { data: cachedData, cacheBusted: false }
  }

  const variables = { webUserGuid }
  const query = `query GetUserAudiences($webUserGuid: String) {
    userAudiencesV2(webUserGuid: $webUserGuid) {
      audiences
      googleAudiences
    }
  }`

  try {
    const response = await GraphQLRequest(customerUrl, query, variables)

    StorageAccessor.localStorage.setObj(
      cacheKey,
      response.userAudiencesV2,
      cacheTTL,
    )

    return { data: response.userAudiencesV2, cacheBusted: true }
  } catch (error) {
    console.error('Error fetching user audiences:', error)
    return { data: { audiences: [], googleAudiences: [] }, cacheBusted: true }
  }
}
