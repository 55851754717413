import React, { useContext, useEffect, useState } from 'react'

import ClassNames from 'classnames'

import Button from 'Molecules/Button'
import Drawer from 'Molecules/NewDrawer/Drawer'

import Panel from 'Atoms/Panel'
import Typography from 'Atoms/Typography'

import { AddToCartExperienceContext } from '../../Contexts/AddToCartExperienceContext/AddToCartExperienceContext'
import KitItems from './items/kitItems'
import styles from './kitDetailsDrawer.module.scss'

const KitDetailsDrawer = ({}) => {
  const addToCartExperienceContext = useContext(AddToCartExperienceContext)

  const [waiting, setWaiting] = useState(false)

  useEffect(() => {
    if (
      !addToCartExperienceContext.isKitDetailsDrawerOpen ||
      addToCartExperienceContext.isIndexOptionErrorState
    )
      setWaiting(false)
  })

  const hasKitComponents =
    addToCartExperienceContext.kitComponents &&
    addToCartExperienceContext.kitComponents.length > 0
  const areAllKitComponentsSelected =
    hasKitComponents &&
    addToCartExperienceContext.kitComponents.every(
      (component) =>
        component.indexOptions.length &&
        component.indexOptions.every(
          (option) =>
            option.indexOptionValues.length &&
            option.indexOptionValues.some((value) => value.isSelected),
        ),
    )

  const areAllKitComponentsPurchaseable =
    hasKitComponents &&
    addToCartExperienceContext.kitComponents.every(
      (component) =>
        component.indexOptions.length &&
        component.indexOptions.every(
          (option) =>
            option.indexOptionValues.length &&
            option.indexOptionValues.some((value) => value.isSelected) &&
            option.isPurchaseable,
        ),
    )

  let item
  if (areAllKitComponentsSelected) {
    item = {
      skuVariantNumber: addToCartExperienceContext.skuVariantNumber,
      skuBaseNumber: addToCartExperienceContext.skuBaseNumber,
      quantityRequested: parseInt(addToCartExperienceContext.quantityRequested),
      kitComponents: addToCartExperienceContext.kitComponents.map(
        (component) => {
          return {
            skuVariantNumber: component.indexOptions[0].skuVariantNumber,
            quantityRequested: parseInt(component.purchaseQuantity),
          }
        },
      ),
      title: addToCartExperienceContext.title,
      priceDecimal:
        addToCartExperienceContext?.product?.priceDecimal ||
        addToCartExperienceContext.price,
      price:
        addToCartExperienceContext?.product?.priceDecimal ||
        addToCartExperienceContext.price,
      images: addToCartExperienceContext.images,
      brand: addToCartExperienceContext.brand,
      segmentCategory: addToCartExperienceContext.segmentCategory,
      url: addToCartExperienceContext.url,
      availability:
        addToCartExperienceContext.availability == 'In Stock'
          ? 'InStock'
          : addToCartExperienceContext.availability,
      rating: addToCartExperienceContext.rating,
      reviewCount: addToCartExperienceContext.reviewCount,
      superMarketAffinity: addToCartExperienceContext.superMarketAffinity,
    }
  }

  const continueToCartEvent =
    addToCartExperienceContext.isKitDetailsDrawerForList
      ? {
          event: 'Clicked',
          properties: {
            element: 'Kit Details Drawer: Add to a List',
          },
        }
      : {
          event: 'Clicked',
          properties: {
            element: 'Kit Details Drawer: Add to Cart',
          },
        }

  const footer = (
    <Button
      intent={'positive'}
      text={
        addToCartExperienceContext.isKitDetailsDrawerForList
          ? 'Add to a List'
          : 'Add to Cart'
      }
      size={'small'}
      isWaiting={waiting}
      segmentEvent={continueToCartEvent}
      onClick={(e) => {
        e.preventDefault()
        setWaiting(true)
        if (addToCartExperienceContext.isKitDetailsDrawerForList) {
          addToCartExperienceContext.showListDrawer([item])
        } else {
          console.log('item', item)
          addToCartExperienceContext.addToCart([item])
        }
      }}
      data-testid={
        addToCartExperienceContext.isKitDetailsDrawerForList
          ? 'kit-continue-to-list-button'
          : 'kit-continue-to-cart-button'
      }
      disabled={!areAllKitComponentsPurchaseable}
      fill
    />
  )

  return (
    <Drawer
      portalId={'kitDetailsDrawer'}
      location={'right'}
      isOpen={addToCartExperienceContext.isKitDetailsDrawerOpen}
      onClose={addToCartExperienceContext.hideKitDetailsDrawer}
      title={'Build your Kit'}
      footer={footer}
    >
      <div
        data-testid={`kit_drawer_${addToCartExperienceContext.isKitDetailsDrawerOpen ? 'open' : 'closed'}`}
      ></div>

      <Typography size={0.875}>
        You're almost there! This kit has an option or two for you to select.
      </Typography>
      <KitItems
        kitItems={addToCartExperienceContext.kitComponents}
        selectKitComponentIndexOption={
          addToCartExperienceContext.selectKitComponentIndexOption
        }
      />
    </Drawer>
  )
}

export default KitDetailsDrawer
