import { useEffect } from 'react'

import useSWR from 'swr'

import {
  IdentifyLogRocket,
  InitLogRocket,
} from '../../Contexts/UserContext/InitLogRocket'
import { UpdateUserProfile } from '../../Contexts/UserContext/UpdateUserProfile'
import useFeatureFlag from '../../Hooks/useFeatureFlag'
import useGlobalPrivacyControl from '../../Hooks/useGlobalPrivacyControl'
import useLogRocket from '../../Hooks/useLogRocket'
import { useUserAudiences } from '../../Hooks/useUserAudiences'
import SetCookie from '../../Utilities/CookieUtilties/SetCookie'
import {
  GetUserGuid,
  IsUserBot,
} from '../../Utilities/CookieUtilties/UserCookieUtilities'
import { StorageAccessor } from '../../Utilities/LocalStorageUtility'
import { useUserStore } from './UserStore'

export default function UserStoreWiring({ set }) {
  const setPromoCodeFromUrl = useUserStore((x) => x.setPromoCodeFromUrl)
  const showFreeShippingToast = useUserStore((x) => x.showFreeShippingToast)
  const userVin = useUserStore((x) => x.context.userVin)
  const logRocketInitialized = useUserStore(
    (x) => x.context.logRocketInitialized,
  )
  const hydrated = useUserStore((x) => x.context.hydrated)
  const redirectUrl = useUserStore((x) => x.context.redirectUrl)
  const emailAddress = useUserStore((x) => x.context.emailAddress)
  const sopCustomerId = useUserStore((x) => x.context.sopCustomerId)
  const logRocketIdentified = useUserStore((x) => x.context.logRocketIdentified)
  const setLogRocketIdentified = useUserStore((x) => x.setLogRocketIdentified)
  const setGoogleAudiences = useUserStore((x) => x.setGoogleAudiences)
  const setLogRocketInitialized = useUserStore((x) => x.setLogRocketInitialized)
  const { isGpcEnabled } = useGlobalPrivacyControl()
  const { logRocketTrack } = useLogRocket()
  const customerGraphqlEndpoint = '/graphql/customer'
  const lrEnabled = useFeatureFlag('Is_Log_Rocket_Enabled')
  const stateObject = useUserStore((x) => x.context.stateObject)
  const userGuidFromUserStore = useUserStore((x) => x.context.userGuid)
  const updateUserGuid = useUserStore((x) => x.context.updateUserGuid)

  const { userAudiences, audiencesCacheBusted } = useUserAudiences(
    customerGraphqlEndpoint,
    GetUserGuid(),
  )

  const { audiences, googleAudiences } = userAudiences ?? {}
  const { data: userProfile } = useSWR(
    IsUserBot()
      ? null
      : ['userProfile', customerGraphqlEndpoint, GetUserGuid(), false],
    ([userProfile, endpoint, userGuid, x]) =>
      UpdateUserProfile(userProfile, endpoint, userGuid, x),
  )

  const gtagCacheKey = 'sentGtagSegmentAudiences'

  useEffect(() => {
    if (hydrated && GetUserGuid() !== userGuidFromUserStore) {
      if (updateUserGuid) {
        updateUserGuid(GetUserGuid())
      }
    }
  })

  useEffect(() => {
    if (audiences && audiencesCacheBusted)
      SetCookie('segment_audiences', JSON.stringify(audiences), 365)
    setPromoCodeFromUrl()
    showFreeShippingToast()
  }, [audiences])

  useEffect(() => {
    if (userProfile && !hydrated) {
      set((state) => {
        return {
          ...state,
          context: {
            ...state.context,
            ...userProfile,
            hydrated: true,
          },
        }
      })
    }
  }, [userProfile])

  useEffect(() => {
    set((state) => {
      return {
        ...state,
        context: {
          ...state.context,
          userGuid: userGuidFromUserStore,
        },
      }
    })
  }, [userGuidFromUserStore])

  useEffect(() => {
    try {
      for (const ga of googleAudiences ?? []) {
        const sentEventsValues =
          StorageAccessor.sessionStorage.getObj(gtagCacheKey) ?? []
        if (!sentEventsValues.includes(ga)) {
          gtag('event', 'segment_google_audiences', {
            google_audience_name: `${ga}`,
          })
          sentEventsValues.push(ga)
          StorageAccessor.sessionStorage.setObj(gtagCacheKey, sentEventsValues)
        }
      }
    } catch (e) {
      console.error('unable to send google gtag event')
    }

    console.log('googleAudiences: ', googleAudiences)
  }, [googleAudiences])

  useEffect(() => {
    if (logRocketInitialized) {
      try {
        const messageQueue = JSON.parse(
          window.localStorage.getItem('errorStorage'),
        )

        if (messageQueue?.length) {
          console.log(' === Following errors from local storage trap')
          for (let i = 0, x = messageQueue.length; i < x; i++) {
            console.error(JSON.stringify(messageQueue[i]))
          }
        }

        window.localStorage.removeItem('errorStorage')
      } catch (err) {
        console.error('Error attempting to read from error trap', err)
      }
    }
  }, [logRocketInitialized])

  useEffect(() => {
    if (
      !isGpcEnabled &&
      hydrated &&
      !IsUserBot() &&
      lrEnabled &&
      logRocketInitialized === false
    ) {
      const nextEnvLogRocketId = process.env.NEXT_PUBLIC_LOGROCKET_APP_ID
      const logRocketAppId = nextEnvLogRocketId || stateObject?.logRocketAppId

      setLogRocketInitialized(null)

      InitLogRocket(logRocketAppId, stateObject?.releaseVersion).then(() => {
        setLogRocketInitialized(true)
      })

      if (userProfile?.isLoyaltyBetaUser) {
        logRocketTrack('Loyalty_BetaUser')
      }
    }

    if (logRocketInitialized && !logRocketIdentified && hydrated) {
      IdentifyLogRocket(GetUserGuid(), emailAddress, userVin(), sopCustomerId)

      setLogRocketIdentified()
    }
  }, [isGpcEnabled, hydrated, IsUserBot(), lrEnabled, logRocketInitialized])

  useEffect(() => {
    if (redirectUrl && typeof window !== 'undefined') {
      window.location = redirectUrl
    }
  }, [redirectUrl])
}
