const GetTimeAgoString = (dateString) => {
  try {
    const now = new Date()
    const date = new Date(dateString)
    isActuallyJustDateAdjustForTimeZone(date)
    if (date == 'Invalid Date') {
      throw `Timeago got an invalid date string ${dateString}`
    }
    const minutes = (date - now) / (1000 * 60)
    if (minutes >= -5 && minutes < 0) {
      return 'just now'
    }
    if (datesMatch(now, date)) {
      return 'today'
    }
    if (datesMatch(addDays(now, 1), date)) {
      return 'tomorrow'
    }
    if (datesMatch(addDays(now, -1), date)) {
      return 'yesterday'
    }
    const difference = date - now

    if (difference > 0) {
      for (let i = 2; i < 13; i++) {
        if (datesMatch(addDays(now, i), date)) {
          return `in ${i} days`
        }
      }
    } else {
      for (let i = -2; i > -13; i--) {
        if (datesMatch(addDays(now, i), date)) {
          return `${Math.abs(i)} days ago`
        }
      }
    }

    const numWeeks = Math.round(GetNumWeeks(Math.abs(difference)))
    if (numWeeks < 8) {
      return GetImpreciseStringFormat(difference, numWeeks, 'week')
    }
    const numMonths = Math.round(GetNumMonths(Math.abs(difference)))
    if (numMonths < 12) {
      return GetImpreciseStringFormat(difference, numMonths, 'month')
    }
    const years = Math.round(numMonths / 12)
    return GetImpreciseStringFormat(difference, years, 'year')
  } catch (err) {
    console.error('timeago failed', err)
    return null
  }
}

const GetImpreciseStringFormat = (difference, count, unit) => {
  return difference > 0
    ? `in about ${count} ${unit}${Pluralize(count)}`
    : `about ${count} ${unit}${Pluralize(count)} ago`
}

const Pluralize = (int) => {
  return Math.abs(int) == 1 ? '' : 's'
}

const GetNumWeeks = (difference) => {
  return difference / 24 / 3600 / 1000 / 7
}

const GetNumMonths = (difference) => {
  return difference / 24 / 3600 / 1000 / 30
}

const isActuallyJustDateAdjustForTimeZone = (date) => {
  if (date.getUTCHours() + date.getUTCMinutes() + date.getUTCSeconds() == 0) {
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
  }
}

const datesMatch = (date1, date2) => {
  return (
    date1.getDate() == date2.getDate() &&
    date1.getFullYear() == date2.getFullYear() &&
    date1.getMonth() == date2.getMonth()
  )
}

function addDays(date, days) {
  var result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

export default GetTimeAgoString
