const articleSegmentEventNames = {
  ARTICLE_VIEWED: 'Article Viewed',
  ARTICLE_CLICKED: 'Article Clicked',
  ARTICLE_LIST_VIEWED: 'Article List Viewed',
}

const createArticleViewedSegmentEvent = (article, index) => {
  return {
    event: articleSegmentEventNames.ARTICLE_VIEWED,
    properties: {
      title: article?.title,
      article_id: article?.id,
      url: article?.url,
      author: article?.authors,
      position: index ?? 0,
      url: article?.url,
      image_url: article?.articleThumbnailUrl,
    },
  }
}

const createArticleClickedSegmentEvent = (article, index) => {
  return {
    event: articleSegmentEventNames.ARTICLE_CLICKED,
    properties: {
      title: `${article?.title}`,
      article_id: `${article?.id}`,
      url: `${article?.url}`,
      author: `${article?.authors}`,
      position: index ?? 0,
      url: `${article?.url}`,
      image_url: `${article?.articleThumbnailUrl}`,
    },
  }
}

const createArticleListViewedSegmentEvent = (articles, articleListName) => {
  const articleEvents = (articles || []).map((article, i) => {
    return {
      title: `${article?.title}`,
      article_id: `${article?.id}`,
      url: `${article?.url}`,
      author: `${article?.authors}`,
      position: i,
      url: `${article?.url}`,
      image_url: `${article?.articleThumbnailUrl}`,
    }
  })
  return {
    event: articleSegmentEventNames.ARTICLE_LIST_VIEWED,
    properties: {
      list_id: articleListName ?? 'Article List (unspecified)',
      articles: articleEvents,
    },
  }
}

export {
  createArticleViewedSegmentEvent,
  createArticleListViewedSegmentEvent,
  createArticleClickedSegmentEvent,
}
