import React from 'react'

import { ThemeProvider, speedwayThemes } from 'Stores/ThemeStore'

import Gutters from 'Atoms/Gutters'
import Panel from 'Atoms/Panel'
import Typography from 'Atoms/Typography'

import styles from './ServerErrorPage.module.scss'

const ServerErrorPage = () => {
  return (
    <ThemeProvider value={speedwayThemes['Light']}>
      <Panel layer={0}>
        <Gutters className={styles.gutters}>
          <div className={styles.text}>
            <Typography as="h2" font={'bold'} size={2.5}>
              Well, That's Embarrassing
            </Typography>
            <br />
            <Typography size={1.5}>
              We’re sorry; the page you were looking for is temporarily
              unavailable. We’ve got our best mechanics fixing it right now!
            </Typography>
            <br />
            <Typography size={1.5}>
              Please try to refresh this page or contact Customer Service at
              800.979.0122 for immediate assistance.
            </Typography>
            <br />
            <Typography size={1.5}>Sorry for the inconvenience.</Typography>
          </div>
          <img
            className={styles.image}
            src={
              'https://content.speedwaymotors.com/OtherImages/crossed-wrenches.png'
            }
          />
        </Gutters>
      </Panel>
    </ThemeProvider>
  )
}

export default ServerErrorPage
