import React, { useContext, useEffect, useState } from 'react'

import ClassNames from 'classnames'

import { AddToCartExperienceContext } from 'Contexts/AddToCartExperienceContext/AddToCartExperienceContext'

import Button from 'Molecules/Button'
import Drawer from 'Molecules/NewDrawer/Drawer'

import Panel from 'Atoms/Panel'
import Typography from 'Atoms/Typography'

import ItemsInSelectIndexOptionsDrawer from './itemsInSelectIndexOptionsDrawer'
import styles from './selectIndexOptionsDrawer.module.scss'

const SelectIndexOptionsDrawer = () => {
  const addToCartExperienceContext = useContext(AddToCartExperienceContext)

  if (
    addToCartExperienceContext.itemsForSelectIndexOptionsDrawer.some(
      (x) => !x.indexOptions?.length && !x.skuVariantNumber,
    )
  ) {
    console.error(
      'Items were not valid for SelectIndexOptionsDrawer. Fix how items are set.',
    )
    return false
  }

  useEffect(() => {
    if (
      !addToCartExperienceContext.isSelectIndexOptionsDrawerOpen ||
      addToCartExperienceContext.isIndexOptionErrorState
    )
      setWaiting(false)
  }, [
    addToCartExperienceContext.isSelectIndexOptionsDrawerOpen,
    addToCartExperienceContext.isIndexOptionErrorState,
  ])

  const [waiting, setWaiting] = useState(false)

  // item.indexOptions.selectedIndexOptions, item.indexOptions.mostRecentlySelectedIndexOption, i, item.purchaseQuantity
  const selectIndexOption = (
    item,
    productPageId,
    selectedIndexOptions,
    lastSelectedOption,
  ) => {
    addToCartExperienceContext.selectIndexOption(
      item,
      productPageId,
      selectedIndexOptions,
      lastSelectedOption,
    )
  }

  const continueToCartEvent = {
    event: 'Clicked',
    properties: {
      element: 'Select Index Options Drawer: Add to Cart',
    },
  }

  const footer = (
    <Button
      intent={'positive'}
      text={'Add to Cart'}
      size={'small'}
      isWaiting={waiting}
      segmentEvent={continueToCartEvent}
      onClick={(e) => {
        e.preventDefault()
        if (addToCartExperienceContext.isIndexOptionErrorState) {
          return
        }
        setWaiting(true)
        addToCartExperienceContext.addToCartSelectIndexOptionsDrawer(
          addToCartExperienceContext.itemsForSelectIndexOptionsDrawer,
          null,
          null,
          true,
        )
      }}
      data-testid={'select-indexoptions-drawer'}
      fill
    />
  )

  return (
    <Drawer
      portalId={'selectIndexOptionsDrawer'}
      location={'right'}
      isOpen={addToCartExperienceContext.isSelectIndexOptionsDrawerOpen}
      onClose={addToCartExperienceContext.hideSelectIndexOptionsDrawer}
      title={'Select options'}
      footer={footer}
      className={styles.selectIndexOptionsDrawer}
    >
      <div
        data-testid={`selectindexoptions_drawer_${addToCartExperienceContext.isSelectIndexOptionsDrawerOpen ? 'open' : 'closed'}`}
      ></div>

      <Typography size={0.875}>
        You're almost there! There is an option or two for you to select.
      </Typography>
      <ItemsInSelectIndexOptionsDrawer
        items={addToCartExperienceContext.itemsForSelectIndexOptionsDrawer}
        selectIndexOption={selectIndexOption}
        isIndexOptionErrorState={
          addToCartExperienceContext.isIndexOptionErrorState
        }
      />
    </Drawer>
  )
}

export default SelectIndexOptionsDrawer
