import React from 'react'

import ClassNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './Gutters.module.scss'

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  noVerticalPadding: PropTypes.bool,
  noRightPadding: PropTypes.bool,
  noHorizontalPadding: PropTypes.bool,
}

const Gutters = ({
  children,
  className,
  noVerticalPadding,
  noHorizontalPadding,
  noRightPadding,
  ...props
}) => {
  const classnames = ClassNames(
    className,
    noVerticalPadding && styles.gutters_no_vertical_padding,
    !noVerticalPadding && styles.gutters,
    noRightPadding && styles.gutters_no_right_padding,
    noHorizontalPadding && styles.gutters_no_horizontal_padding,
  )

  return (
    <div className={classnames} {...props}>
      {children}
    </div>
  )
}

Gutters.propTypes = propTypes

export default Gutters
