import React from 'react'

import ClientRecommendationLoader from './ClientRecommendationLoader'
import RecommendationImpressionHandler from './RecommendationImpressionHandler'
import RecommendationLoader from './RecommendationLoader'

export default function RecommendationStoreWiring({ set }) {
  return (
    <>
      <RecommendationLoader set={set} />
      <ClientRecommendationLoader set={set} />
      <RecommendationImpressionHandler set={set} />
    </>
  )
}
