import React, { useEffect, useRef, useState } from 'react'

import { useThemeStore } from 'Stores/ThemeStore'
import { useVehicleStore } from 'Stores/VehicleStore'

import getEngineIds from 'Utilities/CustomerProjectUtilities/getEngineIds'
import getMatchingProject from 'Utilities/CustomerProjectUtilities/getMatchingProject'
import {
  createClickedSegmentEvent,
  createViewedSegmentEvent,
} from 'Utilities/Instrumentation/Impressions/impressionSegmentEvents'

import { getProperUrl } from 'Contexts/VehicleContext/CoreVehicleRequests'

import useSegment from 'Hooks/useSegment'

import styles from 'Organisms/CustomerProjectDrawer/CustomerProjectDrawer.module.scss'

import Button from 'Molecules/Button'

import EngineDropdown from '../AddEngineView/EngineDropdown/EngineDropdown'
import YmmDropdown from './YmmDropdown'

const initialDropdownState = {
  year: {
    value: null,
    label: null,
  },
  make: {
    value: null,
    label: null,
  },
  model: {
    value: null,
    label: null,
  },
  stockEngine: {
    value: null,
    label: null,
  },
}

const AddYmmView = ({ existingState, hasEngineSelected }) => {
  const [dropdownState, setDropdownState] = useState(initialDropdownState)
  const [showEngineDropdown, setShowEngineDropdown] = useState(false)
  const [submitHref, setSubmitHref] = useState(null)
  const [segmentEventText, setSegmentEventText] = useState('Add Vehicle')
  const [sentSegmentViewedEvent, setSentSegmentViewedEvent] = useState(false)
  const [existingYmmState, setExistingYmmState] = useState(false)

  const makes = useVehicleStore((x) => x.context.makes)
  const allYears = useVehicleStore((x) => x.context.allYears)
  const models = useVehicleStore((x) => x.context.models)
  const stockEngines = useVehicleStore((x) => x.context.stockEngines)
  const reloadCurrentLocation = useVehicleStore(
    (x) => x.context.reloadCurrentLocation,
  )
  const prioritizedMakes = useVehicleStore((x) => x.context.prioritizedMakes)
  const isFetching = useVehicleStore((x) => x.context.isFetching)
  const existingStateFromStore = useVehicleStore((x) => x.context.existingState)
  const customerProjects = useVehicleStore((x) => x.context.customerProjects)

  const fetchYmmData = useVehicleStore((x) => x.fetchYmmData)
  const setIsLoading = useThemeStore((x) => x.setIsLoading)
  const addCustomerProject = useVehicleStore((x) => x.addCustomerProject)
  const selectCustomerProject = useVehicleStore((x) => x.selectCustomerProject)
  const updateCustomerProjectData = useVehicleStore(
    (x) => x.updateCustomerProjectData,
  )
  const { sendSegmentTrackEvent } = useSegment()

  const makeDropdownRef = useRef()
  const modelDropdownRef = useRef()
  const yearDropdownRef = useRef()
  const stockEngineDropdownRef = useRef()

  const isYearDropdownDisabled =
    isFetching || !allYears || allYears?.length === 0
  const isMakeDropdownDisabled =
    isYearDropdownDisabled ||
    isFetching ||
    !makes ||
    makes?.length === 0 ||
    !dropdownState.year.value
  const isModelDropdownDisabled =
    isMakeDropdownDisabled ||
    isFetching ||
    !models ||
    models?.length === 0 ||
    !dropdownState.make.value
  const isEngineDropdownDisabled =
    isModelDropdownDisabled ||
    isFetching ||
    !stockEngines ||
    stockEngines?.length === 0 ||
    !dropdownState.model.value

  useEffect(() => {
    if (existingState) {
      setExistingYmmState(existingState)
    } else {
      setExistingYmmState(existingStateFromStore)
    }
  }, [])

  useEffect(() => {
    if (!sentSegmentViewedEvent) {
      const viewEvent = createViewedSegmentEvent('Add YMM View')
      sendSegmentTrackEvent(viewEvent)
      setSentSegmentViewedEvent(true)
    }
  })

  useEffect(() => {
    const { year, make, model, stockEngine } = dropdownState

    if (
      year.value &&
      make.value &&
      model.value &&
      ((stockEngine.value && stockEngine.value !== -1) || hasEngineSelected)
    ) {
      addOrUpdateYmm()
    } else {
      fetchYmmData(year.value, make.value, model.value)
      if (model.value) {
        const modelInfo = models?.find((x) => x.optionId === model.value)
        if (modelInfo) {
          const url = getProperUrl(modelInfo)
          if (url) setSubmitHref(url)
        }
      }
    }
  }, [dropdownState])

  useEffect(() => {
    if (existingYmmState && !hasEngineSelected) {
      setDropdownState(existingYmmState)
      fetchYmmData(
        existingYmmState.year.value,
        existingYmmState.make.value,
        existingYmmState.model.value,
      )
    }
  }, [existingYmmState])

  useEffect(() => {
    determineDropdownFocus(
      dropdownState,
      allYears,
      makes,
      models,
      stockEngines,
      yearDropdownRef,
      makeDropdownRef,
      modelDropdownRef,
      stockEngineDropdownRef,
      existingYmmState,
      isYearDropdownDisabled,
      isMakeDropdownDisabled,
      isModelDropdownDisabled,
      isEngineDropdownDisabled,
    )
  }, [
    dropdownState,
    allYears,
    makes,
    models,
    stockEngines,
    isYearDropdownDisabled,
    isMakeDropdownDisabled,
    isModelDropdownDisabled,
    isEngineDropdownDisabled,
  ])

  useEffect(() => {
    if (dropdownState.stockEngine.value === -1) {
      const event = createClickedSegmentEvent('Engine Swap Option')
      sendSegmentTrackEvent(event)
    }
    setShowEngineDropdown(dropdownState.stockEngine.value === -1)
  }, [dropdownState.stockEngine.value === -1])

  const addOrUpdateYmm = async () => {
    setIsLoading(true)
    try {
      const year = dropdownState.year.value
      const make = dropdownState.make.label
      const makeId = dropdownState.make.value
      const model = dropdownState.model.label
      const modelId = dropdownState.model.value
      const engineDefinitionId = dropdownState.stockEngine.value

      const selectedStockEngine = stockEngines?.find(
        (x) => x.optionId === engineDefinitionId,
      )

      const engineIds = getEngineIds(
        hasEngineSelected,
        existingYmmState,
        selectedStockEngine,
      )

      let response
      let addedOrUpdatedProjectId
      if (existingYmmState && existingYmmState?.customerProjectId) {
        response = await updateCustomerProjectData({
          year,
          makeId,
          make,
          modelId,
          model,
          engineIds,
          vehicleBaseId: existingYmmState?.vehicleBaseId,
          customerProjectId: existingYmmState?.customerProjectId,
        })
        addedOrUpdatedProjectId = response.updatedCustomerProjectId
      } else {
        response = await addCustomerProject({
          year,
          make,
          makeId,
          model,
          modelId,
          engineIds,
        })
        addedOrUpdatedProjectId = response.addedCustomerProjectId
      }

      if (!response) {
        setIsLoading(false)
        return
      }

      const selectedProject = response.projects.find(
        (project) => project.projectId === addedOrUpdatedProjectId,
      )
      const url = getProperUrl(selectedProject)

      await selectCustomerProject(selectedProject.projectId, url)
      if (reloadCurrentLocation) {
        // return false or it skips to line below
        window.location.reload()
        return false
      }
      window.location.href = url
    } catch (ex) {
      console.error(ex)
    }
    setIsLoading(false)
  }

  // WIFR-33088 DRY, could be broken out to helper function?
  useEffect(() => {
    let eventText
    if (existingYmmState?.customerProjectId) {
      eventText = 'Update Vehicle'
    } else {
      eventText = 'Add Vehicle'
    }
    const stockEngine = dropdownState.stockEngine.value
    // If checks for any engine at all since -1 also counts
    if (stockEngine) {
      const selectedStockEngine = stockEngines?.find(
        (x) => x.optionId === stockEngine,
      )
      const selectedStockEngineNotSwap =
        selectedStockEngine && stockEngine !== -1
      if (selectedStockEngineNotSwap) {
        eventText += ' With Stock Engine'
        const engineIds = selectedStockEngine.engineIds
        if (
          !(engineIds.engineDisplacementId > 1 || engineIds.engineVersionId > 1)
        ) {
          eventText += ' - Family'
        } else {
          eventText += ' - Displacement'
        }
        sendSegmentTrackEvent(createClickedSegmentEvent(eventText))
      } else {
        eventText += ' With Engine Swap'
      }
    }
    setSegmentEventText(eventText)
  }, [existingYmmState?.customerProjectId, dropdownState?.stockEngine])

  return (
    <>
      <YmmDropdown
        options={allYears}
        onChange={(option) =>
          setDropdownState(() => ({
            ...initialDropdownState,
            year: {
              value: parseInt(option.value, 10),
              label: option.label,
            },
          }))
        }
        selectedValue={dropdownState.year}
        label={'Year'}
        placeholder={'Select a Year'}
        selectRef={yearDropdownRef}
        isDisabled={isYearDropdownDisabled}
      />
      <YmmDropdown
        options={makes}
        onChange={(option) => {
          setDropdownState((prevState) => ({
            ...initialDropdownState,
            year: prevState.year,
            make: {
              value: parseInt(option.value, 10),
              label: option.label,
            },
          }))
        }}
        selectedValue={dropdownState.make}
        label={'Make'}
        placeholder={'Select a Make'}
        prioritizedMakes={prioritizedMakes}
        selectRef={makeDropdownRef}
        isDisabled={isMakeDropdownDisabled}
      />
      <YmmDropdown
        options={models}
        onChange={(option) =>
          setDropdownState((prevState) => ({
            ...initialDropdownState,
            year: prevState.year,
            make: prevState.make,
            model: {
              value: parseInt(option.value, 10),
              label: option.label,
            },
          }))
        }
        selectedValue={dropdownState.model}
        label={'Model'}
        placeholder={'Select a Model'}
        selectRef={modelDropdownRef}
        isDisabled={isModelDropdownDisabled}
      />
      {!hasEngineSelected && (
        <YmmDropdown
          options={stockEngines}
          onChange={(option) => {
            console.log('New Engine Interaction')
            setDropdownState((prevState) => ({
              ...prevState,
              stockEngine: {
                value: parseInt(option.value, 10),
                label: option.label,
              },
            }))
          }}
          selectedValue={dropdownState.stockEngine}
          label={'Engine'}
          addonLabel={'(Optional)'}
          placeholder={'Select an Engine'}
          selectRef={stockEngineDropdownRef}
          isDisabled={isEngineDropdownDisabled}
        />
      )}
      {!showEngineDropdown ? (
        <Button
          className={styles.dropdownAddButton}
          icon={'garage'}
          intent={'action'}
          size={'small'}
          segmentEvent={createClickedSegmentEvent(segmentEventText)}
          testingName={
            existingYmmState?.customerProjectId
              ? 'Update Vehicle'
              : 'Add Vehicle'
          }
          type={'button'}
          text={
            existingYmmState?.customerProjectId
              ? 'Update Vehicle'
              : 'Add Vehicle'
          }
          disabled={dropdownState.model.value === null || !submitHref}
          onClick={() => {
            addOrUpdateYmm()
          }}
          fill
        />
      ) : (
        <EngineDropdown
          buttonIcon={'garage'}
          buttonText={
            existingYmmState?.customerProjectId
              ? 'Update Vehicle'
              : 'Add Vehicle'
          }
          segmentEventText={segmentEventText}
          vehicleBaseId={
            models.find((x) => x.optionId === dropdownState.model.value)
              ?.vehicleBaseId
          }
          existingYmmState={existingYmmState}
        />
      )}
    </>
  )
}

const determineDropdownFocus = (
  dropdownState,
  allYears,
  makes,
  models,
  stockEngines,
  yearDropdownRef,
  makeDropdownRef,
  modelDropdownRef,
  stockEngineDropdownRef,
  existingYmmState,
  isYearDropdownDisabled,
  isMakeDropdownDisabled,
  isModelDropdownDisabled,
  isEngineDropdownDisabled,
) => {
  const { year, make, model, stockEngine } = dropdownState

  const isYearSelected = year.value
  const isMakeSelected = make.value
  const isModelSelected = model.value
  const isStockEngineSelected = stockEngine.value

  if (
    allYears &&
    !isYearSelected &&
    !isMakeSelected &&
    !isModelSelected &&
    !isStockEngineSelected &&
    !existingYmmState?.year?.value &&
    yearDropdownRef?.current?.focus &&
    !isYearDropdownDisabled
  )
    yearDropdownRef.current.focus()
  else if (
    makes &&
    isYearSelected &&
    !isMakeSelected &&
    !isModelSelected &&
    !isStockEngineSelected &&
    !existingYmmState?.make?.value &&
    makeDropdownRef?.current?.focus &&
    !isMakeDropdownDisabled
  )
    makeDropdownRef.current.focus()
  else if (
    models &&
    isYearSelected &&
    isMakeSelected &&
    !isModelSelected &&
    !isStockEngineSelected &&
    !existingYmmState?.model?.value &&
    modelDropdownRef?.current?.focus &&
    !isModelDropdownDisabled
  )
    modelDropdownRef.current.focus()
  else if (
    stockEngines &&
    isYearSelected &&
    isMakeSelected &&
    isModelSelected &&
    !isStockEngineSelected &&
    existingYmmState?.model?.value &&
    stockEngineDropdownRef?.current?.focus &&
    !isEngineDropdownDisabled
  )
    stockEngineDropdownRef.current.focus()
}

export default AddYmmView
