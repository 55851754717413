const RestRequest = async (
  url,
  queryStringKeyValuePairs = null,
  body = null,
) => {
  try {
    const options = {
      method: body ? `POST` : `GET`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    if (body) {
      options.body = JSON.stringify(body)
    }
    url = addUrlParams(url, queryStringKeyValuePairs)

    const response = await fetch(url, options)
    const responseBody = await response.json()
    return responseBody
  } catch (err) {
    console.error(`Unexpected error performing rest request`, err)
  }
}

const addUrlParams = (url, queryStringKeyValuePairs) => {
  const isRelative = !url.startsWith(`http`)
  if (isRelative) {
    url = `${window.origin}/${url}`.replaceAll(`//`, `/`)
  }
  const urlObj = new URL(url)
  for (const property in queryStringKeyValuePairs) {
    urlObj.searchParams.append(
      `${property}`,
      `${queryStringKeyValuePairs[property]}`,
    )
  }
  if (isRelative) {
    return `${urlObj.toString().substring(urlObj.origin.length)}`
  }
  return `${urlObj.toString()}`
}

export default RestRequest
