import React, { Fragment } from 'react'

import Textbox from 'Atoms/Form/Elements/Textbox'

const DataList = ({ options, id, label }) => {
  const dataOptions = options.map((option, index) => (
    <option key={'textbox-datalist-' + index} value={option} />
  ))

  return (
    <Fragment>
      <Textbox list={id} label={label} />
      <datalist id={`${id}`}>{dataOptions}</datalist>
    </Fragment>
  )
}

export default DataList
