import { removeItem } from '../../Utilities/LocalStorageUtility/LocalStorageUtility'
import RestRequest from '../../Utilities/RestClient/RestClient'

export async function LogoutUser(setIsLoading) {
  try {
    setIsLoading(true)

    const response = await RestRequest('/api/account/login/logout')

    if (!response?.success) {
      throw `unexpected error from server`
    }
    if (!response?.redirectUrl) {
      throw `no redirect from server`
    }
    localStorage.clear()
    sessionStorage.clear()
    window.location.href = response.redirectUrl
  } catch (error) {
    console.error('logout error', error)
    setIsLoading(false)
  }
}
