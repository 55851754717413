import React, { useEffect, useState } from 'react'

import { RecommendationProvider } from 'Stores/RecommendationStore/RecommendationStore'
import ImpressionBoundary from 'UtilityComponents/ImpressionBoundary'

import CookieReader from 'Utilities/CookieUtilties/CookieReader'
import { createClickedSegmentEvent } from 'Utilities/Instrumentation/Impressions/impressionSegmentEvents'
import { determineFirstTimeUser } from 'Utilities/UserTypeUtilities/userTypeUtils'

import { useLazyFeatureFlag } from 'Hooks/useFeatureFlag/useFeatureFlag'

import Carousel from 'Organisms/Carousel'
import RecommendationSection from 'Organisms/RecommendationSection'

import Button from 'Molecules/Button'

import Panel from 'Atoms/Panel'
import Skeleton from 'Atoms/Skeleton'
import Typography from 'Atoms/Typography'

import useRecentHistory from '../../../../Hooks/useRecentHistory'
import GraphQLRequest from '../../../../Utilities/GraphQLClient/GraphQLClient'
import { RecentSearches } from '../../RecentSearches/RecentSearches'
import styles from './SaytInitialContent.module.scss'

const SaytInitialContent = () => {
  const enabled = useLazyFeatureFlag('Sayt_Initial_Content')

  if (enabled == false) return <RecentSearches />
  if (!enabled) return false

  const userTypeCookie = CookieReader('UserType')
  const userType = userTypeCookie?.length
    ? determineFirstTimeUser(JSON.parse(decodeURIComponent(userTypeCookie)))
    : 'returning'
  const isReturningUser = userType.toLowerCase() === 'returning'

  let recentHistory = useRecentHistory.GetRecentHistory('Pdp', 'skuBaseNumber')
  if (recentHistory.length > 3) {
    recentHistory = recentHistory.slice(0, 6)
  }

  return (
    <>
      <Panel className={styles.content} layer={0}>
        <RecentSearches />
        <RecommendationProvider
          sections={[
            'Sayt_Suggested_Searches',
            'Sayt_Recent_Activity',
            'Sayt_New_Products',
          ]}
        >
          {recentHistory?.length && recentHistory.length > 2 ? (
            <ImpressionBoundary sectionName={'SaytRecentActivity'}>
              <RecommendationSection
                className={styles.recSection}
                section={'Sayt_Recent_Activity'}
                numberOfSkeletons={6}
                displayType={'horizontal'}
                skeletonDisplayType={'horizontal'}
                numberOfSkeletonSections={1}
              />
            </ImpressionBoundary>
          ) : (
            <ImpressionBoundary sectionName={'SaytNewProducts'}>
              <RecommendationSection
                className={styles.recSection}
                section={'Sayt_New_Products'}
                numberOfSkeletons={0}
                displayType={'horizontal'}
                skeletonDisplayType={'horizontal'}
                numberOfSkeletonSections={1}
              />
            </ImpressionBoundary>
          )}
          <ImpressionBoundary sectionName={'SaytSuggestedSearches'}>
            <RecommendationSection
              className={styles.recSection}
              section={'Sayt_Suggested_Searches'}
              numberOfSkeletons={0}
              displayType={'horizontal'}
              skeletonDisplayType={'horizontal'}
              numberOfSkeletonSections={1}
            />
          </ImpressionBoundary>
        </RecommendationProvider>
        {!isReturningUser ? <SaytTopCategories /> : false}
      </Panel>
    </>
  )
}

const SaytTopCategories = () => {
  const [categories, setCategories] = useState([])

  const query = `
     query CategoryLinks{
        categoryLinks{
          displayName
          url
        }
    }`

  useEffect(() => {
    async function getCategoryLinks() {
      const response = await GraphQLRequest('/graphql/trait', query)
      setCategories(response.categoryLinks)
    }

    getCategoryLinks()
  }, [])

  return (
    <div className={styles.topCategories}>
      <Typography className={styles.topCategoryTitle} font={'bold'} size={1.25}>
        Top Categories
      </Typography>
      {!categories.length ? (
        <Skeleton height={42} fullWidth />
      ) : (
        <ImpressionBoundary sectionName={'SaytTopCategory'}>
          <Carousel>
            {categories.map((x, i) => {
              return (
                <Button
                  key={`sayt_top_category_${x.displayName}_${i}`}
                  className={styles.topCategory}
                  intent={'subdued'}
                  size={'small'}
                  text={x.displayName}
                  href={x.url}
                  segmentEvent={createClickedSegmentEvent('SaytTopCategory')}
                  dataTestId={`SaytCategory-${x.displayName}`}
                />
              )
            })}
          </Carousel>
        </ImpressionBoundary>
      )}
    </div>
  )
}

export default SaytInitialContent
