const VehicleRequestWrapper = (query, variables, url) => {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: query,
      variables: variables,
    }),
    credentials: 'include',
  })
    .then((response) => {
      return response.json()
    })
    .then((response) => {
      if (response.errors) {
        response.errors.forEach((x) => console.error(x))
        return false
      }
      return response.data
    })
    .catch((error) => {
      console.error('Failed Vehicle Request', error)
      return false
    })
}

export default VehicleRequestWrapper
