import {
  isInEnum,
  isRequiredInteger,
  isRequiredNumber,
  isRequiredString,
} from '../SegmentUtilities/segmentSchemaUtils'

const productViewedSchema = {
  audience_affinity: (input) =>
    isInEnum(input, ['multiple', 'none', 'race', 'street', 'truck']),
  availability: (input) =>
    isInEnum(input, [
      'NotAvailable',
      'InStock',
      'MadeToOrder',
      'FactoryShipped',
      'Backorderable',
      'Discontinued',
    ]),
  brand: isRequiredString,
  category: (input) =>
    /^[\w\d\s,-\\&é]+\s>\s[\w\d\s,-\\&é]+\s>\s[\w\d\s,-\\&é]+$/.test(input),
  image_url: isRequiredString,
  name: isRequiredString,
  price: isRequiredNumber,
  product_id: isRequiredString,
  rating: isRequiredNumber,
  reviews: isRequiredInteger,
  sku: isRequiredString,
  url: isRequiredString,
}

export { productViewedSchema }
