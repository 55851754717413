import React from 'react'

import { useVehicleStore } from 'Stores/VehicleStore'

import useCustomerProjects from 'Hooks/useCustomerProjects'

import Button from 'Molecules/Button'

import drawerStates from '../../Constants/drawerStates'
import ExistingProjectSection from './ExistingProjectSection'
import styles from './ExistingProjectView.module.scss'

const ExistingProjectView = ({ setDrawerState }) => {
  const {
    vehicles,
    raceTypes,
    engines,
    totalVehicles,
    totalRaceTypes,
    totalEngines,
    total,
    hasCustomerProjects,
  } = useCustomerProjects()

  const selectedCustomerProject = useVehicleStore(
    (x) => x.context.selectedCustomerProject,
  )

  const showClearSelectionVehicle =
    totalVehicles > 0 &&
    vehicles.find((x) => x.projectId == selectedCustomerProject.projectId)
  const showClearSelectionRace =
    totalRaceTypes > 0 &&
    raceTypes.find((x) => x.projectId == selectedCustomerProject.projectId)
  const showClearSelectionEngine =
    totalEngines > 0 &&
    engines.find((x) => x.projectId == selectedCustomerProject.projectId)

  return (
    <>
      {hasCustomerProjects ? (
        <>
          <ExistingProjectSection
            type={'Vehicle'}
            total={totalVehicles}
            projects={vehicles}
            showAllText={'Vehicles'}
            haveClearSelection={showClearSelectionVehicle}
          />
          <ExistingProjectSection
            type={'Race Car'}
            total={totalRaceTypes}
            projects={raceTypes}
            showAllText={'Race Cars'}
            haveClearSelection={showClearSelectionRace}
          />
          <ExistingProjectSection
            type={'Engine'}
            total={totalEngines}
            projects={engines}
            showAllText={'Engines'}
            haveClearSelection={showClearSelectionEngine}
          />
        </>
      ) : (
        <ExistingProjectSection type={'CTA'} />
      )}
      <Button
        icon={'garage'}
        intent={'action'}
        size={'small'}
        onClick={() => setDrawerState(drawerStates.addYmm)}
        segmentEvent={{
          event: 'Clicked',
          properties: { element: `Add a Vehicle View` },
        }}
        testingName={'Add a Vehicle'}
        type={'button'}
        text={'Add a Vehicle'}
        className={styles.addButton}
        fill
      />
      <Button
        icon={'race'}
        intent={'action'}
        size={'small'}
        onClick={() => setDrawerState(drawerStates.addRaceType)}
        segmentEvent={{
          event: 'Clicked',
          properties: { element: `Add a Race Car View` },
        }}
        testingName={'Add a Race Car'}
        type={'button'}
        text={'Add a Race Car'}
        className={styles.addButton}
        fill
      />
      <Button
        intent={'action'}
        icon={'v8-engine'}
        size={'small'}
        onClick={() => setDrawerState(drawerStates.addEngine)}
        segmentEvent={{
          event: 'Clicked',
          properties: { element: `Add an Engine View` },
        }}
        testingName={'Add an Engine'}
        type={'button'}
        text={'Add an Engine'}
        className={styles.addButton}
        fill
      />
      {total > 0 && (
        <Button
          intent={'action'}
          invert
          href={'/CustomerVehicle'}
          size={'small'}
          segmentEvent={{
            event: 'Clicked',
            properties: {
              element: `View your Garage`,
            },
          }}
          type={'button'}
          testingName={'View your Garage'}
          text={'View your Garage'}
          className={styles.garageButton}
          fill
        />
      )}
    </>
  )
}

export default ExistingProjectView
