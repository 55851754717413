import userTypes from 'Constants/UserTypes'

const determineFirstTimeUser = (userTypeCookie) => {
  try {
    if (!userTypeCookie) return userTypes.new

    const userType = userTypeCookie.type

    if (userType === userTypes.returning) {
      return userTypes.returning
    } else if (userType === userTypes.new) {
      const firstVisit = Date.parse(userTypeCookie.firstVisit)
      var hoursSinceFirstVisit = Math.abs(new Date() - firstVisit) / 36e5
      if (hoursSinceFirstVisit > 24) return userTypes.returning
    }
    return userTypes.new
  } catch (err) {
    return userTypes.new
  }
}

export { determineFirstTimeUser }
