import { useEffect, useState } from 'react'

import { useGlobalStateStore } from '../../Stores/GlobalStateStore/GlobalStateStore'
import useGlobalState from '../useGlobalState/useGlobalState'

const useGlobalStateWithFetch = (key, fetchMethod, triggers = []) => {
  const lock = useGlobalStateStore((x) => x.lock)
  const unlock = useGlobalStateStore((x) => x.unlock)
  const isLocked = useGlobalStateStore((x) => x.isLocked)
  const doTriggersMatch = useGlobalStateStore((x) => x.doTriggersMatch)
  const setLastTriggers = useGlobalStateStore((x) => x.setLastTriggers)

  const { globalState, setGlobalState } = useGlobalState(key, {})

  const update = async () => {
    if (isLocked(key)) {
      return
    }

    lock(key)
    try {
      const newState = await fetchMethod()
      setGlobalState(newState)
    } catch (e) {
      console.error(`failed to get global state for ${key}`, e)
    }

    unlock(key)
  }

  useEffect(() => {
    if (doTriggersMatch(triggers, key)) {
      return
    }

    setLastTriggers(triggers, key)
    update()
  }, triggers)

  return { globalState, update }
}

export default useGlobalStateWithFetch
