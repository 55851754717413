import { SpeedInsights } from '@vercel/speed-insights/next'
import AppName from 'AppName'
import Layout from 'Clutch/Layout/Layout'
import 'Clutch/Styles/globals.scss'
import 'Clutch/Styles/globals.scss'
import { WSODErrorBoundary } from 'UtilityComponents/ErrorBoundary'
import 'intersection-observer'
import Head from 'next/head'

import { IsUserBot } from '../../Utilities/CookieUtilties/UserCookieUtilities'

export function reportWebVitals(metric) {
  if (typeof window !== 'undefined') {
    window.smi_web_vitals = window.smi_web_vitals || {}
    if (metric?.name === 'FCP') {
      window.smi_web_vitals.FCP = metric.value
    }
    if (metric?.name === 'LCP') {
      window.smi_web_vitals.LCP = metric.value
    }
  }
}

function NextLayout({ children, ...props }) {
  // todo.. elevate the parsing up one level in DS9
  const hardCodedInitialLayoutState = {
    reloadPageOnVehicleSelection: false,
    featureFlags: {
      isLogRocketEnabled: true,
      isServiceWorkerEnabled: false,
      chatWidgetAndEmailSignupDisabledPathNames: [
        '/Info/Automobile-History-Timeline',
        '/Info/Muscle-Cars-Throughout-History',
        "/Info/A-Collector's-Guide-to-Classic-Trucks",
        '/Info/Car-And-Auto-Safety-For-Teens',
        '/Info/The-Greatest-Race-Car-Drivers-of-All-Time',
        '/Info/Car-Maintenance-and-Tips-for-Beginners',
        '/item/radiator/917347',
        '/Info/Car-Care-Checklist-for-Winter-Driving',
        '/Info/All-About-Electronic-Circuits-for-Kids',
      ],
    },
    logRocketAppId: 'xi3hul/speedwaymotorscom-production',
    microFrontEndHtml: '',
    headerRedesignEnabled: true,
    isAmp: false,
    services: {
      checkout: {
        url: '/graphql/checkout',
      },
      customer: {
        url: '/graphql/customer',
      },
      content: {
        url: '/graphql/content',
      },
      discovery: {
        url: '/graphql/discovery',
      },
      recommendations: {
        url: '/graphql/recommendations',
      },
      traits: {
        url: '/graphql/trait',
      },
      customerProjects: {
        url: '/graphql/customerproject',
      },
    },
  }

  return (
    <Layout
      state={JSON.stringify(hardCodedInitialLayoutState)}
      reserveBannerSpace={props.reserveBannerSpace}
      crispEnabled={props.crispEnabled}
      cwvEventsEnabled={props.cwvEventsEnabled}
      seoClarityEnabled={props.seoClarityEnabled}
      dontAutoSendClarityInitEvent={props.dontAutoSendClarityInitEvent}
      freeShippingThreshold={props.freeShippingThreshold}
      showPwaBackButton={props.showPwaBackButton}
    >
      <WSODErrorBoundary>
        <Head>
          {/* not for sure what's perfectly correct here */}
          <meta
            name="viewport"
            content="initial-scale=1.0, user-scalable=1, width=device-width, height=device-height"
          />

          <style data-href="/Content/Fonts/InterTight-Regular.woff2">
            {
              '@font-face{font-family:InterTight-Regular, sans-serif;font-style:normal;letter-spacing:0.02em;}'
            }
          </style>

          <style data-href="/Content/Fonts/InterTight-Bold.woff2">
            {
              '@font-face{font-family:InterTight-Bold, sans-serif;font-style:normal;}'
            }
          </style>

          <style data-href="/Content/Fonts/InterTight-Bold.woff2">
            {
              '@font-face{font-family:InterTight-Header, sans-serif;font-style:normal;letter-spacing:0.02em;}'
            }
          </style>
        </Head>
        {children}
        <VercelSpeedInsightsWrapper />
      </WSODErrorBoundary>
    </Layout>
  )
}

const VercelSpeedInsightsWrapper = () => {
  if (IsUserBot()) {
    return false
  }

  if (
    AppName &&
    (AppName === 'Pdp' ||
      AppName === 'HomePage' ||
      AppName === 'Account-UI' ||
      AppName === 'Pages')
  ) {
    const environment = process.env.NEXT_ENVIRONMENT || 'production'
    const domain =
      environment === 'production'
        ? 'www.speedwaymotors.com'
        : 'www.qa.speedway2.com'

    let asset_prefix = '/'
    switch (AppName) {
      case 'Pdp':
        asset_prefix = '/mfe/pdp/'
        break
      case 'HomePage':
        asset_prefix = '/mfe/home/'
        break
      case 'Account-UI':
        asset_prefix = '/mfe/account/'
        break
      case 'Pages':
        asset_prefix = '/mfe/pages/'
        break
    }

    return (
      <>
        <SpeedInsights
          sampleRate={0.5}
          endpoint={`https://${domain}${asset_prefix}_vercel/speed-insights/vitals`}
        />
      </>
    )
  }

  return (
    <>
      <SpeedInsights sampleRate={0.5} />
    </>
  )
}

export default NextLayout
