import React from 'react'

import ErrorBoundary from 'UtilityComponents/ErrorBoundary'
import Image from 'next/image'

import Svg from 'Atoms/Svg'

import styles from './cartProducts.module.scss'

const AddToCartDrawerCartProducts = ({ addToCartDrawerItems }) => {
  if (!addToCartDrawerItems || addToCartDrawerItems.length < 1) return false

  return (
    <ErrorBoundary>
      {addToCartDrawerItems.map((item, index) => {
        return (
          <ErrorBoundary key={`cart_product_item_${index}`}>
            <div className={styles.cart_product_wrapper}>
              {item.images && item.images.length > 0 ? (
                item.images[0] && item.images[0].length > 0 ? (
                  <span>
                    <Image
                      src={`https:${item.images[0]}`}
                      width={100}
                      height={100}
                    />
                  </span>
                ) : (
                  false
                )
              ) : (
                false
              )}
            </div>
            {index !== addToCartDrawerItems.length - 1 && (
              <Svg icon={'plus'} tone={'superfluous'} size={1} />
            )}
          </ErrorBoundary>
        )
      })}
    </ErrorBoundary>
  )
}

export default AddToCartDrawerCartProducts
