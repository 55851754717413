import { useContext } from 'react'

import useFeatureFlag from 'Hooks/useFeatureFlag/useFeatureFlag'

import { TrackingContext } from '../../Contexts/TrackingContext/TrackingContext'
import { isInApp } from './AppUtilities'

const useAppInstall = () => {
  const { promptAppInstall, appInstallPrompt } = useContext(TrackingContext)

  const pwaEnabledFlag = useFeatureFlag('Frontend_PWA')

  return {
    displayAppInstallPrompt: promptAppInstall,
    canPromptForAppInstall: pwaEnabledFlag && !!appInstallPrompt,
    pwaEnabledAtAll: pwaEnabledFlag,
  }
}

export default useAppInstall
