import React, { createContext, useContext, useEffect, useRef } from 'react'

import { createStore, useStore } from 'zustand'

const createZustandContext = (initializer, options = {}) => {
  const Context = createContext(null) // the null gets overwritten right away, but is useful for checking if you forgot your provider

  const useStoreFromContext = () => {
    const store = useContext(Context)
    if (!store) {
      // you know how I mentioned the null up top-- this is why it's useful!
      throw `${options.name} zustand store was not defined, you probably are missing your provider at the top of the app/layout. There's a chance you just need to change your import of the ${options.name}Provider (from Contexts to Stores directory).`
    }
    return store
  }

  const useStoreInternal = (selector) => {
    const store = useStoreFromContext()
    if (!selector && options?.missingSelectorBehavior === 'warn') {
      console.warn(
        'No selector was provided, you may want to provide a selector to avoid unnecessary renders',
      )
    }
    // default behavior is to throw an HARD error if no selector is provided
    if (!selector && !options?.missingSelectorBehavior) {
      console.error(
        'No selector was provided, you may must provide a selector to avoid unnecessary renders',
      )
      return null
    }

    const result = useStore(store, selector)
    return result
  }

  const container = {
    useStore: useStoreInternal,

    Provider: null,

    // wiring can be set on a created context so that useEffect hooks can be used
    Wiring: null,

    // this is a means by which we can share values from other
    useSynchronizedState: null,
    useSynchronizedProps: null, // old naming for synchronized state
    useSynchronizedStateForMigration: null,

    name: options?.name,
  }

  function useSynchronizedStateInternal(useSynchronizedStateLocal) {
    if (useSynchronizedStateLocal) {
      // not conditional hooks since useSynchronizedState comes from the container and doesn't change
      const synchronizedState = useSynchronizedStateLocal()
      const store = useStoreFromContext()

      useEffect(() => {
        store.setState({ ...synchronizedState })
        if (options?.verbose) {
          const storeName = options?.name || 'unnamed'
          console.log('syncing state to ' + storeName + ' store')
        }
        store.setState({ ...synchronizedState })
      }, [synchronizedState])
    }
  }

  function SynchronizeState() {
    useSynchronizedStateInternal(container.useSynchronizedState)
    useSynchronizedStateInternal(container.useSynchronizedProps) // old naming for synchronized state
    useSynchronizedStateInternal(container.useSynchronizedStateForMigration) // for migrating from a context to a zustand store (purely internal)
  }

  function Provider({
    children,
    initialProps,
    hardCodedPropsForTesting,
    ...props
  }) {
    const store = useRef()

    if (!store.current) {
      // it's really important for avoiding re-renders
      // that this follows a singleton pattern
      // so that the store's reference does not change
      store.current = createStore((set, get, api) =>
        initializer({
          set,
          get,
          api,
          initialProps,
          hardCodedPropsForTesting,
          props,
        }),
      )
    }

    return (
      <Context.Provider value={store.current}>
        <SynchronizeState />
        {children}
        {container.Wiring && (
          <container.Wiring set={store.current.setState} {...props} />
        )}
      </Context.Provider>
    )
  }

  container.Provider = Provider

  // this is for migrating from a context to a zustand store
  if (options.OldContext && !options.disableOld) {
    // this keeps properties in sync
    container.useSynchronizedStateForMigration = () => {
      const context = useContext(options.OldContext)
      return context
    }

    // this allows us to remove the old provider in favor of this one
    // it also initializes the new provider's props from the old one
    function MigratingProvider({ children, ...props }) {
      const OldProvider = options.OldProvider ?? options.OldContext.Provider
      return (
        <OldProvider {...props}>
          <options.OldContext.Consumer>
            {(context) => (
              <Provider initialProps={context} {...props}>
                {children}
              </Provider>
            )}
          </options.OldContext.Consumer>
        </OldProvider>
      )
    }

    container.Provider = MigratingProvider
  }

  return container
}

export default createZustandContext
