import React from 'react'

import { AppName } from 'AppSettings'
import classNames from 'classnames'
import Image from 'next/image'

import LayerColorFinder from 'Utilities/LayerColorFinder'

import Button from 'Molecules/Button'
import LandingPageLink from 'Molecules/LandingPageLink'

import { createClickedSegmentEvent } from '../../Utilities/Instrumentation/Impressions/impressionSegmentEvents'
import styles from './LandingPageLinkRack.module.scss'

const LandingPageLinkRack = ({
  containerClassName,
  links,
  showMoreLink,
  representativeImage,
  dataTestId,
  isBrandImage,
  isRaceTypeImage,
  areImagesLoading,
}) => {
  const hasImages = links.some((link) => link.imageUrl)
  const linkContainerClasses = classNames(
    styles.linkContainer,
    hasImages ? styles.linkContainerImages : styles.linkContainerNoImages,
    containerClassName,
  )

  const borderColor = LayerColorFinder(2)

  const repImageLink = {
    imageUrl: representativeImage?.url,
    url: showMoreLink?.url,
    altText: representativeImage?.altText,
  }

  return (
    <>
      <div className={styles.container}>
        {representativeImage?.url &&
          representativeImage?.altText &&
          (showMoreLink?.url ? (
            <div className={styles.repImageContainer}>
              <LandingPageLink
                key={`rep_image_${representativeImage?.altText}`}
                link={repImageLink}
                hasImages={true}
                dataTestId={dataTestId}
                areImagesLoading={areImagesLoading}
              />
            </div>
          ) : (
            <div className={styles.repImageContainer}>
              <Image
                src={representativeImage.url}
                height={200}
                width={200}
                alt={representativeImage.altText}
                className={styles.image}
                style={{
                  '--borderColor': borderColor,
                  '--borderRadius': '4px',
                }}
              />
            </div>
          ))}
        <div className={linkContainerClasses}>
          {links?.map((link, index) => {
            return (
              <LandingPageLink
                key={`${link.displayValue}_${index}`}
                hasImages={hasImages}
                link={link}
                dataTestId={dataTestId}
                isBrandImage={isBrandImage}
                isRaceTypeImage={isRaceTypeImage}
                areImagesLoading={areImagesLoading}
              />
            )
          })}
        </div>
      </div>
      {showMoreLink && (
        <div className={styles.showMoreLink}>
          <Button
            href={showMoreLink.url}
            size={'small'}
            segmentEvent={createClickedSegmentEvent('ShowMoreLinkRack')}
            intent={'subdued'}
            className={styles.button}
            testingName={`${dataTestId}_showMoreButton_${showMoreLink.text}`}
          >
            <p className={'p p-color-subtle'}>{showMoreLink.text}</p>
          </Button>
        </div>
      )}
    </>
  )
}

export default LandingPageLinkRack
