'use client'

import React from 'react'

import ClassNames from 'classnames'
import PropTypes from 'prop-types'

import LayerColorFinder from 'Utilities/LayerColorFinder'

import Brands from 'Constants/Brand'
import Intents from 'Constants/Intent'

import styles from './Divider.module.scss'
import styled from 'styled-components'

const propTypes = {
  intent: PropTypes.oneOf(Intents),
  brand: PropTypes.oneOf(Brands),
  layer: PropTypes.oneOf([0, 1, 2, 3]),
  invert: PropTypes.bool,
}

const Divider = ({ className, layer, intent, brand, invert, ...props }) => {
  intent = intent && intent.toLowerCase()
  brand = brand && brand.toLowerCase()

  const classes = ClassNames(className, styles.divider)

  layer = layer || 3

  const color = LayerColorFinder(layer, invert, brand, intent)

  return (
    <StyledDivider
      className={classes}
      color={color}
      {...props}
      role={'separator'}
    />
  )
}

const StyledDivider = styled.div`
  color: ${(props) => props.color};
`

Divider.propTypes = propTypes

export default Divider
