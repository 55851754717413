import React from 'react'

import { useVehicleStore } from 'Stores/VehicleStore'

import TextLink from 'Atoms/TextLink'

const ShowAll = ({ showAllState, setShowAll, type, customerProjects }) => {
  let buttonText = ''
  if (!customerProjects || customerProjects?.length <= 3) {
    return false
  }

  if (showAllState) {
    buttonText = `Hide ${type}s`
  } else {
    buttonText = `Show all ${type}s`
  }

  return (
    <TextLink
      intent={'action'}
      onClick={() => {
        setShowAll(!showAllState)
      }}
      underlineHover
    >
      {buttonText}
    </TextLink>
  )
}

export default ShowAll
