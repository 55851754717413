import React, { useContext, useEffect, useState } from 'react'

import ClassNames from 'classnames'

import LayerColorFinder from 'Utilities/LayerColorFinder'

import PanelContext from 'Contexts/PanelContext'

import Typography from 'Atoms/Typography'

import styles from './Switch.module.scss'
import styled from 'styled-components'

const Switch = ({
  id,
  intent,
  brand,
  checked,
  disabled,
  onChange,
  ...props
}) => {
  if (!intent && !brand) return false

  const panelContext = useContext(PanelContext)
  const layer = panelContext?.layer

  const [active, setActive] = useState(checked)

  useEffect(() => {
    if (checked != active) {
      toggleChecked()
    }
  }, [checked])

  const toggleChecked = (e) => {
    setActive(!active)
    if (onChange) {
      onChange(!active)
    }
  }

  const backgroundColor =
    active && !disabled
      ? LayerColorFinder(undefined, undefined, brand, intent)
      : LayerColorFinder(Math.min(layer + 2, 3))

  const classNames = ClassNames(
    styles.switchWrapper,
    active && styles.activeCheckbox,
  )

  return (
    <div className={classNames}>
      <input
        type="checkbox"
        className={ClassNames(
          styles.checkbox,
          disabled ? styles.switchDisabled : null,
        )}
        id={id}
        name={id}
        onClick={toggleChecked}
        checked={active}
        disabled={disabled}
        onChange={() => {}}
        {...props}
      />
      <StyledLabel
        className={ClassNames(
          styles.label,
          disabled ? styles.switchDisabled : null,
        )}
        htmlFor={id}
        backgroundColor={backgroundColor}
      >
        <Typography as={'span'} className={styles.switch} />
      </StyledLabel>
    </div>
  )
}

const StyledLabel = styled.label`
  background-color: ${(props) => props.backgroundColor};
`

export default Switch
