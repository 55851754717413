import { getCurrentPageTypeFromUrl } from 'Utilities/CurrentPage/currentPageUtils'

const fitmentSegmentEventNames = {
  SEGMENT_FITMENT_ADDED: 'Fitment Added',
  SEGMENT_FITMENT_REMOVED: 'Fitment Removed',
  SEGMENT_FITMENT_UPDATED: 'Fitment Updated',
}

const createFitmentAddedSegmentEventFromCustomerProject = (customerProject) => {
  if (!customerProject?.projectId) {
    console.error('SEGEMENT-FITMENT-EMPTY-ADDED', customerProject)
  }
  return {
    event: fitmentSegmentEventNames.SEGMENT_FITMENT_ADDED,
    properties: {
      make_id: customerProject?.makeId,
      make: customerProject?.make,
      model_id: customerProject?.modelId,
      model: customerProject?.model,
      year: customerProject?.year,
      race_type_id: customerProject?.raceTypeId,
      race_type: customerProject?.raceName,
      page_type: getCurrentPageTypeFromUrl(),
      has_engine_ids: customerProject?.engineManufacturerId > 1,
      engine_manufacturer: customerProject?.engineManufacturer,
      engine_manufacturer_id: customerProject?.engineManufacturerId,
      engine_make: customerProject?.engineMake,
      engine_make_id: customerProject?.engineMakeId,
      engine_series: customerProject?.engineSeries,
      engine_series_id: customerProject?.engineSeriesId,
      engine_configuration: customerProject?.engineConfiguration,
      engine_configuration_id: customerProject?.engineConfigurationId,
      engine_version: customerProject?.engineVersion,
      engine_version_id: customerProject?.engineVersionId,
      engine_qualifier: customerProject?.engineQualifier,
      engine_qualifier_id: customerProject?.engineQualifierId,
      engine_displacement: customerProject?.engineDisplacement,
      engine_displacement_id: customerProject?.engineDisplacementId,
      engine_code: customerProject?.engineCode,
      engine_code_id: customerProject?.engineCodeId,
      engine_camshaft_type: customerProject?.engineCamshaftType,
      engine_camshaft_type_id: customerProject?.engineCamshaftTypeId,
      engine_fuel_system: customerProject?.engineFuelSystem,
      engine_fuel_system_id: customerProject?.engineFuelSystemId,
    },
  }
}

const createFitmentRemovedSegmentEventFromCustomerProject = (
  customerProject,
) => {
  if (!customerProject?.projectId) {
    console.error('SEGEMENT-FITMENT-EMPTY-REMOVED', customerProject)
  }
  return {
    event: fitmentSegmentEventNames.SEGMENT_FITMENT_REMOVED,
    properties: {
      make_id: customerProject?.makeId,
      make: customerProject?.make,
      model_id: customerProject?.modelId,
      model: customerProject?.model,
      year: customerProject?.year,
      race_type_id: customerProject?.raceTypeId,
      race_type: customerProject?.raceName,
      page_type: getCurrentPageTypeFromUrl(),
      has_engine_ids: customerProject?.engineManufacturerId > 1,
      engine_manufacturer: customerProject?.engineManufacturer,
      engine_manufacturer_id: customerProject?.engineManufacturerId,
      engine_make: customerProject?.engineMake,
      engine_make_id: customerProject?.engineMakeId,
      engine_series: customerProject?.engineSeries,
      engine_series_id: customerProject?.engineSeriesId,
      engine_configuration: customerProject?.engineConfiguration,
      engine_configuration_id: customerProject?.engineConfigurationId,
      engine_version: customerProject?.engineVersion,
      engine_version_id: customerProject?.engineVersionId,
      engine_qualifier: customerProject?.engineQualifier,
      engine_qualifier_id: customerProject?.engineQualifierId,
      engine_displacement: customerProject?.engineDisplacement,
      engine_displacement_id: customerProject?.engineDisplacementId,
      engine_code: customerProject?.engineCode,
      engine_code_id: customerProject?.engineCodeId,
      engine_camshaft_type: customerProject?.engineCamshaftType,
      engine_camshaft_type_id: customerProject?.engineCamshaftTypeId,
      engine_fuel_system: customerProject?.engineFuelSystem,
      engine_fuel_system_id: customerProject?.engineFuelSystemId,
    },
  }
}

const createFitmentUpdatedSegmentEventFromCustomerProject = (
  customerProject,
  updatedEngineIds,
) => {
  if (!customerProject?.projectId) {
    console.error('SEGEMENT-FITMENT-EMPTY-UPDATED', customerProject)
  }
  return {
    event: fitmentSegmentEventNames.SEGMENT_FITMENT_UPDATED,
    properties: {
      make_id: customerProject?.makeId,
      make: customerProject?.make,
      model_id: customerProject?.modelId,
      model: customerProject?.model,
      year: customerProject?.year,
      race_type_id: customerProject?.raceTypeId,
      race_type: customerProject?.raceName,
      page_type: getCurrentPageTypeFromUrl(),
      has_engine_ids: customerProject?.engineManufacturerId > 1,
      engine_manufacturer: customerProject?.engineManufacturer,
      engine_manufacturer_id: customerProject?.engineManufacturerId,
      engine_make: customerProject?.engineMake,
      engine_make_id: customerProject?.engineMakeId,
      engine_series: customerProject?.engineSeries,
      engine_series_id: customerProject?.engineSeriesId,
      engine_configuration: customerProject?.engineConfiguration,
      engine_configuration_id: customerProject?.engineConfigurationId,
      engine_version: customerProject?.engineVersion,
      engine_version_id: customerProject?.engineVersionId,
      engine_qualifier: customerProject?.engineQualifier,
      engine_qualifier_id: customerProject?.engineQualifierId,
      engine_displacement: customerProject?.engineDisplacement,
      engine_displacement_id: customerProject?.engineDisplacementId,
      engine_code: customerProject?.engineCode,
      engine_code_id: customerProject?.engineCodeId,
      engine_camshaft_type: customerProject?.engineCamshaftType,
      engine_camshaft_type_id: customerProject?.engineCamshaftTypeId,
      engine_fuel_system: customerProject?.engineFuelSystem,
      engine_fuel_system_id: customerProject?.engineFuelSystemId,
      updated_engine_ids: updatedEngineIds ?? false,
    },
  }
}

export {
  createFitmentAddedSegmentEventFromCustomerProject,
  createFitmentRemovedSegmentEventFromCustomerProject,
  createFitmentUpdatedSegmentEventFromCustomerProject,
}
