import RestRequest from '../../Utilities/RestClient/RestClient'
import useFeatureFlag from '../useFeatureFlag/useFeatureFlag'
import useGlobalStateWithFetch from '../useGlobalStateWithFetch/useGlobalStateWithFetch'

const useNotificationCount = () => {
  const fetchNoteCount = async () =>
    await RestRequest(`/api/customer/pushnotifications/getuserunreadcount`)
  const { globalState, update } = useGlobalStateWithFetch(
    'passiveNoteCount',
    fetchNoteCount,
  )

  const passiveNotificationFF = useFeatureFlag('FrontEnd_Passive_Notifications')
  const count = passiveNotificationFF ? (globalState?.countUnread ?? 0) : 0

  return {
    count,
    update,
    isEnabled: passiveNotificationFF,
  }
}

export default useNotificationCount
