const RecommendationsFragment = `
  sectionType
  treatment
  recommendationGroups {
    clientDataType
    title
    subtitle
    misoId
    isExpandable
    displayType
    getShippingEstimate
    checkFitment
    useReducedArticleCard
    recommendations {
      __typename
      ... on LandingPage {
        landingPageDetails{
            displayValue
            thumbnailImageUrl
            field
            facetOptionLink{
                url
                noFollow
            }
          }
      }
      ... on Category {
        categoryName
        url
      }
      ... on Product {
        recommendationType
        skuVariant
        skuBase
        publicSkuBaseDetails(webUserGuid: $webUserGuid){
          skuVariantSummaries {
            availability
          }
          displayTitle
          skuBaseNumber
          hasNoKitBaseComponents
          indexOptions {
            id 
            ordinality 
            displayName 
            isSelected 
            indexOptionValues {
                id
                ordinality
                displayName
                isSelected
                isDisabled
                isPurchaseable
            }
          }
          productPageId
          productPageUrl
          thumbnailImageLink
          rating
          reviewCount
          availability
          superMarketAffinity
          hasActiveRebate
          pricing {
            salePrice
            salePriceString
          }
          skuVariantSummaries {
            skuNumber
          }
          segmentCategorization
          brand
        }
        publicSkuVariantDetails(webUserGuid: $webUserGuid){
          displayTitle
          skuVariantNumber
          kit {
            skuBaseComponents {
              kitSkuNumber
            }
            skuVariantComponents {
              kitSkuNumber
            }
          }
        }
      }
      ... on Article {
        articleId
        articleDetails{
            id
            misoId
            title
            authors
            description
            createdAt
            lastDeployedAt
            deployDate
            articleThumbnailUrl
            url
            versionId
            
        } 
      }
      ... on ComparisonHeader{
        comparisonLabels
      }
      ... on Ticket {
        eventId 
        __typename
        name
        date
        displayDate
        description
        callout
        image {
          iconImageUrl
        }
        purchaseurl
        ticketgroupid
        latitude
        longitude
        }
      ... on SearchTerm {
        term
        url
      }  
      ... on ComparisonProduct{
        skuBaseNumber
        displayTitle
        productPageUrl
        productPageId
        imageLink
        partTypeName
        rating
        unit
        brand
        segmentCategorization
        availability
        availabilityEnum
        superMarketAffinity
        reviewCount
        comparisonValues
        hasActiveRebate
        ribbonText
        excludeFromThisPartFitsMyVehicle
        pricing {
          percentOffString
          priceString
          strikeThroughPrice
        }
      }
    }
  }
`
export default RecommendationsFragment
