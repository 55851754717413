import React, { useContext, useEffect } from 'react'

import SetCookie from 'Utilities/CookieUtilties/SetCookie'
import createZustandContext from 'Utilities/Zustand/createZustandContext'

import Palette from 'Constants/Palette'

const lightTheme = {
  isLoading: false,
  name: 'Light',
  theme: 'light',
  inverse: 'dark',
  background_color: Palette.light,
  inverse_background_color: Palette.dark,
  content_color: Palette.dark,
  inverse_content_color: Palette.light,
  background_modifier: 'shade', // shade adds black
  inverse_background_modifier: 'tint', // tint adds white
  intent: {
    positive: {
      color: Palette.positive,
      content_color: Palette.light,
    },
    negative: {
      color: Palette.negative,
      content_color: Palette.light,
    },
    action: {
      color: Palette.action,
      content_color: Palette.light,
    },
    warning: {
      color: Palette.warning,
      content_color: Palette.light,
    },
    subdued: {
      color: Palette.light,
      content_color: Palette.dark,
    },
    lingerie: {
      color: Palette.lingerie,
      content_color: Palette.dark,
    },
    facade: {
      color: Palette.facade,
      content_color: Palette.dark,
    },
    disabled: {
      color: Palette.disabled,
      content_color: Palette.dark,
    },
  },
  brand: {
    primary: {
      color: Palette.purple_reign,
      content_color: Palette.light,
    },
    secondary: {
      color: Palette.speedway_yellow,
      content_color: Palette.dark,
    },
    secondarypalette_orange: {
      color: Palette.speedway_orange,
      content_color: Palette.dark,
    },
    secondarypalette_purple: {
      color: Palette.speedway_purple,
      content_color: Palette.light,
    },
    secondarypalette_blue: {
      color: Palette.speedway_blue,
      content_color: Palette.light,
    },
    disabled: {
      color: Palette.disabled,
      content_color: Palette.dark,
    },
    secondarypalette_black: {
      color: Palette.kick_asphalt,
      content_color: Palette.light,
    },
  },
}

const darkTheme = {
  isLoading: false,
  name: 'Dark',
  theme: 'dark',
  inverse: 'dark',
  background_color: Palette.dark,
  inverse_background_color: Palette.dark,
  content_color: Palette.light,
  inverse_content_color: Palette.light,
  background_modifier: 'tint', // tint adds white
  inverse_background_modifier: 'tint', // shade adds black
  intent: {
    positive: {
      color: Palette.positive_dark,
      content_color: Palette.light,
    },
    negative: {
      color: Palette.negative,
      content_color: Palette.light,
    },
    action: {
      color: Palette.action,
      content_color: Palette.light,
    },
    warning: {
      color: Palette.warning,
      content_color: Palette.dark,
    },
    subdued: {
      color: Palette.subdued,
      content_color: Palette.light,
    },
    facade: {
      color: Palette.facade,
      content_color: Palette.dark,
    },
    disabled: {
      color: Palette.disabled,
      content_color: Palette.dark,
    },
  },
  brand: {
    primary: {
      color: Palette.purple_reign,
      content_color: Palette.light,
    },
    secondary: {
      color: Palette.speedway_yellow,
      content_color: Palette.dark,
    },
    secondarypalette_orange: {
      color: Palette.speedway_orange,
      content_color: Palette.dark,
    },
    secondarypalette_purple: {
      color: Palette.speedway_purple,
      content_color: Palette.light,
    },
    secondarypalette_blue: {
      color: Palette.speedway_blue,
      content_color: Palette.light,
    },
    disabled: {
      color: Palette.disabled,
      content_color: Palette.dark,
    },
    secondarypalette_black: {
      color: Palette.light,
      content_color: Palette.kick_asphalt,
    },
  },
}

const speedwayThemes = {
  Light: lightTheme,
  Dark: darkTheme,
}

const themeContext = createZustandContext(
  ({ initialProps, set, get }) => {
    const setThemeByName = (themeName) => {
      if (themeName == 'dark') {
        SetCookie('smi_theme', 'dark', 365)
        set(speedwayThemes.Dark)
      } else {
        SetCookie('smi_theme', 'light', 365)
        set(speedwayThemes.Light)
      }
    }

    const toggleTheme = () => {
      const { theme } = get()
      if (theme == 'light') {
        setThemeByName('dark')
      } else {
        setThemeByName('light')
      }
    }

    const setIsLoading = (isLoading) => {
      set({ isLoading })
    }

    return {
      setThemeByName,
      setIsLoading,
      toggleTheme,
      ...speedwayThemes.Light,
    }
  },
  {
    name: 'ThemeStore',
    missingSelectorBehavior: 'quiet for now', // "warn",
  },
)

export const useThemeStore = themeContext.useStore

themeContext.Wiring = () => {
  const setIsLoading = useThemeStore((x) => x.setIsLoading)
  useEffect(() => {
    window.addEventListener('pageshow', () => setIsLoading(false))
  }, [])
}

export const ThemeProvider = themeContext.Provider
export { speedwayThemes }
