import React, { useContext } from 'react'

import ClassNames from 'classnames'
import PropTypes from 'prop-types'

import ColorFinder from 'Utilities/ColorFinder'

import Brands from 'Constants/Brand'
import Fonts from 'Constants/Font'
import Intents from 'Constants/Intent'
import Sizes from 'Constants/Size'
import Tones from 'Constants/Tone'

import PanelContext from 'Contexts/PanelContext'

import styles from './Typography.module.scss'
import styled from 'styled-components'

const propTypes = {
  font: PropTypes.oneOf(Fonts),
  tone: PropTypes.oneOf(Tones),
  size: PropTypes.oneOf(Sizes),
  intent: PropTypes.oneOf(Intents),
  brand: PropTypes.oneOf(Brands),
  underline: PropTypes.bool,
  underlineHover: PropTypes.bool,
  strikethrough: PropTypes.bool,
  inline: PropTypes.bool,
  fill: PropTypes.bool,
  className: PropTypes.string,
  as: PropTypes.string,
  placeHolder: PropTypes.string,
  disabled: PropTypes.bool,
  hoverable: PropTypes.bool,
  dataTesting: PropTypes.string,
  condensed: PropTypes.bool,
}

const Typography = ({
  tone,
  size,
  font,
  brand,
  intent,
  inline,
  fill,
  children,
  className,
  underline,
  underlineHover,
  strikethrough,
  as,
  placeHolder,
  disabled,
  hoverable,
  dataTesting,
  condensed,
  ...props
}) => {
  const component = as || 'p'

  if (size > Sizes[Sizes.length - 1]) {
    size = Sizes[Sizes.length - 1]
  } else {
    for (const potentialSize of Sizes) {
      if (size <= potentialSize) {
        size = potentialSize
        break
      }
    }
  }

  tone = tone && tone.toLowerCase()
  intent = intent && intent.toLowerCase()
  brand = brand && brand.toLowerCase()

  const classNames = ClassNames(
    styles.typography,
    className,
    styles[font || 'regular'],
    underline && styles.underline,
    hoverable && styles.hoverable,
    as == 'a' && styles.link,
    strikethrough && styles.strikethrough,
  )

  const { color, hoverColor } = ColorFinder(intent, tone, brand, disabled)
  const panelContext = useContext(PanelContext)

  return (
    <StyledTypography
      as={component}
      textColor={color}
      textsize={size}
      condensed={condensed}
      inline={inline}
      fill={fill}
      hoverColor={hoverColor}
      className={classNames}
      underlineHover={underlineHover}
      invert={panelContext.invert}
      hoverable={hoverable}
      data-testid={dataTesting}
      placeholder={placeHolder}
      {...props}
    >
      {children}
    </StyledTypography>
  )
}

const StyledTypography = styled.div`
  color: ${(props) => props.textColor};
  ${(props) => (props.textsize ? `font-size: ${props.textsize}rem;` : '')}
  ${(props) =>
    props.textsize && !props.condensed
      ? `line-height: ${props.textsize * 1.5}rem;`
      : ''}
    ${(props) => props.inline && 'display: inline;'};
  ${(props) => props.fill && 'width: 100%;'};
  ${(props) => props.hoverable && '&:hover {color: ' + props.hoverColor + ';}'};

  a {
    color: ${(props) => props.textColor};

    &:hover {
      color: ${(props) => props.hoverColor};
    }
  }

  ${(props) => props.invert && '&:hover {color: ' + props.hoverColor + ';}'};
  ${(props) =>
    props.underlineHover && '&:hover { text-decoration: underline; }'}
`

Typography.propTypes = propTypes

export default Typography
