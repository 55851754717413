'use client'

import createZustandContext from 'Utilities/Zustand/createZustandContext'

const getLockKey = (key) => {
  return `${key}_lock`
}

const getTriggerKey = (key) => {
  return `${key}_trigger`
}

const GlobalStateStore = createZustandContext(
  ({ set, get }) => {
    const setValue = (key, value) => {
      set({ [key]: value })
    }
    const lock = (key) => {
      set({ [getLockKey(key)]: true })
    }
    const unlock = (key) => {
      set({ [getLockKey(key)]: false })
    }

    const isLocked = (key) => {
      return get()[getLockKey(key)]
    }

    const setLastTriggers = (triggers, key) => {
      set({ [getTriggerKey(key)]: JSON.stringify(triggers) })
    }

    const doTriggersMatch = (triggers, key) => {
      return JSON.stringify(triggers) === get()[getTriggerKey(key)]
    }

    return {
      setValue,
      lock,
      unlock,
      isLocked,
      setLastTriggers,
      doTriggersMatch,
    }
  },
  {
    name: 'GlobalStateStore',
    missingSelectorBehavior: 'warn',
  },
)

export const useGlobalStateStore = GlobalStateStore.useStore
export const GlobalStateStoreProvider = GlobalStateStore.Provider
