import SetCookie from 'Utilities/CookieUtilties/SetCookie'
import { tryUpdateLocation } from 'Utilities/Location/Location'

import useFeatureFlag from 'Hooks/useFeatureFlag/useFeatureFlag'

export async function UpdateUserProfile(
  cacheKey,
  customerServiceUrl,
  userGuidFromCookie,
  authGuidOverwrite,
) {
  if (!userGuidFromCookie) {
    return null
  }

  try {
    const response = await fetch(customerServiceUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `query GetUserProfile($webUserGuid: String = null) {
                    userProfile(webUserGuid: $webUserGuid) {
                        emailAddress
                        firstName
                        lastName
                        userGuid
                        userName
                        isRegistered
                        sopCustomerId
                        isSignedUpForEmails
                        zipCode
                        isInternational
                        isCustomerInternal
                        isSpecialPriceEligible
                        isLoyaltyBetaUser
                        isLoyaltyRegisteredUser
                        isBrowsingCustomerAuthorized
                    }
                }`,

        variables: {
          webUserGuid: userGuidFromCookie,
        },
      }),
      credentials: 'include',
    })

    const data = await response.json()
    if (data.errors) {
      data.errors.forEach((x) => console.error(x.message))
      throw 'Server side error when attempting to log in customer'
    }

    const userProfile = tryUpdateLocation(data?.data?.userProfile)

    let userGuid = userGuidFromCookie
    if (
      authGuidOverwrite &&
      userProfile?.userGuid !== undefined &&
      userProfile.userGuid !== userGuid
    ) {
      userGuid = userProfile.userGuid
      SetCookie('WebUserGuid', userGuid, 365)
    }

    return {
      ...userProfile,
      hydrated: true,
      userGuid: userGuid,
    }
  } catch (error) {
    console.error(error)
    return null
  }
}
