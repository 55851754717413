import styled from 'styled-components'

const spacingWrapper = (Component) => styled(Component)`
  margin-bottom: ${(props) => mapSizeToRem(props.spacingSize)}rem;
`

function mapSizeToRem(spacingSize) {
  spacingSize = spacingSize?.toLowerCase() ?? 'md'

  // todo, we should get rid of these in favor of spacing.module.scss
  switch (spacingSize) {
    case 'none':
      return 0
    case 'xxsm':
      return 0.25
    case 'xsm':
      return 0.5
    case 'sm':
      return 0.75
    case 'md':
      return 1
    case 'lg':
      return 1.5
    case 'xlg':
      return 2
    case 'xxlg':
      return 3
    case 'jumbo-sm':
      return 4
    case 'jumbo-md':
      return 4.5
    case 'jumbo-lg':
      return 5
  }
}

export default spacingWrapper
