'use client'

import React, { useContext } from 'react'

import ClassNames from 'classnames'
import Image from 'next/image'
import PropTypes from 'prop-types'

import ColorFinder from 'Utilities/ColorFinder'
import LayerColorFinder from 'Utilities/LayerColorFinder'

import Brands from 'Constants/Brand'
import Intents from 'Constants/Intent'

import PanelContext from 'Contexts/PanelContext'

import Skeleton from 'Atoms/Skeleton'

import styled from 'styled-components'

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  layer: PropTypes.oneOf([0, 1, 2, 3]),
  brand: PropTypes.oneOf(Brands),
  intent: PropTypes.oneOf(Intents),
  inverseTheme: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  fillHeight: PropTypes.bool,
  minheight: PropTypes.string,
  border: PropTypes.bool,
  borderRadius: PropTypes.bool,
  image: PropTypes.string,
}

const Panel = ({
  children,
  id,
  className,
  layer,
  image,
  brand,
  intent,
  inverseTheme,
  width,
  height,
  fillHeight,
  minheight,
  border,
  borderRadius,
  transparent,
  borderColor,
  imageLoading,
  imageOpacity,
  ...props
}) => {
  const panelContext = useContext(PanelContext)

  inverseTheme =
    inverseTheme !== undefined
      ? inverseTheme
      : panelContext.layer !== undefined
        ? panelContext.inverseTheme
        : false
  layer =
    layer !== undefined
      ? layer
      : panelContext.layer !== undefined
        ? panelContext.layer + 1
        : 0

  const classNames = ClassNames(
    className,
    `bg-color-${brand ?? intent ?? 'none'}`,
    `layer-${layer}`,
    inverseTheme ? 'layer-inverse' : '',
  )
  const ids = ClassNames(id)

  const backgroundLayerColor = LayerColorFinder(
    layer,
    inverseTheme,
    brand,
    intent,
  )

  let backgroundColor =
    transparent && !imageLoading ? 'transparent' : backgroundLayerColor

  if (layer == 0 && intent == 'positive') {
    const hue = backgroundColor.split(',')[0].replace('hsla(', '')
    const saturation = backgroundColor.split(',')[1]
    const lightness = backgroundColor.split(',')[2].replace('%', '')
    const alpha = backgroundColor.split(',')[3].replace(')', '')
    backgroundColor = `hsla(${hue},${saturation},${lightness - 5}%,${alpha})`
  }

  const borderColorColor = ColorFinder(intent, 'subtle', brand)?.color

  const panelBorder = borderColor ? borderColor : borderColorColor
  const panelContextValue = {
    inverseTheme: inverseTheme,
    layer: layer,
    brand: brand,
    intent: intent,
  }

  let imageO = imageOpacity ? 0.8 : 1
  imageO = imageOpacity && typeof imageOpacity == 'number' ? imageOpacity : 1

  return (
    <PanelContext.Provider value={panelContextValue}>
      <StyledPanel
        className={classNames}
        {...(ids ? { id: ids } : {})}
        width={width}
        height={height}
        minheight={minheight}
        fillHeight={fillHeight}
        image={image}
        backgroundColor={backgroundColor}
        {...props}
        border={border}
        borderRadius={borderRadius}
        hoverColor={panelBorder}
        imageOpacity={imageOpacity}
      >
        {image &&
          (imageLoading ? null : (
            <Image
              src={
                image ??
                'https://content.speedwaymotors.com/OtherImages/missingimage2.jpg'
              }
              alt=""
              fill
              style={{
                position: 'absolute',
                inset: 0,
                objectFit: 'cover',
                objectPosition: 'right',
                width: '100%',
                height: '100%',
                opacity: imageO,
              }}
            />
          ))}

        {children}
      </StyledPanel>
    </PanelContext.Provider>
  )
}

const StyledPanel = styled.div`
  width: ${(props) => props.width && props.width};
  height: ${(props) =>
    props.fillHeight ? '100%' : props.height && props.height};
  background: ${(props) => props.backgroundColor};
  min-height: ${(props) => props.minheight && props.minheight};
  border: ${(props) =>
    props.border ? '1px solid ' + props.hoverColor : 'none'};
  border-radius: ${(props) => (props.borderRadius ? '4px' : null)};
  ${(props) => (props.image ? `overflow: hidden;` : null)}
  ${(props) => (props.image ? `position: relative;  ` : null)}
  --spinnerColor: ${(props) => props.hoverColor};
  --hoverColor: ${(props) => props.backgroundColor};
`

Panel.propTypes = propTypes

export default Panel
