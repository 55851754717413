import React, { useEffect } from 'react'

import Head from 'next/head'
import Script from 'next/script'

export default function GoogleAds() {
  // google automatically handles GPC-- see here
  // https://support.google.com/google-ads/answer/9614122?hl=en
  return (
    <>
      {/* We want to get this as close to google's documentation as possible, so use "script" instead of next/script
      See WIFR-32268 */}
      <Head>
        <script
          id="gads-script"
          data-testid="gads-script"
          src={`https://www.googletagmanager.com/gtag/js?id=AW-1061040585`}
          async
        />
      </Head>
      {/* Don't put in head tag according to next docs */}
      <Script id="googleAds-script-config" strategy="beforeInteractive">
        {`
            window.dataLayer = window.dataLayer || []; function gtag() { dataLayer.push(arguments); }
            window.gtag('js', new Date());
            window.gtag('config', 'AW-1061040585', {
                'linker': {
                    'domains': ['app.maker.co']
                },
                'groups': ['ads']
            });
          `}
      </Script>
    </>
  )
}
