import { useLazyFeatureFlag } from 'Hooks/useFeatureFlag/useFeatureFlag'

import useLockedAsyncEffect from '../../../Hooks/useLockedAsyncEffect'
import Retry from '../../../Utilities/Retry'
import { useUserSpecificProductDataStore } from '../UserSpecificProductDataStore'

export default function LazyLoadProductActivityData({ disable }) {
  const featureFlag = useLazyFeatureFlag('Product_Activity_Frontend_Enabled')

  const trackedSkuBases = useUserSpecificProductDataStore(
    (x) => x.trackedSkuBases,
  )

  const productActivityDataBySkuBasesOrVariants =
    useUserSpecificProductDataStore(
      (x) => x.productActivityDataBySkuBasesOrVariants,
    )
  const addProductActivityDataForSkuBasesOrVariants =
    useUserSpecificProductDataStore(
      (x) => x.addProductActivityDataForSkuBasesOrVariants,
    )

  async function loadProductActivityData() {
    if (disable || featureFlag == false) {
      return
    }

    const skuBasesToRequest =
      trackedSkuBases.filter(
        (skuBase) => !productActivityDataBySkuBasesOrVariants[skuBase],
      ) ?? []

    if (skuBasesToRequest.length) {
      const utility_module = await Retry(
        () => import('Utilities/ProductActivity/productActivities'),
      )
      const getProductActivities = utility_module.default
      const productActivityData = await getProductActivities(skuBasesToRequest)

      ;(productActivityData || []).forEach((productActivity) => {
        addProductActivityDataForSkuBasesOrVariants(
          productActivity.key,
          productActivity,
        )
      })
    }
  }

  useLockedAsyncEffect(async () => {
    await loadProductActivityData()
  }, [disable, trackedSkuBases])

  return false
}
