'use client'

import React, { useContext, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import { useRecommendationStore } from 'Stores/RecommendationStore/RecommendationStore'
import ErrorBoundary from 'UtilityComponents/ErrorBoundary'

import RecommendationBlock from 'Organisms/RecommendationBlock'

import RecommendationsSkeleton from 'Molecules/RecommendationsSkeleton'

import Typography from 'Atoms/Typography'

import Spacing from '../../Atoms/Spacing/Spacing'
import { useRecommendationSection } from '../../Stores/RecommendationStore/RecommendationStore'
import styles from './RecommendationSection.module.scss'

const RecommendationSection = ({
  section,
  isCard,
  spacingSize,
  testData,
  className,
  useDivider,
  useSubtitle,
  useItemCount,
  displayType,
  leftAlignCarousel,
  horizontalCard,
  cardDisplayType,
  isExpandable,
  skeletonDisplayType,
  numberOfSkeletons = 3,
  numberOfSkeletonSections = 1,
  skeletonType,
  showCarouselGradient,
  extendGuttersForCarousel,
  lazyMargin,
  cardProps,
  noMargin,
  showAddToCart,
  displayNoRecMessage = false,
}) => {
  const isLoading = useRecommendationStore((x) => x.isLoading)
  const setRecommendationSectionInView = useRecommendationStore(
    (x) => x.setRecommendationSectionInView,
  )
  const sectionData = testData ? testData : useRecommendationSection(section)

  const inViewObj = {
    triggerOnce: true,
    threshold: 0,
    rootMargin: `${lazyMargin ? lazyMargin : '400px'} 0px`,
  }

  const { ref, inView } = useInView(inViewObj)

  useEffect(() => {
    if (inView) {
      setRecommendationSectionInView(section)
    }
  }, [inView])

  displayType = (
    displayType || sectionData?.recommendationGroups?.displayType
  )?.toLowerCase()

  if (!isLoading && !sectionData?.recommendationGroups?.length) {
    if (displayNoRecMessage) {
      return (
        <div className={styles.noRecs}>
          <Typography>No recommendations could be found.</Typography>
        </div>
      )
    }
    return false
  }

  const wrapperClass = () => {
    if (displayType == 'card') {
      return styles.recommendation_cards
    } else if (!noMargin) {
      return styles.recSection
    }
    return
  }

  return (
    <ErrorBoundary>
      <div className={wrapperClass()}>
        <div ref={ref} />
        {sectionData?.recommendationGroups?.map((recBlock, blockIndex) => (
          <Spacing
            key={blockIndex}
            spacingSize={spacingSize}
            className={className}
          >
            {isLoading && !testData?.hydrated ? (
              <RecommendationsSkeleton
                dataTestId={section}
                displayType={skeletonDisplayType || 'Carousel'}
                numberOfSkeletons={numberOfSkeletons}
                skeletonType={skeletonType}
                blockIndex={blockIndex}
                isCard={isCard}
                useDivider={useDivider}
                useSubtitle={useSubtitle}
                useItemCount={useItemCount}
                leftAlignCarousel={leftAlignCarousel}
                horizontalCard={horizontalCard}
                cardDisplayType={cardDisplayType}
                className={styles.recBottomSpacing}
                cardProps={cardProps}
              />
            ) : (
              <RecommendationBlock
                cardProps={cardProps}
                index={blockIndex}
                recBlock={recBlock}
                isCard={isCard}
                displayType={displayType}
                isExpandable={isExpandable}
                numberOfSkeletons={numberOfSkeletons}
                numberOfSkeletonSections={numberOfSkeletonSections}
                skeletonType={skeletonType}
                dataTestId={section}
                treatment={sectionData.treatment}
                id={`${section}_recs_${blockIndex}`}
                useDivider={useDivider}
                useSubtitle={useSubtitle}
                useItemCount={useItemCount}
                leftAlignCarousel={leftAlignCarousel}
                horizontalCard={horizontalCard}
                cardDisplayType={cardDisplayType}
                className={styles.recBottomSpacing}
                showCarouselGradient={showCarouselGradient}
                extendGuttersForCarousel={extendGuttersForCarousel}
                showAddToCart={showAddToCart}
              />
            )}
          </Spacing>
        )) ?? (
          <RecommendationsSkeleton
            dataTestId={section}
            displayType={skeletonDisplayType || 'Carousel'}
            numberOfSkeletons={numberOfSkeletons}
            numberOfSkeletonSections={numberOfSkeletonSections}
            skeletonType={skeletonType}
            blockIndex={0}
            isCard={isCard}
            useDivider={useDivider}
            useSubtitle={useSubtitle}
            useItemCount={useItemCount}
            leftAlignCarousel={leftAlignCarousel}
            horizontalCard={horizontalCard}
            cardDisplayType={cardDisplayType}
            className={styles.recBottomSpacing}
            cardProps={cardProps}
            spacingSize={spacingSize}
          />
        )}
      </div>
    </ErrorBoundary>
  )
}

export default RecommendationSection
