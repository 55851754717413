import React, { useContext, useEffect, useState } from 'react'

import Svg from 'Atoms/Svg'

import useNavigationState from '../../../Hooks/useAppInstall/useNavigationState'
import styles from './BackButton.module.scss'

const BackButton = ({ show = true }) => {
  const { showNavigation } = useNavigationState()
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  const onClick = () => {
    showNavigation()
    window.history.back()
  }

  if (!show || (isClient && window?.location?.pathname === '/')) {
    return null
  }

  return (
    <div
      role={`button`}
      data-testid={'pwaBackButton'}
      onClick={onClick}
      title={`Back to previous`}
      className={styles.button}
    >
      <Svg size={1.5} icon={'backarrow'} />
    </div>
  )
}

export default BackButton
