import React, { useContext } from 'react'

import ClassNames from 'classnames'
import PropTypes from 'prop-types'

import LayerColorFinder from 'Utilities/LayerColorFinder'

import Brands from 'Constants/Brand'
import Intents from 'Constants/Intent'

import PanelContext from 'Contexts/PanelContext'

import Panel from 'Atoms/Panel'
import Typography from 'Atoms/Typography'

import styles from './Badge.module.scss'
import styled from 'styled-components'

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  brand: PropTypes.oneOf(Brands),
  intent: PropTypes.oneOf(Intents),
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', undefined]),
  position: PropTypes.oneOf([
    'topRight',
    'topLeft',
    'bottomLeft',
    'bottomRight',
    undefined,
  ]),
  onClick: PropTypes.func,
  number: PropTypes.number,
  invert: PropTypes.bool,
}

const sizes = {
  xsmall: { badge: 10, font: 8 },
  small: { badge: 12, font: 8 },
  medium: { badge: 14, font: 10 },
  large: { badge: 16, font: 12 },
}

const Badge = ({
  className,
  children,
  brand,
  intent,
  onClick,
  size,
  number,
  position,
  hover = true,
  hideIfNumberIsFalsy,
  testid = 'badgeContainer',
}) => {
  const panelContext = useContext(PanelContext)
  const layer = panelContext?.layer

  const backgroundColor = LayerColorFinder(layer, panelContext.inverseTheme)
  const backgroundHoverColor = LayerColorFinder(
    layer + 1,
    panelContext.inverseTheme,
  )

  let parsedNumber = parseInt(number)
  if (hideIfNumberIsFalsy && (!parsedNumber || !number)) {
    return children
  }

  if (!children) return false
  if (!intent && !brand) return false
  if (isNaN(parsedNumber)) return false

  intent = intent && intent.toLowerCase()
  brand = brand && brand.toLowerCase()
  size = (size && size.toLowerCase()) || 'large'
  position = position || 'topRight'

  const isOblong = parsedNumber > 9 ? true : false

  const badgeSize = sizes[size].badge
  const xPosition = isOblong ? badgeSize + 5 : badgeSize / 2
  const yPosition = badgeSize / 2
  const fontSize = sizes[size].font

  if (parsedNumber > 99) {
    parsedNumber = '99+'
  }

  if (parsedNumber === 0) {
    parsedNumber = ''
  }

  return (
    <div
      data-testid={testid}
      className={styles.badgeContainer}
      onClick={onClick}
    >
      {children}
      <BadgeBorder
        className={ClassNames(styles.badgeBorder, styles.badge)}
        position={position}
        xPosition={xPosition}
        yPosition={yPosition}
        isOblong={isOblong}
        style={{
          '--borderHoverColor': hover ? backgroundHoverColor : backgroundColor,
        }}
        backgroundColor={backgroundColor}
      >
        <StyledBadge
          className={ClassNames(className, styles.badge)}
          brand={brand}
          intent={intent}
          size={badgeSize}
          isOblong={isOblong}
          inverseTheme={false}
        >
          <Typography
            size={size == 'small' ? 0.5 : 0.75}
            font={'bold'}
            data-testid={'badgeText'}
          >
            {parsedNumber}
          </Typography>
        </StyledBadge>
      </BadgeBorder>
    </div>
  )
}

const BadgeBorder = styled.div`
  ${(props) =>
    props.position === 'topRight'
      ? `right: -${props.xPosition}px; top: -${props.yPosition}px;`
      : null}
  ${(props) =>
    props.position === 'topLeft'
      ? `left: -${props.xPosition}px; top: -${props.yPosition}px; `
      : null}
    ${(props) =>
    props.position === 'bottomLeft'
      ? `left: -${props.xPosition}px; bottom: -${props.yPosition}px; `
      : null}
    ${(props) =>
    props.position === 'bottomRight'
      ? `right: -${props.xPosition}px; bottom: -${props.yPosition}px; `
      : null}
    border-radius: ${(props) => (props.isOblong ? '12px' : '50%')};
  background-color: ${(props) => props.backgroundColor};
  &:hover {
    background-color: ${(props) => props.backgroundHoverColor};
  }
`

const StyledBadge = styled(Panel)`
  height: ${(props) => props.size}px;
  border-radius: ${(props) => (props.isOblong ? '12px' : '50%')};
  ${(props) =>
    props.isOblong ? `min-width: ${props.size}px;` : `width: ${props.size}px;`}
  ${(props) =>
    props.isOblong ? `padding-left: 5px; padding-right: 5px` : null}
`

Badge.propTypes = propTypes

export default Badge
