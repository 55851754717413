import React, { createContext, useContext } from 'react'

const ServicesContext = createContext()

const ServicesProvider = ({ children, services }) => {
  return (
    <ServicesContext.Provider value={services}>
      {children}
    </ServicesContext.Provider>
  )
}

const useServicesContext = () => {
  const services = useContext(ServicesContext)
  return services
}

export { ServicesProvider, useServicesContext }
