import {
  GetDeviceGuid,
  GetUserGuid,
} from 'Utilities/CookieUtilties/UserCookieUtilities'
import RestRequest from 'Utilities/RestClient/RestClient'

import { StorageAccessor } from '../../Utilities/LocalStorageUtility/LocalStorageUtility'
import { isInApp } from './AppUtilities'

export const handleSubscription = async (openUserPermissionPrompt) => {
  if (!doesDeviceSupportNotifications()) {
    return
  }
  const existing = await getSubscription()
  if (existing) {
    await handleRoutinePushSubscriptionUpdate(openUserPermissionPrompt)
    return
  }
  await handleInitPromptShow(openUserPermissionPrompt)
}

export const handleUserPermissionPromptDismiss = async (successCallback) => {
  logUserWasShownPrompt()
  sendSegmentClickedEvent(false)
  successCallback(true)
}

export const handleUserPermissionPromptSubmission = async (
  userPermissions,
  successCallback,
) => {
  try {
    if (!userPermissions.some((x) => x.granted)) {
      sendSegmentClickedEvent(false, 'via none selected')
      logUserWasShownPrompt()
      successCallback(true)
      return
    }
    if (!doesDeviceSupportNotifications()) {
      throw `A device with no push notification support was prompted`
    }

    const devicePermission = await getDevicePermission()
    if (devicePermission !== 'granted') {
      sendSegmentClickedEvent(false, 'via device permission refusal')
      logUserWasShownPrompt()
      successCallback(true)
      return
    }

    const sub = await getSubscription()
    await sendSubcriptionToServer(sub, userPermissions)
    sendSegmentClickedEvent(true)
    logUserWasShownPrompt()
    successCallback(true)
  } catch (err) {
    logUserWasShownPrompt()
    successCallback(false)
    console.error(
      `unexpected error during handleManualPushSubscriptionUpdate`,
      err,
    )
  }
}

export const getCurrentUserPushPermissions = async () => {
  try {
    if (!doesDeviceSupportNotifications() || !isInApp()) {
      return null
    }
    const response = await RestRequest(
      'api/customer/pushnotifications/getuserpermissions',
      { deviceGuid: GetDeviceGuid() },
    )
    if (!response?.length) {
      return null
    }
    sendSegmentViewedEvent()
    response.forEach((x) => (x.granted = x.granted ?? true))
    return response
  } catch (err) {
    console.error(`unexpected error during getCurrentUserPushPermissions`)
  }
}

const segmentElementPrefix = `Push Note User Permission`
const sendSegmentClickedEvent = (accepted, elementDetails = '') => {
  try {
    analytics.track('Clicked', {
      element:
        `${segmentElementPrefix} - ${accepted ? 'Accepted' : 'Dismissed'} ${elementDetails}`.trim(),
    })
  } catch (err) {
    console.error(
      `error sending ${segmentElementPrefix} segment clicked event`,
      err,
    )
  }
}
const sendSegmentViewedEvent = () => {
  try {
    analytics.track('Viewed', { element: `${segmentElementPrefix}` })
  } catch (err) {
    console.error(
      `error sending ${segmentElementPrefix} segment viewed event`,
      err,
    )
  }
}

const handleRoutinePushSubscriptionUpdate = async (
  openUserPermissionPrompt,
) => {
  const sub = await getSubscription()
  const response = await sendSubcriptionToServer(sub)
  if (response?.shouldPromptForUserPermissions) {
    console.log(
      'an existing push notification subscription did not have any user permissions',
    )
    if (hasUserBeenShownPrompt()) {
      return
    }
    openUserPermissionPrompt()
  }
}

const handleInitPromptShow = async (openUserPermissionPrompt) => {
  if (!isInApp()) {
    return
  }

  if (hasUserBeenShownPrompt()) {
    return
  }
  openUserPermissionPrompt()
}

const key = 'PushNotePromptShown'
const logUserWasShownPrompt = () => {
  StorageAccessor.localStorage.setObj(key, true, 20160)
}

const hasUserBeenShownPrompt = () => {
  return StorageAccessor.localStorage.getObj(key) ?? false
}

const getDevicePermission = async () => {
  return await window.Notification.requestPermission()
}

const doesDeviceSupportNotifications = () => {
  return window.Notification && window.Notification.permission !== 'denied'
}

const getExistingSubscription = async () => {
  const registration = await getServiceWorkerReg()
  return await registration.pushManager.getSubscription()
}

const getServiceWorkerReg = async () => {
  return await window.navigator.serviceWorker.ready
}

const getSubscription = async () => {
  const subscription = await getExistingSubscription()
  if (subscription) {
    return subscription
  }
  if (window.Notification.permission !== 'granted') {
    return null
  }

  const registration = await getServiceWorkerReg()
  console.log('creating new subscription')
  return await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey:
      'BAwjUb6vTdKfzqwANnQd7_JpNM-TeipvVrhJBgvNKNiSQrJ3-3qmsbbxA3iawnQLpSWxRArCFJcEjZaZjme_CeY',
  })
}

const sendSubcriptionToServer = async (
  subscription,
  userPermissions = null,
) => {
  const request = {
    key: arrayBufferToString(subscription.getKey('p256dh')),
    endpoint: subscription.endpoint,
    authSecret: arrayBufferToString(subscription.getKey('auth')),
    webUserGuid: GetUserGuid(),
    deviceGuid: GetDeviceGuid(),
    userPermissions,
  }

  const response = await RestRequest(
    'api/customer/pushnotifications/createorupdatesubscription',
    null,
    request,
  )
  console.log(`${response?.message ?? 'server message fallback'}`)
  if (!response?.success) {
    throw `Server returned non success when submitting a push note subscrition: ${response.message}`
  }
  return response
}

function arrayBufferToString(buffer) {
  if (typeof buffer == 'string') {
    return buffer
  }
  return btoa(String.fromCharCode.apply(null, new Uint8Array(buffer)))
}
