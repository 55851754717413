import React from 'react'

import Typography from 'Atoms/Typography'

const Fieldset = ({ legend, children }) => {
  return (
    <fieldset>
      <Typography as={'legend'} size={1}>
        {legend}
      </Typography>
      {children}
    </fieldset>
  )
}

export default Fieldset
