import React, { useContext } from 'react'

import { useThemeStore } from 'Stores/ThemeStore'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'

import ColorFinder from 'Utilities/ColorFinder'
import LayerColorFinder from 'Utilities/LayerColorFinder'

import PanelContext from 'Contexts/PanelContext'

import Svg from 'Atoms/Svg'

import styles from './PaginationControl.module.scss'

const propTypes = {
  className: PropTypes.string,
  direction: PropTypes.string,
  onClick: PropTypes.func,
  intent: PropTypes.string,
  brand: PropTypes.string,
  isStaticBackground: PropTypes.bool,
}

const PaginationControl = ({
  className,
  direction,
  onClick,
  intent,
  brand,
  isStaticBackground,
  inverseTheme,
  hideOnMobile = true,
  inverseThemeBrand = 'secondary',
  ...props
}) => {
  const theme = useThemeStore((x) => x?.theme)

  const panelContext = useContext(PanelContext)

  inverseTheme = theme == 'dark' || panelContext?.inverseTheme || inverseTheme

  if (!intent && !brand) return false
  if (!direction) return false
  if (direction != 'left' && direction != 'right') return false

  const { controlHoverBorderColor, controlHoverBackgroundColor } =
    getBorderAndBackgroundColor(intent, brand)
  const inverseControlHoverBorderColor = getBorderAndBackgroundColor(
    undefined,
    inverseThemeBrand,
  ).controlHoverBorderColor
  const inverseControlHoverBackgroundColor = getBorderAndBackgroundColor(
    undefined,
    inverseThemeBrand,
  ).controlHoverBackgroundColor

  const colorStyles = {
    '--hoverBackgroundColor': isStaticBackground
      ? inverseTheme
        ? LayerColorFinder(3, true)
        : LayerColorFinder(0)
      : inverseTheme
        ? inverseControlHoverBackgroundColor
        : controlHoverBackgroundColor,
    '--hoverBorderColor': inverseTheme
      ? inverseControlHoverBorderColor
      : controlHoverBorderColor,
    '--backgroundColor': inverseTheme
      ? LayerColorFinder(3, true)
      : LayerColorFinder(0),
  }

  return (
    <button
      className={ClassNames(
        className,
        styles.carouselControl,
        hideOnMobile ? styles.carouselControlHidden : null,
        direction == 'left'
          ? styles.carouselControlLeft
          : styles.carouselControlRight,
      )}
      onClick={onClick}
      style={colorStyles}
      {...props}
    >
      {direction == 'left' ? (
        <Svg
          tone={'normal'}
          size={0.75}
          icon={'chevron-left'}
          className={styles.controlLeftSvg}
        />
      ) : (
        <Svg
          tone={'normal'}
          size={0.75}
          icon={'chevron'}
          className={styles.controlRightSvg}
        />
      )}
    </button>
  )
}

PaginationControl.propTypes = propTypes

const getBorderAndBackgroundColor = (intent, brand) => {
  const { color } = ColorFinder(intent, undefined, brand)
  const controlHoverHue = color.split(',')[0].replace('hsla(', '')
  const controlHoverSaturation = color.split(',')[1]
  const controlHoverLightness = color.split(',')[2]
  const controlHoverAlpha = color.split(',')[3].replace(')', '')
  const controlHoverBorderColor = `hsla(${controlHoverHue},${controlHoverSaturation},${controlHoverLightness},${controlHoverAlpha})`
  const controlHoverBackgroundColor = `hsla(${controlHoverHue},${controlHoverSaturation},${controlHoverLightness},${
    0.2 * controlHoverAlpha
  })`
  return { controlHoverBorderColor, controlHoverBackgroundColor }
}

export default PaginationControl
