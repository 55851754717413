import React from 'react'

import RecommendationsGrid from 'Containers/RecommendationsGrid'
import RecommendationsVertical from 'Containers/RecommendationsVertical'
import classNames from 'classnames'

import Card from 'Atoms/Card'

import RecommendationsCard from '../../Containers/RecommendationsCard/RecommendationsCard'
import RecommendationsCarousel from '../../Containers/RecommendationsCarousel'
import RecommendationsCategoryGrid from '../../Containers/RecommendationsCategoryGrid'
import RecommendationsCompare from '../../Containers/RecommendationsCompare/RecommendationsCompare'
import styles from './RecommendationBlock.module.scss'

const RecommendationBlock = ({
  recBlock,
  dataTestId,
  index,
  id,
  numberOfSkeletons,
  numberOfSkeletonSections,
  skeletonDisplayType,
  skeletonType,
  displayType,
  isCard,
  isExpandable,
  isLoading,
  useDivider,
  useSubtitle,
  useItemCount,
  leftAlignCarousel,
  horizontalCard,
  cardDisplayType,
  showCarouselGradient,
  extendGuttersForCarousel,
  className,
  treatment,
  showAddToCart,
  cardProps,
}) => {
  displayType = (recBlock?.displayType || displayType)?.toLowerCase()

  const isClientProductDataLoading =
    recBlock.clientDataType == 'PdpRecentHistory' && !recBlock.clientHydrated

  if (!recBlock?.recommendations?.length && !isClientProductDataLoading) {
    return false
  } else {
    return isCard ? (
      <Card fill className={classNames(className, styles.card)} {...cardProps}>
        <RecommendationBlockBase
          recBlock={recBlock}
          dataTestId={dataTestId}
          index={index}
          id={id}
          isLoading={isLoading}
          numberOfSkeletons={numberOfSkeletons}
          numberOfSkeletonSections={numberOfSkeletonSections}
          skeletonDisplayType={skeletonDisplayType}
          skeletonType={skeletonType}
          isExpandable={isExpandable}
          displayType={displayType}
          useDivider={useDivider}
          useSubtitle={useSubtitle}
          useItemCount={useItemCount}
          leftAlignCarousel={leftAlignCarousel}
          horizontalCard={horizontalCard}
          cardDisplayType={cardDisplayType}
          showCarouselGradient={showCarouselGradient}
          extendGuttersForCarousel={extendGuttersForCarousel}
          treatment={treatment}
          showAddToCart={showAddToCart}
        />
      </Card>
    ) : (
      <RecommendationBlockBase
        recBlock={recBlock}
        dataTestId={dataTestId}
        index={index}
        id={id}
        isLoading={isLoading}
        skeletonDisplayType={skeletonDisplayType}
        numberOfSkeletons={numberOfSkeletons}
        numberOfSkeletonSections={numberOfSkeletonSections}
        skeletonType={skeletonType}
        isExpandable={isExpandable}
        displayType={displayType}
        useDivider={useDivider}
        useSubtitle={useSubtitle}
        useItemCount={useItemCount}
        className={className}
        leftAlignCarousel={leftAlignCarousel}
        horizontalCard={horizontalCard}
        cardDisplayType={cardDisplayType}
        showCarouselGradient={showCarouselGradient}
        extendGuttersForCarousel={extendGuttersForCarousel}
        treatment={treatment}
        showAddToCart={showAddToCart}
      />
    )
  }
}

const RecommendationBlockBase = ({
  recBlock,
  dataTestId,
  index,
  id,
  skeletonDisplayType,
  numberOfSkeletons,
  numberOfSkeletonSections,
  skeletonType,
  displayType,
  isExpandable,
  isLoading,
  useDivider,
  useSubtitle,
  useItemCount,
  horizontalCard,
  leftAlignCarousel,
  cardDisplayType,
  showCarouselGradient,
  extendGuttersForCarousel,
  className,
  treatment,
  showAddToCart,
}) => {
  displayType = displayType?.toLowerCase()

  if (displayType === 'grid') {
    return (
      <RecommendationsGrid
        recBlock={recBlock}
        dataTestId={dataTestId}
        blockIndex={index}
        isLoading={isLoading}
        skeletonDisplayType={skeletonDisplayType}
        numberOfSkeletons={numberOfSkeletons}
        numberOfSkeletonSections={numberOfSkeletonSections}
        skeletonType={skeletonType}
        isExpandable={isExpandable}
        useDivider={useDivider}
        useSubtitle={useSubtitle}
        useItemCount={useItemCount}
        horizontalCard={horizontalCard}
        className={className}
        treatment={treatment}
      />
    )
  } else if (displayType === 'vertical') {
    return (
      <RecommendationsVertical
        recBlock={recBlock}
        dataTestId={dataTestId}
        blockIndex={index}
        isLoading={isLoading}
        skeletonDisplayType={skeletonDisplayType}
        numberOfSkeletons={numberOfSkeletons}
        numberOfSkeletonSections={numberOfSkeletonSections}
        isExpandable={isExpandable}
        useDivider={useDivider}
        useSubtitle={useSubtitle}
        useItemCount={useItemCount}
        className={className}
        treatment={treatment}
        showAddToCart={showAddToCart}
      />
    )
  } else if (displayType === 'card') {
    return (
      <RecommendationsCard
        recBlock={recBlock}
        dataTestId={dataTestId}
        blockIndex={index}
        isLoading={isLoading}
        skeletonDisplayType={skeletonDisplayType}
        numberOfSkeletons={numberOfSkeletons}
        numberOfSkeletonSections={numberOfSkeletonSections}
        isExpandable={isExpandable}
        useDivider={useDivider}
        useSubtitle={useSubtitle}
        useItemCount={useItemCount}
        horizontalCard={horizontalCard}
        cardDisplayType={cardDisplayType}
        treatment={treatment}
      />
    )
  } else if (displayType === 'comparison') {
    return (
      <RecommendationsCompare
        recBlock={recBlock}
        dataTestId={dataTestId}
        blockIndex={index}
        displayType={displayType}
        isLoading={isLoading}
        skeletonDisplayType={skeletonDisplayType}
        numberOfSkeletons={numberOfSkeletons}
        numberOfSkeletonSections={numberOfSkeletonSections}
        treatment={treatment}
      />
    )
  } else if (displayType === 'categorygrid') {
    return (
      <RecommendationsCategoryGrid recBlock={recBlock} isLoading={isLoading} />
    )
  } else {
    return (
      <RecommendationsCarousel
        recBlock={recBlock}
        dataTestId={dataTestId}
        blockIndex={index}
        id={id}
        isLoading={isLoading}
        skeletonDisplayType={skeletonDisplayType}
        numberOfSkeletons={numberOfSkeletons}
        numberOfSkeletonSections={numberOfSkeletonSections}
        skeletonType={skeletonType}
        useDivider={useDivider}
        useSubtitle={useSubtitle}
        useItemCount={useItemCount}
        horizontalCard={horizontalCard}
        leftAlignCarousel={leftAlignCarousel}
        showCarouselGradient={showCarouselGradient}
        extendGuttersForCarousel={extendGuttersForCarousel}
        className={className}
        treatment={treatment}
      />
    )
  }
}

const recBottomSpacing = (BaseComponent) => (props) => (
  <BaseComponent
    {...props}
    className={classNames(props.className, styles.bottomPadding)}
  />
)

export default recBottomSpacing(RecommendationBlock)
