/**
 * @deprecated please consider using 'StorageAccessor'
 */
export const setItem = (key, value) => {
  console.warn('Please consider using StorageAccessor to setItem')
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(key, value)
  }
}
/**
 * @deprecated please consider using 'StorageAccessor'
 */
export const getItem = (key) => {
  console.warn('Please consider using StorageAccessor to getItem')
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem(key)
  }
  return null
}

export const removeItem = (key) => {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem(key)
    window.sessionStorage.removeItem(key)
    window[buildWindowKey(key)] = undefined
  }
}

export const StorageAccessor = {
  sessionStorage: {
    setObj(key, value, minutesToExpire = null) {
      if (typeof window !== 'undefined') {
        window.sessionStorage.setItem(
          key,
          setupCacheObj(value, minutesToExpire),
        )
      }
    },
    getObj(key) {
      if (typeof window !== 'undefined') {
        return getCacheObj(window.sessionStorage.getItem(key), key)
      }
      return null
    },
    removeItem(key) {
      if (typeof window !== 'undefined') {
        window.sessionStorage.removeItem(key)
      }
    },
  },
  localStorage: {
    setObj(key, value, minutesToExpire = null) {
      if (typeof window !== 'undefined') {
        try {
          window.localStorage.setItem(
            key,
            setupCacheObj(value, minutesToExpire),
          )
        } catch (error) {
          //this is very unlikely to ever actually happen, but just in case
          window.localStorage.clear()
          console.error('local storage was full, clearing', error)
        }
      }
    },
    getObj(key) {
      if (typeof window !== 'undefined') {
        const roaw = window.localStorage.getItem(key)
        return getCacheObj(roaw, key)
      }
      return null
    },
    removeItem(key) {
      if (typeof window !== 'undefined') {
        window.localStorage.removeItem(key)
      }
    },
  },
  windowStorage: {
    setObj(key, value, minutesToExpire = null) {
      if (typeof window !== 'undefined') {
        window[buildWindowKey(key)] = setupCacheObj(value, minutesToExpire)
      }
    },
    getObj(key) {
      if (typeof window !== 'undefined') {
        const builtKey = buildWindowKey(key)
        return getCacheObj(window[builtKey], builtKey)
      }
      return null
    },
    removeItem(key) {
      if (typeof window !== 'undefined') {
        window[buildWindowKey(key)] = undefined
      }
    },
  },
}

const buildWindowKey = (rawKey) => {
  return `windowStorage_${rawKey}`
}

const setupCacheObj = (value, minutesToExpire = null) => {
  const cacheObj = { data: value }
  if (minutesToExpire) {
    const now = new Date()
    cacheObj.TimeToExpire = new Date(now.getTime() + minutesToExpire * 60000)
  }
  return JSON.stringify(cacheObj)
}
const getCacheObj = (rawJson, key) => {
  try {
    const cacheObj = JSON.parse(rawJson)
    if (
      !cacheObj ||
      (cacheObj.TimeToExpire && new Date(cacheObj.TimeToExpire) < new Date())
    ) {
      removeItem(key)
      return null
    }
    return cacheObj.data
  } catch (e) {
    console.error('Failed to get json data for key ' + key)
    console.error(e)
    return null
  }
}
