import getFitmentDetailsFromSelectedFacets from 'Utilities/CustomerProjectUtilities/getFitmentDetailsFromSelectedFacets'
import createZustandContext from 'Utilities/Zustand/createZustandContext'

import UserSpecificProductDataStoreWiring from './Wiring/UserSpecificProductDataStoreWiring'

const productCardContext = createZustandContext(
  ({ set, get, api, initialProps }) => {
    return {
      // the various sku bases that we're listening to in order to get data for
      trackedSkuBases: [],
      addTrackedSkuBases: (skuBases) => {
        set((state) => {
          const uniqueSkuBases = new Set([
            ...state.trackedSkuBases,
            ...skuBases,
          ])
          if (uniqueSkuBases.length == state.trackedSkuBases.length) {
            return state // no change, we've got duplicate calls
          } else {
            return { trackedSkuBases: [...uniqueSkuBases] }
          }
        })
      },

      trackedSkuVariants: [],
      addTrackedSkuVariants: (skuVariants) => {
        set((state) => {
          const uniqueSkuVariants = new Set([
            ...state.trackedSkuVariants,
            ...skuVariants,
          ])
          if (uniqueSkuVariants.length == state.trackedSkuVariants.length) {
            return state // no change, we've got duplicate calls
          } else {
            return { trackedSkuVariants: [...uniqueSkuVariants] }
          }
        })
      },
      // useful for where we want to delay updates for some period of time
      // for example, it's wasteful to update products when a customer is selecting facets on mobile
      // so we disable updates for a period of time
      areUpdatesAllowed: true,
      setAreUpdatesAllowed: (areAllowed) =>
        set({ areUpdatesAllowed: areAllowed }),

      // todo pricing data! (this is ALL over the card, so it's harder to decouple/rerender only a portion)
      isSkuBaseInCartDictionary: {},
      setCartDataForSkuBases: (skuBaseInCartDictionary) => {
        set({ isSkuBaseInCartDictionary: skuBaseInCartDictionary })
      },

      isSkuBaseInAnyListDictionary: {},
      setListDataForSkuBases: (skuBaseInListDictionary) => {
        set({ isSkuBaseInAnyListDictionary: skuBaseInListDictionary })
      },

      fitmentDataBySkuBase: {},
      addFitmentDataForSkuBase: (skuBase, fitmentData) => {
        set((state) => ({
          fitmentDataBySkuBase: {
            ...state.fitmentDataBySkuBase,
            [skuBase]: fitmentData,
          },
        }))
      },

      shippingDataBySkuBasesOrVariants: {},
      addShippingDataForSkuBasesOrVariants: (
        skuBaseOrVariant,
        shippingData,
      ) => {
        set((state) => ({
          shippingDataBySkuBasesOrVariants: {
            ...state.shippingDataBySkuBasesOrVariants,
            [skuBaseOrVariant]: shippingData,
          },
        }))
      },

      productActivityDataBySkuBasesOrVariants: {},
      addProductActivityDataForSkuBasesOrVariants: (
        skuBaseOrVariant,
        productActivityData,
      ) => {
        set((state) => ({
          productActivityDataBySkuBasesOrVariants: {
            ...state.productActivityDataBySkuBasesOrVariants,
            [skuBaseOrVariant]: productActivityData,
          },
        }))
      },

      promoDataBySkuBase: {},
      addPromoDataForSkuBase: (skuBase, promo) => {
        set((state) => ({
          promoDataBySkuBase: {
            ...state.promoDataBySkuBase,
            [skuBase]: promo,
          },
        }))
      },

      flush: () => {
        // if you're navigating, we need to clear this out so it doesn't cause a memory leak
        set({
          trackedSkuBases: [],
          trackedSkuVariants: [],
          fitmentDataBySkuBase: {},
          shippingDataBySkuBasesOrVariants: {},
          promoDataBySkuBase: {},
        })
      },

      fitmentDetailsFromFitmentFacet: {},
      setFitmentDetailsFromFitmentFacet: (fitmentFacet) => {
        const fitmentDetails = fitmentFacet?.fitmentDetails
        set(() => ({
          fitmentDetailsFromFitmentFacet: fitmentDetails,
        }))
      },

      fitmentDetailsFromSelectedFacets: {},
      setFitmentDetailsFromSelectedFacets: (selectedFacets) => {
        const fitmentDetails =
          getFitmentDetailsFromSelectedFacets(selectedFacets)

        set(() => ({
          fitmentDetailsFromSelectedFacets: fitmentDetails,
        }))
      },
    }
  },
  {
    name: 'UserSpecificProductDataStore',
  },
)

// synchronize the carts, lists, and more!
productCardContext.Wiring = UserSpecificProductDataStoreWiring

export const UserSpecificProductDataProvider = productCardContext.Provider

export const useUserSpecificProductDataStore = productCardContext.useStore

export const useSkuBaseFitmentData = (skuBase) =>
  useUserSpecificProductDataStore(
    (state) => state.fitmentDataBySkuBase[skuBase],
  )

export const useSkuBasesOrVariantsShippingData = (skuBasesOrVariants) =>
  useUserSpecificProductDataStore(
    (state) => state.shippingDataBySkuBasesOrVariants[skuBasesOrVariants],
  )

export const useSkuBasesProductActivityData = (skuBasesOrVariants) =>
  useUserSpecificProductDataStore(
    (state) =>
      state.productActivityDataBySkuBasesOrVariants?.[skuBasesOrVariants] ||
      null,
  )

export const useSkuBasePromoData = (skuBase) =>
  useUserSpecificProductDataStore((state) => state.promoDataBySkuBase[skuBase])

export const useIsSkuBaseInAnyList = (skuBase) =>
  useUserSpecificProductDataStore(
    (state) => state.isSkuBaseInAnyListDictionary[skuBase] || false,
  )

export const useIsSkuBaseInCart = (skuBase) =>
  useUserSpecificProductDataStore(
    (state) => state.isSkuBaseInCartDictionary[skuBase] || false,
  )
