import { useEffect } from 'react'

const KEYCODES = {
  escape: 'Escape',
}

export default function useKeypress(key, action) {
  useEffect(() => {
    function onKeyup(e) {
      if (e.key === key || e.keyCode === key) action()
    }
    window.addEventListener('keyup', onKeyup)
    return () => window.removeEventListener('keyup', onKeyup)
  }, [])
}

export { KEYCODES }
