import React, { useEffect, useState } from 'react'

export default function useLockedAsyncEffect(asyncEffect, deps) {
  const [locked, setLocked] = useState(false)
  useEffect(() => {
    if (locked) {
      return
    }

    setLocked(true)

    asyncEffect()
      .then(() => {
        setLocked(false)
      })
      .catch(() => {
        setLocked(false)
      })
  }, deps) //if you include locked here you will cause an infinite loop
}
