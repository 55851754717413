import React from 'react'

import ClassNames from 'classnames'

import ColorFinder from 'Utilities/ColorFinder'

import Badge from 'Atoms/Badge'
import Svg from 'Atoms/Svg'
import Typography from 'Atoms/Typography'

import useNavigationState from '../../../../Hooks/useAppInstall/useNavigationState'
import styles from './ControlBarItem.module.scss'
import styled from 'styled-components'

const ControlBarItem = ({
  id,
  children,
  optional,
  onClick,
  isActive,
  href,
  icon,
  label,
  isCart,
  badge,
  badgeProps,
  invert,
  ...props
}) => {
  // eclusive XOR
  const iconLabelRequirement = !label && !icon
  const { showNavigation } = useNavigationState()
  if (
    (iconLabelRequirement && !children) ||
    (!iconLabelRequirement && children)
  ) {
    return false
  }
  const component = href ? 'a' : 'div'
  const classNames = ClassNames(
    styles.controlBarItemPanel,
    optional === true ? styles.mobileLimitedHide : null,
  )
  const brand = isActive
    ? invert
      ? 'secondarypalette_blue'
      : 'secondarypalette_purple'
    : null
  const { color: speedwayPurple } = ColorFinder(
    null,
    null,
    invert ? 'secondarypalette_blue' : 'secondarypalette_purple',
  )

  let contents = children ? (
    children
  ) : (
    <>
      <Icon icon={icon} brand={brand} />
      <Label label={label} brand={brand} />
    </>
  )

  if (badge && badgeProps && badgeProps.number && badgeProps.number > 0) {
    const badgeSettings = {
      ...badgeProps,
      number: badgeProps.removeNumber ? 0 : badgeProps.number,
    }
    contents = (
      <>
        <Badge {...badgeSettings}>
          <Icon icon={icon} brand={brand} />
        </Badge>
        <Label label={label} brand={brand} />
      </>
    )
  }

  const onClickWrapper = (e, onClick) => {
    if (href) {
      showNavigation()
    }

    if (onClick) onClick(e)
  }

  return (
    <StyledControlBarItem
      as={component}
      className={classNames}
      data-testid={label + 'ControlBar'}
      onClick={(e) => onClickWrapper(e, onClick)}
      href={href}
      isActive={isActive}
      indicatorColor={speedwayPurple}
    >
      {contents}
    </StyledControlBarItem>
  )
}

const Icon = ({ icon, brand }) => {
  if (!icon) {
    return false
  }

  return (
    <div>
      <Svg icon={icon} brand={brand} tone={'normal'} size={1.25} />
    </div>
  )
}
const Label = ({ label, brand }) => {
  if (!label) {
    return false
  }

  return (
    <div>
      <Typography tone={'normal'} brand={brand} size={0.75} font={'bold'}>
        {label}
      </Typography>
    </div>
  )
}

const StyledControlBarItem = styled.div`
  ${(props) =>
    props.isActive
      ? `border-top: 3px solid ${props.indicatorColor};`
      : 'border-top: 1px solid #d3d3d3;'}
`

export default ControlBarItem
