import React from 'react'

import ArticleCard from 'Containers/ArticleCard'

import { getSuperMarketAffinity } from 'Utilities/Instrumentation/SegmentUtilities/segmentFunctions'

import useRecentHistory from 'Hooks/useRecentHistory'

import Button from 'Molecules/Button/Button'
import LandingPageImageLink from 'Molecules/LandingPageImageLink'
import LandingPageLink from 'Molecules/LandingPageLink'

import ProductCard from '../../Components/ProductCard'
import EventCard from '../EventCard/EventCard'
import styles from './RecommendationCard.module.scss'

const RecommendationCard = ({
  rec,
  horizontal,
  index,
  recBlock,
  dataTestId,
  treatment,
}) => {
  const segmentEvent = {
    event: 'Clicked',
    properties: { element: recBlock.title },
  }

  // reviewCount, strikeThroughPriceString, percentOffString, horizontal

  if (
    rec.publicSkuBaseDetails &&
    rec.publicSkuBaseDetails.availability !== 'Discontinued' &&
    rec.publicSkuBaseDetails.availability !== 'NotAvailable'
  ) {
    return (
      <div className={horizontal ? styles.horizontalCard : ''}>
        <ProductCard
          productPageUrl={rec.publicSkuBaseDetails.productPageUrl}
          imageUrl={rec.publicSkuBaseDetails.thumbnailImageLink}
          displayTitle={rec.publicSkuBaseDetails.displayTitle}
          brand={rec.publicSkuBaseDetails.brand}
          category={rec.publicSkuBaseDetails.segmentCategorization}
          rating={rec.publicSkuBaseDetails.rating}
          treatment={treatment}
          reviewCount={rec.publicSkuBaseDetails.reviewCount}
          salePriceString={rec.publicSkuBaseDetails.pricing?.salePriceString}
          strikeThroughPriceString={
            rec.publicSkuBaseDetails.pricing?.strikeThroughPriceString
          }
          percentOffString={rec.publicSkuBaseDetails.pricing?.percentOffString}
          skuBaseNumber={rec.skuBase}
          horizontal={horizontal}
          skuVariant={rec.publicSkuVariantDetails}
          index={index}
          dataTestId={dataTestId}
          segmentEvent={{
            event: 'Product Clicked',
            properties: {
              list_id: dataTestId,
              product_id: rec.skuBase,
              sku: rec.skuBase,
              category: rec.publicSkuBaseDetails.segmentCategorization,
              name: rec.publicSkuBaseDetails.displayTitle,
              brand: rec.publicSkuBaseDetails.brand,
              variant: rec.publicSkuVariantDetails?.skuVariantNumber ?? null,
              price: rec.publicSkuBaseDetails.pricing?.salePrice,
              position: index,
              url: rec.publicSkuBaseDetails.productPageUrl,
              image_url: rec.publicSkuBaseDetails.thumbnailImageLink,
              availability: rec.publicSkuBaseDetails.availability,
              rating: rec.publicSkuBaseDetails.reviewCount
                ? rec.publicSkuBaseDetails.rating
                : 0,
              reviews: rec.publicSkuBaseDetails.reviewCount,
              audience_affinity: getSuperMarketAffinity(
                rec.publicSkuBaseDetails,
              ),
              miso: {
                miso_id: recBlock.misoId,
              },
            },
          }}
          skuBaseDetails={rec.publicSkuBaseDetails}
          onClick={() => {
            if (recBlock.misoId) {
              useRecentHistory.SetRecentMisoId(recBlock.misoId, rec.skuBase)
            }
          }}
        />
      </div>
    )
  } else if (rec.articleDetails) {
    return rec.articleDetails.url &&
      rec.articleDetails.title &&
      rec.articleDetails.description &&
      rec.articleDetails.authors ? (
      <div
        className={
          horizontal
            ? styles.horizontalCard
            : recBlock.useReducedArticleCard
              ? styles.reducedArticleCard
              : styles.articleCard
        }
      >
        <ArticleCard
          article={rec.articleDetails}
          horizontal={horizontal}
          dataTestId={dataTestId}
          segmentEvent={{
            event: 'Article Clicked',
            properties: {
              list_id: dataTestId,
              article_id: rec.articleDetails.id,
              title: rec.articleDetails.title,
              author: rec.articleDetails.authors,
              position: index,
              url: rec.articleDetails.url,
              image_url: rec.articleDetails.articleThumbnailUrl,
            },
          }}
          border={recBlock.useBorders ?? false}
          useReducedArticleCard={recBlock.useReducedArticleCard ?? false}
        />
      </div>
    ) : (
      false
    )
  } else if (rec.landingPageDetails) {
    if (rec.landingPageDetails.thumbnailImageUrl) {
      return (
        <LandingPageImageLink
          landingPageDetails={rec.landingPageDetails}
          dataTestId={dataTestId}
          segmentEvent={segmentEvent}
          isBrandImage={
            rec.landingPageDetails.field &&
            rec.landingPageDetails.field === 'GA_Brand'
          }
          isRaceTypeImage={
            rec.landingPageDetails.field &&
            rec.landingPageDetails.field === 'GA_RaceType'
          }
        />
      )
    } else {
      return (
        <LandingPageLink
          link={{
            ...rec.landingPageDetails,
            url:
              rec.landingPageDetails?.url ??
              rec.landingPageDetails?.facetOptionLink?.url,
            noFollow:
              rec.landingPageDetails?.noFollow ??
              rec.landingPageDetails?.facetOptionLink?.noFollow,
          }}
          dataTestId={dataTestId}
          segmentEvent={segmentEvent}
          isBrandImage={
            rec.landingPageDetails.field &&
            rec.landingPageDetails.field === 'GA_Brand'
          }
          isRaceTypeImage={
            rec.landingPageDetails.field &&
            rec.landingPageDetails.field === 'GA_RaceType'
          }
        />
      )
    }
  } else if (rec.eventId) {
    return (
      <EventCard
        event={rec}
        dataTestId={dataTestId}
        segmentEvent={segmentEvent}
      />
    )
  } else if (rec.term) {
    return (
      <Button
        className={styles.search_term}
        intent={'subdued'}
        size={'small'}
        icon={'magnifyingglass'}
        text={rec.term}
        href={rec.url}
        segmentEvent={segmentEvent}
        dataTestId={dataTestId}
      />
    )
  } else {
    return false
  }
}

export default RecommendationCard
