import React from 'react'

import AutoComplete from 'Atoms/Form/Elements/AutoComplete/AutoComplete'

const YmmDropdown = ({
  options,
  onChange,
  selectedValue,
  label,
  addonLabel,
  placeholder,
  prioritizedMakes,
  selectRef,
  isDisabled,
}) => {
  const formattedOptions = []

  prioritizedMakes?.forEach((data) => {
    formattedOptions.push({ label: data.displayName, value: data.optionId })
  })

  options?.forEach((option) => {
    formattedOptions.push({
      label: option.displayName,
      value: option.optionId,
    })
  })

  return (
    <AutoComplete
      options={formattedOptions}
      onChange={onChange}
      selectedValue={selectedValue}
      isDisabled={isDisabled}
      boldLabel={true}
      label={label}
      addonLabel={addonLabel}
      placeholder={placeholder}
      ref={selectRef}
      useNativeSelect={false}
    />
  )
}

export default YmmDropdown
