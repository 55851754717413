import { useEffect } from 'react'

import { validateZip } from 'Utilities/Location/Location'

import { useUserStore } from '../../Stores/UserStore/UserStore'
import { IsUserBot } from '../../Utilities/CookieUtilties/UserCookieUtilities'

const GeoLocation = ({ enabled = true }) => {
  const updateUserZipCode = useUserStore((x) => x.updateUserZipCode)
  const updateUserIsInternational = useUserStore(
    (x) => x.updateUserIsInternational,
  )
  const hydrated = useUserStore((x) => x.context.hydrated)
  const zipCode = useUserStore((x) => x.context.zipCode)

  const requestGeoLocation = async () => {
    try {
      const res = await fetch(
        'https://api.ipstack.com/check?access_key=d1f2dc908712c8edc8d9d38a1cec0367',
      )
      const data = await res.json()
      var [zip, isValidZip] = validateZip(data?.zip)
      if (!isValidZip) {
        throw `zip code from ip stack is not valid: ${data?.zip}`
      }

      updateUserZipCode(`${zip}`)
      updateUserIsInternational(data.country_code !== 'US')
    } catch (err) {
      console.error('error getting geolocation', err)
    }
  }

  useEffect(() => {
    if (hydrated && !zipCode && enabled && !IsUserBot()) {
      requestGeoLocation()
    }
  }, [hydrated, enabled])

  return false
}

export default GeoLocation
