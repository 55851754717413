export const DataSets = [
  {
    imageSource:
      'https://content.speedwaymotors.com/PageNotFoundImages/DPV-5287%20Error%20Page%20Graphics%20Missing2.jpg',
    h3: "There's nothing in here.",
    h4: 'Find something to fill the box:',
  },
  {
    imageSource:
      'https://content.speedwaymotors.com/PageNotFoundImages/DPV-5287%20Error%20Page%20Graphics%20Dale%20car.jpg',
    h3: "It's a shame The Dale was never real. Just like this page.",
    h4: 'Head to an actual, functioning page here:',
  },
  {
    imageSource:
      'https://content.speedwaymotors.com/PageNotFoundImages/DPV-5287%20Error%20Page%20Graphics%20race3.jpg',
    h3: "Something's not right.",
    h4: 'Get back on track with one of these pages:',
  },
  {
    imageSource:
      'https://content.speedwaymotors.com/PageNotFoundImages/DPV-5287%20Error%20Page%20Graphics%20Missing1.jpg',
    h3: "There's nothing in here.",
    h4: 'Find something to fill the box:',
  },
  {
    imageSource:
      'https://content.speedwaymotors.com/PageNotFoundImages/DPV-5287%20Error%20Page%20Graphics%20Lost3.jpg',
    h3: "Welcome to Nowheresville. This page doesn't exist.",
    h4: 'Head back to civilization:',
  },
  {
    imageSource:
      'https://content.speedwaymotors.com/PageNotFoundImages/DPV-5287%20Error%20Page%20Graphics%20Model%20T%20Flames.jpg',
    h3: "Hmmm... that doesn't look right.",
    h4: "Like a Model T with a factory flame job, this page doesn't exist.",
    addText: 'Try one of these:',
  },
  {
    imageSource:
      'https://content.speedwaymotors.com/PageNotFoundImages/DPV-5287%20Error%20Page%20Graphics%20race2.jpg',
    h3: "As luck would have it, this page doesn't exist.",
    h4: 'Try one of these:',
  },
  {
    imageSource:
      'https://content.speedwaymotors.com/PageNotFoundImages/DPV-5287%20Error%20Page%20Graphics%20Secret.jpg',
    h3: "There's nothing to see here.",
    h4: 'Move along:',
  },
  {
    imageSource:
      'https://content.speedwaymotors.com/PageNotFoundImages/DPV-5287%20Error%20Page%20Graphics%20Lost1.jpg',
    h3: "Welcome to Nowheresville. This page doesn't exist.",
    h4: 'Head back to civilization:',
  },
  {
    imageSource:
      'https://content.speedwaymotors.com/PageNotFoundImages/DPV-5287%20Error%20Page%20Graphics%20Muscle%20Mashup.jpg',
    h3: "Like a Dodgmaro Mustiac GTO, this page doesn't exist.",
    h4: 'Try one of these:',
  },
  {
    imageSource:
      'https://content.speedwaymotors.com/PageNotFoundImages/DPV-5287%20Error%20Page%20Graphics%20race1.jpg',
    h3: "Like a dirt-track F1 car, this page doesn't exist.",
    h4: 'Try one of these:',
  },
  {
    imageSource:
      'https://content.speedwaymotors.com/PageNotFoundImages/DPV-5287%20Error%20Page%20Graphics%20Lost2.jpg',
    h3: "Welcome to Nowheresville. This page doesn't exist.",
    h4: 'Head back to civilization:',
  },
]

export const CategoryLinks = [
  {
    displayValue: 'Chassis & Suspension',
    url: '/shop/chassis-and-suspension~2-16',
  },
  {
    displayValue: 'Air & Fuel Delivery',
    url: '/shop/air-and-fuel-delivery~2-12',
  },
  {
    displayValue: 'Engine',
    url: '/shop/engine~2-10',
  },
  {
    displayValue: 'Brakes',
    url: '/shop/brakes~2-3',
  },
  {
    displayValue: 'Steering',
    url: '/shop/steering~2-15',
  },
  {
    displayValue: 'Transmission',
    url: '/shop/transmission~2-20',
  },
  {
    displayValue: 'Wheel & Tire',
    url: '/shop/wheel-and-tire~2-17',
  },
  {
    displayValue: 'Driveline & Axles',
    url: '/shop/driveline-and-axles~2-5',
  },
  {
    displayValue: 'Exterior, Accessories & Trim',
    url: '/shop/exterior-accessories-and-trim~2-2',
  },
  {
    displayValue: 'Ignition, Charging & Starting',
    url: '/shop/ignition-charging-and-starting~2-14',
  },
]
