import React from 'react'

import {
  Checkbox,
  DataList,
  Fieldset,
  Quantity,
  Radio,
  Select,
  Switch,
  TextArea,
  Textbox,
} from 'Atoms/Form/Elements'

const Form = ({ onSubmit, id, children, className }) => {
  if (!onSubmit || typeof onSubmit !== 'function') {
    console.error(`A form element does not have an onsubmit function`)
    return false
  }
  return (
    <form onSubmit={onSubmit} id={id} className={className}>
      {children}
    </form>
  )
}

Form.CheckboxGroup = Checkbox
Form.DataList = DataList
Form.Fieldset = Fieldset
Form.Radio = Radio
Form.Select = Select
Form.Switch = Switch
Form.TextArea = TextArea
Form.Textbox = Textbox
Form.Quantity = Quantity

export default Form
